import {
  Autofill,
  IBasePickerStyleProps,
  IBasePickerStyles,
  IStyleFunctionOrObject,
  ITag,
} from "@fluentui/react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useAddNewOriginMutation, useGetAllOriginsQuery } from "../../api/cockpit";
import { Tag } from "../../types/usecase";
import { TagPickerWithCreation } from "../common/TagPickerWithCreation";
import { onChangeAddNew, tagToAPIModel } from "./helpers";

interface OriginsProps {
  styles?: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles> | undefined;
  currentOrigins?: Tag[];
  onChange?: ((items?: ITag[] | undefined) => void) | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement | Autofill> | undefined;
  hoverStyle?: boolean;
}

export const Origins: FunctionComponent<OriginsProps> = (props: OriginsProps) => {
  const { t } = useTranslation();
  const originsData = useGetAllOriginsQuery();
  const [addOrigin] = useAddNewOriginMutation();

  return (
    <TagPickerWithCreation
      {...props}
      tags={originsData.data ? originsData.data.map(tagToAPIModel) : []}
      selectedItems={props.currentOrigins?.map((t) => ({ key: t.id, name: t.label }))}
      removeButtonAriaLabel={t("remove_origin")}
      selectionAriaLabel={t("select_origin")}
      onChange={(items?: ITag[]) => {
        onChangeAddNew(addOrigin, props.onChange, items);
      }}
      inputProps={
        props.currentOrigins?.length === 0
          ? { placeholder: t("origin_tagpicker_placeholder") }
          : undefined
      }
      hoverStyle={props.hoverStyle}
    />
  );
};
