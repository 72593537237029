import { FunctionComponent, MouseEvent, useEffect } from "react";
import { Task, MissionData } from "../../../types/missions";
import { hostStyles } from "../../../helpers/commonStyles";
import { DirectionalHint, Icon, IIconStyles, TooltipDelay, TooltipHost } from "@fluentui/react";
import useState from "react-usestateref";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
} from "@fluentui/react/lib/DetailsList";
import { Dataset } from "../../../types/dataset";
import { getDatasetName } from "../../../helpers/mission-helper";
import moment from "moment";
import { copyAndSort, filterColumns } from "../../../helpers/utils";
import { useTranslation } from "react-i18next";

const iconStyle: Partial<IIconStyles> = {
  root: {
    fontSize: 16,
    marginRight: "10px",
    color: "#000000",
    cursor: "pointer",
  },
};

export interface TasksListState {
  setTaskId(id: string | undefined): void;
  toggleHideDeleteTaskDialog: any;
  startPauseTask(item: Task): void;
  tasks: Task[];
  mission?: MissionData;
  handleModalData(mode: string, task: Task, activate?: boolean): void;
}

export const TaskList: FunctionComponent<TasksListState> = (props: TasksListState) => {
  const { t } = useTranslation();
  const [allTasks, setAllTasks, allTasksRef] = useState(props.tasks);
  const [, , missionRef] = useState(props.mission);

  useEffect(() => {
    setAllTasks(props.tasks);
  }, [props, setAllTasks]);

  const displayDatasetName = (datasets: Dataset[], id: string) => {
    let name = getDatasetName(datasets ? datasets : [], id);
    if (name === "") {
      name = "-";
    }
    return name;
  };

  const columnDataArray = [
    { name: t("tasks_datatable_dataset"), fieldName: "dataset_id" },
    {
      name: t("tasks_datatable_dimension"),
      fieldName: "quality_dimension",
    },
    {
      name: t("tasks_datatable_responsible"),
      fieldName: "responsibles",
    },
    { name: t("tasks_datatable_startdate"), fieldName: "start_date" },
    { name: t("tasks_datatable_enddate"), fieldName: "end_date" },
    { name: t("tasks_datatable_status"), fieldName: "percentage_completed" },
    { name: t("tasks_datatable_action"), fieldName: "is_active" },
  ];
  const onColumnSortClick = (ev: MouseEvent<HTMLElement>, column: IColumn): void => {
    const { newColumns, currColumn } = filterColumns(columns ? columns : [], column);
    const newItems: Task[] = copyAndSort(
      allTasksRef.current,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    setColumns(newColumns);
    setAllTasks(newItems);
  };

  const tempColumns: IColumn[] = [];
  columnDataArray.forEach((columnData, index) => {
    tempColumns.push({
      key: `column${index}`,
      name: columnData.name,
      fieldName: columnData.fieldName,
      minWidth: 130,
      maxWidth: 150,
      isRowHeader: index in [0] ? true : false,
      onColumnClick: onColumnSortClick,
      onRender: (item: Task) => {
        switch (index) {
          case 0:
            return (
              <>
                {displayDatasetName(
                  missionRef.current?.datasets ? missionRef.current.datasets : [],
                  item.dataset_id
                )}
              </>
            );
          case 1:
            return <>{t(item.quality_dimension)}</>;
          case 2:
            return <>{item.responsibles ? item.responsibles.map((r) => r.name).join(", ") : "-"}</>;
          case 3:
            return <>{moment(item.start_date).format("DD.MM.YYYY")}</>;
          case 4:
            return (
              <>
                {item.is_active && item.is_recurring
                  ? moment(item.end_date).format("DD.MM.YYYY")
                  : "-"}
              </>
            );
          case 5:
            return <>{`${item.percentage_completed ? item.percentage_completed * 100 : 0} %`}</>;
          case 6:
            return (
              <>
                <TooltipHost
                  content={t("edit")}
                  delay={TooltipDelay.zero}
                  directionalHint={DirectionalHint.bottomCenter}
                  styles={hostStyles}
                >
                  <Icon
                    styles={iconStyle}
                    iconName="Edit"
                    onClick={() => props.handleModalData("edit", item)}
                    aria-label={t("edit")}
                  />
                </TooltipHost>
                <TooltipHost
                  content={t("delete")}
                  delay={TooltipDelay.zero}
                  directionalHint={DirectionalHint.bottomCenter}
                  styles={hostStyles}
                >
                  <Icon
                    styles={iconStyle}
                    iconName="Delete"
                    onClick={() => {
                      props.setTaskId(item?.id);
                      props.toggleHideDeleteTaskDialog(true);
                    }}
                    aria-label={t("delete")}
                  />
                </TooltipHost>
                {item.is_active ? (
                  item.is_recurring ? (
                    <TooltipHost
                      content={t("pause")}
                      delay={TooltipDelay.zero}
                      directionalHint={DirectionalHint.bottomCenter}
                      styles={hostStyles}
                    >
                      <Icon
                        styles={iconStyle}
                        iconName="Pause"
                        onClick={() => props.startPauseTask(item)}
                        aria-label={t("pause")}
                      />
                    </TooltipHost>
                  ) : null
                ) : (
                  <TooltipHost
                    content={item.is_recurring ? t("start") : t("activate")}
                    delay={TooltipDelay.zero}
                    directionalHint={DirectionalHint.bottomCenter}
                    styles={hostStyles}
                  >
                    <Icon
                      styles={iconStyle}
                      iconName="Play"
                      onClick={() => {
                        item.is_recurring
                          ? props.startPauseTask(item)
                          : props.handleModalData("edit", item, true);
                      }}
                      aria-label={item.is_recurring ? t("start") : t("activate")}
                    />
                  </TooltipHost>
                )}
              </>
            );
        }
      },
    });
  });
  const [columns, setColumns] = useState<IColumn[]>(tempColumns);
  return (
    <DetailsList
      items={allTasks ? allTasks : []}
      columns={columns}
      selectionMode={SelectionMode.none}
      setKey="none"
      layoutMode={DetailsListLayoutMode.justified}
      isHeaderVisible={true}
    />
  );
};
