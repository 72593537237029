import { CockpitTemplate } from "../types/cockpit";
import { DataAssetQuery } from "../types/dataasset";
import {
  DataAsset,
  Origin,
  AddingDatasetToUseCase,
  UpdatedDataAssetList,
  CreateDataAsset,
} from "../types/dataasset";
import { CreateUseCase, UseCase, Tag } from "../types/usecase";
import { api_base, dqcApi } from "./dqcQuery";

export const cockpitApi = dqcApi.injectEndpoints({
  endpoints: (builder) => ({
    createDataAsset: builder.mutation({
      query: (dataAsset: CreateDataAsset) => ({
        url: `/${api_base}/data_assets/`,
        method: "POST",
        data: dataAsset,
      }),
      invalidatesTags: ["DataAssets", "Origins"],
    }),
    getDataAssets: builder.query<DataAsset[], any>({
      query: (dataAssetQuery: DataAssetQuery) => ({
        url: `/${api_base}/data_assets/`,
        method: "get",
        params: dataAssetQuery,
      }),
      providesTags: ["DataAssets"],
    }),
    createUseCases: builder.mutation({
      query: (useCase: CreateUseCase) => ({
        url: `/${api_base}/use_cases/`,
        method: "POST",
        data: useCase,
      }),
      invalidatesTags: ["UseCases", "Tags", "DataAssetsList", "DataAssets"],
    }),
    getAllUseCases: builder.query<UseCase[], void>({
      query: () => ({ url: `/${api_base}/use_cases/`, method: "get" }),
      providesTags: ["UseCases"],
    }),
    getAsset: builder.query<DataAsset, string>({
      query: (id: string) => ({
        url: `/${api_base}/data_assets/${id}/`,
        method: "get",
      }),
      providesTags: ["Asset"],
    }),
    getAllOrigins: builder.query<Origin[], void>({
      query: () => ({
        url: `/${api_base}/data_assets/origins?all=true`,
        method: "get",
        providesTags: ["Origins"],
      }),
    }),
    addNewOrigin: builder.mutation({
      query: (origin: Origin) => ({
        url: `/${api_base}/data_assets/origins`,
        method: "post",
        data: origin,
      }),
      invalidatesTags: ["Origins"],
    }),
    updateAsset: builder.mutation({
      query: (asset: DataAsset) => ({
        url: `/${api_base}/data_assets/${asset.id}/`,
        method: "PUT",
        data: asset,
      }),
      invalidatesTags: ["Asset", "DataAssetsList", "DataAssets"],
    }),
    getUseCase: builder.query<UseCase, string>({
      query: (id: string) => ({
        url: `/${api_base}/use_cases/${id}/`,
        method: "get",
      }),
      providesTags: ["UseCase"],
    }),
    updateUseCase: builder.mutation({
      query: (useCase: UseCase) => ({
        url: `/${api_base}/use_cases/${useCase.id}/`,
        method: "PUT",
        data: useCase,
      }),
      invalidatesTags: ["UseCase", "UseCases"],
    }),
    getAllTags: builder.query<Tag[], void>({
      query: () => ({
        url: `/${api_base}/use_cases/tags?all=true`,
        method: "get",
        providesTags: ["Tags"],
      }),
    }),
    addNewTag: builder.mutation({
      query: (origin: Tag) => ({
        url: `/${api_base}/use_cases/tags`,
        method: "post",
        data: origin,
      }),
      invalidatesTags: ["Tags"],
    }),
    deleteDataAssets: builder.mutation({
      query: (
        deleteArray: [
          {
            op: "delete";
            ids: string[];
            props: { delete_children: boolean };
          }
        ]
      ) => ({
        url: `/${api_base}/data_assets`,
        method: "patch",
        data: deleteArray,
      }),
      invalidatesTags: ["DataAssets", "DataAssetsList"],
    }),
    deleteUseCases: builder.mutation({
      query: (
        deleteArray: [
          {
            op: "delete";
            ids: string[];
          }
        ]
      ) => ({
        url: `/${api_base}/use_cases`,
        method: "patch",
        data: deleteArray,
      }),
      invalidatesTags: ["UseCases", "UseCase"],
    }),
    addDataAssetToUseCase: builder.mutation<UseCase, AddingDatasetToUseCase>({
      query: (params: AddingDatasetToUseCase) => ({
        url: `/${api_base}/use_cases/${params.useCaseId}/data-asset/`,
        method: "post",
        params: { data_asset_id: params.dataAssetId },
      }),
      invalidatesTags: ["UseCase", "Asset"],
    }),
    updateDataAssetsWithCustomOrder: builder.mutation({
      query: (
        modifyArray: [
          {
            op: "modify";
            props: { update_custom_order: boolean };
            assets_custom_order: UpdatedDataAssetList[];
          }
        ]
      ) => ({
        url: `/${api_base}/data_assets/`,
        method: "PATCH",
        data: modifyArray,
      }),
      invalidatesTags: ["DataAssets"],
    }),
    loadCockpitTemplate: builder.mutation({
      query: (cockpitTemplate: CockpitTemplate) => ({
        url: `/${api_base}/data_assets/load-cockpit-templates/`,
        method: "POST",
        data: cockpitTemplate,
      }),
      invalidatesTags: ["DataAssets"],
    }),
  }),
});

export const {
  useGetDataAssetsQuery,
  useCreateUseCasesMutation,
  useCreateDataAssetMutation,
  useGetAllUseCasesQuery,
  useGetAssetQuery,
  useGetAllOriginsQuery,
  useAddNewOriginMutation,
  useUpdateAssetMutation,
  useGetUseCaseQuery,
  useUpdateUseCaseMutation,
  useGetAllTagsQuery,
  useAddNewTagMutation,
  useDeleteDataAssetsMutation,
  useDeleteUseCasesMutation,
  useAddDataAssetToUseCaseMutation,
  useUpdateDataAssetsWithCustomOrderMutation,
  useLoadCockpitTemplateMutation,
} = cockpitApi;
