import { useParams } from "react-router-dom";
import { TopContainer } from "../../components/common/TopContainer";
import { useGetAlertQuery } from "../../api/v2/alertsNotifications";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Spinner, Stack, TextField, Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";

export const AlertDetail = () => {
  const { alertid } = useParams();
  const { t } = useTranslation();
  const { data: alert, isLoading } = useGetAlertQuery(alertid ?? skipToken);

  if (isLoading) return <Spinner title="loading" />;
  if (!alert)
    return (
      <TopContainer title={`Alert NOT FOUND`}>
        <>!!!</>
      </TopContainer>
    );
  return (
    <TopContainer title={`Alert ${alert.name}`}>
      <Stack>
        <TextField label={t("name")} value={alert.name} readOnly />
        <Text>{alert.notification_channel_id}</Text>
      </Stack>
    </TopContainer>
  );
};
