import { Stack, Text, IconButton } from "@fluentui/react";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  endAnalysisAndClearEverything,
  selectLatestQuickAnalysisQualityIssues,
  selectMessageBarVisibility,
  setMessageBarVisibility,
  setQuickAnalysisStatus,
} from "../../../store/slices/quickAnalysis";
import { t } from "i18next";
import { OverallQuality } from "./OverallQuality";
import { QualityDimension } from "./QualityDimension";
import { allDimensions } from "../../../helpers/quickAnalysis/qualityChecks/constants";
import {
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_UP,
  ArrowKey,
} from "../../../helpers/quickAnalysis/dimensionUtil";
import { useEffect } from "react";
import { handleArrowPress } from "../../../store/slices/issueNavigator";
import { ExportRulesButton } from "../ExportRulesButton";
import { selectAllRulesByFileName } from "../../../store/slices/rules";
import { selectLatestUpload } from "../../../store/slices/upload";
import { QuickAnalysisMessageBar } from "./QuickAnalysisMessageBar";

export const QualitySummary = () => {
  const issues = useAppSelector(selectLatestQuickAnalysisQualityIssues);
  const messageBarVisibility = useAppSelector(selectMessageBarVisibility);

  const rules = useAppSelector(selectAllRulesByFileName);
  const fileName = useAppSelector(selectLatestUpload)?.fileName ?? "";
  const isCustomRulesExist = !!rules.find(
    (rule) => rule.qualityTest.testFunctionName === "customRule"
  );
  const allDimensionsToDisplay = allDimensions.filter(
    (dimension) => isCustomRulesExist || dimension !== "custom"
  );
  const dispatch = useAppDispatch();
  const keydownListener = (event: Event) => {
    if (event instanceof KeyboardEvent) {
      if ([ARROW_UP, ARROW_DOWN, ARROW_LEFT, ARROW_RIGHT].includes(event.key as ArrowKey)) {
        event.preventDefault();
        event.stopPropagation();
        dispatch(handleArrowPress(event.key as ArrowKey));
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keydownListener);
    return () => document.removeEventListener("keydown", keydownListener);
  }, []);

  const handleEndAnalysis = () => {
    dispatch(setMessageBarVisibility(true));
    dispatch(endAnalysisAndClearEverything());
  };
  return (
    <>
      <Stack horizontal horizontalAlign="space-between" styles={{ root: { alignItems: "center" } }}>
        <Stack
          horizontal
          styles={{ root: { maxWidth: "70%", marginLeft: 10, alignItems: "center" } }}
        >
          <Text
            nowrap
            block
            styles={{ root: { textOverflow: "ellipsis", alignSelf: "center", paddingBottom: 4 } }}
          >
            {t(fileName)}
          </Text>
          <IconButton
            iconProps={{ iconName: "cancel" }}
            styles={{ root: { border: "none" } }}
            onClick={handleEndAnalysis}
          />
        </Stack>
        <IconButton
          iconProps={{ iconName: "settings" }}
          onClick={() => dispatch(setQuickAnalysisStatus("rules"))}
        />
      </Stack>
      {messageBarVisibility && <QuickAnalysisMessageBar rules={rules} />}
      <OverallQuality issues={issues} />
      <Stack tokens={{ childrenGap: 4 }}>
        {allDimensionsToDisplay.map((qualityDimension) => {
          return <QualityDimension key={qualityDimension} qualityDimension={qualityDimension} />;
        })}
      </Stack>
      <Stack>
        <ExportRulesButton />
      </Stack>
    </>
  );
};
