import React, { useEffect, useState } from "react";
import { sendMessageModalProps } from "../../../types/missions";
import { extractEmails, validateEmails } from "../../../helpers/mission-helper";
import { useSendNotificationRequestMutation } from "../../../api/mission";
import {
  DefaultButton,
  Icon,
  IStackStyles,
  Label,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { contentStyles, iconStyle } from "./CommonTaskStyles";
import { useGetMessagingConnectionsQuery } from "../../../api/installation";

const modalMainStyle: React.CSSProperties = {
  padding: "30px 20px 30px 20px",
};

const buttonContainer: React.CSSProperties = {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "4%",
};

const buttonSubContainer: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
};

const headerContainer: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 3% 10px 3%",
  },
};

export const SendMessageModal: React.FunctionComponent<sendMessageModalProps> = ({
  showMessageModal,
  setShowMessageModal,
}) => {
  const { t } = useTranslation();
  const [messageText, setMessageText] = useState("");
  const [media, setMedia] = useState("");
  const [link, setLink] = useState("");
  const [receiversText, setReceiversText] = useState("");
  const [validationError, setValidationError] = useState("");
  const [messagingFlag, setMessagingFlag] = useState<boolean>(false);
  const getMessagingConnections = useGetMessagingConnectionsQuery("");

  const [addNewNotification] = useSendNotificationRequestMutation();

  function processMessageFields(messageText: string, receiversText: string) {
    const message = messageText.trim();
    const receivers = extractEmails(receiversText);
    return {
      message,
      receivers,
    };
  }

  useEffect(() => {
    if (!getMessagingConnections.isLoading && getMessagingConnections.isSuccess) {
      if (getMessagingConnections.data.length === 0) {
        setValidationError(t("messaging_connector_validation"));
        setMessagingFlag(true);
      }
    }
  }, [getMessagingConnections]);

  const sendNotification = async () => {
    if (!validateEmails(receiversText)) {
      setValidationError(t("send_message_email_validation"));
    } else {
      const { message, receivers } = processMessageFields(messageText, receiversText);
      await addNewNotification({
        message: message,
        receivers: receivers,
        media_url: media,
        link_url: link,
      });
      setShowMessageModal(false);
      setMessageText("");
      setReceiversText("");
    }
  };

  return (
    <>
      <Modal
        isOpen={showMessageModal}
        onDismiss={() => setShowMessageModal(false)}
        containerClassName={contentStyles.container}
      >
        <Stack horizontal styles={headerContainer}>
          <Label>{t("send_message_title")}</Label>
          <Icon
            iconName="ChromeClose"
            onClick={() => setShowMessageModal(false)}
            styles={iconStyle}
          />
        </Stack>
        <div style={{ border: "1px solid #DDDDDD" }} />
        <div style={modalMainStyle}>
          <div>
            <TextField
              label={t("send_message_msg")}
              multiline
              rows={3}
              value={messageText}
              required={true}
              onChange={(e: any) => setMessageText(e.target.value)}
            />
          </div>
          <div>
            <TextField
              label={t("send_message_media")}
              value={media}
              onChange={(e: any) => setMedia(e.target.value)}
            />
          </div>
          <div>
            <TextField
              label={t("send_message_link")}
              value={link}
              onChange={(e: any) => setLink(e.target.value)}
            />
          </div>
          <div>
            <TextField
              label={t("send_message_receivers")}
              value={receiversText}
              required={true}
              onChange={(e: any) => setReceiversText(e.target.value)}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <span style={{ color: "red" }}>{validationError}</span>
          </div>
          <div style={buttonContainer}>
            <div style={buttonSubContainer}>
              <PrimaryButton
                text={t("send")}
                onClick={sendNotification}
                styles={{
                  root: { marginRight: "4%", whiteSpace: "noWrap" },
                }}
                disabled={!messageText || !receiversText || messagingFlag}
              />
              <DefaultButton
                text={t("cancel")}
                onClick={() => setShowMessageModal(false)}
                styles={{ root: { marginRight: "2%" } }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
