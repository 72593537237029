import React, { useEffect, useMemo, useRef } from "react";
import { Grid, GridCellRenderer } from "react-virtualized";
import IssueSliderStyles from "./IssueSlider.module.css";
import { Stack } from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectCurrentIssueNavigator,
  handleSelectIssueInSheet,
  selectIssuesByQualityDimensionFactory,
  selectCalculatedLongestIssueRowTextFromIssues,
} from "../../../store/slices/issueNavigator";
import { RootState } from "../../../store/store";
import { Arrow } from "./Arrow";
import { QuickAnalysisIssueTypes } from "../../../types/quickAnalysis";
import { getAddressRendererStyle, getStackStyle } from "./IssueSlider.style";

type SliderProps = {
  qualityDimension: QuickAnalysisIssueTypes;
  duplicateGroupId?: number;
  customCheckName?: string;
};

export const IssueSlider = ({ qualityDimension }: SliderProps) => {
  const dispatch = useAppDispatch();
  const issueNavigator = useAppSelector(selectCurrentIssueNavigator);
  const dimensionScopedSelector = useMemo(
    () => selectIssuesByQualityDimensionFactory(),
    [qualityDimension]
  );
  const issues = useAppSelector((state: RootState) =>
    dimensionScopedSelector(state, qualityDimension)
  );
  const calculatedTextWidth = useAppSelector(selectCalculatedLongestIssueRowTextFromIssues);
  const gridRef = useRef<Grid | null>(null);
  const sideBarWidth = 220;

  const scrollToPosition = (columnIndex: number) => {
    if (gridRef.current) {
      gridRef.current.scrollToCell({ rowIndex: 0, columnIndex });
    }
  };

  useEffect(() => {
    if (issueNavigator?.issueIndex !== undefined) scrollToPosition(issueNavigator?.issueIndex);
  }, [issueNavigator]);

  const AddressRenderer: GridCellRenderer = ({ columnIndex, key, style }) => {
    if (!issues?.length) return null;
    const isSelected = columnIndex === issueNavigator?.issueIndex;
    const issue = issues[columnIndex];
    return (
      <Stack
        key={key}
        style={{ ...getAddressRendererStyle(isSelected), ...style }}
        onClick={() =>
          dispatch(
            handleSelectIssueInSheet({
              issue,
            })
          )
        }
      >
        #{columnIndex + 1}
      </Stack>
    );
  };

  if (!issues?.length) return null;

  return (
    <Stack horizontal verticalAlign="center">
      <Arrow direction={"Left"} />
      <Stack
        styles={getStackStyle(sideBarWidth)}
        className={IssueSliderStyles.hideScrollbar}
        verticalAlign="center"
      >
        <Grid
          className={IssueSliderStyles.hideScrollbar}
          ref={gridRef}
          width={sideBarWidth}
          height={20}
          rowCount={1}
          columnCount={issues.length}
          rowHeight={20}
          columnWidth={calculatedTextWidth}
          cellRenderer={AddressRenderer}
        />
      </Stack>
      <Arrow direction={"Right"} />
    </Stack>
  );
};
