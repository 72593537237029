import { DataConnector, DataConnectorCreate } from "../../routes/dataConnectors/dataConnectorTypes";
import { dqcApi } from "../dqcQuery";

export const dataConnectorsApi = dqcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllDataConnectors: builder.query<DataConnector[], any>({
      query: () => ({ url: `/apiV2/data_connector/`, method: "get" }),
      providesTags: ["DataConnectorList"],
    }),
    addNewDataConnector: builder.mutation({
      query: (newDataConnector: DataConnectorCreate) => ({
        url: `/apiV2/data_connector/`,
        method: "POST",
        data: newDataConnector,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["DataConnectorList"],
    }),
    deleteDataConnector: builder.mutation({
      query: (id: string) => ({ url: `/apiV2/data_connector/${id}`, method: "DELETE" }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["DataConnectorList"],
    }),
  }),
});

export const {
  useGetAllDataConnectorsQuery,
  useAddNewDataConnectorMutation,
  useDeleteDataConnectorMutation,
} = dataConnectorsApi;
