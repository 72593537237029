import { Issue, IssuesQuery } from "../types/issue";
import { ConfigureDialogQualityData, MissionData, Task } from "../types/missions";
import { QualitySummary, QualityConfig, QualityConfigQuery } from "../types/quality";
import { api_base, dqcApi } from "./dqcQuery";

export const missionApi = dqcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllMissions: builder.query<MissionData[], any>({
      query: () => ({ url: `/${api_base}/missions/`, method: "get" }),
      providesTags: ["MissionList"],
    }),
    getMissionById: builder.query<MissionData, string>({
      query: (id: string) => ({ url: `/${api_base}/missions/${id}/`, method: "get" }),
      providesTags: ["Mission"],
    }),
    getIssues: builder.query<Issue[], IssuesQuery>({
      query: (issuesQuery: IssuesQuery) => ({
        url: `/${api_base}/missions/${issuesQuery.missionId}/issues/`,
        method: "GET",
        params: { dataset_id: issuesQuery.datasetId },
      }),
      transformResponse: (issues: Issue[], _, issuesQuery: IssuesQuery) =>
        issues.filter(
          (issue) =>
            !issuesQuery.qualityDimension ||
            issue.quality_dimension === issuesQuery.qualityDimension
        ),
      providesTags: ["Mission"],
    }),
    getQualitySummaryByMissionId: builder.query<QualitySummary, string>({
      query: (id: string) => ({ url: `/${api_base}/quality_summary/${id}/`, method: "get" }),
      providesTags: ["Quality"],
    }),
    getQualityConfig: builder.query<QualityConfig, QualityConfigQuery>({
      query: (qualityConfigQuery: QualityConfigQuery) => ({
        url: `/${api_base}/quality_config/`,
        method: "GET",
        params: {
          dataset_id: qualityConfigQuery.dataset_id,
          mission_id: qualityConfigQuery.mission_id,
        },
      }),
      providesTags: ["QualityConfig"],
    }),
    updateQuality: builder.mutation({
      query: (qualityData: ConfigureDialogQualityData) => ({
        url: `/${api_base}/quality_config/`,
        method: "PUT",
        data: qualityData,
      }),
      invalidatesTags: ["Quality", "Mission", "QualityConfig"],
    }),
    addNewMission: builder.mutation({
      query: (newMission: { title: string; description: string }) => ({
        url: `/${api_base}/missions/`,
        method: "POST",
        data: newMission,
      }),
      invalidatesTags: ["MissionList"],
    }),
    updateMission: builder.mutation({
      query: (missionObj: { mission: any; missionId: string }) => ({
        url: `/${api_base}/missions/${missionObj.missionId}/`,
        method: "PUT",
        data: missionObj.mission,
      }),
      invalidatesTags: ["Mission", "MissionList", "Quality", "QualityConfig"],
    }),
    addNewDataset: builder.mutation({
      query: (newDataset: {
        connection_id: number;
        name: string;
        urn: string;
        origin: string;
        connected_mission: string;
      }) => ({
        url: `/${api_base}/missions/${newDataset.connected_mission}/dataset/`,
        method: "POST",
        data: newDataset,
      }),
      invalidatesTags: ["Mission", "MissionList", "Quality"],
    }),
    sendNotificationRequest: builder.mutation({
      query: (newNotificationRequest: {
        message: string;
        receivers: any;
        media_url: string;
        link_url: string;
      }) => ({
        url: `/${api_base}/bot/notify/`,
        method: "POST",
        data: newNotificationRequest,
      }),
    }),
    autoGenerateTasks: builder.mutation<Task[], string>({
      query: (id: string) => ({
        url: `/${api_base}/missions/${id}/autogeneratetasks/`,
        method: "get",
      }),
      invalidatesTags: ["Mission"],
    }),
    deleteMission: builder.mutation({
      query: (id: string) => ({ url: `/${api_base}/missions/${id}/`, method: "delete" }),
      invalidatesTags: ["MissionList"],
    }),
    updateTask: builder.mutation({
      query: (taskObject: { id: string; taskModel: Task }) => ({
        url: `/${api_base}/tasks/${taskObject.id}/`,
        method: "put",
        data: taskObject.taskModel,
      }),
      invalidatesTags: ["Mission"],
    }),
    createTask: builder.mutation({
      query: (taskObject: Task) => ({
        url: `/${api_base}/tasks/`,
        method: "post",
        data: taskObject,
      }),
      invalidatesTags: ["Mission"],
    }),
    deleteTask: builder.mutation({
      query: (id: string) => ({ url: `/${api_base}/tasks/${id}/`, method: "delete" }),
      invalidatesTags: ["Mission"],
    }),
    updateIssues: builder.mutation({
      query: (issuesObject: { missionId: string; data: any; dataset_id: string }) => ({
        url: `/${api_base}/missions/${issuesObject.missionId}/issues/?dataset_id=${issuesObject.dataset_id}`,
        method: "patch",
        data: issuesObject.data,
      }),
      invalidatesTags: ["Mission", "Quality"],
    }),
  }),
});

export const {
  useGetAllMissionsQuery,
  useGetMissionByIdQuery,
  useGetIssuesQuery,
  useGetQualitySummaryByMissionIdQuery,
  useUpdateQualityMutation,
  useGetQualityConfigQuery,
  useAddNewMissionMutation,
  useUpdateMissionMutation,
  useAddNewDatasetMutation,
  useSendNotificationRequestMutation,
  useAutoGenerateTasksMutation,
  useDeleteMissionMutation,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  useUpdateIssuesMutation,
} = missionApi;
