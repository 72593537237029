import { MessageBar, MessageBarType, ProgressIndicator, Stack } from "@fluentui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import { getFlowIdOrAcquire, useFetchRegistrationDataQuery } from "../../api/profile";
import { loginStackStyle } from "../../helpers/commonStyles";
import { setFlowId } from "../../store/slices/app";
import { SignInData } from "../../types/auth";
import { EmailOrSSO } from "./email-or-sso";
import {
  BottomSectionSignInOrLogin,
  TopSectionSignInOrLogin,
} from "./office-welcome/topButtonSectionSignInOrLogin";

export const SignUp: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [showSpinner, setShowSpinner] = useState(false);
  const email = localStorage.getItem("email");

  const flowId = searchParams.get("flow");
  const signUpData: SignInData | undefined = useFetchRegistrationDataQuery(
    flowId ?? skipToken
  ).data;

  // in case the existing flowId is not valid we have to acquire a new one
  const newFlowId = getFlowIdOrAcquire();
  setFlowId(newFlowId!);

  useEffect(() => {
    signUpData?.return_to && localStorage.setItem("returnTo", signUpData?.return_to);
  }, [signUpData]);

  const ui = signUpData?.ui;
  if (!ui) return null;
  const [csrf] = ui.nodes.filter((n) => n.attributes.name === "csrf_token");

  return (
    <Stack horizontalAlign="center" styles={loginStackStyle}>
      {showSpinner && (
        <ProgressIndicator label={t("loading")} styles={{ root: { width: "80%" } }} />
      )}
      {signUpData?.ui?.messages?.length ? (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {signUpData?.ui.messages
            .map((m) => (i18n.exists(String(m.id)) ? t(String(m.id)) : m.text))
            .join(";")}
        </MessageBar>
      ) : (
        <></>
      )}
      <TopSectionSignInOrLogin emailString={email} />
      <form
        style={{ width: "100%" }}
        action={signUpData?.ui.action}
        method={signUpData?.ui.method}
        onSubmit={() => {
          setShowSpinner(true);
        }}
      >
        <input
          title={csrf.attributes.name}
          type={"hidden"}
          name={csrf.attributes.name}
          value={csrf.attributes.value}
        ></input>
        <EmailOrSSO nodes={ui.nodes} signUp={location.pathname === "/signup"} enabled={true} />
      </form>
      <BottomSectionSignInOrLogin emailString={email} />
    </Stack>
  );
};
