import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { QualitySummary } from "../../types/quality";

// Define a type for the slice state
interface QualitySummaryState {
  quality: QualitySummary;
  pollingInterval: number;
}

// Define the initial state using that type
const initialState: QualitySummaryState = {
  quality: {
    id: "string",
    combined_issue_counts: [],
    combined_quality: 0,
    created_at: "",
    dataset_quality_summaries: [],
    mission_id: "",
    status: "failed",
    updated_at: "",
  },
  pollingInterval: 0,
};

export const qualitySummarySlice = createSlice({
  name: "qualitySummary",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setQuality: (state, action: PayloadAction<QualitySummary>) => {
      state.quality = action.payload;
    },
    setPollingInterval: (state, action: PayloadAction<number>) => {
      state.pollingInterval = action.payload;
    },
  },
});

export const { setQuality, setPollingInterval } = qualitySummarySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getQuality = (state: RootState) => state.qualitySummary.quality;
export const getPollingInterval = (state: RootState) => state.qualitySummary.pollingInterval;

export default qualitySummarySlice.reducer;
