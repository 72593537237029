import {
  DirectionalHint,
  IPersonaStyles,
  ITooltipProps,
  Label,
  Persona,
  PersonaSize,
  TooltipHost,
} from "@fluentui/react";

const personaStyle: Partial<IPersonaStyles> = {
  root: {
    cursor: "pointer",
  },
};

const ownerMouseOverRootStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
};

const ownerTooltipProps: ITooltipProps = {
  calloutProps: {
    style: {
      width: 150,
      height: 35,
    },
  },
};

interface PersonaTooltipProps {
  name?: string;
}
export const PersonaTooltip: React.FunctionComponent<PersonaTooltipProps> = ({ name }) => {
  return (
    <TooltipHost
      content={
        <div style={ownerMouseOverRootStyle}>
          <Label>{name !== "" ? name : "-"}</Label>
          <Persona
            text={name}
            hidePersonaDetails={true}
            size={PersonaSize.size24}
            initialsColor={"#169DFF"}
            styles={personaStyle}
          />
        </div>
      }
      tooltipProps={ownerTooltipProps}
      directionalHint={DirectionalHint.topCenter}
    >
      <Persona
        text={name}
        hidePersonaDetails={true}
        size={PersonaSize.size24}
        initialsColor={"#169DFF"}
        styles={personaStyle}
      />
    </TooltipHost>
  );
};
