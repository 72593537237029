import React, { createElement } from "react";
import { ReactComponent as valueNA } from "../../assets/value-NA.svg";
import { ReactComponent as valueLow } from "../../assets/value-low.svg";
import { ReactComponent as valueMedium } from "../../assets/value-medium.svg";
import { ReactComponent as valueHigh } from "../../assets/value-high.svg";
import {
  DirectionalHint,
  ILabelStyles,
  ITooltipProps,
  Label,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import { AssetUsecaseValue } from "../../types/dataasset";
import { useTranslation } from "react-i18next";

export interface ValueProps {
  value?: AssetUsecaseValue;
  width?: string;
  height?: string;
}
const valueMouseOverIconStyle: React.CSSProperties = {
  marginBottom: 4,
};
const valueMouseOverLabelStyle: ILabelStyles = {
  root: {
    fontSize: 14,
    marginBottom: 4,
    paddingLeft: 15,
  },
};
export const valueTooltipProps: ITooltipProps = {
  calloutProps: {
    style: {
      width: 160,
      height: 135,
    },
  },
};
const valueIconStyle: React.CSSProperties = {
  marginRight: 10,
  marginLeft: 10,
  cursor: "pointer",
  marginTop: 2,
};
export const ValueToolTip: React.FunctionComponent<ValueProps> = ({
  value,
  width = "100%",
  height = "12",
}) => {
  const { t } = useTranslation();

  const valueMouseOverData = [
    {
      icon: React.createElement(valueNA, { width: "100%" }),
      label: t("value-na"),
    },
    {
      icon: React.createElement(valueLow, { width: "100%" }),
      label: t("value-low"),
    },
    {
      icon: React.createElement(valueMedium, { width: "100%" }),
      label: t("value-medium"),
    },
    {
      icon: React.createElement(valueHigh, { width: "100%" }),
      label: t("value-high"),
    },
  ];

  const valueSVGs = [valueNA, valueLow, valueMedium, valueHigh].map((val) =>
    createElement(val, { width: `${width}`, height: `${height}px` })
  );

  const valueIcon: Record<string, any> = {
    "Not assessed": valueSVGs[0],
    Low: valueSVGs[1],
    Medium: valueSVGs[2],
    High: valueSVGs[3],
  };
  return (
    <>
      <TooltipHost
        content={
          <div>
            {valueMouseOverData.map((data, index) => (
              <Stack horizontal verticalAlign="center" key={index}>
                <span style={valueMouseOverIconStyle}>{data.icon}</span>
                <Label styles={valueMouseOverLabelStyle}>{data.label}</Label>
              </Stack>
            ))}
          </div>
        }
        tooltipProps={valueTooltipProps}
        directionalHint={DirectionalHint.topCenter}
      >
        <div style={valueIconStyle}>{valueIcon[value!]}</div>
      </TooltipHost>
    </>
  );
};
