import {
  Modal,
  TextField,
  Dropdown,
  IDropdownOption,
  IStackTokens,
  Stack,
  DefaultButton,
  IButtonStyles,
  Icon,
  Label,
  IDropdownStyles,
  ITextFieldStyles,
  ITag,
  PrimaryButton,
} from "@fluentui/react";
import React, { useState } from "react";
import { iconStyle, modalArea, modalHeading } from "../../helpers/commonStyles";
import { useCreateUseCasesMutation } from "../../api/cockpit";
import { getDefaultUseCase } from "./helpers";
import {
  DataAssetDropDownData,
  valueOptions,
  problemOptions,
  onRenderDropDownOption,
  onRenderTitle,
} from "./dropdownOptions";
import { RichTextEditor } from "../common/RichTextEditor";
import { CreateUseCase, Tag } from "../../types/usecase";
import { SelectLinkedDataAssets } from "./SelectLinkedDataAssets";
import { SelectMainDomain } from "./SelectMainDomain";
import { Tags } from "./Tags";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const addButtonStyle: IButtonStyles = {
  root: {
    width: "139px",
    height: "32px",
    borderRadius: "2px",
    padding: 10,
    marginTop: "5%",
  },
};
const cancelButtonStyle: IButtonStyles = {
  root: {
    width: "139px",
    height: "32px",
    color: "#201F1E",
    borderRadius: "2px",
    padding: 10,
    marginTop: "5%",
  },
};
const fieldStyle: React.CSSProperties = {
  padding: "20px",
};
const field: Partial<IDropdownStyles> = {
  root: {
    marginTop: "2%",
  },
};
const fieldText: Partial<ITextFieldStyles> = {
  root: {
    marginTop: "2%",
  },
};

const sectionStackTokens: IStackTokens = { childrenGap: 10 };

type CreateDataAssetError = {
  title?: string;
  type?: string;
};

type CreateUseCaseModalProps = {
  open: boolean;
  handleClose: () => void;
};
export const CreateUseCaseModal: React.FunctionComponent<CreateUseCaseModalProps> = ({
  open,
  handleClose,
}) => {
  const [useCase, setUseCase] = useState<CreateUseCase>(getDefaultUseCase());
  const [createUseCase] = useCreateUseCasesMutation();
  const [error, setError] = useState<CreateDataAssetError>({});
  const navigate = useNavigate();

  // Create use case Api call
  const createUseCaseFunc = async () => {
    const res = await createUseCase(useCase).unwrap();
    if (res) {
      navigate("/cockpit/use-cases");
      handleClose();
    }
  };
  const onChangeTextField = (e: any) => {
    setUseCase({
      ...useCase,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "title" && e.target.value === "")
      setError({ ...error, title: "Title cannot be left blank" });
    else setError({ ...error, title: "" });
  };
  const onChangeDropDown = (_: any, option?: IDropdownOption<DataAssetDropDownData>) => {
    if (!option?.data) return;
    setUseCase({
      ...useCase,
      [option.data.field]: option.key,
    });
  };

  return (
    <>
      <Modal isOpen={open} containerClassName={modalArea.container}>
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
          <h2 style={modalHeading}>{t("add-use-case")}</h2>
          <Icon styles={iconStyle} iconName="cancel" onClick={handleClose} />
        </Stack>
        <div style={fieldStyle}>
          <TextField
            label="Title"
            name="title"
            placeholder={t("enter-title")}
            invalid={error.title ? true : false}
            errorMessage={error.title ? <span>{error.title}</span> : ""}
            onChange={onChangeTextField}
            required
          />
          <Label>{t("description")}</Label>
          <RichTextEditor
            toggleEditor={false}
            onChange={(description) => setUseCase({ ...useCase, description })}
            value={useCase.description || ""}
          />
          <TextField
            label="Owner"
            placeholder="Select owner"
            name="owner"
            onChange={onChangeTextField}
            styles={fieldText}
          />

          <Dropdown
            label="Value"
            placeholder="Select value"
            styles={field}
            options={valueOptions}
            onRenderOption={onRenderDropDownOption}
            selectedKey={useCase.value}
            onChange={onChangeDropDown}
            onRenderTitle={onRenderTitle}
          />

          <Dropdown
            label="Problems"
            placeholder="Select problems"
            styles={field}
            options={problemOptions}
            onRenderOption={onRenderDropDownOption}
            selectedKey={useCase.problems}
            onChange={onChangeDropDown}
            onRenderTitle={onRenderTitle}
          />

          <Label styles={field}>{t("tags")}</Label>
          <Tags
            currentTags={useCase.tags}
            onChange={(items?: ITag[]) =>
              setUseCase({
                ...useCase,
                tags: items?.map((i) => ({ id: i.key, label: i.name } as Tag)) || [],
              })
            }
          />
          <SelectMainDomain
            selectedMainDomainId={useCase.main_domain_id}
            setSelectMainDomainId={(id) => setUseCase({ ...useCase, main_domain_id: id })}
          />
          <SelectLinkedDataAssets
            linkedIds={useCase.linked_assets_ids || []}
            setLinkedIds={(ids: string[]) => {
              setUseCase({ ...useCase, linked_assets_ids: ids });
            }}
            styles={field}
          />

          <Stack horizontal reversed={true} tokens={sectionStackTokens}>
            <DefaultButton text="Cancel" onClick={handleClose} styles={cancelButtonStyle} />
            <PrimaryButton
              text="Add"
              onClick={createUseCaseFunc}
              styles={addButtonStyle}
              disabled={!useCase.title}
            />
          </Stack>
        </div>
      </Modal>
    </>
  );
};
