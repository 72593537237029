import React from "react";
import { useTranslation } from "react-i18next";
import { headerStyle, subContainerStyle } from "../../helpers/commonStyles";
const textContainer: React.CSSProperties = {
  margin: 0,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
const heading: React.CSSProperties = {
  margin: 0,
  width: "140px",
};
const values: React.CSSProperties = {
  margin: 0,
  width: "140px",
  marginLeft: "1%",
};
const container: React.CSSProperties = {
  marginTop: "10%",
  display: "flex",
  justifyContent: "center",
};

export const Usage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <section style={subContainerStyle}>
      <h3 style={headerStyle}>{t("interaction_usage")}</h3>
      <div style={container}>
        <ul>
          <li>
            <span style={textContainer}>
              <p style={heading}>{t("excel_plugin")}:</p>
              <p style={values}>49 {t("active_users_usage")}</p>
            </span>
          </li>
          <li>
            <span style={textContainer}>
              <p style={heading}>{t("powerful_bi_plugin")}:</p>
              <p style={values}>12 {t("active_users_usage")}</p>
            </span>
          </li>
          <li>
            <span style={textContainer}>
              <p style={heading}>{t("tableau_plugin")}:</p>
              <p style={values}>3 {t("active_users_usage")}</p>
            </span>
          </li>
          <li>
            <span style={textContainer}>
              <p style={heading}>{t("sharepoint_plugin")}:</p>
              <p style={values}>83 {t("active_users_usage")}</p>
            </span>
          </li>
          <li>
            <span style={textContainer}>
              <p style={heading}>{t("crm")}:</p>
              <p style={values}>2 {t("active_users_usage")}</p>
            </span>
          </li>
          <li>
            <span style={textContainer}>
              <p style={heading}>{t("erp")}:</p>
              <p style={values}>{t("not_yet_setup")}</p>
            </span>
          </li>
          <li>
            <span style={textContainer}>
              <p style={heading}>{t("procurement")}:</p>
              <p style={values}>{t("not_yet_setup")}</p>
            </span>
          </li>
        </ul>
      </div>
    </section>
  );
};
