import React, { useState } from "react";
import { useGetDataAssetsQuery, useDeleteDataAssetsMutation } from "../../api/cockpit";
import { DeleteDataAssetsModalProps } from "../../types/dataasset";
import {
  DefaultButton,
  Icon,
  IStackStyles,
  Label,
  Modal,
  PrimaryButton,
  Stack,
  Checkbox,
  ChoiceGroup,
  IChoiceGroupOption,
  Separator,
  IStackTokens,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IIconStyles, mergeStyleSets } from "@fluentui/react";
import { ReactComponent as GitForkIcon } from "../../assets/GitFork.svg";

export const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "40%",
  },
});

export const iconStyle: Partial<IIconStyles> = {
  root: {
    fontSize: 20,
    cursor: "pointer",
  },
};

const modalMainStyle: React.CSSProperties = {
  padding: "30px 20px 30px 20px",
};

const buttonContainer: React.CSSProperties = {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "4%",
};

const buttonSubContainer: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
};

const checkboxListStackTokens: IStackTokens = {
  childrenGap: 10,
};

const headerContainer: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 3% 10px 3%",
  },
};

const choiceGroupStyles = {
  label: {
    display: "inline",
  },
  flexContainer: {
    columnGap: "1em",
    display: "inline-flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
};

export const DeleteDataAssetsModal: React.FunctionComponent<DeleteDataAssetsModalProps> = ({
  showModal,
  setShowModal,
}) => {
  const { t } = useTranslation();
  const { data, isLoading, isSuccess } = useGetDataAssetsQuery({ parent: "none" });
  const [deleteDataAssets] = useDeleteDataAssetsMutation();
  const [selectedCheckboxList, setSelectedCheckboxList] = useState<string[]>([]);
  const [selectedChoiceGroupKey, setSelectedChoiceGroupKey] = useState<string>("");

  const options: IChoiceGroupOption[] = [
    { key: "DELETE_DOMAIN_ONLY", text: t("delete_data_assets_domain_only") },
    { key: "DELETE_DOMAIN_AND_ALL", text: t("delete_data_assets_domain_and_all") },
  ];

  const renderCheckboxLabel = (label: string) => {
    return (
      <>
        <GitForkIcon style={{ width: "20px", marginLeft: "3px", transform: "scaleY(-1)" }} />
        <span style={{ marginLeft: "5px" }}>{label}</span>
      </>
    );
  };

  const onCheckboxChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) => {
    ev &&
      (isChecked
        ? setSelectedCheckboxList([...selectedCheckboxList, ev.currentTarget.id])
        : setSelectedCheckboxList(
            selectedCheckboxList.filter((item) => item === ev.currentTarget.id)
          ));
  };

  const onChoiceGroupChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option?: IChoiceGroupOption
  ): void => {
    option && setSelectedChoiceGroupKey(option.key);
  };

  const renderDataAssets = () => {
    if (data && data.length > 0) {
      return data.map((item) => (
        <Checkbox
          id={item.id}
          key={item.id}
          name={item.title}
          onRenderLabel={() => renderCheckboxLabel(item.title)}
          onChange={onCheckboxChange}
        ></Checkbox>
      ));
    }
    return <span>{t("delete_data_assets_no_data")}</span>;
  };

  const onDelete = async () => {
    try {
      await deleteDataAssets([
        {
          op: "delete",
          ids: selectedCheckboxList,
          props: { delete_children: selectedChoiceGroupKey === "DELETE_DOMAIN_ONLY" },
        },
      ]).unwrap();
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        onDismiss={() => setShowModal(false)}
        containerClassName={contentStyles.container}
      >
        <Stack horizontal styles={headerContainer}>
          <Label>{t("delete_data_assets_title")}</Label>
          <Icon iconName="ChromeClose" onClick={() => setShowModal(false)} styles={iconStyle} />
        </Stack>
        <div style={modalMainStyle}>
          <Stack tokens={checkboxListStackTokens}>
            {!isLoading && isSuccess ? renderDataAssets() : null}
          </Stack>
          <Separator />
          <ChoiceGroup
            selectedKey={selectedChoiceGroupKey}
            options={options}
            onChange={onChoiceGroupChange}
            required={true}
            styles={choiceGroupStyles}
          />
          <div style={buttonContainer}>
            <div style={buttonSubContainer}>
              <PrimaryButton
                text={t("delete")}
                onClick={onDelete}
                styles={{
                  root: { marginRight: "4%", whiteSpace: "noWrap" },
                }}
                disabled={!selectedChoiceGroupKey || selectedCheckboxList.length === 0}
              />
              <DefaultButton
                text={t("cancel")}
                onClick={() => setShowModal(false)}
                styles={{ root: { marginRight: "2%" } }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
