import { Stack } from "@fluentui/react";
import { t } from "i18next";

export const ComingSoon = (): JSX.Element => {
  return (
    <Stack verticalAlign="center" horizontalAlign="center" style={{ margin: 20, marginTop: 200 }}>
      <h1>{t("coming_soon")}</h1>
    </Stack>
  );
};
