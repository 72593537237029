import { FunctionComponent } from "react";
import { t } from "i18next";
import analyzeIcon from "../../../assets/welcome-screen/welcome-analyze-icon.svg";
import compareIcon from "../../../assets/welcome-screen/welcome-compare-icon.svg";
import exploreIcon from "../../../assets/welcome-screen/welcome-explore-icon.svg";

export const WhyDQA: FunctionComponent = () => {
  const spanStyle = { lineHeight: "1.5em", verticalAlign: "middle", marginLeft: 4 };
  return (
    <>
      <ul style={{ listStyle: "none", textAlign: "center", margin: "30 0 0 0", padding: 0 }}>
        <span style={spanStyle}>{t("enablesyou")}</span>
        <li>
          <img src={analyzeIcon} alt="analyze" style={{ verticalAlign: "middle" }} />
          <span style={spanStyle}>{t("enablesyou-analyze")}</span>
        </li>
        <li>
          <img src={compareIcon} alt="compare" style={{ verticalAlign: "middle" }} />
          <span style={spanStyle}>{t("enablesyou-compare")}</span>
        </li>
        <li>
          <img src={exploreIcon} alt="explore" style={{ verticalAlign: "middle" }} />
          <span style={spanStyle}>{t("enablesyou-explore")}</span>
        </li>
      </ul>
    </>
  );
};
