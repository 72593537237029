import { useState } from "react";
import { ColumnDefinition } from "../../../types/fileUploader";
import { TestFunctionName } from "../../../types/rules";
import {
  CommandBarButton,
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
  IDropdownStyles,
  Stack,
  Text,
} from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectLatestUpload } from "../../../store/slices/upload";
import { QUALITY_RULE_FUNCTION_MAP } from "../../../helpers/predictRules/qualityRuleFunctions";
import { dqcPalette } from "../../../utils/colors";
import { t } from "i18next";
import { createRule } from "../../../store/slices/quickAnalysis";
import { TESTFUNCTION_TO_RULE_NAME } from "../../../helpers/quickAnalysis/qualityChecks/constants";

const twoColumnTestFunctionNames: TestFunctionName[] = ["columnCorrelation", "columnMatching"];
const multipleColumnTestFunctionNames: TestFunctionName[] = ["duplicates", "customRule"];
const allTestFunctionNames = Object.keys(QUALITY_RULE_FUNCTION_MAP) as TestFunctionName[];
const singleColumnTestFunctionNames = allTestFunctionNames.filter(
  (name) =>
    !multipleColumnTestFunctionNames.includes(name) &&
    !twoColumnTestFunctionNames.includes(name) &&
    // We only want to show one outlier option, the user can switch between stdDev and iqr in the modal
    name !== "stdDevOutlier"
);
const dropdownStyle: Partial<IDropdownStyles> = { root: { minWidth: 320 } };

const mapTestFunctionNameToDropdown = (
  name: TestFunctionName,
  disabled: boolean
): IDropdownOption<TestFunctionName> => {
  return {
    key: name,
    text: TESTFUNCTION_TO_RULE_NAME[name],
    data: name,
    disabled,
  };
};

export const AddRuleManually = () => {
  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState<ColumnDefinition[]>([]);
  const [testFunctionName, setTestFunctionName] = useState<TestFunctionName | undefined>();
  const dataContainer = useAppSelector(selectLatestUpload);

  if (!dataContainer) return null;
  const columnDropdownOptions: IDropdownOption<ColumnDefinition>[] = dataContainer.columns.map(
    (column) => ({
      key: column.index,
      text: column.name,
      data: column,
    })
  );
  const testFunctionDropdownOptions: IDropdownOption<TestFunctionName>[] = [
    {
      text: "Single column rules",
      key: "single_column_rules",
      itemType: DropdownMenuItemType.Header,
    },
    ...singleColumnTestFunctionNames.map((name) =>
      mapTestFunctionNameToDropdown(name, columns.length > 1)
    ),
    {
      text: "Two column rules",
      key: "two_column_rules",
      itemType: DropdownMenuItemType.Header,
    },
    ...twoColumnTestFunctionNames.map((name) =>
      mapTestFunctionNameToDropdown(name, columns.length !== 2)
    ),
    {
      text: "Multi column rules",
      key: "multi_column_rules",
      itemType: DropdownMenuItemType.Header,
    },
    ...multipleColumnTestFunctionNames.map((name) => mapTestFunctionNameToDropdown(name, false)),
  ];
  return (
    <Stack verticalFill tokens={{ childrenGap: 8 }} styles={{ root: { marginTop: 20 } }}>
      <Text variant="large">{t("generate_new_rule")}</Text>
      <Text variant="small" styles={{ root: { color: dqcPalette.grey } }}>
        {t("choose_columns_and_rule")}
      </Text>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <Dropdown
          placeholder={t("column_placeholder")}
          options={columnDropdownOptions}
          onChange={(_, option?: IDropdownOption<ColumnDefinition>) => {
            const column = columnDropdownOptions.find(
              (columnOption) => columnOption.key === option?.key
            )?.data;
            if (!column) return;
            if (columns.includes(column))
              setColumns(columns.filter((col) => col.index !== column.index));
            else setColumns([...columns, column]);
          }}
          multiSelect
          selectedKeys={columns.map((column) => column.index)}
          styles={dropdownStyle}
        />
        <Dropdown
          placeholder={t("rule_placeholder")}
          options={testFunctionDropdownOptions}
          onChange={(_, option?: IDropdownOption<TestFunctionName>) => {
            if (!option?.data) return;
            setTestFunctionName(option.data);
          }}
          selectedKey={testFunctionName}
          styles={dropdownStyle}
        />
      </Stack>
      <Stack horizontal>
        <CommandBarButton
          disabled={!columns.length || !testFunctionName}
          iconProps={{ iconName: "add" }}
          text={t("add_rule")}
          onClick={() => {
            if (!columns.length || !testFunctionName) return;
            dispatch(createRule({ columns, testFunctionName, dataContainer }));
          }}
          styles={{ root: { padding: 4 } }}
        />
      </Stack>
    </Stack>
  );
};
