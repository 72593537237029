import { IStackItemStyles } from "@fluentui/react";

export const buttonStyle: IStackItemStyles = {
  root: {
    borderRadius: 4,
    width: "100%",
    textAlign: "left",
    ".ms-Button-flexContainer": {
      marginLeft: 16,
    },
    ".ms-Button-menuIcon": {
      marginRight: 10,
    },
  },
};
