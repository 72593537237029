import { ColumnDefinition, UploadDataContainer } from "../../../types/fileUploader";
import { RuleCandidateWithoutMeta } from "../../../types/rules";

export const predictInconsistentRules = (
  dataContainer: UploadDataContainer
): RuleCandidateWithoutMeta[] => {
  const ruleCandidates: RuleCandidateWithoutMeta[] = [];
  dataContainer.columns.forEach((column) => {
    if (
      column.dataType === "Unknown" ||
      column.dataType === "Empty" ||
      column.dataType === "Categorical" ||
      column.dataType === "GeoCoordinate"
    )
      return;
    ruleCandidates.push(getInconsistentRule(column, dataContainer));
  });

  return ruleCandidates;
};

export const getInconsistentRule = (
  column: ColumnDefinition,
  dataContainer: UploadDataContainer
): RuleCandidateWithoutMeta => {
  const isWarning = column.dataTypeFrequency / dataContainer.data.length > 0.9;
  return {
    id: `${dataContainer.fileName}::inconsistent::dataTypes::${column.index}`,
    columns: [column],
    dimension: "inconsistent",
    severity: isWarning ? "warning" : "info",
    confidence: isWarning ? 4 : 3,
    isAccepted: isWarning,
    name: "Consistent",
    description: "Test that all data is of the same type in this column",
    qualityTest: {
      testFunctionName: "inconsistentDataType",
      meta: {
        dataType: column.dataType,
      },
    },
    fileName: dataContainer.fileName,
  };
};
