// @TODO: remove once all charts are connected to API

function valueOrDefault(value: any, defaultValue: any) {
  return typeof value === "undefined" ? defaultValue : value;
}
// Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
let _seed = Date.now();

export function rand(min: any, max: any) {
  min = valueOrDefault(min, 0);
  max = valueOrDefault(max, 0);
  _seed = (_seed * 9301 + 49297) % 233280;
  return min + (_seed / 233280) * (max - min);
}

export function numbers(config: any) {
  let cfg = config || {};
  let min = valueOrDefault(cfg.min, 0);
  let max = valueOrDefault(cfg.max, 100);
  let from = valueOrDefault(cfg.from, []);
  let count = valueOrDefault(cfg.count, 8);
  let decimals = valueOrDefault(cfg.decimals, 8);
  let continuity = valueOrDefault(cfg.continuity, 1);
  let dfactor = Math.pow(10, decimals) || 0;
  let data: number[] = [];
  let i, value;

  for (i = 0; i < count; ++i) {
    value = (from[i] || 0) + rand(min, max);
    if (rand(min, max) <= continuity) {
      data.push(Math.round(dfactor * value) / dfactor);
    } else {
      data.push(Math.round(dfactor * value) / dfactor);
    }
  }
  return data;
}
