import { UploadDataContainer } from "../../../types/fileUploader";
import { PiiCategory, QuickAnalysisIssue } from "../../../types/quickAnalysis";
import { RuleCandidate } from "../../../types/rules";
import {
  IBAN,
  creditCardNumber,
  emailAddress,
  ipAddress,
  passportDE,
  phoneNumber,
  usSocialSecurityNumber,
} from "../../quickAnalysis/qualityChecks/piiRegexPatterns";
import { addIssueId } from "../../quickAnalysis/qualityChecks/qualityUtils";

const regexLibrary: Map<PiiCategory, RegExp> = new Map([
  ["Email address", emailAddress],
  ["Social security number", usSocialSecurityNumber],
  ["Passport number", passportDE],
  ["Credit card", creditCardNumber],
  ["Phone number", phoneNumber],
  ["IP address", ipAddress],
  ["IBAN", IBAN],
]);

type RegExResult = {
  category: PiiCategory;
  regex: RegExp;
};

export const getRegexFromPiiCategories = (): RegExResult[] => {
  const regexResults: RegExResult[] = [];
  Array.from(regexLibrary.entries()).forEach((regEx) => {
    regexResults.push({ category: regEx[0], regex: new RegExp(regEx[1]) });
  });
  return regexResults;
};

/**
 * Returns the category of sensitive data found or null
 * @param value
 * @param regexResults
 * @returns
 */
export const containsSensitive = (
  value: string,
  regexResults: RegExResult[]
): PiiCategory | undefined => {
  for (const { category, regex } of regexResults) {
    if (regex.test(value)) {
      return category;
    }
  }
  return undefined;
};

export const sensitiveTest = (
  dataContainer: UploadDataContainer,
  rule: RuleCandidate
): QuickAnalysisIssue[] => {
  const issues: QuickAnalysisIssue[] = [];
  if (rule.qualityTest.testFunctionName !== "sensitiveTest") return issues;
  const column = rule.columns[0].index;
  const cells = dataContainer.data.map((row) => row[column]);
  cells.forEach((cell) => {
    const regexResults = getRegexFromPiiCategories();
    const matchedCategory = containsSensitive(cell.value.toString(), regexResults);
    if (matchedCategory) {
      issues.push({
        row: cell.row,
        column: cell.column,
        comment: "",
        severity: "warning",
        type: "sensitive",
        category: matchedCategory,
        id: "",
        rule_id: rule.id,
      });
    }
  });
  return addIssueId(issues);
};
