import { t } from "i18next";
import { useAppDispatch } from "../../../store/hooks";
import { stackRulesCountStyles } from "./QualitySummary.styles";
import { Link, MessageBar, Stack } from "@fluentui/react";
import {
  setMessageBarVisibility,
  setQuickAnalysisStatus,
} from "../../../store/slices/quickAnalysis";
import { RuleCandidate } from "../../../types/rules";
import { useEffect } from "react";

type QuickAnalysisMessageBarType = {
  rules: RuleCandidate[];
};

export const QuickAnalysisMessageBar = ({ rules }: QuickAnalysisMessageBarType) => {
  const dispatch = useAppDispatch();

  const handleMessageBarClose = () => {
    dispatch(setMessageBarVisibility(false));
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setMessageBarVisibility(false));
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Stack horizontal>
      <MessageBar
        messageBarIconProps={{
          iconName: "documentTable",
          styles: { root: { alignSelf: "center", lineHeight: 16 } },
        }}
        styles={stackRulesCountStyles}
        onDismiss={handleMessageBarClose}
      >
        {t("rules_found_1")}
        <Link
          underline
          styles={{ root: { color: "blue", fontWeight: "bold" } }}
          onClick={() => dispatch(setQuickAnalysisStatus("rules"))}
        >
          {rules.length}
          {t("rules_found_2")}
        </Link>
        !
      </MessageBar>
    </Stack>
  );
};
