import { FunctionComponent, useEffect, useState } from "react";
import { IButtonStyles, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { Mission, MissionData } from "../../types/missions";

import { MissionsList } from "../../components/mission/mission-list/MissionsList";
import { SpinnerStyles } from "../../helpers/commonStyles";
import { TopContainer } from "../../components/common/TopContainer";
import { useGetAllMissionsQuery } from "../../api/mission";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const newMissionButtonStyle: IButtonStyles = {
  root: {
    width: "189px",
    height: "40px",
    borderRadius: "4px",
  },
};

export const AllMissions: FunctionComponent = () => {
  const { t } = useTranslation();
  const missionsListRequest = useGetAllMissionsQuery("");
  const [missions, setMissions] = useState<Mission[]>([]);
  let navigate = useNavigate();

  useEffect(() => {
    const items: Mission[] | undefined = missionsListRequest.data?.map((data: MissionData) => {
      return {
        id: data.id,
        name: data.title,
        description: data.description,
        function: data.function,
        owner: data.owner,
        quality: data.combined_quality,
        status: data.status,
      };
    });
    items && setMissions(items);
  }, [missionsListRequest]);

  return (
    <TopContainer title={t("missions")}>
      {missionsListRequest.isLoading ? (
        <Spinner size={SpinnerSize.large} styles={SpinnerStyles} />
      ) : (
        <main>
          <div>
            <PrimaryButton
              text={t("add_mission")}
              onClick={() => {
                navigate("/missions/new");
              }}
              styles={newMissionButtonStyle}
            />
          </div>
          <div>{missions?.length > 0 ? <MissionsList missions={missions} /> : null}</div>
        </main>
      )}
    </TopContainer>
  );
};
