import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ProfileResult } from "../types/user-profile";
import Axios from "axios";

export const microsoftGraphApi = createApi({
  reducerPath: "microsoftGraphApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://graph.microsoft.com/v1.0" }),
  tagTypes: ["MicrosoftUsers"],
  endpoints: (builder) => ({
    getUserById: builder.query<ProfileResult, { userId: string; accessToken: string }>({
      query: ({ userId, accessToken }) => ({
        url: `/users/${userId}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ConsistencyLevel: "eventual",
        },
      }),
      providesTags: ["MicrosoftUsers"],
    }),
    getMyUser: builder.query<ProfileResult, { accessToken: string }>({
      query: ({ accessToken }) => ({
        url: `/me`,
        method: "get",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ConsistencyLevel: "eventual",
        },
      }),
      providesTags: ["MicrosoftUsers"],
    }),
    getProfilePictureById: builder.query<string, { userId: string; accessToken: string }>({
      queryFn: ({ userId, accessToken }) => {
        return fetchUserProfileImage(userId, accessToken);
      },
    }),
  }),
});

export const fetchUserProfileImage = async (
  userId: string,
  accessToken: string
): Promise<{ data: string } | { error: any }> => {
  return Axios.get(`https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL || window.webkitURL;
      const blobUrl = url.createObjectURL(response.data);
      return { data: blobUrl };
    })
    .catch((error) => {
      return { error: error };
    });
};

export const { useGetUserByIdQuery, useGetProfilePictureByIdQuery, useGetMyUserQuery } =
  microsoftGraphApi;
