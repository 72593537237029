import { PayloadAction, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { QuickAnalysisRule, RuleCandidate, RuleCandidateWithoutMeta } from "../../types/rules";
import { RootState } from "../store";
import { createRule, doRulesAnalysis } from "./quickAnalysis";
import { selectLatestUpload } from "./upload";
import { PROBABILITY_SCALE } from "../../helpers/quickAnalysis/qualityChecks/constants";
import { isQuickAnalysisRule } from "../../utils/rules";

const rulesAdapter = createEntityAdapter<RuleCandidate>({
  selectId: (rule) => rule.id,
});

function addUserRuleMeta(rule: RuleCandidateWithoutMeta): RuleCandidate {
  const now = new Date().toISOString();
  return {
    ...rule,
    createdAt: now,
    updatedAt: now,
    createdBy: "User",
    updatedBy: "User",
  };
}

export const rulesSlice = createSlice({
  name: "rules",
  initialState: rulesAdapter.getInitialState(),
  reducers: {
    setRule: (state, action: PayloadAction<RuleCandidate>) => {
      rulesAdapter.setOne(state, action.payload);
    },
    setAllRules: (state, action: PayloadAction<RuleCandidate[]>) => {
      rulesAdapter.setMany(state, action.payload);
    },
    updateAllRules: (state, action: PayloadAction<RuleCandidate[]>) => {
      rulesAdapter.upsertMany(state, action.payload);
    },
    deleteAllRules: (state) => {
      rulesAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doRulesAnalysis.fulfilled, (state, { payload }) => {
      rulesAdapter.upsertMany(state, payload.rules);
    });
    builder.addCase(createRule.fulfilled, (state, { payload }) => {
      payload && rulesAdapter.upsertOne(state, addUserRuleMeta(payload));
    });
  },
});

export const { selectById: selectRuleById, selectAll: selectAllRules } =
  rulesAdapter.getSelectors<RootState>((state) => {
    return state.rules;
  });

export const { setRule, setAllRules, updateAllRules, deleteAllRules } = rulesSlice.actions;

export const selectAllRulesByFileName = createSelector(
  selectAllRules,
  selectLatestUpload,
  (rules: RuleCandidate[], uploadData): RuleCandidate[] => {
    const fileName = uploadData?.fileName;
    const filteredRules = rules.filter(
      (rule) =>
        isQuickAnalysisRule(rule) &&
        (rule as QuickAnalysisRule).fileName === fileName &&
        !rule.deletedAt
    );
    return filteredRules;
  }
);

export const groupRules = (rules: RuleCandidate[]): RuleCandidate[][] => {
  const columnsToRulesMap: Record<string, RuleCandidate[]> = {};
  rules.forEach((rule) => {
    if (rule.deletedAt) return;
    const columnsJoined = rule.columns.map((c) => c.index).join("::");
    if (columnsToRulesMap[columnsJoined]) columnsToRulesMap[columnsJoined].push(rule);
    else columnsToRulesMap[columnsJoined] = [rule];
  });
  return Object.values(columnsToRulesMap);
};

export const selectNonDeletedRulesGroupedByColumn = createSelector(
  selectAllRulesByFileName,
  (rules): RuleCandidate[][] => groupRules(rules)
);

export const searchGroups = (
  rulesGrouped: RuleCandidate[][],
  searchValue: string
): RuleCandidate[][] => {
  if (!searchValue) return rulesGrouped;
  const filteredRules = rulesGrouped.map((ruleGroup) => {
    const filteredGroup = ruleGroup.filter((rule) => {
      const columnNamesJoined = rule.columns.map((col) => col.name).join("");
      const confidenceString = PROBABILITY_SCALE[rule.confidence - 1];
      // create a search string for every visible information about the rule
      const ruleInformationsJoined =
        `${rule.name}${rule.dimension}${columnNamesJoined}${confidenceString}${rule.description}`.toLocaleLowerCase();
      return ruleInformationsJoined.includes(searchValue.toLocaleLowerCase());
    });
    return filteredGroup;
  });
  return filteredRules.filter((group) => !!group.length);
};

/**
 * Selects all open rules for the last analyzed file, filtered by the search input considering
 * rule description, name, dimension, confidence level and column names
 */
export const selectOpenRulesBySearch = createSelector(
  selectNonDeletedRulesGroupedByColumn,
  (_: RootState, searchValue: string) => searchValue,
  (rulesGrouped, searchValue): RuleCandidate[][] => searchGroups(rulesGrouped, searchValue)
);

export default rulesSlice.reducer;
