import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { contentStyles, iconStyle } from "../mission/tasks/CommonTaskStyles";
import { useAddGroupMutation } from "../../api/companyAndGroups";
import {
  DefaultButton,
  Icon,
  IStackStyles,
  Label,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";

const headerContainer: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 3% 10px 3%",
  },
};

const settingContainer: IStackStyles = {
  root: {
    padding: "3%",
  },
};

const editLabelContainer: IStackStyles = {
  root: {
    width: "40%",
  },
};

const buttonContainer: IStackStyles = {
  root: {
    justifyContent: "flex-end",
  },
};

export interface AddGroupModalProps {
  showGroupModal: boolean;
  setShowGroupModal(showGroupModal: boolean): void;
}

export const AddGroupModal: FunctionComponent<AddGroupModalProps> = ({
  showGroupModal,
  setShowGroupModal,
}) => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState<string>("");
  const [groupDescription, setGroupDescription] = useState<string>("");
  const [addNewGroup] = useAddGroupMutation();

  const addGroup = async () => {
    try {
      await addNewGroup({
        name: groupName,
        description: groupDescription,
      }).unwrap();
      setShowGroupModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal isOpen={showGroupModal} containerClassName={contentStyles.container}>
        <Stack horizontal styles={headerContainer}>
          <Label>{t("add_group")}</Label>
          <Icon
            iconName="ChromeClose"
            onClick={() => setShowGroupModal(false)}
            styles={iconStyle}
          />
        </Stack>
        <div style={{ border: "1px solid #DDDDDD" }} />
        <Stack styles={settingContainer}>
          <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }}>
            <Stack.Item styles={editLabelContainer}>
              <Label>{t("group_name")}</Label>
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextField
                name="group_name"
                value={groupName}
                onChange={(e: any) => setGroupName(e.target.value)}
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }}>
            <Stack.Item styles={editLabelContainer}>
              <Label>{t("group_description")}</Label>
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextField
                name="group_description"
                value={groupDescription}
                multiline
                rows={3}
                onChange={(e: any) => setGroupDescription(e.target.value)}
              />
            </Stack.Item>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }} styles={buttonContainer}>
            <PrimaryButton text={t("save")} onClick={addGroup} disabled={!groupName} />
            <DefaultButton text={t("cancel")} onClick={() => setShowGroupModal(false)} />
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
