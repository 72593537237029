import { FunctionComponent, createElement } from "react";
import { ReactComponent as gauge1 } from "../../assets/gauge-1.svg";
import { ReactComponent as gauge2 } from "../../assets/gauge-2.svg";
import { ReactComponent as gauge3 } from "../../assets/gauge-3.svg";
import { ReactComponent as gauge4 } from "../../assets/gauge-4.svg";
import { ReactComponent as gauge5 } from "../../assets/gauge-5.svg";

export interface QualityGaugeProps {
  qualityScore?: number;
  width?: string;
  height?: string;
}

export const QualityGauge: FunctionComponent<QualityGaugeProps> = ({
  qualityScore = 1,
  width = "222",
  height = "122",
}) => {
  const gaugeSVGs = [gauge1, gauge2, gauge3, gauge4, gauge5].map((val) =>
    createElement(val, { width: `${width}px`, height: `${height}px` })
  );
  return <>{gaugeSVGs[qualityScore - 1]}</>;
};
