import { FunctionComponent } from "react";
import { UploadReportModel } from "../../types/upload";
import { useTranslation } from "react-i18next";

export interface UploadReportProps {
  uploadReport: UploadReportModel;
}

export const UploadReport: FunctionComponent<UploadReportProps> = (props: UploadReportProps) => {
  const { t } = useTranslation();
  const { uploadReport } = props;
  return (
    <div>
      <p>
        {t("upload_successfully_uploaded")}
        {uploadReport.successfulUploadCount}
        {t("upload_rows_sharepoint")}
      </p>
      {uploadReport.errors.map((errorReport) => {
        return (
          <div>
            <p>
              {t("upload_linenumber")}: {errorReport.lineNumber}
              {t("upload_message")}: {errorReport.errorDescription}
            </p>
          </div>
        );
      })}
    </div>
  );
};
