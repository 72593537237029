import { Label, Spinner, SpinnerSize } from "@fluentui/react";
import { CSSProperties, FunctionComponent } from "react";
import { TopContainer } from "../../components/common/TopContainer";
import { topHeading } from "../../helpers/commonStyles";
import { useGetRemarksQuery } from "../../api/remark";
import { RemarksList } from "./remarks-list";
import { useTranslation } from "react-i18next";

const spinnerContainer: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginTop: "3%",
};

export const Remarks: FunctionComponent = () => {
  const { t } = useTranslation();
  // FIXME don't use this accessToken to get the userID
  const { isLoading } = useGetRemarksQuery({});

  return (
    <TopContainer title={t("remarks")}>
      <main>
        <Label styles={topHeading}>{t("remarks_label")}</Label>
        <div>
          {isLoading ? (
            <div style={spinnerContainer}>
              <Spinner size={SpinnerSize.large} />
            </div>
          ) : (
            <RemarksList />
          )}
        </div>
      </main>
    </TopContainer>
  );
};
