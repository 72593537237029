import millify from "millify";

import { Remark, RemarksSortKey } from "../types/colab";
import * as Diff from "diff";
export const sortRemarks = (remarks: Remark[], sortedKey?: RemarksSortKey): Remark[] => {
  if (sortedKey === "creator")
    return remarks.sort((r1, r2) => r1.creator.localeCompare(r2.creator));
  if (sortedKey === "creator_reversed")
    return remarks.sort((r1, r2) => r2.creator.localeCompare(r1.creator));
  if (sortedKey === "date")
    return remarks.sort((r1, r2) => (r1.created_at > r2.created_at ? -1 : 1));
  if (sortedKey === "date_reversed")
    return remarks.sort((r1, r2) => (r2.created_at > r1.created_at ? -1 : 1));
  if (sortedKey === "upvotes")
    return remarks.sort((r1, r2) => (r1.thumbs_up.length > r2.thumbs_up.length ? 1 : -1));
  if (sortedKey === "upvotes_reversed")
    return remarks.sort((r1, r2) => (r2.thumbs_up.length >= r1.thumbs_up.length ? 1 : -1));
  return remarks.sort((r1, r2) => (r1.created_at > r2.created_at ? -1 : 1));
};

export const replaceStrSeperators = (str: string): string =>
  str.replace(/\r/gi, "").replace(/\n/gi, ",");

export const numberTidier = (s: string) => {
  let newStr = s;
  const matchNumberInString = /([+-]?([0-9]*[.])?[0-9]){5,}/g;
  const numberExtracts = s.match(matchNumberInString);
  numberExtracts?.forEach((ne) => {
    newStr = newStr.replace(ne, millify(Number(ne), { precision: 2 }));
  });
  return newStr;
};

export const stringTidier = (s: string) => {
  const baseStr = s.replace(/\r|\n/gi, " ").replace(/\,\s|\,/gi, ", ");
  return numberTidier(baseStr);
};

export const SINGLE_LINE_TEXTFIELD_LENGTH = 20;

export const showDiffHTML = (s1: string, s2: string) => {
  var textDiff = Diff.diffWords(s1, s2); // produces diff array
  return textDiff.map((part: any) =>
    part.added
      ? `<ins>${part.value}</ins>`
      : part.removed
      ? `<del>${part.value}</del>`
      : `<span>${part.value}</span>`
  ); // produces a formatted HTML string
};

export const FIELD_SEPARATOR: string = ":---:";
