import {
  IconButton,
  MessageBar,
  MessageBarType,
  Persona,
  PersonaSize,
  Separator,
  Stack,
} from "@fluentui/react";
import { Report } from "powerbi-client";
import { FunctionComponent } from "react";
import { ReportManager, Remark as RemarkType } from "../../types/colab";
import moment from "moment";
import { useBoolean } from "@fluentui/react-hooks";
import { CommentWrapper } from "../../components/common/CommentWrapper";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";
import {
  useDeleteRemarkMutation,
  useLikeRemarkMutation,
  useUpdateRemarkMutation,
} from "../../api/remark";
import { EditRemark } from "./edit-remark";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectEditRemarkId,
  selectMicrosoftUserId,
  selectMsGraphApiAccessToken,
  setEditRemarkId,
} from "../../store/slices/remarkSlice";
import { useGetProfilePictureByIdQuery, useGetUserByIdQuery } from "../../api/users";
import { ProfileResult } from "../../types/user-profile";
import { useGetCommentsQuery } from "../../api/comments";
import { ThumbsUp } from "./thumbs-up";
import { CommentIcon } from "./comment-icon";
import { FIELD_SEPARATOR, showDiffHTML } from "../../helpers/remarkUtils";
import { Assignees } from "./assignees";
import { Visibility } from "./visibility";

export interface RemarkState {
  reportManager: ReportManager;
  remark: RemarkType;
}

const POLLING_FOR_COMMENTS_EVERY_ONE_MINUTE = 1000 * 60;

export const Remark: FunctionComponent<RemarkState> = ({ reportManager, remark }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [opened, { toggle: toggleOpen }] = useBoolean(false);
  const [showComments, { toggle: toggleShowComments }] = useBoolean(false);
  const [updateRemark] = useUpdateRemarkMutation();
  const [deleteRemark] = useDeleteRemarkMutation();
  const editRemarkId = useAppSelector(selectEditRemarkId);
  const accessToken = useAppSelector(selectMsGraphApiAccessToken);
  const microsoftUserId = useAppSelector(selectMicrosoftUserId) || "";
  const profileRequest = useGetUserByIdQuery({ userId: remark.creator, accessToken });
  const getProfilePictureRequest = useGetProfilePictureByIdQuery({
    userId: remark.creator,
    accessToken,
  });
  const imageUrl = getProfilePictureRequest.data;
  const user: ProfileResult | undefined = profileRequest.data;
  const comments = useGetCommentsQuery((remark && remark.id) ?? skipToken, {
    pollingInterval: POLLING_FOR_COMMENTS_EVERY_ONE_MINUTE,
  }); // Added interval for comments api so that we have latest comments getting updated on frontend for consistency.
  const userHasLiked = remark.thumbs_up.includes(microsoftUserId);
  const [likeRemark] = useLikeRemarkMutation();
  const [showErrorMessage, { toggle: toggleShowErrorMessage }] = useBoolean(false);
  const onClickThumbsUp = async () => {
    try {
      await likeRemark({
        remark,
        userId: microsoftUserId,
        thumbs_up: !userHasLiked,
      }).unwrap();
    } catch (error) {
      toggleShowErrorMessage();
    }
  };
  if (editRemarkId === remark.id)
    return <EditRemark onCancel={() => dispatch(setEditRemarkId(undefined))} remark={remark} />;

  return (
    <Stack key={remark.id}>
      <Stack
        className={`remarkTile status-${remark.status}`}
        style={{ border: "1px solid grey", padding: 8, cursor: "pointer", marginBottom: 4 }}
        onClick={async () => {
          await (reportManager?.report as Report).bookmarksManager.applyState(
            remark.bookmark ? remark.bookmark : ""
          );
          toggleOpen();
        }}
      >
        {showErrorMessage && (
          <Stack.Item style={{ marginTop: "5px", marginBottom: "5px" }}>
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
              onDismiss={() => {
                toggleShowErrorMessage();
              }}
              dismissButtonAriaLabel="Close"
            >
              {t("something_went_wrong")}
            </MessageBar>
          </Stack.Item>
        )}
        <Stack horizontal horizontalAlign="space-between">
          <Persona
            imageUrl={imageUrl}
            size={PersonaSize.size40}
            coinSize={30}
            text={user?.displayName}
            secondaryText={moment.utc(remark.created_at).fromNow()}
          />
          <IconButton
            menuProps={{
              items: [
                {
                  key: "delete",
                  text: t("delete"),
                  onClick: async () => {
                    try {
                      await deleteRemark(remark.id).unwrap();
                    } catch (error) {
                      if (error) {
                        toggleShowErrorMessage();
                      }
                    }
                  },
                },
                {
                  key: "edit",
                  text: t("edit"),
                  onClick: () => {
                    dispatch(setEditRemarkId(remark.id));
                  },
                },
                {
                  key: remark.status === "SOLVED" ? "unresolve" : "resolve",
                  text: remark.status === "SOLVED" ? t("unresolve") : t("resolve"),
                  onClick: async () => {
                    try {
                      await updateRemark({
                        remarkId: remark.id,
                        remark: {
                          ...remark,
                          status: remark.status === "SOLVED" ? "OPEN" : "SOLVED",
                        },
                      }).unwrap();
                    } catch (error) {
                      toggleShowErrorMessage();
                    }
                  },
                },
              ],
            }}
            onRenderMenuIcon={() => null}
            iconProps={{ iconName: "MoreVertical" }}
            title="More"
            ariaLabel="More"
          />
        </Stack>

        <div
          className="changedText"
          style={{ marginTop: 8 }}
          dangerouslySetInnerHTML={{
            __html: showDiffHTML(
              remark.original_field.replaceAll(FIELD_SEPARATOR, ""),
              remark.changed_field.replaceAll(FIELD_SEPARATOR, "")
            ),
          }}
        />
        <Separator />
        <Stack.Item>
          {opened ? (
            <Stack tokens={{ childrenGap: 4 }}>
              <div
                className="rich-text"
                dangerouslySetInnerHTML={{ __html: remark.description }}
              ></div>
              {remark.assignees.length > 0 && <Assignees remark={remark} />}
              {remark.visibility === "SPECIFIC" && <Visibility remark={remark} />}
              <div className="open-closer">{t("close")}</div>
            </Stack>
          ) : (
            <Stack>
              <div
                className={`overflow-container rich-text text-l${remark.description.length}`}
                dangerouslySetInnerHTML={{ __html: remark.description }}
              ></div>
              <div className="open-closer">{t("to-open")}</div>
            </Stack>
          )}
          <Separator />

          <Stack horizontal verticalAlign="center" horizontalAlign="end">
            <CommentIcon
              count={(comments?.data?.comments || []).length}
              toggleShowComments={toggleShowComments}
            />
            <ThumbsUp
              isActive={userHasLiked}
              count={remark.thumbs_up.length}
              onClick={onClickThumbsUp}
            />
          </Stack>
        </Stack.Item>
      </Stack>

      {showComments && (
        <Stack style={{ marginLeft: 16 }}>
          <CommentWrapper
            comments={comments?.data?.comments || []}
            subjectType={"remark"}
            subjectId={remark.id}
            commentThreadId={comments?.data?.id}
          />
        </Stack>
      )}
    </Stack>
  );
};
