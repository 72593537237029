import React, { createElement } from "react";
import { ReactComponent as problemsNA } from "../../assets/problems-NA.svg";
import { ReactComponent as problemsNone } from "../../assets/problems-none.svg";
import { ReactComponent as problemsFew } from "../../assets/problems-few.svg";
import { ReactComponent as problemsSevere } from "../../assets/problems-severe.svg";

export interface ProblemsProps {
  score: number;
  width?: string;
  height?: string;
}
const problemIconStyle: React.CSSProperties = {
  marginRight: 10,
  marginLeft: 10,
  cursor: "pointer",
  paddingBottom: 3,
};
export const ProblemIcon: React.FunctionComponent<ProblemsProps> = ({
  score = 1,
  width = "100%",
  height = "8",
}) => {
  const problemSVGs = [problemsNA, problemsNone, problemsFew, problemsSevere].map((val) =>
    createElement(val, { width: `${width}`, height: `${height}px` })
  );
  return <div style={problemIconStyle}>{problemSVGs[score - 1]}</div>;
};
