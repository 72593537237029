import { QuickAnalysisIssue, QualityScore } from "../../../types/quickAnalysis";

const WARNING_ISSUE_WEIGHTING = 0.8;
export const getQualityScore = (issues: QuickAnalysisIssue[], totalRows: number): QualityScore => {
  const outstandingIssues = issues.filter(
    (issue) => !issue.isIgnored && !issue.isSolved && issue.type !== "sensitive"
  );
  const warningIssues = outstandingIssues.filter((issue) => issue.severity === "warning");
  const infoIssues = outstandingIssues.filter((issue) => issue.severity === "info");
  const infoScore = Math.min(infoIssues.length / totalRows, 1);
  const warningScore = Math.min(warningIssues.length / totalRows, 1);
  const score = WARNING_ISSUE_WEIGHTING * warningScore + (1 - WARNING_ISSUE_WEIGHTING) * infoScore;
  if (score > 0.59) return "E";
  if (score > 0.39) return "D";
  if (score > 0.25) return "C";
  if (score > 0.1) return "B";
  return "A";
};

export const getMostImportantIssueForCell = (
  issues: QuickAnalysisIssue[],
  activeFilter?: string,
  selectedIssue?: QuickAnalysisIssue
): QuickAnalysisIssue | undefined => {
  if (issues.length === 0) return undefined;
  if (issues.length === 1) return issues[0];
  return issues.sort((issueA, issueB) => {
    if (issueA.id === selectedIssue?.id) return -1;
    if (issueB.id === selectedIssue?.id) return 1;
    // Solved issues come last
    if (issueA.isSolved && !issueB.isSolved) return 1;
    if (issueB.isSolved && !issueA.isSolved) return -1;
    // Actively filtered issues take precedent
    if (issueA.type === activeFilter && issueB.type !== activeFilter) return -1;
    if (issueB.type === activeFilter && issueA.type !== activeFilter) return 1;
    // CustomRules have priority over other rules
    if (issueA.type === "custom" && issueB.type !== "custom") return -1;
    if (issueB.type === "custom" && issueA.type !== "custom") return 1;
    // Warnings
    if (issueA.severity === "warning" && issueB.severity !== "warning") return -1;
    if (issueB.severity === "warning" && issueA.severity !== "warning") return 1;
    return 0;
  })[0];
};
