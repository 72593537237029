import { FunctionComponent, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { OvertimeChange } from "./OvertimeChange";
import { ReactComponent as LearningImage } from "../../../assets/learning.svg";

const addHeadStyle: CSSProperties = {
  margin: "0",
};

const mainBoxStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  height: "210px",
};

const iconStyle: CSSProperties = {
  width: "59px",
  height: "59px",
};

const countStyle: CSSProperties = {
  fontWeight: "500",
  fontSize: "40px",
  color: "#292929",
};

const subContainerStyle: CSSProperties = {
  boxSizing: "border-box",
  padding: "15px",
  width: "361px",
  height: "264px",
  background: "#ffffff",
  border: "1px solid #cccccc",
};

export const LearningJourneys: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <section style={subContainerStyle}>
      <h3 style={addHeadStyle}>{t("performance_learning")}</h3>
      <div style={mainBoxStyle}>
        <LearningImage style={iconStyle} />
        <p style={countStyle}>271</p>
        <OvertimeChange score={42} percentage={22} />
      </div>
    </section>
  );
};
