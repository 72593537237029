import { useAppSelector } from "../../store/hooks";
import { selectLatestQuickAnalysisStatus } from "../../store/slices/quickAnalysis";
import { Loader } from "../../components/quick-analysis/Loader";
import { AnalysisResult } from "../../components/quick-analysis/AnalysisResult";
import { TopContainer } from "../../components/common/TopContainer";
import { t } from "i18next";
import { QuickAnalysisDragAndDrop } from "../../components/quick-analysis/QuickAnalysisDragAndDrop";
import { Breadcrumbs } from "../../components/quick-analysis/Breadcrumbs";
import { RuleOverview } from "../../components/quick-analysis/rule-overview/RuleOverview";

export const QuickAnalysis = (): JSX.Element => {
  const status = useAppSelector(selectLatestQuickAnalysisStatus);

  return (
    <TopContainer title={t("quick_analysis")}>
      <Breadcrumbs />
      {["selectFile", "loadingFile", "filePreview"].includes(status) && (
        <QuickAnalysisDragAndDrop />
      )}
      {status === "analyzing" && <Loader />}
      {status === "results" && <AnalysisResult />}
      {status === "rules" && <RuleOverview />}
    </TopContainer>
  );
};
