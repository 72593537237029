import { Stack, Text } from "@fluentui/react";
import { FunctionComponent, createElement } from "react";
import { ReactComponent as PSQLLogo } from "../../assets/postgresql-logo.svg";
import { ReactComponent as BQLogo } from "../../assets/bigquery-logo.svg";
import { ReactComponent as SnowflakeLogo } from "../../assets/snowflake-logo.svg";

interface DataConnectorButtonIconProps {
  iconName: string;
  width?: string;
  height?: string;
}

export const DataConnectorButtonIcon: React.FunctionComponent<DataConnectorButtonIconProps> = ({
  iconName = "none",
  width = "20",
  height = "20",
}) => {
  const iconMap: Record<string, FunctionComponent<any>> = {
    postgresql: PSQLLogo,
    bigquery: BQLogo,
    snowflake: SnowflakeLogo,
    "": {} as FunctionComponent,
  };
  return (
    <Stack styles={{ root: { marginTop: 2 } }}>
      {createElement(iconMap[iconName], { width: `${width}`, height: `${height}px` })}
    </Stack>
  );
};

type DataConnectorButtonProps = {
  title: string;
  iconName: string;
  onClick: () => void;
};

export const DataConnectorButton = (props: DataConnectorButtonProps) => {
  return (
    <Stack
      horizontal
      styles={{
        root: {
          borderRadius: 3,
          border: "1px solid lightgrey",
          padding: 10,
          cursor: "pointer",
          minWidth: 160,
          minHeight: 56,
          selectors: {
            ":hover": {
              border: "1px solid grey",
            },
          },
        },
      }}
      tokens={{ childrenGap: 4 }}
      horizontalAlign="center"
      verticalAlign="center"
      onClick={props.onClick}
    >
      <Stack.Item>
        <Text
          styles={{
            root: {
              fontFamily: "SegoeUI-Light, Segoe UI, Helvetica, Verdana, Arial",
              fontSize: 18,
            },
          }}
        >
          {props.title}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <DataConnectorButtonIcon iconName={props.iconName} />
      </Stack.Item>
    </Stack>
  );
};
