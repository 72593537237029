import { FunctionComponent, useEffect, useState } from "react";
import { MissionData } from "../../../types/missions";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useBoolean } from "@fluentui/react-hooks";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";
import { IStackStyles, IStackTokens, Stack } from "@fluentui/react";
import {
  useDeleteMissionMutation,
  useGetMissionByIdQuery,
  useUpdateMissionMutation,
} from "../../../api/mission";
import { useTranslation } from "react-i18next";
import { MissionBrief } from "./MissionBrief";
import { MissionQualityBrief } from "./MissionQualityBrief";

const headingQualityContainer: IStackStyles = {
  root: {
    display: "flex",
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 5,
};

export interface HeaderSectionState {
  mission?: MissionData;
}

export const HeaderSection: FunctionComponent<HeaderSectionState> = () => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const params = useParams();
  const missionRequest = useGetMissionByIdQuery(params.id ?? skipToken);
  const [mission, setMission] = useState(missionRequest.data);
  const [hideDeleteMissionDialog, { toggle: toggleHideDeleteMissionDialog }] = useBoolean(true);
  const [deleteMissionReq] = useDeleteMissionMutation();

  const [updateMission] = useUpdateMissionMutation();

  let navigate = useNavigate();

  useEffect(() => {
    setMission(missionRequest.data);
  }, [missionRequest]);

  const updateMissionFunc = async () => {
    if (isEditing && mission?.id) {
      await updateMission({ mission: mission, missionId: mission?.id });
    }
    setIsEditing(!isEditing);
  };

  const deleteMission = async () => {
    if (!mission?.id) return;
    try {
      await deleteMissionReq(mission.id).unwrap();
      navigate("/missions");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <ConfirmationDialog
        showHideDialog={hideDeleteMissionDialog}
        toggleShowHideDialog={toggleHideDeleteMissionDialog}
        primaryButtonText={t("delete")}
        secondaryButtonText={t("cancel")}
        primaryButtonClicked={deleteMission}
        dialogTitle={t("mission_delete_title")}
        confirmationText={t("mission_delete_confirmation")}
      ></ConfirmationDialog>

      <Stack horizontal tokens={stackTokens} styles={headingQualityContainer}>
        {missionRequest.isLoading ? null : (
          <MissionBrief
            isEditing={isEditing}
            mission={mission}
            setMission={setMission}
            updateMissionFunc={updateMissionFunc}
            toggleHideDeleteMissionDialog={toggleHideDeleteMissionDialog}
          ></MissionBrief>
        )}

        <MissionQualityBrief mission={mission}></MissionQualityBrief>
      </Stack>
    </>
  );
};
