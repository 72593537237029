import { DetailsList, SelectionMode, Spinner, Stack } from "@fluentui/react";
import { TopContainer } from "../../components/common/TopContainer";
import { useGetAllAlertsQuery } from "../../api/v2/alertsNotifications";
import { Link, useNavigate } from "react-router-dom";

export const AlertsOverview = () => {
  const navigate = useNavigate();
  const { data: alerts, isLoading } = useGetAllAlertsQuery({});

  if (isLoading) return <Spinner title="loading" />;

  return (
    <TopContainer title="Alerts">
      <Stack>
        <DetailsList
          items={
            alerts?.map((alert) => ({
              key: alert.id ?? "",
              name: alert.name,
            })) ?? []
          }
          columns={[
            {
              key: "name",
              name: "Name",
              fieldName: "name",
              minWidth: 100,
              maxWidth: 200,
              isResizable: true,
              onRender: (item) => <Link to={`/alerts/${item?.key ?? ""}`}>{item.name}</Link>,
            },
          ]}
          onActiveItemChanged={(item) => {
            navigate(`/alerts/${item?.key ?? ""}`);
          }}
          selectionMode={SelectionMode.none}
        />
      </Stack>
    </TopContainer>
  );
};
