import { SearchResult } from "../../types/colab";

export const GlossaryFakeSearchData: SearchResult[] = [
  {
    title: "Quantity",
    description: "Number of individual units sold. Includes product bundles and single SKUs.",
  },
  {
    title: "Avg Unit Price",
    description: "Sales divided by Quantity equals the Average Unit Price",
  },
  { title: "Price", description: "Exchange rates; overview of VAT taxes by country." },
  {
    title: "Sales by Product",
    description:
      "Sales (revenue) divided by product category equals Sales by Product (denoted in US dollars).",
  },
  {
    title: "Sales % All Regions",
    description:
      "Regional sales as percentage of total sales (e.g., overall or for selected products). Calculated in US dollars, i.e. both regional and total sales numbers are converted in US$.",
  },
  {
    title: "Sales Decomposition",
    description: "Break-down of Sales numbers by Demographic, Product and Region.",
  },
];
