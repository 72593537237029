import {
  ITextStyles,
  ICheckboxStyles,
  ITextFieldStyles,
  IStackProps,
  IChoiceGroupStyles,
} from "@fluentui/react";

export const MODAL_DETAIL_LEFT_MARGIN = "16%";

export const textStyles: ITextStyles = {
  root: { marginLeft: MODAL_DETAIL_LEFT_MARGIN, width: "12%", fontSize: 16, fontWeight: 300 },
};

export const checkBoxStyles: ICheckboxStyles = {
  checkbox: { borderRadius: 4 },
  text: { fontSize: 16, fontWeight: 300 },
};

export const sensitiveCheckBoxStyles: ICheckboxStyles = {
  ...checkBoxStyles,
  root: { marginLeft: MODAL_DETAIL_LEFT_MARGIN },
};

export const textFieldStyles: Partial<ITextFieldStyles> = {
  fieldGroup: { borderRadius: 4 },
  root: { width: "90%" },
};

const monospaced =
  'Menlo, "DejaVu Sans Mono", "Liberation Mono", Consolas, "Ubuntu Mono", "Courier New", "andale mono", "lucida console", monospace';

export const patternTextFieldStyles: Partial<ITextFieldStyles> = {
  root: { fontFamily: monospaced, width: "90%" },
  fieldGroup: { borderRadius: 4 },
};

export const columnMatchingParentStackProps: IStackProps = {
  styles: { root: { textAlign: "left", width: "50%" } },
  tokens: { childrenGap: 8 },
};

export const ChoiceGroupStyles: IChoiceGroupStyles = {
  root: {
    marginLeft: 4,
    alignSelf: "baseline",
    selectors: {
      ".ms-ChoiceField-wrapper": {
        alignSelf: "baseline",
      },
    },
  },
  label: {
    display: "inline",
    alignSelf: "baseline",
  },
  flexContainer: {
    columnGap: "1em",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingLeft: 3,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 4,
    marginTop: -6,
    alignSelf: "baseline",
  },
};
