import { RulesetApi, RulesetApiUpdate } from "../../types/rules";
import { dqcApi } from "../dqcQuery";

export const rulesetsApi = dqcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllRulesets: builder.query<RulesetApi[], any>({
      query: () => ({ url: `/apiV2/rulesets/`, method: "get" }),
      providesTags: ["Rulesets"],
    }),
    getRulesetsForDataTable: builder.query<RulesetApi[], string>({
      query: (data_table_id) => ({
        url: `/apiV2/rulesets/?data_table_id=${data_table_id}`,
        method: "get",
      }),
      providesTags: ["Rulesets"],
    }),
    updateRuleset: builder.mutation<RulesetApi, RulesetApiUpdate>({
      query: (ruleset) => ({ url: `/apiV2/rulesets/${ruleset.id}`, method: "put", data: ruleset }),
      invalidatesTags: ["Rules", "DataConnectorList", "Rulesets"],
    }),
  }),
});

export const { useGetAllRulesetsQuery, useGetRulesetsForDataTableQuery, useUpdateRulesetMutation } =
  rulesetsApi;
