import { Stack, Text, Dropdown, IDropdownOption, TextField } from "@fluentui/react";
import { t } from "i18next";
import { RuleCandidate } from "../../../../types/rules";
import { useState } from "react";
import { NameAndDescriptionInput } from "./../NameDescription";
import { RuleDetailModalFooter } from "./../RuleDetailModalFooter";
import { MODAL_DETAIL_LEFT_MARGIN, columnMatchingParentStackProps } from "./bodyStyles";
import { useAppSelector } from "../../../../store/hooks";
import { selectLatestUpload } from "../../../../store/slices/upload";
import { ColumnDefinition } from "../../../../types/fileUploader";

export const ColumnCorrelationRuleDetailBody = ({
  rule,
  toggleModal,
}: {
  rule: RuleCandidate;
  toggleModal: () => void;
}) => {
  const [localRule, setLocalRule] = useState<RuleCandidate>(rule);
  const uploadedFile = useAppSelector(selectLatestUpload);
  if (!uploadedFile || localRule.qualityTest.testFunctionName !== "columnCorrelation") return null;
  const localQualityTest = localRule.qualityTest;
  const options: IDropdownOption<ColumnDefinition>[] = uploadedFile.columns.map((c) => ({
    key: `${rule.name}::${c.name}`,
    text: c.name,
    data: c,
  }));
  const optionsFirstColumn = options.filter((option) => option.text !== localRule.columns[1].name);
  const optionsSecondColumn = options.filter((option) => option.text !== localRule.columns[0].name);

  return (
    <>
      <Stack tokens={{ childrenGap: 12 }}>
        <NameAndDescriptionInput rule={localRule} updateRule={setLocalRule} />
        <Stack
          styles={{ root: { marginLeft: MODAL_DETAIL_LEFT_MARGIN, marginTop: 4 } }}
          tokens={{ childrenGap: 8 }}
        >
          <Stack>
            <Text>{t("column_correlation_header")}</Text>
            <TextField
              defaultValue={localQualityTest.meta.correlation.toString()}
              onGetErrorMessage={(newValue) => {
                if (!isValidInput(newValue)) return t("invalid_correlation");
              }}
              styles={{ root: { width: "100%" }, fieldGroup: { borderRadius: 4 } }}
              onChange={(_event, newValue?: string | undefined) => {
                if (newValue === undefined || !isValidInput(newValue)) return;
                const updatedCorrelation =
                  Math.round(parseFloat(newValue.replace(",", ".")) * 100) / 100;
                const newRule: RuleCandidate = {
                  ...localRule,
                  qualityTest: {
                    ...localQualityTest,
                    meta: { correlation: updatedCorrelation },
                  },
                };
                setLocalRule(newRule);
              }}
            />
          </Stack>
          <Stack tokens={{ childrenGap: 16 }} horizontal>
            <Stack {...columnMatchingParentStackProps}>
              <Text styles={{ root: { alignSelf: "start" } }}>{t("first_column_matching")}</Text>
              <Dropdown
                options={optionsFirstColumn}
                onChange={(_, option) => {
                  if (!option) return;
                  const newRule: RuleCandidate = {
                    ...localRule,
                    columns: [option.data, localRule.columns[1]],
                  };
                  setLocalRule(newRule);
                }}
                defaultSelectedKey={`${rule.name}::${localRule.columns[0].name}`}
                styles={{ title: { borderRadius: 4 }, root: { width: "100%" } }}
              />
            </Stack>
            <Stack {...columnMatchingParentStackProps}>
              <Text styles={{ root: { alignSelf: "start" } }}>{t("second_column_matching")}</Text>
              <Dropdown
                options={optionsSecondColumn}
                onChange={(_, option) => {
                  if (!option) return;
                  const newRule: RuleCandidate = {
                    ...localRule,
                    columns: [localRule.columns[0], option.data],
                  };
                  setLocalRule(newRule);
                }}
                defaultSelectedKey={`${rule.name}::${localRule.columns[1].name}`}
                styles={{ title: { borderRadius: 4 }, root: { width: "100%" } }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <RuleDetailModalFooter rule={localRule} updateRule={setLocalRule} toggleModal={toggleModal} />
    </>
  );
};

const inputValidatonRegex = /^[0-1]$|^0?[\.,]\d+$|^1[\.,]0$/;
const isValidInput = (value: string): boolean => {
  return inputValidatonRegex.test(value);
};
