/**
 * Creates the comment for inconsistent issues. When a columnName is passed,
 * the result will contain the column information and be used to create the comment column.
 * Otherwise the result is used as issue explanation.
 *
 * @param issueComment Comment describing the issue
 * @param t Translation function
 * @param columnName Name of the column the issue is in - needed to create  the comment column
 * @returns
 */
export const getInconsistentIssueComment = (
  issueComment: string,
  t: (key: string) => string,
  columnName?: string
): string => {
  const columnInfo = `in ${t("column")} ${columnName}`;
  if (!issueComment.includes("format") || issueComment === "inconsistent_date_format_comment")
    return columnName === undefined ? `${t(issueComment)}` : `${t(issueComment)} ${columnInfo}`;
  // we construct a <cellNumberFormatCategory>_format_in_<mostFrequentNumberFormatCategory> comment for inconsistent numberFormats
  // To not need 12x11 translation strings, we translate the categories and plug it into the static parts
  else {
    const commentStringParts: string[] = issueComment.split("_");
    if (commentStringParts.length !== 4)
      throw new Error("Couldn't construct inconsistent issue comment!");
    const [cellFormat, format, ...dominantFormat] = commentStringParts;
    const rawComment = `${t(cellFormat)} ${t(format)} in ${t(
      `${dominantFormat[1]}_dominant_format`
    )}`;
    return columnName === undefined ? rawComment : `${rawComment} ${columnInfo}`;
  }
};
