import { IStackStyles, PrimaryButton, Stack } from "@fluentui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetGroupsQuery } from "../../api/companyAndGroups";
import { Group } from "../../types/group";
import { ConfirmationDialogProps } from "../../types/missions";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { AddGroupModal } from "./AddGroupModal";
import { GroupHeader } from "./GroupHeader";
import { GroupList } from "./GroupList";

const mainStyle: IStackStyles = {
  root: {
    marginTop: "20px",
  },
};

export const ColleaguesGroupsDetail: FunctionComponent = () => {
  const { t } = useTranslation();
  const groupList = useGetGroupsQuery("");
  const [showGroupModal, setShowGroupModal] = useState<boolean>(false);
  const [currentGroups, setCurrentGroups] = useState<Group[] | undefined>(groupList.data);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState<ConfirmationDialogProps>();

  useEffect(() => {
    setCurrentGroups(groupList.data);
  }, [groupList]);

  return (
    <Stack styles={mainStyle}>
      <ConfirmationDialog
        showHideDialog={confirmationDialogProps?.showHideDialog}
        toggleShowHideDialog={confirmationDialogProps?.toggleShowHideDialog}
        primaryButtonText={confirmationDialogProps?.primaryButtonText}
        secondaryButtonText={confirmationDialogProps?.secondaryButtonText}
        primaryButtonClicked={confirmationDialogProps?.primaryButtonClicked}
        dialogTitle={confirmationDialogProps?.dialogTitle}
        confirmationText={confirmationDialogProps?.confirmationText}
      />
      {showGroupModal ? (
        <AddGroupModal showGroupModal={showGroupModal} setShowGroupModal={setShowGroupModal} />
      ) : null}
      <div>
        <PrimaryButton text={t("add_group")} onClick={() => setShowGroupModal(true)} />
      </div>
      {currentGroups?.map((group, index) => (
        <div key={index}>
          <div>
            <GroupHeader group={group} setConfirmationDialogProps={setConfirmationDialogProps} />
          </div>
          <div>
            <GroupList group={group} setConfirmationDialogProps={setConfirmationDialogProps} />
          </div>
        </div>
      ))}
    </Stack>
  );
};
