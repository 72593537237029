import { Stack, Text, Dropdown, IDropdownOption } from "@fluentui/react";
import { t } from "i18next";
import { RuleCandidate } from "../../../../types/rules";
import { useState } from "react";
import { NameAndDescriptionInput } from "./../NameDescription";
import { RuleDetailModalFooter } from "./../RuleDetailModalFooter";

import { MODAL_DETAIL_LEFT_MARGIN, columnMatchingParentStackProps } from "./bodyStyles";
import { useAppSelector } from "../../../../store/hooks";
import { selectLatestUpload } from "../../../../store/slices/upload";
import { ColumnDefinition } from "../../../../types/fileUploader";

export const ColumnMatchRuleDetailBody = ({
  rule,
  toggleModal,
}: {
  rule: RuleCandidate;
  toggleModal: () => void;
}) => {
  const [localRule, setLocalRule] = useState<RuleCandidate>(rule);
  const uploadedFile = useAppSelector(selectLatestUpload);
  if (localRule.qualityTest.testFunctionName !== "columnMatching" || !uploadedFile) return null;
  const options: IDropdownOption<ColumnDefinition>[] = uploadedFile.columns.map((c) => ({
    key: `${rule.name}::${c.name}`,
    text: c.name,
    data: c,
  }));
  const optionsFirstColumn = options.filter((option) => option.text !== localRule.columns[1].name);
  const optionsSecondColumn = options.filter((option) => option.text !== localRule.columns[0].name);
  return (
    <>
      <Stack tokens={{ childrenGap: 12 }}>
        <NameAndDescriptionInput rule={localRule} updateRule={setLocalRule} />
        <Stack
          tokens={{ childrenGap: 16 }}
          horizontal
          styles={{ root: { marginLeft: MODAL_DETAIL_LEFT_MARGIN, marginTop: 4 } }}
        >
          <Stack {...columnMatchingParentStackProps}>
            <Text styles={{ root: { alignSelf: "start" } }}>{t("first_column_matching")}</Text>
            <Dropdown
              options={optionsFirstColumn}
              onChange={(_, option) => {
                if (!option) return;
                const newRule: RuleCandidate = {
                  ...localRule,
                  columns: [option.data, localRule.columns[1]],
                };
                setLocalRule(newRule);
              }}
              defaultSelectedKey={`${rule.name}::${localRule.columns[0].name}`}
              styles={{ title: { borderRadius: 4 }, root: { width: "100%" } }}
            />
          </Stack>
          <Stack {...columnMatchingParentStackProps}>
            <Text styles={{ root: { alignSelf: "start" } }}>{t("second_column_matching")}</Text>
            <Dropdown
              options={optionsSecondColumn}
              onChange={(_, option) => {
                if (!option) return;
                const newRule: RuleCandidate = {
                  ...localRule,
                  columns: [localRule.columns[0], option.data],
                };
                setLocalRule(newRule);
              }}
              defaultSelectedKey={`${rule.name}::${localRule.columns[1].name}`}
              styles={{ title: { borderRadius: 4 }, root: { width: "100%" } }}
            />
          </Stack>
        </Stack>
      </Stack>
      <RuleDetailModalFooter rule={localRule} updateRule={setLocalRule} toggleModal={toggleModal} />
    </>
  );
};
