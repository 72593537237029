import { Dropdown, IDropdownOption, Label, Stack } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectSortOption, setSortOption } from "../../store/slices/cockpitSlice";
import { SortOption } from "../../types/cockpit";

export const SortBy: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const sortOptions: IDropdownOption[] = [
    { key: "custom", text: t("custom") },
    { key: "problems", text: t("problems") },
    { key: "value", text: t("value") },
    { key: "use-cases", text: t("use-cases") },
  ];
  const dispatch = useAppDispatch();
  const sortBy = useAppSelector(selectSortOption);
  const handleChangeSort = (_: any, option?: IDropdownOption): void => {
    dispatch(setSortOption(option?.key as SortOption));
  };

  return (
    <Stack horizontal tokens={{ childrenGap: 10 }} className="orderContainer">
      <Label className="dropdownLabel">{t("order")}:</Label>
      <Dropdown
        options={sortOptions}
        onChange={handleChangeSort}
        selectedKey={sortBy}
        className="showFieldDropdown"
      />
    </Stack>
  );
};
