import {
  DefaultButton,
  DefaultPalette,
  Dialog,
  DialogFooter,
  DialogType,
  IButtonStyles,
  IStackStyles,
  Label,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { useDeleteSubscriptionMutation, useGetMyProfileQuery } from "../../api/profile";
import { useState } from "react";

import PricingTable from "../../components/licenses/PricingTable";
import { ThankYouForPurchase } from "./thankyou";
import { useFetchLogoutDataQuery } from "../../api/profile";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const stackStyle: IStackStyles = {
  root: {
    paddingLeft: "20px",
    paddingRight: "20px",
    marginTop: 10,
  },
};

const deleteAccount: IButtonStyles = {
  root: {
    backgroundColor: DefaultPalette.red,
    color: DefaultPalette.white,
    borderColor: DefaultPalette.red,
    width: 200,
  },
};

const warningStyle: IStackStyles = {
  root: {
    border: "1px solid red",
    borderRadius: "0.3em",
    padding: "10px",
    marginBottom: 10,
    marginTop: 10,
  },
};

export const ManageLicenses = (): JSX.Element | null => {
  const { t } = useTranslation();
  const profileRequest = useGetMyProfileQuery({});
  const logoutData = useFetchLogoutDataQuery({});
  const [cancelSubscriptionTrigger, cancelSubscriptionResult] = useDeleteSubscriptionMutation();
  const [showDialog, setShowDialog] = useState(false);
  const [searchParams] = useSearchParams();

  const buySuccess = searchParams.get("buy") === "success";

  const noLicenseCondition = !profileRequest.currentData?.license && !buySuccess;
  const showCancelLicenseCondition =
    profileRequest.data?.isSelfServiceAccount || cancelSubscriptionResult.data;
  const dialogContentProps = {
    type: DialogType.normal,
    title: t("cancel_subscription"),
    closeButtonAriaLabel: "Close",
    subText: t("are_you_sure_cancel_subscription"),
  };

  if (!logoutData.isSuccess) return null;
  return (
    <Stack styles={stackStyle}>
      {buySuccess && <ThankYouForPurchase />}
      <Dialog
        hidden={!showDialog}
        onDismiss={() => setShowDialog(false)}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <DefaultButton
            onClick={() => setShowDialog(false)}
            title={t("abort")}
            text={t("abort")}
          />
          <PrimaryButton
            onClick={() => {
              profileRequest.data?.email
                ? cancelSubscriptionTrigger(profileRequest?.data.email).then(() =>
                    profileRequest.refetch()
                  )
                : alert(t("no_email"));
              setShowDialog(false);
            }}
            title={t("cancel_subscription")}
            text={t("cancel_subscription")}
            styles={deleteAccount}
          />
        </DialogFooter>
      </Dialog>
      <Stack tokens={{ childrenGap: 10 }} horizontalAlign="center">
        {noLicenseCondition ? (
          <PricingTable email={profileRequest.data?.email} />
        ) : (
          <p>{t("profile_active_license")}</p>
        )}
        <DefaultButton
          style={{ width: 200 }}
          href={"/profile"}
          title={t("manage_profile")}
          text={t("manage_profile")}
        />
        <Stack.Item>
          {showCancelLicenseCondition && (
            <Stack styles={warningStyle}>
              <Label>{t("profile_warning_area")}</Label>
              {!cancelSubscriptionResult.data ? (
                <DefaultButton
                  title={t("cancel_subscription")}
                  text={t("cancel_subscription")}
                  styles={deleteAccount}
                  onClick={() => setShowDialog(true)}
                />
              ) : (
                <p>{t(`cancel_subscription_${cancelSubscriptionResult.data}`)}</p>
              )}
            </Stack>
          )}
        </Stack.Item>
      </Stack>
    </Stack>
  );
};
