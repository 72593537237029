import { FunctionComponent, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { useGetDataQualityScoresQuery } from "../../../api/performance";
import { OvertimeChange } from "./OvertimeChange";
import { QualityGauge } from "../../common/QualityGauge";
import { mainContainerStyle } from "../../../helpers/commonStyles";

const subContainerStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  height: "220px",
};

const addHeadStyle: CSSProperties = {
  margin: "0",
};

const datasetContainerStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const dataCountStyle: CSSProperties = {
  fontWeight: "500",
  fontSize: "40px",
  margin: "0",
  color: "#292929",
};

const dataTextStyle: CSSProperties = {
  margin: "0",
  fontWeight: "400",
  fontSize: "13px",
  color: "#666666",
};

const overtimeContainerStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  marginTop: "8%",
};

const dateStyle: CSSProperties = {
  margin: "0",
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "20px",
  color: "#666666",
};

const infoStyle: CSSProperties = {
  display: "flex",
  height: "224px",
  alignItems: "center",
  justifyContent: "center",
};

export const QualityScore: FunctionComponent = () => {
  const { t } = useTranslation();
  const { isLoading, isSuccess, data } = useGetDataQualityScoresQuery();

  return (
    <section style={mainContainerStyle}>
      <h3 style={addHeadStyle}>{t("data_q_score")}</h3>
      {!isLoading && isSuccess ? (
        <div style={subContainerStyle}>
          <div>
            <QualityGauge qualityScore={data?.quality_score} />
          </div>
          <div style={datasetContainerStyle}>
            <p style={dataCountStyle}>{data?.nr_datasets}</p>
            <p style={dataTextStyle}>#{t("datasets")}</p>
          </div>
          <div style={overtimeContainerStyle}>
            <OvertimeChange
              score={data?.changed_score!}
              percentage={data?.changed_score_percentage!}
            />
            <p style={dateStyle}>{moment(data?.start_date).format("DD.MM.YYYY")}</p>
          </div>
        </div>
      ) : (
        <span style={infoStyle}>
          <Spinner size={SpinnerSize.large} />
        </span>
      )}
    </section>
  );
};
