import { Stack } from "@fluentui/react";
import { useAppSelector } from "../../store/hooks";
import { selectFirstThreeNotifications } from "../../store/slices/notificationCenter";
import { parentContainerStyle } from "./notificationStyles";
import { NotificationCard } from "./NotificationCard";

export const NotificationCenter: React.FC = () => {
  const notificationsToDisplay = useAppSelector(selectFirstThreeNotifications);
  return (
    <Stack styles={parentContainerStyle} tokens={{ childrenGap: 4 }}>
      {notificationsToDisplay.map((notification) => {
        return <NotificationCard notification={notification} />;
      })}
    </Stack>
  );
};
