import { IStackItemStyles } from "@fluentui/react";
import { dqcPalette } from "../../../utils/colors";

export const getQualityContainerStyle = (isActiveFilter: boolean): IStackItemStyles => {
  return {
    root: {
      boxShadow: isActiveFilter ? "0 0 0 1px #D2D0CE" : "none",
      borderRadius: "4px",
      backgroundColor: "white",
      selectors: {
        ":hover": {
          boxShadow: isActiveFilter ? "0 0 0 1px #D2D0CE" : "none",
          borderRadius: "4px",
          backgroundColor: "#F0F0F0",
        },
      },
    },
  };
};

export const iconStyle: IStackItemStyles = {
  root: {
    fontSize: "1.5em",
    marginRight: 20,
    width: "21px",
    height: "25px",
  },
};

export const getStackStyle = (enabled: boolean): IStackItemStyles => {
  return {
    root: {
      color: enabled ? "unset" : "rgba(171, 167, 167, 1)",
      pointerEvents: enabled ? "unset" : "none",
    },
  };
};

export const getSeparatorStyle: IStackItemStyles = {
  root: {
    marginTop: -10,
    marginBottom: -10,
  },
};

export const getInnerStackStyle: IStackItemStyles = {
  root: { marginLeft: 20, minHeight: 44 },
};

export const getTextStyle = (enabled: boolean): IStackItemStyles => {
  return {
    root: {
      color: enabled ? "unset" : dqcPalette.grey,
    },
  };
};

export const getHighLightedStyle: IStackItemStyles = {
  root: {
    width: "calc(100% - 50px)",
    margin: "0px auto 5px auto",
    overflowY: "hidden",
  },
};
