import React, { useState } from "react";
import {
  ITagPickerProps,
  TagPicker,
  ValidationState,
  ITag,
  IBasePickerSuggestionsProps,
  ITagItemStyles,
  ITagItemStyleProps,
  IStyleFunctionOrObject,
  Stack,
} from "@fluentui/react";
import { t } from "i18next";
import { FunctionComponent } from "react";

const getTextFromItem = (item: ITag) => item.name;

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: t("suggest_tags"),
  noResultsFoundText: t("no_tags_found"),
};

const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some((compareTag) => compareTag.key === tag.key);
};

type ExtendedTagPickerProps = Omit<ITagPickerProps, "onResolveSuggestions"> & {
  tags: ITag[];
  onResolveSuggestions?: (
    filter: string,
    selectedItems?: ITag[] | undefined
  ) => ITag[] | PromiseLike<ITag[]>;
  hoverStyle?: boolean | undefined;
};

export const TagPickerWithCreation: FunctionComponent<ExtendedTagPickerProps> = (
  props: ExtendedTagPickerProps
) => {
  const [mouseOver, setMouseOver] = useState(false);
  const filterSuggestedTags = (filterText: string, tagList: ITag[] | undefined): ITag[] => {
    return filterText
      ? props.tags.filter(
          (tag: ITag) =>
            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 &&
            !listContainsTagList(tag, tagList)
        )
      : [];
  };

  const showMostPromising = (selectedItems?: ITag[] | undefined) => {
    return props.tags.filter((tag: ITag) => !listContainsTagList(tag, selectedItems));
  };
  const tagPickerStyle: IStyleFunctionOrObject<ITagItemStyleProps, ITagItemStyles> = {
    text: {
      border: mouseOver ? "1px solid black" : "none",
      minWidth: "90px",
    },
  };
  return (
    <Stack onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)}>
      <TagPicker
        {...props}
        onResolveSuggestions={filterSuggestedTags}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        onEmptyResolveSuggestions={showMostPromising}
        onValidateInput={(input: string) => {
          return input ? ValidationState.valid : ValidationState.invalid;
        }}
        createGenericItem={(input: string) => {
          return { key: "", name: input } as ITag;
        }}
        onItemSelected={(item?: ITag | undefined): ITag | PromiseLike<ITag> | null => {
          if (item) {
            if (!item?.key) {
              return { key: `temp-${item.name.replace(/(?:,| )+/gi, "")}`, name: item.name };
            }
            return item;
          }
          return null;
        }}
        onChange={props.onChange}
        onBlur={props.onBlur}
        styles={props.hoverStyle ? tagPickerStyle : undefined}
      />
    </Stack>
  );
};
