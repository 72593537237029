import { FunctionComponent } from "react";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
} from "@fluentui/react/lib/DetailsList";
import { Stack, Text } from "@fluentui/react";
import { useGetRemarksQuery } from "../../api/remark";
import { useNavigate } from "react-router-dom";
import { Remark } from "../../types/colab";

export const RemarksList: FunctionComponent = () => {
  // FIXME use auth flow via our user management system
  const { data } = useGetRemarksQuery({});

  const remarks = data || [];
  const navigate = useNavigate();
  const columns: IColumn[] = [
    { key: "description", name: "Description", minWidth: 120 },
    { key: "created_at", name: "Created at", minWidth: 120 },
  ];
  function _renderItemColumn(item?: Remark, _?: number, column?: IColumn) {
    if (!item || !column) return <></>;
    const fieldContent = item[column.key as keyof Remark] as string;
    return (
      <Stack style={{ cursor: "pointer" }}>
        <Text onClick={() => navigate(`/colab/powerbi/report/${item.report_id}`)}>
          {fieldContent}
        </Text>
      </Stack>
    );
  }

  return (
    <Stack>
      <DetailsList
        items={remarks}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        onRenderItemColumn={_renderItemColumn}
      />
    </Stack>
  );
};
