import {
  TenantInvite,
  TenantSettings,
  TenantSettingsUpdate,
} from "../../routes/company-info/settingTypes";
import { dqcApi } from "../dqcQuery";

export const tenantSettingsApi = dqcApi.injectEndpoints({
  endpoints: (builder) => ({
    getTenantSettings: builder.query<TenantSettings, any>({
      query: () => ({ url: `/apiV2/settings/`, method: "get" }),
      providesTags: ["TenantSettings"],
    }),
    updateTenantSettings: builder.mutation<TenantSettings, TenantSettingsUpdate>({
      query: (data) => ({ url: `/apiV2/settings/`, method: "put", data }),
      invalidatesTags: ["TenantSettings"],
    }),
    createTenantInvite: builder.mutation<TenantInvite, string>({
      query: (email: string) => ({ url: `/apiV2/settings/invite?email=${email}`, method: "get" }),
    }),
  }),
});

export const {
  useGetTenantSettingsQuery,
  useUpdateTenantSettingsMutation,
  useCreateTenantInviteMutation,
} = tenantSettingsApi;
