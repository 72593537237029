import {
  IBasePickerSuggestionsProps,
  ILabelStyleProps,
  ILabelStyles,
  IStyleFunctionOrObject,
  ISuggestionItemProps,
  ITag,
  Label,
  Stack,
  TagPicker,
} from "@fluentui/react";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useGetDataAssetsQuery } from "../../api/cockpit";
import { DataAsset } from "../../types/dataasset";
import { DataAssetToType } from "./dropdownOptions";
import { listContainsTagList } from "./helpers";

type TagWithIcon = ITag & {
  icon: ReactElement;
};

type SelectLinkedDataAssetsProps = {
  linkedIds: string[];
  setLinkedIds: (ids: string[]) => void;
  styles?: IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles> | undefined;
};
export const SelectLinkedDataAssets: React.FunctionComponent<SelectLinkedDataAssetsProps> = ({
  linkedIds,
  setLinkedIds,
  styles,
}) => {
  const { t } = useTranslation();
  const dataAssetsQuery = useGetDataAssetsQuery({ all: true });
  const dataAssets: DataAsset[] = dataAssetsQuery.data || [];
  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: t("suggest_link_data_assets_Header"),
    noResultsFoundText: t("no_data_asset_found"),
  };

  const getTextFromItem = (item: ITag) => item.name;
  const filterSuggestedTags = (filterText: string, tagList: ITag[] | undefined): TagWithIcon[] => {
    return filterText
      ? dataAssets
          .map(
            (da: DataAsset): TagWithIcon => ({
              key: da.id,
              name: da.title,
              icon: DataAssetToType.get(da.type) as ReactElement,
            })
          )
          .filter(
            (tag: TagWithIcon) =>
              tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 &&
              !listContainsTagList(tag, tagList)
          )
      : [];
  };

  const showMostPromising = (selectedItems?: ITag[] | undefined) => {
    return dataAssets
      .map(
        (da: DataAsset): TagWithIcon => ({
          key: da.id,
          name: da.title,
          icon: DataAssetToType.get(da.type) as ReactElement,
        })
      )
      .filter((tag: ITag) => !listContainsTagList(tag, selectedItems));
  };

  return (
    <>
      <Label styles={styles}>{t("link-assets")}</Label>
      <TagPicker
        inputProps={
          linkedIds.length === 0
            ? { placeholder: t("suggest_link_data_assets_placeholder") }
            : undefined
        }
        removeButtonAriaLabel={t("remove")}
        selectionAriaLabel={t("select-assets")}
        onResolveSuggestions={filterSuggestedTags}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        onEmptyResolveSuggestions={showMostPromising}
        onRenderSuggestionsItem={(props: any, _itemProps: ISuggestionItemProps<ITag>) => {
          return (
            <Stack horizontal>
              <span>{props.icon}</span>
              <span>{props.name}</span>
            </Stack>
          );
        }}
        onChange={(items?: ITag[]) => {
          items && setLinkedIds(items.map((tag?: ITag) => tag?.key as string));
        }}
      />
    </>
  );
};
