import React, { useState } from "react";
import { IStackStyles, Stack, TextField } from "@fluentui/react";
import { ProfileResult } from "../../types/user-profile";
import { fetchUserProfiles } from "../../api/userProfile";
import { selectMsGraphApiAccessToken } from "../../store/slices/remarkSlice";
import { useAppSelector } from "../../store/hooks";
import { CommentUserTagSuggestions } from "./CommentUserTagSuggestions";
import { SubjectType, TaggedUser } from "../../types/comments";

const responsibleStyle: IStackStyles = {
  root: {
    cursor: "pointer",
    padding: "5px",
  },
};

interface ProfilePickerProps {
  taggedUsers: TaggedUser[];
  setTaggedUsers: (taggedUsers: TaggedUser[]) => void;
  subjectType: SubjectType;
  placeholder: string;
}

export const ProfilePicker: React.FunctionComponent<ProfilePickerProps> = ({
  taggedUsers,
  setTaggedUsers,
  subjectType,
  placeholder,
}) => {
  const [taggableUsers, setTaggableUsers] = useState<TaggedUser[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const accessToken = useAppSelector(selectMsGraphApiAccessToken);

  const getUserProfile = async (keyword: string) => {
    setSearchTerm(keyword);
    if (keyword) {
      const profiles: ProfileResult[] = await fetchUserProfiles(subjectType, keyword, accessToken);
      const users: TaggedUser[] = profiles.map((p) => {
        return {
          ...p,
          email: p.email || p.mail || "",
        };
      });
      const availableUsers = users.filter(
        (user) => !taggedUsers.find((taggedProfile) => taggedProfile.id === user.id)
      );
      setTaggableUsers(availableUsers);
    } else {
      setTaggableUsers([]);
    }
  };

  const onChipClose = (id: string) => {
    setTaggedUsers(taggedUsers.filter((taggedUser) => taggedUser.id !== id));
  };

  return (
    <>
      <Stack>
        <CommentUserTagSuggestions
          selectedProfiles={taggedUsers}
          onChipClose={onChipClose}
        ></CommentUserTagSuggestions>
      </Stack>
      <Stack>
        <TextField
          name="responsible"
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e: any) => {
            getUserProfile(e.target.value);
          }}
        />
      </Stack>
      <Stack>
        {taggableUsers.map((item, index) => (
          <Stack
            key={`${placeholder}::${index}`}
            styles={responsibleStyle}
            className="responsible"
            onClick={() => {
              setTaggedUsers([...taggedUsers, item]);
              setSearchTerm("");
              setTaggableUsers([]);
            }}
          >
            {item.displayName}
          </Stack>
        ))}
      </Stack>
    </>
  );
};
