import { FunctionComponent, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { useGetGamificationQuery } from "../../../api/performance";
import { OvertimeChange } from "./OvertimeChange";
import { ReactComponent as GamificationImage } from "../../../assets/gamification.svg";

const addHeadStyle: CSSProperties = {
  margin: "0",
};

const mainBoxStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  height: "210px",
};

const iconStyle: CSSProperties = {
  width: "59px",
  height: "59px",
};

const countStyle: CSSProperties = {
  fontWeight: "500",
  fontSize: "40px",
  color: "#292929",
};

const subContainerStyle: CSSProperties = {
  boxSizing: "border-box",
  padding: "15px",
  width: "361px",
  height: "264px",
  background: "#ffffff",
  border: "1px solid #cccccc",
};

const infoStyle: CSSProperties = {
  display: "flex",
  height: "224px",
  alignItems: "center",
  justifyContent: "center",
};

export const Gamification: FunctionComponent = () => {
  const { t } = useTranslation();
  const { isLoading, isSuccess, data } = useGetGamificationQuery();

  return (
    <section style={subContainerStyle}>
      <h3 style={addHeadStyle}>{t("performance_gamification")}</h3>
      {!isLoading && isSuccess ? (
        <div style={mainBoxStyle}>
          <GamificationImage style={iconStyle} />
          <p style={countStyle}>{data?.points_as_of_now}</p>
          <OvertimeChange score={data?.change_points} percentage={data?.change_points_percentage} />
        </div>
      ) : (
        <span style={infoStyle}>
          <Spinner size={SpinnerSize.large} />
        </span>
      )}
    </section>
  );
};
