import { UploadDQCDataType, UploadNumberType } from "../../types/fileUploader";

export const getNumericCellsForColumn = (
  data: UploadDQCDataType[][],
  columnIndex: number
): UploadNumberType[] => {
  const cells = data.map((row) => row[columnIndex]);
  const numericItems: UploadNumberType[] = [];
  cells.forEach((cell) => {
    const isNumber = cell.type === "Integer" || cell.type === "Double";

    isNumber && !isNaN(cell.value) && numericItems.push(cell);
  });
  return numericItems;
};

type TwoNumericCells = {
  cell1: UploadNumberType;
  cell2: UploadNumberType;
};
export const getNumericCellsForTwoColumns = (
  data: UploadDQCDataType[][],
  columnIndex1: number,
  columnIndex2: number
): TwoNumericCells[] => {
  const numericItems: TwoNumericCells[] = [];
  data.forEach((row) => {
    const cell1 = row[columnIndex1];
    const cell2 = row[columnIndex2];
    const isCell1Number = cell1.type === "Integer" || cell1.type === "Double";
    const isCell2Number = cell2.type === "Integer" || cell2.type === "Double";
    if (isCell1Number && !isNaN(cell1.value) && isCell2Number && !isNaN(cell2.value))
      numericItems.push({ cell1, cell2 });
  });
  return numericItems;
};
