import { ITag } from "@fluentui/react";
import { CreateDataAsset } from "../../types/dataasset";
import { CreateUseCase } from "../../types/usecase";

export const getDefaultDataAsset = (): CreateDataAsset => {
  return {
    title: "",
    description: "",
    type: "Domain",
    owner: "",
    steward: "",
    custodian: "",
    value: "Not assessed",
    problems: "Not assessed",
    governance: "Central",
    origins: [],
  };
};

export const getDefaultUseCase = (): CreateUseCase => {
  return {
    title: "",
    description: "",
    value: "Not assessed",
    problems: "Not assessed",
    status: "New",
    tags: [],
    linked_assets_ids: [],
  };
};

export const onChangeAddNew = (
  addCall: (p: any) => void,
  onChange?: (items?: ITag[] | undefined) => void,
  items?: ITag[]
) => {
  const newTags = items?.filter((a) => a.key.toString().match(/^temp-/));
  if (newTags && newTags.length > 0) {
    Promise.all(newTags.map((tag) => addCall({ id: "", label: tag.name })))
      .then((results) => {
        if (onChange)
          onChange([
            ...items?.filter((a) => !a.key.toString().match(/^temp-/))!,
            ...results.map((r: any) => ({
              key: r.data.id,
              name: r.data.label,
            })),
          ]);
      })
      .catch(() => {
        console.error("Update failed");
      });
  } else {
    if (onChange) onChange(items);
  }
};

export const tagToAPIModel = (item: any): ITag => ({
  key: item.id!,
  name: item.label,
});

export const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some((compareTag) => compareTag.key === tag.key);
};
