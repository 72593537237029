import { useTranslation } from "react-i18next";
import { TopContainer } from "../../components/common/TopContainer";
import {
  DefaultButton,
  DetailsListLayoutMode,
  IColumn,
  IconButton,
  MessageBar,
  MessageBarType,
  Panel,
  PrimaryButton,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
  TextField,
  mergeStyleSets,
} from "@fluentui/react";

import { useCallback, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { DataConnector, DataConnectorCreate } from "./dataConnectorTypes";
import DataConnectorsStyles from "./DataConnectors.module.css";
import {
  useAddNewDataConnectorMutation,
  useDeleteDataConnectorMutation,
  useGetAllDataConnectorsQuery,
} from "../../api/v2/dataConnectors";
import moment from "moment";
import { DataConnectorButton, DataConnectorButtonIcon } from "./Connector";

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: "16px",
  },
  fileIconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px",
  },
  controlWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  exampleToggle: {
    display: "inline-block",
    marginBottom: "10px",
    marginRight: "30px",
  },
  selectionDetails: {
    marginBottom: "20px",
  },
});

export const DataConnectors: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [addConnectionFor, setAddConnectionFor] = useState<string>("");
  const [connection, setConnection] = useState<DataConnectorCreate>({
    name: "",
    connection_string: "",
    origin: "",
  });
  const { data: dataConnectors } = useGetAllDataConnectorsQuery({});
  const [addNewDataConnector] = useAddNewDataConnectorMutation();
  const [deleteDataConnector] = useDeleteDataConnectorMutation();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onRenderFooterContent = useCallback(
    () => (
      <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 4 }}>
        <DefaultButton onClick={dismissPanel}>Cancel</DefaultButton>
        <PrimaryButton
          onClick={async () => {
            try {
              if (!connection) return;
              const response = await addNewDataConnector(connection).unwrap();
              if (response) dismissPanel();
              else setErrorMessage("Unknown error");
            } catch (error) {
              setErrorMessage((error as any).data.message);
            }
          }}
          disabled={!connection.name || !connection.connection_string}
        >
          {t("save")}
        </PrimaryButton>
      </Stack>
    ),
    [addConnectionFor, connection, dismissPanel]
  );

  // @TODO: make nicer
  const columns: IColumn[] = [
    {
      key: "DC-originIconColumn",
      name: t("origin"),
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      iconName: "Database",
      isIconOnly: true,
      minWidth: 16,
      maxWidth: 16,
      onRender: (item: DataConnector) => {
        if (!item.origin) return <></>;
        return <DataConnectorButtonIcon iconName={item.origin} />;
      },
    },
    {
      key: "DC-nameColumn",
      name: t("name"),
      fieldName: "name",
      minWidth: 210,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      data: "string",
      isPadded: true,
      onRender: (item: DataConnector) => {
        return (
          <Stack horizontal>
            <Text>{item.name}</Text>
            <IconButton
              iconProps={{ iconName: "Delete" }}
              styles={{ root: { marginTop: -6 } }}
              onClick={async () => {
                try {
                  await deleteDataConnector(item.id).unwrap();
                } catch (error) {
                  setErrorMessage((error as any).data.message);
                }
              }}
            />
          </Stack>
        );
      },
      flexGrow: 1,
    },
    {
      key: "DC-dataTablesColumn",
      name: t("tables"),
      fieldName: "data_tables",
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      onRender: (item: DataConnector) => {
        return <span>{item.data_tables?.length}</span>;
      },
      isPadded: true,
    },
    {
      key: "DC-createdAtColumn",
      name: t("created_at"),
      fieldName: "created_at",
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      data: "string",
      onRender: (item: DataConnector) => {
        return <span>{moment(item.created_at).format("DD.MM.YYYY HH:mm")}</span>;
      },
      isPadded: true,
    },
  ];

  return (
    <TopContainer title={t("data_connectors")}>
      <Stack styles={{ root: { paddingTop: 16 } }}>
        <Text>{t("connect_data_sources_description")}</Text>
        <Stack horizontal tokens={{ childrenGap: 8 }} styles={{ root: { paddingTop: 16 } }}>
          <DataConnectorButton
            title="PostgreSQL"
            iconName="postgresql"
            onClick={() => {
              setAddConnectionFor("PostgreSQL");
              setConnection({ ...connection, origin: "postgresql" });
              openPanel();
            }}
          />
          <DataConnectorButton
            title="BigQuery"
            iconName="bigquery"
            onClick={() => {
              setAddConnectionFor("BigQuery");
              setConnection({ ...connection, origin: "bigquery" });
              openPanel();
            }}
          />
          <DataConnectorButton
            title="Snowflake"
            iconName="snowflake"
            onClick={() => {
              setAddConnectionFor("Snowflake");
              setConnection({ ...connection, origin: "snowflake" });
              openPanel();
            }}
          />
        </Stack>
        <ShimmeredDetailsList
          items={dataConnectors ?? []}
          compact={true}
          columns={columns}
          selectionMode={SelectionMode.none}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
        />
      </Stack>

      <Panel
        isOpen={isOpen}
        onDismiss={dismissPanel}
        closeButtonAriaLabel={t("close")}
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
        headerText={t("header_add_new_data_connector")}
        headerClassName={DataConnectorsStyles.panelHeader}
      >
        <Stack>
          <Text styles={{ root: { marginBottom: 10 } }}>
            {addConnectionFor === "PostgreSQL" && t("connect_to_psql_db")}
          </Text>

          {!!errorMessage && (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
              {errorMessage}
            </MessageBar>
          )}
          <TextField
            label={t("name")}
            value={connection?.name}
            onChange={(_e, value) => {
              setConnection({ ...connection, name: value ?? "" });
            }}
          />
          <TextField
            label={t("connection_string")}
            value={connection?.connection_string}
            onChange={(_e, value) => {
              setConnection({ ...connection, connection_string: value ?? "" });
            }}
          />
        </Stack>
      </Panel>
    </TopContainer>
  );
};
