import { CSSProperties, FunctionComponent, useState } from "react";
import { TopContainer } from "../../components/common/TopContainer";
import { TextField } from "@fluentui/react/lib/TextField";
import { useNavigate } from "react-router-dom";
import { PrimaryButton, DefaultButton } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useAddNewMissionMutation } from "../../api/mission";

const textFieldStyle: CSSProperties = {
  marginTop: "10px",
};

const mainButtonStyle: CSSProperties = {
  marginTop: "30px",
  float: "right",
  display: "flex",
};

const PrimaryButtonStyle: CSSProperties = {
  marginLeft: "20px",
};

export const NewMission: FunctionComponent = () => {
  const { t } = useTranslation();
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [addNewMission] = useAddNewMissionMutation();

  let navigate = useNavigate();

  const saveMission = async () => {
    try {
      const response = await addNewMission({ title, description }).unwrap();
      if (response) {
        navigate("/missions");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TopContainer title={t("add_mission")}>
      <main>
        <div>
          <h2>{t("new_mission_heading")}</h2>
          <div>
            <TextField
              label={t("new_mission_title")}
              onChange={(e: any) => setTitle(e.target.value)}
            />
          </div>
          <div style={textFieldStyle}>
            <TextField
              label={t("new_mission_desc")}
              multiline
              rows={3}
              onChange={(e: any) => setDescription(e.target.value)}
            />
          </div>
          <div style={mainButtonStyle}>
            <div>
              <DefaultButton
                text={t("back")}
                onClick={() => {
                  navigate("/missions");
                }}
              />
            </div>
            <div style={PrimaryButtonStyle}>
              <PrimaryButton text={t("new_mission_next")} onClick={saveMission} disabled={!title} />
            </div>
          </div>
        </div>
      </main>
    </TopContainer>
  );
};
