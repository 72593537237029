import { IIconStyles, mergeStyleSets } from "@fluentui/react";

export const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "40%",
  },
});

export const iconStyle: Partial<IIconStyles> = {
  root: {
    fontSize: 20,
    cursor: "pointer",
  },
};
