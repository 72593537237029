import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { updateNotifications } from "../../utils/updateNotifications";
import { RootState } from "../store";
import { loadFile } from "./upload";
import { uuid4 } from "@sentry/utils";

export interface NotificationPayload {
  type: "success" | "info" | "warning" | "error";
  headerText: string;
  bodyText: string;
  id: string;
  primaryButtonText?: string;
  primaryButtonOnClick?: () => void;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
  autoHideAfterSeconds?: number;
}

interface NotificationCenterState {
  activeNotifications: NotificationPayload[];
}

const initialState: NotificationCenterState = { activeNotifications: [] };
export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<NotificationPayload>) => {
      const activeNotificationsCopy = _.cloneDeep(state.activeNotifications);
      const updatedNotifications = updateNotifications(activeNotificationsCopy, action.payload);
      state.activeNotifications = updatedNotifications;
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      const updatedNotifications = state.activeNotifications.filter(
        (notification) => notification.id !== action.payload
      );
      state.activeNotifications = updatedNotifications;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadFile.rejected, (state) => {
      state.activeNotifications.push({
        bodyText: "File needs to be a CSV or Excel sheet",
        headerText: "Invalid file format",
        id: JSON.stringify(uuid4()),
        type: "error",
      });
    });
  },
});
export const { addNotification, removeNotification } = notificationSlice.actions;

export const selectFirstThreeNotifications = (state: RootState) =>
  state.notifications.activeNotifications.slice(0, 3);
