import { FunctionComponent, useEffect, useState } from "react";
import { Task, MissionData } from "../../../types/missions";
import { topHeading } from "../../../helpers/commonStyles";
import {
  DefaultButton,
  IIconProps,
  IStackStyles,
  Label,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";

import { skipToken } from "@reduxjs/toolkit/query/react";
import { useParams } from "react-router-dom";
import { useAutoGenerateTasksMutation, useGetMissionByIdQuery } from "../../../api/mission";
import { SendMessageModal } from "./SendMessageModal";
import { dqcRESTApi } from "../../../api/dqcREST";
import { TaskAddEditModal } from "./TaskAddEditModal";
import { TaskDeleteDialog } from "./TaskDeleteDialog";
import { TaskList } from "./TaskList";
import { useTranslation } from "react-i18next";
import { useBoolean } from "@fluentui/react-hooks";

const innerContentBoxStyles: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "auto",
  },
};

const addDatasetBtnBoxStyles: IStackStyles = {
  root: {
    paddingTop: "13px",
    display: "flex",
    flexDirection: "row",
  },
};

export const TaskSection: FunctionComponent = () => {
  const { t } = useTranslation();
  const addIcon: IIconProps = { iconName: "Add" };
  const params = useParams();
  const missionRequest = useGetMissionByIdQuery(params.id ?? skipToken);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [mission, setMission] = useState<MissionData>();
  const [allTasks, setAllTasks] = useState<Task[]>([]);
  const mailIcon: IIconProps = { iconName: "Mail" };
  const repeatAllIcon: IIconProps = { iconName: "RepeatAll" };
  const [fetchingAutoGeneratedTasks, setFetchingAutoGeneratedTasks] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<Task>();
  const [modelTitle, setModelTitle] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<string | undefined>("");
  const [hideDeleteTaskDialog, { toggle: toggleHideDeleteTaskDialog }] = useBoolean(true);
  const [autoGenerateTaskRequest] = useAutoGenerateTasksMutation();

  useEffect(() => {
    if (missionRequest.data) {
      setMission(missionRequest.data);
      setAllTasks(missionRequest?.data?.tasks);
    }
  }, [missionRequest]);

  const handleModalData = (mode: string, item?: Task, activate?: boolean) => {
    if (mode === "edit") {
      setModalData(item);
      setOpenModal(true);
      setModelTitle(activate ? t("add_task_activate") : t("add_task_edit"));
      setIsEdit(true);
    } else {
      setModalData(undefined);
      setOpenModal(true);
      setModelTitle(t("add_task_add"));
      setIsEdit(false);
    }
  };

  const autoGenerateTasks = async () => {
    setFetchingAutoGeneratedTasks(true);
    if (!params.id) return;
    try {
      const newTasks = await autoGenerateTaskRequest(params.id).unwrap();
      setAllTasks(newTasks);
      setFetchingAutoGeneratedTasks(false);
    } catch (err) {
      console.log(err);
    }
  };

  const startPauseTask = async (task: Task) => {
    let newTask = {
      ...task,
      is_active: !task.is_active,
    };
    const updatedTask = await dqcRESTApi.put(`/tasks/${task.id}/`, newTask);
    if (updatedTask) {
      autoGenerateTasks();
    }
  };

  return (
    <div>
      {showMessageModal ? (
        <SendMessageModal
          showMessageModal={showMessageModal}
          setShowMessageModal={setShowMessageModal}
        />
      ) : null}
      <Stack styles={innerContentBoxStyles}>
        <Stack>
          <Label styles={topHeading}>{t("tasks")}</Label>
        </Stack>
        {mission ? (
          <Stack styles={addDatasetBtnBoxStyles}>
            <DefaultButton
              text={t("send_message")}
              iconProps={mailIcon}
              style={{ marginRight: "15px" }}
              onClick={() => setShowMessageModal(true)}
            />
            <DefaultButton
              text={fetchingAutoGeneratedTasks ? "..." : t("autogenerate_tasks")}
              iconProps={repeatAllIcon}
              style={{ marginRight: "15px" }}
              onClick={() => autoGenerateTasks()}
            />
            <DefaultButton
              text={t("add")}
              iconProps={addIcon}
              onClick={() => handleModalData("add")}
            />
          </Stack>
        ) : (
          <></>
        )}
      </Stack>
      {allTasks?.length > 0 ? (
        <TaskList
          tasks={allTasks}
          mission={mission}
          setTaskId={setTaskId}
          toggleHideDeleteTaskDialog={toggleHideDeleteTaskDialog}
          startPauseTask={startPauseTask}
          handleModalData={handleModalData}
        ></TaskList>
      ) : missionRequest.isLoading ? (
        <Spinner size={SpinnerSize.large} />
      ) : null}
      {openModal ? (
        <TaskAddEditModal
          modalState={openModal}
          modelTitle={modelTitle}
          task={modalData}
          datasets={mission ? mission?.datasets : []}
          isEdit={isEdit}
          missionId={mission ? mission?.id : ""}
          secondaryButtonOnClick={() => {
            setOpenModal(false);
          }}
        />
      ) : null}
      <TaskDeleteDialog
        taskId={taskId}
        allTasks={allTasks}
        setAllTasks={setAllTasks}
        hideDeleteTaskDialog={hideDeleteTaskDialog}
        toggleHideDeleteTaskDialog={toggleHideDeleteTaskDialog}
      />
    </div>
  );
};
