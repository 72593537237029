import { IStackStyles, Stack } from "@fluentui/react";
import { FunctionComponent } from "react";
import { UseCase } from "../../types/usecase";
import { UseCaseListItem } from "./UseCaseListItem";

const mainContainer: IStackStyles = {
  root: {
    border: "0.5px solid #605E5C",
    borderRadius: 4,
    marginLeft: 10,
  },
};

interface UseCaseListState {
  useCases: UseCase[] | undefined;
}

export const UseCaseList: FunctionComponent<UseCaseListState> = (props: UseCaseListState) => {
  return (
    <Stack>
      <Stack styles={props.useCases && props.useCases?.length > 0 ? mainContainer : undefined}>
        {props.useCases?.map((data, index) => (
          <Stack key={index}>
            <UseCaseListItem
              data={data}
              index={index}
              length={props.useCases ? props.useCases.length : 0}
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
