import { ReactElement } from "react";
import { AssetType } from "../../types/dataasset";
import { IDropdownOption, Stack } from "@fluentui/react";
import { ListIcon } from "../common/ListIcon";
import { ProblemIcon } from "../common/ProblemIcon";
import { ValueIcon } from "../common/ValueIcon";

export type DataAssetDropDownData = {
  field: "type" | "problems" | "value" | "governance" | "status";
  render?: () => JSX.Element;
};
export const DataAssetToType: Map<AssetType, ReactElement> = new Map([
  ["Domain", <ListIcon score={1} />],
  ["Family", <ListIcon score={2} />],
  ["Product", <ListIcon score={3} />],
]);
export const typeOptions: IDropdownOption<DataAssetDropDownData>[] = [
  { key: "Domain", text: "Domain", data: { render: () => <ListIcon score={1} />, field: "type" } },
  { key: "Family", text: "Family", data: { render: () => <ListIcon score={2} />, field: "type" } },
  {
    key: "Product",
    text: "Product",
    data: { render: () => <ListIcon score={3} />, field: "type" },
  },
];
export const governanceOptions: IDropdownOption<DataAssetDropDownData>[] = [
  {
    key: "Central",
    text: "Central",
    data: { field: "governance" },
  },
  {
    key: "Federated",
    text: "Federated",
    data: { field: "governance" },
  },
];
export const statusDomainOptions: IDropdownOption<DataAssetDropDownData>[] = [
  { key: "New", text: "New", data: { field: "status" } },
  { key: "Active", text: "Active", data: { field: "status" } },
  { key: "WIP", text: "WIP", data: { field: "status" } },
  { key: "Inactive", text: "Inactive", data: { field: "status" } },
];

export const problemOptions: IDropdownOption<DataAssetDropDownData>[] = [
  {
    key: "Not assessed",
    text: "Not assessed",
    data: { render: () => <ProblemIcon score={1} />, field: "problems" },
  },
  {
    key: "None",
    text: "None",
    data: { render: () => <ProblemIcon score={2} />, field: "problems" },
  },
  {
    key: "Few",
    text: "Few",
    data: { render: () => <ProblemIcon score={3} />, field: "problems" },
  },
  {
    key: "Severe",
    text: "Severe",
    data: { render: () => <ProblemIcon score={4} />, field: "problems" },
  },
];
export const valueOptions: IDropdownOption<DataAssetDropDownData>[] = [
  {
    key: "Not assessed",
    text: "Not assessed",
    data: { render: () => <ValueIcon score={1} />, field: "value" },
  },
  { key: "Low", text: "Low", data: { render: () => <ValueIcon score={2} />, field: "value" } },
  {
    key: "Medium",
    text: "Medium",
    data: { render: () => <ValueIcon score={3} />, field: "value" },
  },
  { key: "High", text: "High", data: { render: () => <ValueIcon score={4} />, field: "value" } },
];

export const statusOptions: IDropdownOption[] = [
  { key: "new", text: "New" },
  { key: "active", text: "Active" },
  { key: "wip", text: "WIP" },
  { key: "inactive", text: "Inactive" },
];

export const NoDropdownData = (text: string) => {
  const noData: IDropdownOption[] = [
    {
      key: "0",
      text: text,
      disabled: true,
    },
  ];
  return noData;
};
const optionText: React.CSSProperties = {
  paddingRight: 5,
  paddingLeft: 10,
  paddingBottom: 3,
};
export const onRenderDropDownOption = (
  option?: IDropdownOption<DataAssetDropDownData>
): JSX.Element | null => {
  if (!option || !option.data) return null;
  return (
    <Stack horizontal verticalAlign="center">
      {option.data?.render && <span>{option.data?.render()}</span>}
      <span style={optionText}>{option.text}</span>
    </Stack>
  );
};

export const onRenderTitle = (options?: IDropdownOption<DataAssetDropDownData>[]): JSX.Element => {
  if (!options || !options.length) return <></>;
  const selectedOption = options[0];
  return onRenderDropDownOption(selectedOption) || <></>;
};
