import { ReportMetricsAPIResponseModel } from "../types/colab";
import { dqcApi, api_base } from "./dqcQuery";

export const colabApi = dqcApi.injectEndpoints({
  endpoints: (builder) => ({
    getColabDashboardData: builder.query<Map<string, ReportMetricsAPIResponseModel>, string>({
      query: (colabQuery: string) => ({
        url: `/${api_base}/colab/dashboard/?${colabQuery}`,
        method: "get",
      }),
      extraOptions: { maxRetries: 1 },
      providesTags: ["Colab"],
    }),
  }),
});

export const { useGetColabDashboardDataQuery } = colabApi;
