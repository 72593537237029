import { ILabelStyles, IStackStyles, IStackTokens, Label, Stack } from "@fluentui/react";
import React, { memo } from "react";
import { useAppSelector } from "../../store/hooks";
import { selectShowFields } from "../../store/slices/cockpitSlice";
import { ShowFieldsOptions } from "../../types/cockpit";
import { AssetUsecaseProblems, AssetUsecaseValue, DataAsset } from "../../types/dataasset";
import { CockpitTileHeader } from "./CockpitTileHeader";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../../types/item-types";
const bottomContainer: IStackStyles = {
  root: {
    width: 370,
    padding: 7,
    background: "#E9E9E9",
  },
};

const innerContainer: IStackStyles = {
  root: {
    display: "flex",
    justifyContent: "center",
    width: 115.33,
    height: 27,
    background: "#FFFFFF",
  },
};

const innerLabelStyle: ILabelStyles = {
  root: {
    fontSize: 12,
    textAlign: "center",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    paddingLeft: 4,
    paddingRight: 4,
  },
};

const divider: React.CSSProperties = {
  border: "0.5px solid #4D4D4D",
  marginTop: "10px",
};
const useCaseCountStyle: ILabelStyles = {
  root: {
    fontSize: 12,
    paddingLeft: 7,
    textOverflow: "ellipsis",
  },
};

const style: React.CSSProperties = {
  cursor: "move",
};

const dropContainer: IStackStyles = {
  root: {
    border: "2px dashed green",
    background: "#E5F6DF",
  },
};

const wrapStackTokens: IStackTokens = { childrenGap: 5 };

type CockpitTitleProps = {
  title: string;
  useCaseCount: number;
  familyProducts: DataAsset[];
  problems: AssetUsecaseProblems;
  value: AssetUsecaseValue;
  owner: string;
  id: string;
  moveCard: (id: string, to: number) => void;
  findCard: (id: string) => { index: number };
  dropCard: () => void;
};

interface Item {
  id: string;
  originalIndex: number;
}

export const CockpitTile: React.FunctionComponent<CockpitTitleProps> = memo(
  ({
    title,
    useCaseCount,
    familyProducts,
    problems,
    value,
    owner,
    id,
    moveCard,
    findCard,
    dropCard,
  }) => {
    const showFields: ShowFieldsOptions[] = useAppSelector(selectShowFields);
    const originalIndex = findCard(id).index;
    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: ItemTypes.CARD,
        item: { id, originalIndex },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
          const { id: droppedId, originalIndex } = item;
          const didDrop = monitor.didDrop();
          if (!didDrop) {
            moveCard(droppedId, originalIndex);
          } else {
            dropCard();
          }
        },
      }),
      [id, originalIndex, moveCard]
    );
    const [, drop] = useDrop(
      () => ({
        accept: ItemTypes.CARD,
        hover({ id: draggedId }: Item) {
          if (draggedId !== id) {
            const { index: overIndex } = findCard(id);
            moveCard(draggedId, overIndex);
          }
        },
      }),
      [findCard, moveCard]
    );
    const opacity = isDragging ? 0 : 1;

    return (
      <div ref={(node) => drag(drop(node))} style={{ ...style }}>
        <Stack styles={isDragging ? dropContainer : {}}>
          <div style={{ opacity }}>
            <CockpitTileHeader title={title} problems={problems} value={value} owner={owner} />
            {showFields.includes("families-products") ? (
              <Stack styles={bottomContainer}>
                <Stack horizontal wrap tokens={wrapStackTokens}>
                  {familyProducts.length !== 0 &&
                    familyProducts.map((values: DataAsset, index) => {
                      return (
                        <Stack key={index}>
                          {index <= 5 ? (
                            <Stack.Item styles={innerContainer}>
                              {index < 5 ? (
                                <Label styles={innerLabelStyle}>{values.title}</Label>
                              ) : (
                                <Label styles={innerLabelStyle}>
                                  ({familyProducts.length - 5} more)
                                </Label>
                              )}
                            </Stack.Item>
                          ) : null}
                        </Stack>
                      );
                    })}
                </Stack>
              </Stack>
            ) : null}
            <Stack>
              {showFields.includes("use-cases") ? (
                <Stack styles={bottomContainer}>
                  {showFields.includes("families-products") && familyProducts.length !== 0 ? (
                    <div style={divider} />
                  ) : null}
                  <Label styles={useCaseCountStyle}>{useCaseCount} use cases</Label>
                </Stack>
              ) : null}
            </Stack>
          </div>
        </Stack>
      </div>
    );
  }
);
