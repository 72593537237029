import {
  ActionButton,
  DetailsList,
  DetailsListLayoutMode,
  DirectionalHint,
  IButtonStyles,
  IColumn,
  IIconProps,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Spinner,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import moment from "moment";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUpdateSurveyMutation } from "../../api/survey";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { hostStyles } from "../../helpers/commonStyles";
import { Survey } from "../../types/survey";
import { SurveyQuestionsModal } from "./questions-modal";
import { SurveySendoutDialog } from "./sendout-dialog";

export interface SurveyListState {
  surveys: Survey[];
}

const startIcon: IIconProps = { iconName: "Send" };
const stopIcon: IIconProps = { iconName: "CircleStop" };
const showQuestionsIcon: IIconProps = { iconName: "SurveyQuestions" };
const responsesIcon: IIconProps = { iconName: "ResponsesMenu" };
const calIcon: IIconProps = { iconName: "Calendar" };
const linkIcon: IIconProps = { iconName: "Link" };
const btnStyle: IButtonStyles = {
  label: {},
  labelHovered: { textDecoration: "underline" },
};
const lblStyle: IButtonStyles = {
  label: { fontWeight: "bold", textDecoration: "underline" },
  labelHovered: { textDecoration: "underline" },
};

export const SurveyList: FunctionComponent<SurveyListState> = (props: SurveyListState) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [isDialogOpen, { toggle: toggleHideDialog }] = useBoolean(true);
  const [selectedSurveyForSendoutId, setSelectedSurveyForSendoutId] = useState("");
  const [showQuestionsForSurveyId, setShowQuestionsForSurveyId] = useState("");
  const [copyLinkText, setShowCopyLinkText] = useState("");
  const [toBeStoppedSurvey, setToBeStoppedSurvey] = useState<Survey>();
  const [hideStopDialog, { toggle: toggleStopDialog }] = useBoolean(true);
  const [waitingForHTTPRequest, { setTrue: httpReqPending, setFalse: httpRegDone }] =
    useBoolean(false);

  const [updateSurvey] = useUpdateSurveyMutation();

  const columns: IColumn[] = [
    {
      key: "title",
      name: t("Title"),
      fieldName: "title",
      minWidth: 100,
      isResizable: true,
      data: "string",
      onRender: (item: Survey) => {
        return (
          <ActionButton onClick={() => navigate(`/surveys/${item.id}`)} styles={lblStyle}>
            {item.title}
          </ActionButton>
        );
      },
    },
    {
      key: "total_responses",
      name: `# ${t("responses")}`,
      fieldName: "total_responses",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Survey) => {
        return (
          <ActionButton iconProps={responsesIcon}>
            {item.total_responses > 0 ? item.total_responses : "-"}
          </ActionButton>
        );
      },
    },
    {
      key: "started_on",
      name: t("started_on"),
      fieldName: "started_at",
      minWidth: 300,
      isResizable: false,
      onRender: (item: Survey) => {
        return (
          <ActionButton iconProps={calIcon} onClick={() => {}}>
            {item.started_at
              ? moment(moment.utc(item.started_at)).local().format("DD.MM.YYYY")
              : t("not_yet_started")}
          </ActionButton>
        );
      },
    },
    {
      key: "actions",
      name: t("actions"),
      fieldName: "actions",
      minWidth: 170,
      onRender: (item: Survey) => {
        return (
          <>
            <TooltipHost
              content={!item.activated ? t("start_survey_tooltip") : t("stop_survey_tooltip")}
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.topCenter}
              styles={hostStyles}
            >
              <ActionButton
                iconProps={!item.activated ? startIcon : stopIcon}
                styles={btnStyle}
                onClick={() => {
                  if (item.activated) {
                    setToBeStoppedSurvey(item);
                    toggleStopDialog();
                    return;
                  }
                  setSelectedSurveyForSendoutId(item.id);
                  toggleHideDialog();
                }}
              ></ActionButton>
            </TooltipHost>

            <TooltipHost
              content={t("show_questions_tooltip")}
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.topCenter}
              styles={hostStyles}
            >
              <ActionButton
                iconProps={showQuestionsIcon}
                styles={btnStyle}
                onClick={() => {
                  setShowQuestionsForSurveyId(item.id);
                  showModal();
                }}
              ></ActionButton>
            </TooltipHost>

            <TooltipHost
              content={t("show_link")}
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.topCenter}
              styles={hostStyles}
            >
              <ActionButton
                iconProps={linkIcon}
                styles={btnStyle}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/surveys/form/${item.id}`
                  );
                  setShowCopyLinkText(t("copied"));
                  setTimeout(() => {
                    setShowCopyLinkText("");
                  }, 1500);
                }}
              ></ActionButton>
            </TooltipHost>
          </>
        );
      },
    },
    {
      key: "loading",
      name: "",
      minWidth: 110,
      onRender: (item: Survey) => {
        return (
          <>
            {waitingForHTTPRequest ? (
              <ActionButton>
                <Spinner label="Wait, wait..." ariaLive="assertive" labelPosition="right" />
              </ActionButton>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      <ConfirmationDialog
        showHideDialog={hideStopDialog}
        toggleShowHideDialog={toggleStopDialog}
        primaryButtonText={t("stop")}
        secondaryButtonText={t("cancel")}
        primaryButtonClicked={async () => {
          httpReqPending();
          toggleStopDialog();
          await updateSurvey({ id: toBeStoppedSurvey?.id!, activated: false });
          httpRegDone();
        }}
        dialogTitle={t("stop_dialog_title")}
        confirmationText={
          t("stop_dialog_text...") + `${toBeStoppedSurvey?.title}` + t("...stop_dialog_text")
        }
      />
      <SurveySendoutDialog
        dialogState={isDialogOpen}
        hideDialog={toggleHideDialog}
        surveyId={selectedSurveyForSendoutId}
        waitForHTTPToFinish={httpReqPending}
        HTTPFinished={httpRegDone}
      />
      <SurveyQuestionsModal
        modalState={isModalOpen}
        hideModal={hideModal}
        surveyId={showQuestionsForSurveyId}
      />
      {copyLinkText === "" ? (
        ""
      ) : (
        <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
          {copyLinkText}
        </MessageBar>
      )}
      <DetailsList
        items={props.surveys}
        columns={columns}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
      />
    </>
  );
};
