import { NotificationPayload } from "../store/slices/notificationCenter";

// error > warning > success > info
const notificationTypeImportanceMap: Record<string, string[] | undefined> = {
  error: ["warning", "success", "info"],
  warning: ["success", "info"],
  success: ["info"],
  info: undefined,
};

/**
 * Inserts the new notification at the correct index and returns an updated array
 * @param activeNotificationsCopy
 * @param newNotification
 * @returns
 */
export const updateNotifications = (
  activeNotificationsCopy: NotificationPayload[],
  newNotification: NotificationPayload
): NotificationPayload[] => {
  const typeToInsertAbove = notificationTypeImportanceMap[newNotification.type];
  const indexToInsertAt = activeNotificationsCopy.findIndex((activeNotification) =>
    typeToInsertAbove?.includes(activeNotification.type)
  );
  if (indexToInsertAt === -1 || indexToInsertAt > activeNotificationsCopy.length - 1) {
    activeNotificationsCopy.push(newNotification);
    return activeNotificationsCopy;
  } else {
    const moreImportantNotifications = activeNotificationsCopy.slice(0, indexToInsertAt);
    const lessImportantNotifications = activeNotificationsCopy.slice(indexToInsertAt);
    return [...moreImportantNotifications, newNotification, ...lessImportantNotifications];
  }
};
