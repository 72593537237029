import { Stack } from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UseCaseList } from "../../../components/cockpit/UseCaseList";
import { CockpitHeader } from "../../../components/cockpit/CockpitHeader";
import { TopContainer } from "../../../components/common/TopContainer";
import { searchData } from "../../../helpers/cockpit-helper";
import { useAppSelector } from "../../../store/hooks";
import { selectDomainIds } from "../../../store/slices/cockpitSlice";
import { useGetAllUseCasesQuery } from "../../../api/cockpit";

export const UseCases = () => {
  const { t } = useTranslation();
  const useCasesQuery = useGetAllUseCasesQuery();
  const allUseCases = useCasesQuery.data || [];
  const [searchTerm, setSearchTerm] = useState("");
  let sortedAndFilteredUseCases = [...allUseCases];
  const domainIds = useAppSelector(selectDomainIds);
  if (searchTerm) {
    sortedAndFilteredUseCases = searchData(sortedAndFilteredUseCases, searchTerm, "title");
  }
  if (domainIds && !domainIds.includes("all")) {
    sortedAndFilteredUseCases = sortedAndFilteredUseCases.filter(
      (u) => u.main_domain?.id && domainIds.includes(u.main_domain?.id || "")
    );
  }

  return (
    <TopContainer title={t("use-cases")}>
      <Stack>
        <CockpitHeader
          screen="useCase"
          searchBoxText={t("search-use-cases")}
          buttonLabel={t("add-use-case")}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </Stack>
      <UseCaseList useCases={sortedAndFilteredUseCases} />
    </TopContainer>
  );
};
