import React from "react";
import { useTranslation } from "react-i18next";
import { TileRootProps } from "../../types/tile";
import { Tile } from "./Tile";

export const TileRoot: React.FunctionComponent<TileRootProps> = ({
  item,
  type,
  loader,
  icon,
  configData,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
}: TileRootProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Tile
        type={type}
        title={item.attributes.title}
        subTitle={item.attributes.subtitle}
        icon={icon}
        category={item.attributes.category}
        description={item.attributes.description}
        loader={loader}
        configureState={configData}
        primaryButtonText={t(primaryButtonText)}
        primaryButtonOnClick={() => primaryButtonOnClick()}
        secondaryButtonText={t(secondaryButtonText)}
        secondaryButtonOnClick={() => secondaryButtonOnClick()}
        status={item.attributes.status}
      />
    </>
  );
};
