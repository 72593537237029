import { Stack, Icon, Link } from "@fluentui/react";
import { t } from "i18next";
import { FunctionComponent } from "react";
import { loginStackItemStyles } from "../../../helpers/commonStyles";
import { WelcomeBack } from "./welcomeBack";
import { WhyDQA } from "./whyDQA";
import logo from "../../../assets/welcome-screen/dqc-logo-square.svg";
import { LearnMore } from "./learnMore";
import { WelcomeUser } from "./WelcomeUser";

type TopButtonProps = {
  emailString?: string | null;
  userExists?: boolean;
};

export const TopSectionSignInOrLogin: FunctionComponent<TopButtonProps> = ({
  userExists,
  emailString,
}) => {
  const isOffice = localStorage.getItem("loginFromOffice") !== null;
  const backHref = isOffice
    ? localStorage.getItem("addinUrl") || window.location.origin
    : "/signin";
  const showWhyDQA = isOffice && !userExists && !emailString;
  const showWelcomeBack = isOffice && userExists;
  const showWelcomeUser = isOffice && !userExists && emailString;
  return (
    <Stack.Item align="stretch" styles={loginStackItemStyles}>
      {(!showWhyDQA || showWelcomeBack) && (
        <Link href={backHref} styles={{ root: { position: "absolute", top: 5, left: 5 } }}>
          <Icon iconName="ChevronLeft" />
          {t("back")}
        </Link>
      )}
      <Stack verticalAlign="center" horizontalAlign="center">
        <img src={logo} alt="logo" style={{ width: 50, justifyContent: "center" }} />
      </Stack>
      {showWhyDQA && <WhyDQA />}
      {showWelcomeBack && <WelcomeBack />}
      {showWelcomeUser && <WelcomeUser />}
    </Stack.Item>
  );
};

export const BottomSectionSignInOrLogin: FunctionComponent<TopButtonProps> = ({
  userExists,
  emailString,
}) => {
  const isOffice = localStorage.getItem("loginFromOffice") !== null;
  const showWhyDQA = isOffice && !userExists && !emailString;
  return (
    <Stack>
      <p>
        {t("sign-in-agreement")}
        <Link
          underline={true}
          href="https://www.dqc.ai/_files/ugd/eff187_64c75149d17e4a51b98da3d1693a9715.pdf"
          target="_blank"
        >
          {t("tos")}
        </Link>
        {t("tos_registration_2")}
      </p>
      {showWhyDQA && (
        <Stack style={{ marginTop: "20vh" }}>
          <LearnMore />
        </Stack>
      )}
    </Stack>
  );
};
