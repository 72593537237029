import { selectLatestUpload } from "../../../store/slices/upload";
import { useAppSelector } from "../../../store/hooks";
import { selectOpenRulesBySearch } from "../../../store/slices/rules";
import { RootState } from "../../../store/store";
import { RuleTableBasis } from "../../rules/RuleTableBasis";
import { useState } from "react";
import { selectLatestQuickAnalysisQualityIssues } from "../../../store/slices/quickAnalysis";

export const RuleOverview = () => {
  const uploadedFile = useAppSelector(selectLatestUpload);
  const [searchValue, setSearchValue] = useState<string>("");
  const rulesGroupedByColumn = useAppSelector((state: RootState) =>
    selectOpenRulesBySearch(state, searchValue)
  );
  const items = rulesGroupedByColumn.flatMap((rule) => rule);
  const issues = useAppSelector(selectLatestQuickAnalysisQualityIssues);

  if (!uploadedFile) return null;
  return (
    <RuleTableBasis
      rulesGroupedByColumn={rulesGroupedByColumn}
      items={items}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      issues={issues}
    />
  );
};
