import { Modal, IIconProps, Stack, Text, Icon, Separator } from "@fluentui/react";
import { IconButton } from "@fluentui/react/lib/Button";
import { t } from "i18next";
import { RuleCandidate } from "../../../types/rules";
import { QUALITY_RULE_COMPONENT_MAP } from "../../../helpers/predictRules/qualityRuleFunctions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectOpenRuleId, setOpenRuleId } from "../../../store/slices/quickAnalysis";

export const RuleDetailModal = ({ rule }: { rule: RuleCandidate }) => {
  const openRuleId = useAppSelector(selectOpenRuleId);
  const dispatch = useAppDispatch();
  const isModalOpen = openRuleId === rule.id;
  const closeModal = () => dispatch(setOpenRuleId(undefined));
  const columnNamesJoined = rule.columns.map((c) => c.name).join(" ");
  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={closeModal}
      isBlocking={false}
      styles={{ main: { borderRadius: 8 } }}
    >
      <Stack verticalFill styles={{ root: { minWidth: 600, maxWidth: 800, margin: 8 } }}>
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
          <Stack
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: 8 }}
            styles={{ root: { width: "calc(100% - 32px)" } }} // 32px is the IconButton width (L38)
          >
            <Icon iconName="wandIcon" />
            <Text
              block
              nowrap
              styles={{ root: { textOverflow: "ellipsis" } }}
              variant="xLarge"
            >{`${t("edit_rule_for")} ${columnNamesJoined}`}</Text>
          </Stack>
          <IconButton iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={closeModal} />
        </Stack>
        {/* SETTINGS MENU FOR THE RULE */}
        <Separator />
        {QUALITY_RULE_COMPONENT_MAP[rule.qualityTest.testFunctionName]({
          rule,
          toggleModal: closeModal,
        })}
      </Stack>
    </Modal>
  );
};

const cancelIcon: IIconProps = { iconName: "Cancel" };
