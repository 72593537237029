import { Stack, Text, Checkbox } from "@fluentui/react";
import { t } from "i18next";
import { RuleCandidate, EmptyCellsTest } from "../../../../types/rules";
import { useState } from "react";
import { NameAndDescriptionInput } from "./../NameDescription";
import { RuleDetailModalFooter } from "./../RuleDetailModalFooter";
import {
  EMPTY_CHAR_VALUES,
  EMPTY_STRING_VALUES,
  NAN_VALUES,
  NULL_VALUES,
} from "../../../../helpers/quickAnalysis/qualityChecks/constants";
import { textStyles, checkBoxStyles } from "./bodyStyles";

export const MissingRuleDetailBody = ({
  rule,
  toggleModal,
}: {
  rule: RuleCandidate;
  toggleModal: () => void;
}) => {
  const [localRule, setLocalRule] = useState<RuleCandidate>(rule);
  if (localRule.qualityTest.testFunctionName !== "missingValues") return null;
  const localQualityTest = localRule.qualityTest;

  const isEmptyStringChecked = !!localQualityTest.meta.valuesConsideredMissing.filter((value) =>
    EMPTY_STRING_VALUES.includes(value)
  ).length;
  const isNaNChecked = !!localQualityTest.meta.valuesConsideredMissing.filter((value) =>
    NAN_VALUES.includes(value)
  ).length;
  const isCharsChecked = !!localQualityTest.meta.valuesConsideredMissing.filter((value) =>
    EMPTY_CHAR_VALUES.includes(value)
  ).length;
  const isNullChecked = !!localQualityTest.meta.valuesConsideredMissing.filter((value) =>
    NULL_VALUES.includes(value)
  ).length;

  return (
    <>
      <Stack tokens={{ childrenGap: 12 }}>
        <NameAndDescriptionInput rule={localRule} updateRule={setLocalRule} />
        <Stack tokens={{ childrenGap: 8 }}>
          <Stack horizontal>
            <Text styles={textStyles}>{t("blank")}</Text>
            <Checkbox
              styles={checkBoxStyles}
              label={t("blank_description")}
              key={`${rule.name}::emptyString::checkbox`}
              checked={isEmptyStringChecked}
              onChange={(_, isChecked) => {
                const newRule = updateValuesConsideredMissing(
                  localRule,
                  localQualityTest,
                  !!isChecked,
                  EMPTY_STRING_VALUES
                );
                setLocalRule(newRule);
              }}
            />
          </Stack>
          <Stack horizontal>
            <Text styles={textStyles}>{t("n/a")}</Text>
            <Checkbox
              styles={checkBoxStyles}
              label={t("n/a_description")}
              key={`${rule.name}::emptyString::checkbox`}
              checked={isNaNChecked}
              onChange={(_, isChecked) => {
                const newRule = updateValuesConsideredMissing(
                  localRule,
                  localQualityTest,
                  !!isChecked,
                  NAN_VALUES
                );
                setLocalRule(newRule);
              }}
            />
          </Stack>
          <Stack horizontal>
            <Text styles={textStyles}>{t("empty_chars")}</Text>
            <Checkbox
              styles={checkBoxStyles}
              label={t("empty_chars_description")}
              key={`${rule.name}::emptyString::checkbox`}
              checked={isCharsChecked}
              onChange={(_, isChecked) => {
                const newRule = updateValuesConsideredMissing(
                  localRule,
                  localQualityTest,
                  !!isChecked,
                  EMPTY_CHAR_VALUES
                );
                setLocalRule(newRule);
              }}
            />
          </Stack>
          <Stack horizontal>
            <Text styles={textStyles}>{t("null")}</Text>
            <Checkbox
              styles={checkBoxStyles}
              label={t("null_description")}
              key={`${rule.name}::emptyString::checkbox`}
              checked={isNullChecked}
              onChange={(_, isChecked) => {
                const newRule = updateValuesConsideredMissing(
                  localRule,
                  localQualityTest,
                  !!isChecked,
                  NULL_VALUES
                );
                setLocalRule(newRule);
              }}
            />
          </Stack>
        </Stack>
      </Stack>
      <RuleDetailModalFooter rule={localRule} updateRule={setLocalRule} toggleModal={toggleModal} />
    </>
  );
};

const updateValuesConsideredMissing = (
  localRule: RuleCandidate,
  localQualityTest: EmptyCellsTest,
  isChecked: boolean,
  newValues: string[]
): RuleCandidate => {
  let newValuesConsideredMissing = localQualityTest.meta.valuesConsideredMissing;
  if (!isChecked) {
    newValuesConsideredMissing = localQualityTest.meta.valuesConsideredMissing.filter(
      (value) => !newValues.includes(value)
    );
  } else {
    newValuesConsideredMissing = [...localQualityTest.meta.valuesConsideredMissing, ...newValues];
  }
  const newRule = {
    ...localRule,
    qualityTest: {
      ...localQualityTest,
      meta: {
        valuesConsideredMissing: newValuesConsideredMissing,
        emptyRows: localQualityTest.meta.emptyRows,
      },
    },
  };
  return newRule;
};
