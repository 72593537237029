import axios from "axios";
import { SubjectType } from "../types/comments";
import { ProfileResult } from "../types/user-profile";
import { dqcRESTApi } from "./dqcREST";

export const fetchUserProfiles = async (
  subjectType: SubjectType,
  keyword: string,
  accessToken: string
): Promise<ProfileResult[]> => {
  let results: ProfileResult[] = [];
  if (subjectType === "remark") {
    try {
      const resp = await axios.get(
        `https://graph.microsoft.com/v1.0/users?$search="displayName:${keyword}"`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            ConsistencyLevel: "eventual",
          },
        }
      );

      results = resp.data.value;
    } catch (e) {
      console.log(e);
    }
  } else {
    try {
      const resp = await dqcRESTApi.get("/colleagues", {
        params: { q: encodeURIComponent(keyword) },
      });
      results = resp.data;
    } catch (e) {
      console.log(e);
    }
  }
  return results;
};
