export interface Author {
  id?: string;
  email?: string;
  display_name: string;
  first_name?: string;
  last_name?: string;
}

export const defaultEmoticons = {
  "👍": [],
  "👎": [],
  "😀": [],
};
export interface defaultEmoticonsProps {
  "👍"?: string[];
  "👎"?: string[];
  "😀"?: string[];
}

export interface TaggedUser {
  email: string;
  id: string;
  displayName: string;
}

export interface CommentBase {
  author: Author;
  body: string;
  emoticons: defaultEmoticonsProps;
  comment_props?: {};
  tagged_users?: TaggedUser[];
}

export interface CommentData extends CommentBase {
  id: string;
  created_at: string;
  updated_at: string;
}

export type SubjectType =
  | "remark"
  | "use_case"
  | "asset"
  | "excel"
  | "powerbi"
  | "tableau"
  | "mission";

export interface CommentThread {
  id: string;
  subject_type: SubjectType;
  subject_id: string;
  comments: CommentData[];
}
