import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CockpitSlice, ShowFieldsOptions, SortOption } from "../../types/cockpit";
import { RootState } from "../store";

const initialState: CockpitSlice = {
  sortOption: "custom",
  showFields: ["owner"],
};

export const cockpitSlice = createSlice({
  name: "cockpit",
  initialState,
  reducers: {
    setSortOption: (state: CockpitSlice, action: PayloadAction<SortOption>) => {
      state.sortOption = action.payload;
    },
    setShowFields: (state: CockpitSlice, action: PayloadAction<ShowFieldsOptions[]>) => {
      state.showFields = action.payload;
    },
    setDomainIds: (state: CockpitSlice, action: PayloadAction<string[] | undefined>) => {
      state.domainIds = action.payload;
    },
  },
});

export const { setSortOption, setShowFields, setDomainIds } = cockpitSlice.actions;
const selectCockpitSlice = (state: RootState) => state.cockpit;
export const selectSortOption = createSelector(
  selectCockpitSlice,
  (cockpitSlice) => cockpitSlice.sortOption
);
export const selectShowFields = createSelector(
  selectCockpitSlice,
  (cockpitSlice) => cockpitSlice.showFields
);
export const selectDomainIds = createSelector(
  selectCockpitSlice,
  (cockpitSlice) => cockpitSlice.domainIds
);
