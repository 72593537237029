import { Remark, CreateRemark } from "../types/colab";
import { dqcApi, api_base } from "./dqcQuery";

export const remarkApi = dqcApi.injectEndpoints({
  endpoints: (builder) => ({
    getRemarks: builder.query<Remark[], { userId?: string }>({
      query: () => ({ url: `/${api_base}/remarks/`, method: "get" }),
      providesTags: ["Remarks"],
      transformResponse: (remarks: Remark[], _, { userId }) => {
        // FIXME don't require userId as part of the payload, instead use accessToken with
        // better auth flow
        if (!userId) return remarks;
        return remarks.filter((remark) => {
          return (
            remark.visibility === "ALL" ||
            (remark.visibility === "SPECIFIC" &&
              remark.specific_visibilities?.find((user) => user.id === userId)) ||
            remark.creator === userId
          );
        });
      },
    }),
    createRemark: builder.mutation({
      query: (newRemark: CreateRemark) => ({
        url: `/${api_base}/remarks/`,
        method: "POST",
        data: newRemark,
      }),
      extraOptions: { maxRetries: 1 },
      invalidatesTags: ["Remarks"],
    }),
    updateRemark: builder.mutation({
      query: (remarkObj: { remark: Remark; remarkId: string }) => ({
        url: `/${api_base}/remarks/${remarkObj.remarkId}/`,
        method: "PUT",
        data: remarkObj.remark,
      }),
      extraOptions: { maxRetries: 1 },
      invalidatesTags: ["Remarks"],
    }),
    likeRemark: builder.mutation({
      query: ({
        remark,
        userId,
        thumbs_up,
      }: {
        remark: Remark;
        userId: string;
        thumbs_up: boolean;
      }) => ({
        url: `/${api_base}/remarks`,
        method: "PATCH",
        data: {
          op: "modify",
          ids: [remark.id],
          props: {
            user_id: userId,
            thumbs_up,
          },
        },
      }),
      extraOptions: { maxRetries: 1 },
      invalidatesTags: ["Remarks"],
    }),
    deleteRemark: builder.mutation({
      query: (remarkId: string) => ({
        url: `/${api_base}/remarks/${remarkId}/`,
        method: "DELETE",
      }),
      extraOptions: { maxRetries: 1 },
      invalidatesTags: ["Remarks"],
    }),
  }),
});

export const {
  useGetRemarksQuery,
  useCreateRemarkMutation,
  useUpdateRemarkMutation,
  useDeleteRemarkMutation,
  useLikeRemarkMutation,
} = remarkApi;
