import { Pivot, PivotItem } from "@fluentui/react";

import React from "react";
import { dqcTheme, pivotSelectedProps } from "../../helpers/commonStyles";
import { t } from "i18next";

type TabBarProps = {
  currentTab: navigationPaths;
  setCurrentTab: (tab: navigationPaths) => void;
};

export type navigationPaths = "remarks" | "glossary" | "quality";

export const TabBar: React.FC<TabBarProps> = ({ currentTab, setCurrentTab }) => {
  const switchRoute = (item?: PivotItem) => {
    if (item && item.props.itemKey) {
      setCurrentTab(item.props.itemKey as navigationPaths);
    }
  };

  const highlightLink = {
    style: {
      fontWeight: 700,
      backgroundColor: dqcTheme.palette.white,
      color: dqcTheme.palette.themePrimary,
    },
  };
  return (
    <Pivot onLinkClick={switchRoute} selectedKey={currentTab} {...pivotSelectedProps}>
      <PivotItem
        headerButtonProps={currentTab === "remarks" ? highlightLink : {}}
        headerText={t("remarks")}
        itemKey="remarks"
      />
      <PivotItem
        headerButtonProps={currentTab === "glossary" ? highlightLink : {}}
        headerText={t("glossary")}
        itemKey="glossary"
      />
      <PivotItem
        headerButtonProps={currentTab === "quality" ? highlightLink : {}}
        headerText={t("quality")}
        itemKey="quality"
      />
    </Pivot>
  );
};
