import {
  IBasePickerSuggestionsProps,
  ILabelStyleProps,
  ILabelStyles,
  IStyleFunctionOrObject,
  ITag,
  Label,
  TagPicker,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetAllUseCasesQuery } from "../../api/cockpit";
import { UseCase } from "../../types/usecase";
import { listContainsTagList } from "./helpers";

type SelectLinkedUseCasesProps = {
  linkedIds: string[];
  setLinkedIds: (ids: string[]) => void;
  styles?: IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles> | undefined;
};
export const SelectLinkedUseCases: React.FunctionComponent<SelectLinkedUseCasesProps> = ({
  linkedIds,
  setLinkedIds,
  styles,
}) => {
  const { t } = useTranslation();
  const useCasesQuery = useGetAllUseCasesQuery();
  const useCases: UseCase[] = useCasesQuery.data || [];
  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: t("suggest_link_use_cases"),
    noResultsFoundText: t("no_use_case_found"),
  };

  const getTextFromItem = (item: ITag) => item.name;
  const filterSuggestedTags = (filterText: string, tagList: ITag[] | undefined): ITag[] => {
    return filterText
      ? useCases
          .map(
            (uc: UseCase): ITag => ({
              key: uc.id,
              name: uc.title,
            })
          )
          .filter(
            (tag: ITag) =>
              tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 &&
              !listContainsTagList(tag, tagList)
          )
      : [];
  };

  const showMostPromising = (selectedItems?: ITag[] | undefined) => {
    return useCases
      .map(
        (uc: UseCase): ITag => ({
          key: uc.id,
          name: uc.title,
        })
      )
      .filter((tag: ITag) => !listContainsTagList(tag, selectedItems));
  };

  return (
    <>
      <Label styles={styles}>{t("link-use-case")}</Label>
      <TagPicker
        inputProps={linkedIds.length === 0 ? { placeholder: "Find a use case to link" } : undefined}
        removeButtonAriaLabel={t("remove")}
        selectionAriaLabel={t("select-use-cases")}
        onResolveSuggestions={filterSuggestedTags}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        onEmptyResolveSuggestions={showMostPromising}
        onChange={(items?: ITag[] | undefined) => {
          items && setLinkedIds(items.map((tag?: ITag) => tag?.key as string));
        }}
      />
    </>
  );
};
