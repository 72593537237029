import { DefaultButton, Icon, Stack, Text } from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const CopyLicenseKey = ({ licenseKey }: { licenseKey: string }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const { t } = useTranslation();

  return (
    <Stack horizontal style={{ width: 200 }}>
      {showKey ? (
        <Stack horizontal verticalAlign="center">
          <Stack style={{ maxWidth: 180, overflow: "scroll", marginRight: 8 }}>
            <Text>{licenseKey}</Text>
          </Stack>
          {isCopied ? (
            <Icon iconName="accept" />
          ) : (
            <Icon
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(licenseKey);
                setIsCopied(true);
              }}
              iconName="Copy"
            />
          )}
        </Stack>
      ) : (
        <DefaultButton onClick={() => setShowKey(true)}>{t("show_license_key")}</DefaultButton>
      )}
    </Stack>
  );
};
