import React from "react";
import { Stack, Text } from "@fluentui/react";
import { DuplicateIssueWithMeta, QuickAnalysisIssue } from "../../../types/quickAnalysis";
import { groupDuplicateIssues } from "../../../helpers/quickAnalysis/dimensionUtil";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  handleSelectIssueInSheet,
  selectHighlightedIssue,
} from "../../../store/slices/issueNavigator";
import { getDuplicateFirstRowStyle } from "./DuplicateDetails.styles";
import { DuplicateIssueSlider } from "./DuplicateIssueSlider";

type DuplicateDetailsProps = {
  issues: QuickAnalysisIssue[];
};

export const DuplicateDetails = ({ issues }: DuplicateDetailsProps) => {
  const selectedIssue: QuickAnalysisIssue | undefined = useAppSelector(selectHighlightedIssue);
  const dispatch = useAppDispatch();
  const duplicateIssues = issues.filter((issue) => !issue.isIgnored && !issue.isSolved);
  const groupedIssues: DuplicateIssueWithMeta[][] = groupDuplicateIssues(
    duplicateIssues as DuplicateIssueWithMeta[]
  );

  const onSliderCellClicked = (issue: QuickAnalysisIssue) => {
    dispatch(
      handleSelectIssueInSheet({
        issue,
      })
    );
  };
  return (
    <Stack
      styles={{
        root: {
          marginRight: 10,
          overflowWrap: "anywhere",
          wordBreak: "break-all",
        },
      }}
    >
      <Stack horizontal horizontalAlign="start">
        <Text styles={{ root: { width: 100, fontSize: 10 } }}>{t("selected_entry")}</Text>
        <Text block nowrap styles={{ root: { fontSize: 10 } }}>
          {t("duplicate_rows")}
        </Text>
      </Stack>
      {groupedIssues.map((issues) => {
        const isSelected = issues[0].row === selectedIssue?.row;
        return (
          <Stack horizontal horizontalAlign="start" key={`DuplicateGroup::${issues[0].groupId}`}>
            <Stack onClick={() => onSliderCellClicked(issues[0])}>
              {/* adding +2 (+1 for headerRow + 1 for list index starting at 0) */}
              <Text styles={getDuplicateFirstRowStyle(isSelected)}>{issues[0].row + 2}</Text>
            </Stack>
            <Stack styles={{ root: { marginTop: 2 } }}>
              <DuplicateIssueSlider duplicateGroupId={issues[0].groupId} />
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
