import {
  Dropdown,
  IconButton,
  IDropdownOption,
  IDropdownStyles,
  IIconStyles,
  Stack,
} from "@fluentui/react";
import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useGetDataAssetsQuery } from "../../api/cockpit";
import { DataAsset } from "../../types/dataasset";
import { assetTypeToIcons, ListIcon } from "../common/ListIcon";
import { useBoolean } from "@fluentui/react-hooks";
import { NoDropdownData } from "./dropdownOptions";

const dropdownStyles: Partial<IDropdownStyles> = {
  root: {
    marginTop: "2%",
  },
};
const cancelIcon: IIconStyles = {
  root: {
    fontSize: 10,
    padding: 0,
    margin: 0,
  },
};
const optionText: React.CSSProperties = {
  display: "inline-block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: 14,
};
type SelectMainDomainProps = {
  selectedMainDomainId: string | undefined;
  setSelectMainDomainId: (id: string | undefined) => void;
  showLabel?: boolean;
  showDetailStyle?: boolean;
};
export const SelectMainDomain: React.FunctionComponent<SelectMainDomainProps> = ({
  selectedMainDomainId,
  setSelectMainDomainId,
  showLabel,
  showDetailStyle,
}) => {
  const [mouseOver, { setTrue: mouseIsOver, setFalse: mouseIsOut }] = useBoolean(false);
  const extraStyle: Partial<IDropdownStyles> = {
    root: {
      marginTop: 0,
    },
    title: {
      border: mouseOver ? "" : "none",
    },
    dropdownItemsWrapper: {
      whiteSpace: "nowrap",
    },
  };
  const style = showDetailStyle ? extraStyle : undefined;
  const combinedStyle = { ...dropdownStyles, ...style };
  const { t } = useTranslation();
  const dataAssetsQuery = useGetDataAssetsQuery({ all: true });
  const dataAssets: DataAsset[] = dataAssetsQuery.data || [];

  const parentOptionList: IDropdownOption[] = dataAssets
    .filter((item: DataAsset) => item.type === "Domain")
    .map((item: DataAsset) => {
      return { key: item?.id, text: item?.title, id: item.type, data: item };
    });

  const onRenderDropDownOption = (option?: IDropdownOption<DataAsset>): JSX.Element | null => {
    if (!option || !option.data) return <span>{option?.text}</span>;
    const iconIndex = assetTypeToIcons.get(option.data.type);
    return (
      <Stack horizontal verticalAlign="center">
        <ListIcon score={iconIndex} />
        <span style={showDetailStyle ? optionText : {}}>{option.text}</span>
      </Stack>
    );
  };

  const onRenderTitle = (options?: IDropdownOption<DataAsset>[]): JSX.Element => {
    if (!options || !options.length) return <></>;
    const selectedOption = options[0];
    return (
      <Stack horizontal>
        {onRenderDropDownOption(selectedOption)}
        <IconButton
          iconProps={{ iconName: "Cancel" }}
          styles={cancelIcon}
          onClick={(event) => {
            event.stopPropagation();
            setSelectMainDomainId(undefined);
          }}
        />
      </Stack>
    );
  };

  return (
    <Dropdown
      label={showLabel !== false ? t("main_domain") : undefined}
      placeholder={t("select_main_domain")}
      styles={combinedStyle}
      onChange={(event: FormEvent<HTMLDivElement>, item?: IDropdownOption | any) => {
        setSelectMainDomainId(item.key);
      }}
      selectedKey={selectedMainDomainId || null}
      onRenderOption={onRenderDropDownOption}
      onRenderTitle={onRenderTitle}
      options={
        parentOptionList.length > 0 ? parentOptionList : NoDropdownData(t("no_domain_found"))
      }
      onMouseOver={mouseIsOver}
      onMouseOut={mouseIsOut}
    />
  );
};
