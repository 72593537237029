import React from "react";
import { ConfigureColumnListProps } from "../../../types/missions";
import { ColumnInfo } from "../../../types/quality";
import { SelectDeselectButton } from "./SelectDeselectButton";
import { columnArray } from "../../../helpers/dataset-helper";
import { ConfigureCheckbox } from "./ConfigureCheckbox";
import { useTranslation } from "react-i18next";
export const ConfigureColumnList: React.FunctionComponent<ConfigureColumnListProps> = ({
  qualityConfig,
  configuration,
  setConfiguration,
}) => {
  const { t } = useTranslation();
  const selectAllColumns = (columnName: string) => {
    setConfiguration({
      ...configuration,
      [columnName]: {
        toggle: !configuration[columnName].toggle,
        column: configuration[columnName].toggle ? [] : qualityConfig?.all_column_infos,
      },
    });
  };

  const onChangeHandle = (index: number, columnInfo: ColumnInfo, columnName: string) => {
    const isChecked = configuration[columnName].column?.some((item) => item.index === index);
    if (isChecked) {
      const removeValue = configuration[columnName].column?.filter((item) => item.index !== index);
      setConfiguration({
        ...configuration,
        [columnName]: { toggle: configuration[columnName].toggle, column: removeValue },
      });
    } else {
      setConfiguration({
        ...configuration,
        [columnName]: {
          toggle: configuration[columnName].toggle,
          column: [...configuration[columnName].column, columnInfo],
        },
      });
    }
  };

  const isBoxChecked = (index: number, columnName: string): boolean => {
    return configuration[columnName].column
      ? configuration[columnName].column?.some((item) => item.index === index)
      : false;
  };

  return (
    <table className="dt-table">
      <thead>
        <tr>
          <th>{t("edit_column_config_column")}</th>
          <th>{t("edit_column_config_duplicate")}</th>
          <th>{t("edit_column_config_missing")}</th>
          <th>{t("edit_column_config_iqr")}</th>
          <th>{t("edit_column_config_sign")}</th>
          <th>{t("edit_column_config_string_length")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td />
          {columnArray.map((columnName, index) => (
            <td key={index}>
              <SelectDeselectButton
                columnName={columnName}
                selectAllColumns={selectAllColumns}
                configuration={configuration}
              />
            </td>
          ))}
        </tr>
        {qualityConfig?.all_column_infos.map((columnInfo, index) => (
          <tr key={index}>
            <td>{columnInfo.name}</td>
            {columnArray.map((columnName, key) => (
              <td key={key}>
                <ConfigureCheckbox
                  index={index}
                  columnInfo={columnInfo}
                  onChangeHandle={onChangeHandle}
                  isBoxChecked={isBoxChecked}
                  columnName={columnName}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
