import { IStackItemStyles } from "@fluentui/react";
import { dqcPalette } from "../../utils/colors";

export const mainStackStyle: IStackItemStyles = {
  root: {
    backgroundColor: dqcPalette.lightGreyBg,
    border: "1px solid #E4E4E7",
    padding: 24,
  },
};

export const dataGridPreviewStyle: React.CSSProperties = {
  height: 195,
  width: 650,
};
