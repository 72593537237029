import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./ag-theme.css";
import { CellClickedEvent, ColDef, IRowNode } from "ag-grid-community";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectColumnDefsFromLatestUpload,
  selectLatestUpload,
  selectRowsFromLatestUpload,
} from "../../../store/slices/upload";
import { DQCTableData, DQCTableValue, UploadDataContainer } from "../../../types/fileUploader";
import {
  selectActiveFilter,
  selectVisibleRowIndicesMap,
} from "../../../store/slices/quickAnalysis";
import { CSSProperties, useCallback, useEffect, useMemo, useRef } from "react";
import { AgGridReact as AgGridReactType } from "ag-grid-react/lib/agGridReact";
import {
  EMPTY_COLUMN_DEFS,
  EMPTY_ROW_DATA,
  OVERALL_QUALITY,
} from "../../../helpers/quickAnalysis/qualityChecks/constants";
import {
  handleSelectIssueInSheet,
  selectHighlightedIssue,
} from "../../../store/slices/issueNavigator";
import { DataGridHeader } from "./DataGridHeader";

export const DataGrid = ({ styles }: { styles: CSSProperties }) => {
  const uploadContainer: UploadDataContainer | undefined = useAppSelector(selectLatestUpload);
  const gridRef = useRef<AgGridReactType<DQCTableData> | null>(null);
  const columnDefs: ColDef[] | undefined =
    useAppSelector(selectColumnDefsFromLatestUpload) ?? EMPTY_COLUMN_DEFS;
  const rowData = useAppSelector(selectRowsFromLatestUpload) ?? EMPTY_ROW_DATA;

  const activeFilter = useAppSelector(selectActiveFilter);
  const visibleRowIndicesMap = useAppSelector(selectVisibleRowIndicesMap);
  const selectedIssue = useAppSelector(selectHighlightedIssue);
  const dispatch = useAppDispatch();

  useEffect(() => {
    gridRef.current?.api?.onFilterChanged();
  }, [activeFilter]);

  useEffect(() => {
    if (!selectedIssue) return;
    let visibleRowIndex: number | undefined = selectedIssue.row;
    if (visibleRowIndicesMap) visibleRowIndex = visibleRowIndicesMap.get(selectedIssue.row);
    visibleRowIndex !== undefined &&
      gridRef.current?.api?.ensureIndexVisible(visibleRowIndex, "middle");
    if (selectedIssue.type !== "duplicate") {
      const columnToShow = columnDefs[selectedIssue.column].field;
      columnToShow && gridRef.current?.api?.ensureColumnVisible(columnToShow, "middle");
    }
  }, [selectedIssue]);

  const isExternalFilterPresent = useCallback(() => {
    return activeFilter !== undefined;
  }, [activeFilter]);

  const doesExternalFilterPass = useCallback(
    ({ data }: IRowNode<DQCTableData>) => {
      if (activeFilter === undefined) return true;
      if (!data) return false;
      if (activeFilter === OVERALL_QUALITY)
        return Object.values(data).some(({ issues }) => issues?.length);
      return Object.values(data).some(
        ({ issues }) => issues?.filter((issue) => issue.type === activeFilter)?.length
      );
    },
    [activeFilter]
  );

  const defaultColDef: ColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 120,
      filter: true,
    };
  }, []);

  const components = useMemo<{
    [p: string]: any;
  }>(() => {
    return {
      agColumnHeader: DataGridHeader,
    };
  }, []);

  const onCellClicked = ({ colDef, data }: CellClickedEvent<DQCTableData, DQCTableValue>) => {
    const columnIndexString = colDef.field?.split(".")[0] || "";
    const columnIndex = parseInt(columnIndexString);

    if (!data) return;
    const issue = data[columnIndex]?.issues?.[0];
    if (issue) {
      dispatch(handleSelectIssueInSheet({ issue }));
    }
  };

  if (uploadContainer && (!columnDefs || !rowData)) return null;

  return (
    // TODO figure out correct size for grid view...
    <div className={`ag-theme-alpine`} style={styles}>
      <AgGridReact<DQCTableData>
        onCellClicked={onCellClicked}
        rowData={rowData}
        columnDefs={columnDefs}
        components={components}
        isExternalFilterPresent={isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
        defaultColDef={defaultColDef}
        ref={gridRef}
      />
    </div>
  );
};
