// get power bi link
export const getPowerBIReportLink = (link: string): string | undefined => {
  if (link === "") return undefined;
  if (matchedLinkLength(link, /groups|reports/gi) >= 2) {
    const [, , , , reportID] = new URL(link).pathname.split("/");
    return `/colab/powerbi/report/${reportID}`;
  } else if (matchedLinkLength(link, /reportEmbed/gi) >= 1) {
    const u = new URL(link);
    const reportID = u.searchParams.get("reportId");
    return `/colab/powerbi/report/${reportID}`;
  }
};
// match the link with pattern
export const matchedLinkLength = (link: string, pattern: RegExp): number => {
  const matchedList = link.match(pattern);
  return matchedList ? matchedList.length : 0;
};
