import { QuickAnalysisIssue } from "../../../types/quickAnalysis";
import { UploadDataContainer, UploadDQCDataType } from "../../../types/fileUploader";
import { RuleCandidate } from "../../../types/rules";
import { addIssueId } from "../../quickAnalysis/qualityChecks/qualityUtils";

export const inconsistentDataType = (
  dataContainer: UploadDataContainer,
  rule: RuleCandidate
): QuickAnalysisIssue[] => {
  const issues: QuickAnalysisIssue[] = [];
  if (rule.qualityTest.testFunctionName !== "inconsistentDataType") return issues;
  const { dataType } = rule.qualityTest.meta;
  const [column] = rule.columns;
  const cells = dataContainer.data.map((row) => row[column.index]);
  cells.forEach((cell) => {
    const isCellEmpty = cell.type === "Empty" || cell.value === "";
    if (isCellEmpty) return;
    if (cell.type !== dataType) {
      issues.push({
        row: cell.row,
        column: cell.column,
        comment: "inconsistent_datatype_comment",
        severity: rule.severity,
        type: "inconsistent",
        id: "",
        rule_id: rule.id,
      });
    }
  });
  return addIssueId(issues);
};

const cellTypeToGroup: Record<UploadDQCDataType["type"], string> = {
  Boolean: "boolean",
  Integer: "number",
  Double: "number",
  String: "text",
  Empty: "text",
  Unknown: "text",
  Date: "date",
  Categorical: "categorical",
  GeoCoordinate: "geo_coordinate",
};
export const getCellTypeComment = (
  cellType: UploadDQCDataType["type"],
  mostFrequentType: string
): string => {
  const typeGroup = cellTypeToGroup[cellType];
  return `${typeGroup}_in_${mostFrequentType}_comment`;
};
