import { Stack, TextField, Text } from "@fluentui/react";
import { t } from "i18next";
import { RuleCandidate } from "../../../types/rules";
import { MODAL_DETAIL_LEFT_MARGIN, textFieldStyles } from "./detail-bodies/bodyStyles";

export const NameAndDescriptionInput = ({
  rule,
  updateRule,
}: {
  rule: RuleCandidate;
  updateRule: (rule: RuleCandidate) => void;
}) => {
  return (
    <>
      <Stack tokens={{ childrenGap: 12 }}>
        <Stack horizontal>
          <Text styles={{ root: { width: MODAL_DETAIL_LEFT_MARGIN } }}>{t("name")}</Text>
          <TextField
            styles={textFieldStyles}
            value={t(rule.name)}
            placeholder={t("name")}
            onChange={(_event, newValue?: string | undefined) => {
              if (!newValue) return;
              const newRule = { ...rule, name: newValue };
              updateRule(newRule);
            }}
          />
        </Stack>
        <Stack horizontal>
          <Text styles={{ root: { width: MODAL_DETAIL_LEFT_MARGIN } }}>{t("description")}</Text>
          <TextField
            styles={textFieldStyles}
            value={t(rule.description)}
            placeholder={t("description")}
            multiline
            rows={2}
            onChange={(_event, newValue?: string | undefined) => {
              if (!newValue) return;
              const newRule = { ...rule, description: newValue };
              updateRule(newRule);
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};
