import {
  IButtonStyles,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Icon,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { centerDiv, iconStyle, topHeading } from "../../helpers/commonStyles";

import { LearningData } from "../../types/learning";
import { TopContainer } from "../../components/common/TopContainer";
import { useGetInstallationsQuery } from "../../api/installation";
import { useTranslation } from "react-i18next";

const selectedstackStyles: IStackStyles = {
  root: {
    background: "#EEEEEE",
    marginTop: "1%",
    width: "70%",
    borderRadius: "9px",
  },
};

const unSelectedstackStyles: IStackStyles = {
  root: {
    background: "#FFFFFF",
    marginTop: "1%",
    width: "70%",
    borderRadius: "9px",
  },
};

const stackItemStyles: IStackItemStyles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
  },
};

const stackItemStyles1: IStackItemStyles = {
  root: {
    display: "flex",
    alignItems: "center",
    marginLeft: "3%",
    height: 40,
    width: "35%",
  },
};

const titleStyle: IStackItemStyles = {
  root: {
    marginLeft: "2%",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  },
};

const selectedContainer: React.CSSProperties = {
  width: "9px",
  height: "9px",
  borderRadius: "6px",
  marginTop: "0.3%",
  backgroundColor: "#5C85F4",
};

const mainButton: IButtonStyles = {
  root: {
    marginTop: "2%",
    marginBottom: "2%",
    backgroundColor: "#F5F5F5",
    border: "2px solid #2D3648",
    width: "652px",
    height: "40px",
    borderRadius: "6px",
    padding: "8px 12px",
    color: "#2D3648",
  },
};

// Tokens definition
const stackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};

export const LearningJourneys: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [data, setData] = useState<LearningData[]>([]);
  const [loader, setLoader] = useState(true);
  const installationData = useGetInstallationsQuery();
  useEffect(() => {
    if (!installationData.isLoading && installationData.isSuccess) {
      setData(installationData.data.learning_materials.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }, [installationData]);

  const headButton = () => {
    alert("YAAAAY BUTTON PRESS");
  };

  return (
    <TopContainer title={t("performance_learning")}>
      <main>
        <Label styles={topHeading}>{t("learning_journeys_label")}</Label>
        <PrimaryButton
          text={t("learning_journeys_primary_button")}
          onClick={() => headButton()}
          styles={mainButton}
        />
        {data && data.length !== 0 ? (
          data.map((data: LearningData, index) => (
            <Stack
              horizontal
              styles={value === index ? selectedstackStyles : unSelectedstackStyles}
              tokens={stackTokens}
              key={index}
              onClick={() => setValue(index)}
            >
              <Stack.Item grow={5} styles={stackItemStyles1}>
                {value === index ? <div style={selectedContainer} /> : null}
                <Label styles={titleStyle}>
                  {index + 1}. {data.attributes.title}
                </Label>
              </Stack.Item>
              <Stack.Item grow={1} styles={stackItemStyles}>
                <Label>{data.attributes.video_length}</Label>
              </Stack.Item>
              <Stack.Item grow={1} styles={stackItemStyles}>
                <Icon
                  styles={iconStyle}
                  iconName="MSNVideosSolid"
                  onClick={() => window.open(data.attributes.video_url)}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Icon
                  styles={iconStyle}
                  iconName="DrillDownSolid"
                  onClick={() => window.open(data.attributes.download_url)}
                />
              </Stack.Item>
            </Stack>
          ))
        ) : loader ? (
          <div style={centerDiv}>
            <Spinner size={SpinnerSize.large} />
          </div>
        ) : null}
      </main>
    </TopContainer>
  );
};
