import {
  Dropdown,
  Text,
  IDropdownOption,
  Stack,
  DefaultButton,
  PrimaryButton,
  TextField,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Report } from "powerbi-client";
import { useTranslation } from "react-i18next";
import { useCreateRemarkMutation } from "../../api/remark";
import {
  CreateRemark,
  remarkAddEditInitialState,
  RemarkAddEditState,
  ReportManager,
} from "../../types/colab";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectCurrentSelectedData,
  setCurrentSelectedData,
  selectMsGraphApiAccessToken,
  setSelectedData,
} from "../../store/slices/remarkSlice";
import { RichTextEditor } from "../../components/common/RichTextEditor";
import { containerBorder } from "../../helpers/commonStyles";
import jwt_decode from "jwt-decode";
import { Visibility } from "./visibility";
import { Assignees } from "./assignees";
import {
  FIELD_SEPARATOR,
  numberTidier,
  replaceStrSeperators as replaceStrSeparators,
  showDiffHTML,
  SINGLE_LINE_TEXTFIELD_LENGTH,
  stringTidier,
} from "../../helpers/remarkUtils";

interface AddRemarkProps {
  reportManager: ReportManager;
  toggleShowAddRemark: () => void;
}

export const AddRemark: FunctionComponent<AddRemarkProps> = ({
  reportManager,
  toggleShowAddRemark,
}) => {
  const currentSelectedData = useAppSelector(selectCurrentSelectedData);
  const accessToken = useAppSelector(selectMsGraphApiAccessToken);
  const decoded: Record<string, string> = jwt_decode(accessToken);
  const dispatch = useAppDispatch();
  const [createRemark] = useCreateRemarkMutation();
  const { t } = useTranslation();
  const [addRemarkState, setAddRemarkState] =
    useState<RemarkAddEditState>(remarkAddEditInitialState);

  const onChange = (
    _event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    newValue?: string | undefined
  ): void => {
    const changedFields: string[] = [...addRemarkState.dataCells.changed];
    changedFields[index] = newValue ? newValue : "";
    handleTextFieldsSize(index, undefined, {
      ...addRemarkState,
      dataCells: { ...addRemarkState.dataCells, changed: changedFields },
    });
  };

  const [localRemark, setLocalRemark] = useState<CreateRemark>({
    original_field: "",
    changed_field: "",
    bookmark: "",
    report_id: reportManager.reportId,
    report_type: "POWER_BI",
    creator: "",
    description: "",
    assignees: [],
    visibility: "ALL",
    specific_visibilities: [],
    status: "OPEN",
    thumbs_up: [],
  });

  useEffect(() => {
    if (currentSelectedData?.type === "single") {
      let splittedValues: string[] = [];
      if (currentSelectedData.value.includes(":")) {
        splittedValues = currentSelectedData.value.split(":").map(numberTidier); // In case of selected from multiple data points
      } else {
        splittedValues = replaceStrSeparators(currentSelectedData.value)
          .split(",")
          .filter(Boolean)
          .map(numberTidier); // In case of single data point split by command and remove any blank entries
      }
      const newRemarkState = {
        ...addRemarkState,
        dataCells: {
          ...addRemarkState.dataCells,
          original: splittedValues,
          changed: splittedValues,
        },
      };
      handleTextFieldsSize(-1, splittedValues, newRemarkState);
    }
  }, [currentSelectedData]);

  const handleTextFieldsSize = (
    index: number,
    splitValues?: string[],
    newState?: RemarkAddEditState
  ) => {
    const state = newState ? newState : addRemarkState;
    let multilinesState: boolean[] = [...state.multilines];
    if (index > -1) {
      //Update the multiline state at an index based on char length
      multilinesState[index] =
        state.dataCells.changed[index] &&
        state.dataCells.changed[index].length > SINGLE_LINE_TEXTFIELD_LENGTH
          ? true
          : false;
    } else if (splitValues && splitValues.length > 0) {
      //Initialisizing the multiline state
      multilinesState = [];
      splitValues.forEach((changedField) => {
        multilinesState.push(
          changedField && changedField.length > SINGLE_LINE_TEXTFIELD_LENGTH ? true : false
        );
      });
    }
    const newRemarkState = {
      ...state,
      multilines: multilinesState,
    };
    setAddRemarkState(newRemarkState);
  };

  const someDataSelected = (
    <Stack.Item>
      {currentSelectedData && currentSelectedData.type === "multiple" ? (
        <Stack className="selectionChange" tokens={{ childrenGap: 8 }}>
          <Text>{t("multiple_values_selected")}</Text>
          <Dropdown
            placeholder="Select values"
            options={currentSelectedData.value.map((v: string, i: number) => ({
              key: `val-${i}`,
              text: v.includes(":") ? v : stringTidier(v as string),
            }))}
            onChange={(_, item?: IDropdownOption) => {
              if (!item?.text) return;
              dispatch(
                setCurrentSelectedData({
                  type: "single",
                  value: item.text.includes(":") ? item.text : stringTidier(item.text as string),
                  lastChanged: Date.now(),
                })
              );
            }}
          />
        </Stack>
      ) : (
        <Stack horizontal horizontalAlign="space-between" className="selectionChange">
          <Stack style={{ width: "49%", minWidth: "49%" }}>
            {addRemarkState.dataCells.original.map((changedDataCell, index) => {
              return (
                <div
                  key={`original-${index}`}
                  className="changedText"
                  dangerouslySetInnerHTML={{
                    __html: showDiffHTML(
                      addRemarkState.dataCells.original[index],
                      addRemarkState.dataCells.changed[index]
                    ),
                  }}
                />
              );
            })}
          </Stack>
          <Stack style={{ width: "49%", minWidth: "49%" }}>
            {addRemarkState.dataCells.changed.map((_originalDataCell, index) => {
              return (
                <TextField
                  key={`changed-${index}`}
                  value={addRemarkState.dataCells.changed[index]}
                  onChange={(e, newValue) => {
                    onChange(e, index, newValue);
                  }}
                  className="originalCell"
                  autoAdjustHeight={false}
                  multiline={addRemarkState.multilines[index]}
                  borderless={false}
                  resizable={false}
                />
              );
            })}
          </Stack>
        </Stack>
      )}
    </Stack.Item>
  );

  const fullDashboardSelection = (
    <Stack.Item className="fullDashboardSelection">{t("make_a_selection")}</Stack.Item>
  );

  return (
    <Stack style={{ marginBottom: 12, marginTop: 20 }}>
      {!currentSelectedData ? fullDashboardSelection : someDataSelected}

      <Stack.Item styles={containerBorder}>
        <RichTextEditor
          toggleEditor={false}
          onChange={(description) => setLocalRemark({ ...localRemark, description: description! })}
          value={localRemark.description || ""}
          comment={true}
          enableDistractionFreeMode={true}
        />
      </Stack.Item>
      <Assignees remark={localRemark} setRemark={setLocalRemark} editMode />
      <Visibility remark={localRemark} setRemark={setLocalRemark} editMode />

      <Stack horizontal grow={1} horizontalAlign="end">
        <DefaultButton
          text={t("cancel")}
          onClick={() => {
            dispatch(setSelectedData({}));
            toggleShowAddRemark();
          }}
          styles={{ root: { marginRight: 10 } }}
        />
        <PrimaryButton
          text={t("submit")}
          onClick={async () => {
            const capturedBookmark = await (
              reportManager?.report as Report
            ).bookmarksManager.capture();
            const newRemark = {
              ...localRemark,
              bookmark: capturedBookmark.state,
              creator: decoded["oid"],
              original_field: addRemarkState.dataCells.original.join(FIELD_SEPARATOR),
              changed_field: addRemarkState.dataCells.changed.join(FIELD_SEPARATOR),
            };
            try {
              await createRemark(newRemark).unwrap();
              toggleShowAddRemark();
            } catch (error) {
              setAddRemarkState({
                ...addRemarkState,
                showErrorMessage: true,
              });
            }
          }}
        />
      </Stack>
      {addRemarkState.showErrorMessage && (
        <Stack.Item style={{ marginTop: "10px" }}>
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            onDismiss={() => {
              setAddRemarkState({ ...addRemarkState, showErrorMessage: false });
            }}
            dismissButtonAriaLabel="Close"
          >
            {t("something_went_wrong")}
          </MessageBar>
        </Stack.Item>
      )}
    </Stack>
  );
};
