import { Stack } from "@fluentui/react";
import * as React from "react";
import { growStackBody, mainBodyStack } from "../../helpers/commonStyles";
import { Header } from "./Header";

type TopContainerProps = {
  title: string;
  children: any;
  backButton?: boolean;
  isQuickStart?: boolean;
};
export const TopContainer: React.FunctionComponent<TopContainerProps> = (props) => {
  return (
    <Stack styles={growStackBody}>
      <Header title={props.title} backButton={props.backButton}></Header>
      {props.isQuickStart ? (
        <Stack styles={mainBodyStack} tokens={{ childrenGap: 40 }}>
          {props.children}
        </Stack>
      ) : (
        <Stack styles={mainBodyStack}>{props.children}</Stack>
      )}
    </Stack>
  );
};
