import { IColumn } from "@fluentui/react";
import { Dataset } from "../types/dataset";
import { ProfileResult } from "../types/user-profile";

export function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items
    .slice()
    .sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

export function filterColumns(
  columns: IColumn[],
  column: IColumn
): { newColumns: IColumn[]; currColumn: IColumn } {
  const newColumns: IColumn[] = columns.slice();
  const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
  newColumns.forEach((newCol: IColumn) => {
    if (newCol === currColumn) {
      currColumn.isSortedDescending = !currColumn.isSortedDescending;
      currColumn.isSorted = true;
    } else {
      newCol.isSorted = false;
      newCol.isSortedDescending = true;
    }
  });
  return { newColumns, currColumn };
}

export const getBarColorForDataset = (datasetId: string, datasets: Dataset[]): string => {
  return `rgba(0, 83, 163, ${
    (1 / datasets.length) *
    (datasets.length - datasets.map((dset) => dset.id).indexOf(datasetId) || 0)
  })`;
};

export const getTaggedUserId = (item: ProfileResult) => {
  return item.id || item.identityId || "";
};

export const getTaggedUserEmail = (item: ProfileResult) => {
  return item.mail || item.email || "";
};

export const getInitialsFromMail = (email: string): string => {
  return email
    .split("@")[0]
    .split(".")
    .map((s) => s.slice(0, 1))
    .join("")
    .toUpperCase();
};

export const getInitialsFromDisplayName = (name: string): string => {
  return name
    .split(" ")
    .map((s) => s.slice(0, 1))
    .join("")
    .toUpperCase();
};
