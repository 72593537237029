import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";
import { t } from "i18next";
import React from "react";
import { useGetDataAssetsQuery } from "../../api/cockpit";
import { AssetType, DataAsset } from "../../types/dataasset";
import { NoDropdownData } from "./dropdownOptions";

const dropdownStyles: Partial<IDropdownStyles> = {
  root: {
    marginTop: "2%",
  },
};

type SelectChildrenDataAssetsProps = {
  assetType: AssetType;
  selectedChildrenIds: string[];
  setSelectedChildrenIds: (ids: string[]) => void;
};
export const SelectChildrenDataAssets: React.FunctionComponent<SelectChildrenDataAssetsProps> = ({
  assetType,
  selectedChildrenIds,
  setSelectedChildrenIds,
}) => {
  const dataAssetsQuery = useGetDataAssetsQuery({ all: true });
  const dataAssets: DataAsset[] = dataAssetsQuery.data || [];
  const parentOptionList: IDropdownOption[] = dataAssets
    .filter((item: DataAsset) => {
      if (item.parent_asset_id) return false;
      if (assetType === "Domain") return item.type === "Family" || item.type === "Product";
      if (assetType === "Family") return item.type === "Product";
      return false;
    })
    .map((item: DataAsset) => {
      return { key: item?.id, text: item?.title, id: item.type };
    });

  return (
    <Dropdown
      label={t("select-children-assets")}
      placeholder={t("select-children-assets-placeholder")}
      multiSelect={parentOptionList.length > 0 ? true : false}
      styles={dropdownStyles}
      selectedKeys={selectedChildrenIds}
      onChange={(_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined) => {
        if (option?.selected)
          setSelectedChildrenIds([...selectedChildrenIds, option.key.toString()]);
        else setSelectedChildrenIds(selectedChildrenIds.filter((i) => i !== option?.key));
      }}
      options={
        parentOptionList.length > 0
          ? parentOptionList
          : NoDropdownData(t("no_assets_available_as_children"))
      }
    />
  );
};
