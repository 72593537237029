import {
  CommandBarButton,
  IContextualMenuProps,
  IStackStyles,
  Overlay,
  Stack,
} from "@fluentui/react";
import { t } from "i18next";
import { dqcPalette } from "../../utils/colors";
import { useState } from "react";
import { ContactSalesModal } from "./ContactSalesModal";
import { DBTTestModal } from "./ExportDBTTestModal";
import { buttonStyle } from "./ExportRulesButton.styles";

export const ExportRulesButton = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showDBTTest, setShowDBTTest] = useState(false);
  const defaultProps = {
    iconProps: { iconName: "exportIcon" },
    onClick: () => setShowOverlay(true),
  };
  const menuProps: IContextualMenuProps = {
    title: t("export_quality_rules_to"),
    items: [
      {
        key: "dqaRuleset",
        text: "DQA Ruleset",
        ...defaultProps,
      },
      {
        key: "great_expectations",
        text: "Great Expectations",
        ...defaultProps,
      },
      {
        key: "dbt_tests",
        text: "dbt Tests",
        ...defaultProps,
        onClick: () => setShowDBTTest(true),
      },
      {
        key: "informatica",
        text: "Informatica",
        ...defaultProps,
      },
      {
        key: "aws_dqdl",
        text: "AWS DQDL",
        ...defaultProps,
      },
    ],
    styles: {
      title: {
        fontSize: 12,
        margin: 4,
        paddingLeft: 4,
        color: dqcPalette.grey,
        backgroundColor: dqcPalette.white,
      },
    },
  };
  const stackStyles: Partial<IStackStyles> = {
    root: { height: 44, marginTop: 20, width: "100%" },
  };

  if (showDBTTest)
    return (
      <Overlay>
        <DBTTestModal isModalOpen={showDBTTest} setModalOpen={setShowDBTTest} />
      </Overlay>
    );
  if (showOverlay)
    return (
      <Overlay>
        <ContactSalesModal isModalOpen={showOverlay} setModalOpen={setShowOverlay} />
      </Overlay>
    );
  return (
    <Stack horizontal styles={stackStyles}>
      <CommandBarButton
        iconProps={{
          iconName: "exportIcon",
          styles: { root: { height: 21, marginRight: 16, marginLeft: "unset" } },
        }}
        text={t("export")}
        menuProps={menuProps}
        styles={buttonStyle}
      />
    </Stack>
  );
};
