import React, { useEffect, useState } from "react";
import { Accordion } from "../../components/common/Accordion";
import { useNavigate } from "react-router-dom";
import { TopContainer } from "../../components/common/TopContainer";
import { useTranslation } from "react-i18next";
import { useGetAllConfigurationsQuery } from "../../api/installation";
import surveyGif from "../../assets/survey-animation.gif";
import pluginGif from "../../assets/plugin-animation.gif";
import systemConnectGif from "../../assets/system-connector-animation.gif";
import chatIcon from "../../assets/chat-icon.svg";
import qIcon from "../../assets/q-icon.svg";
import { DefaultPalette, ILabelStyles, IStackStyles, Label, Stack } from "@fluentui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const subTextStyle: React.CSSProperties = {
  marginTop: "20px",
};
const containerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};
const descriptionStyle: React.CSSProperties = {
  marginTop: 5,
};
type status = "completed" | "ready" | "disabled";

const textContainer: IStackStyles = {
  root: {
    paddingLeft: 20,
  },
};

const headingText: ILabelStyles = {
  root: {
    fontSize: 16,
    fontWeight: 600,
    color: DefaultPalette.black,
    marginBottom: 15,
  },
};

type QuickStartProps = {
  hasInstallation: boolean;
};

export const Quickstart = ({ hasInstallation }: QuickStartProps) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const readyOrDisabled = hasInstallation ? "ready" : "disabled";
  const allConfigurations = useGetAllConfigurationsQuery(!hasInstallation ? skipToken : "");
  const [surveyStatus, setSurveyStatus] = useState<status>(readyOrDisabled);
  const [pluginStatus, setPluginStatus] = useState<status>(readyOrDisabled);
  const [connectionStatus, setConnectionStatus] = useState<status>(readyOrDisabled);

  useEffect(() => {
    if (!allConfigurations.isLoading && allConfigurations.isSuccess) {
      if (allConfigurations.data.surveys.length > 0) {
        setSurveyStatus("completed");
      }
      if (allConfigurations.data.plugins.length > 0) {
        setPluginStatus("completed");
      }
      if (allConfigurations.data.connections.length > 0) {
        setConnectionStatus("completed");
      }
    }
  }, [allConfigurations]);

  return (
    <TopContainer title={t("quick_start")} isQuickStart={true}>
      <Accordion
        title={t("quick_analysis")}
        icon="NewAnalyticsQuery"
        body={
          <div style={containerStyle}>
            <div>
              <span>
                <b>{t("quick-analysis_body_1")}</b>
                <br />
                <p style={subTextStyle}>{t("quick-analysis_body_2")}</p>
              </span>
            </div>
            <div></div>
          </div>
        }
        primaryButtonText={t("quickstart_to_quick-analysis")}
        primaryButtonOnClick={() => {
          navigate("quick-analysis");
        }}
        secondaryButtonText={t("learn_more")}
        secondaryButtonOnClick={(): void => {
          alert("Secondary button press");
        }}
        status={"ready"}
      />
      <Accordion
        title={t("quickstart_title")}
        icon="ClipboardList"
        body={
          <div style={containerStyle}>
            <div>
              <span>
                <b>{t("quickstart_body_1")}</b>
                <br />
                <p style={subTextStyle}>{t("quickstart_body_2")}</p>
              </span>
            </div>
            <div>
              <img src={surveyGif} width={175} height={175} alt="survey-gif" />
            </div>
          </div>
        }
        primaryButtonText={t("quickstart_to_surveys")}
        primaryButtonOnClick={() => {
          navigate("surveys");
        }}
        secondaryButtonText={t("learn_more")}
        secondaryButtonOnClick={(): void => {
          alert("Secondary button press");
        }}
        status={surveyStatus}
      />
      <Accordion
        title={t("quickstart_deploy")}
        icon="PlugConnected"
        body={
          <div style={containerStyle}>
            <div>
              <span>
                <b>{t("quickstart_deploy_body_1")}</b>
                <br />
                <p style={subTextStyle}>{t("quickstart_deploy_body_2")}</p>
              </span>
            </div>
            <div>
              <img src={pluginGif} width={175} height={175} alt="plugin-gif" />
            </div>
          </div>
        }
        primaryButtonText={t("quickstart_to_plug_ins")}
        primaryButtonOnClick={() => {
          navigate("plugins");
        }}
        secondaryButtonText={t("learn_more")}
        secondaryButtonOnClick={(): void => {
          alert("Secondary button press");
        }}
        status={pluginStatus}
      />
      <Accordion
        title={t("quickstart_connect")}
        icon="VerifiedBrand"
        body={
          <div style={containerStyle}>
            <div>
              <span>
                <b>{t("quickstart_connect_body_1")}</b>
                <br />
                <p style={subTextStyle}>{t("quickstart_connect_body_2")}</p>
              </span>
            </div>
            <div>
              <img src={systemConnectGif} width={175} height={175} alt="system-connect-gif" />
            </div>
          </div>
        }
        primaryButtonText={t("quickstart_to_connections")}
        primaryButtonOnClick={() => {
          navigate("connections");
        }}
        secondaryButtonText={t("learn_more")}
        secondaryButtonOnClick={(): void => {
          alert("Secondary button press");
        }}
        status={connectionStatus}
      />

      <Stack styles={textContainer}>
        <Label styles={headingText}>{t("material_and_resources")}</Label>
        <Stack horizontal verticalAlign="center" style={subTextStyle}>
          <Stack.Item>
            <img src={qIcon} width={75} height={75} alt="question-mark-icon" />
          </Stack.Item>
          <Stack.Item styles={textContainer}>
            <b>{t("data_management_&_quality")}</b>
            <p style={descriptionStyle}>{t("send_question_&_get_support")}</p>
          </Stack.Item>
        </Stack>
        <Stack horizontal verticalAlign="center" style={subTextStyle}>
          <Stack.Item>
            <img src={chatIcon} width={75} height={75} alt="question-mark-icon" />
          </Stack.Item>
          <Stack.Item styles={textContainer}>
            <b>{t("chat_with_us")}</b>
            <p style={descriptionStyle}>{t("get_immediate_support")}</p>
          </Stack.Item>
        </Stack>
      </Stack>
    </TopContainer>
  );
};
