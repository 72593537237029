import { Modal, IIconProps, Stack, Text, Icon } from "@fluentui/react";
import { DefaultButton, IconButton } from "@fluentui/react/lib/Button";
import { t } from "i18next";

export const ContactSalesModal = ({
  isModalOpen,
  setModalOpen,
}: {
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
}) => {
  return (
    <Modal isOpen={isModalOpen} onDismiss={() => setModalOpen(false)} isBlocking={false}>
      <Stack
        verticalFill
        verticalAlign="space-between"
        styles={{ root: { minHeight: 400, minWidth: 600, margin: 8 } }}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          tokens={{ childrenGap: 12 }}
        >
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
            <Icon iconName="wandIcon" />
            <Text variant="xLarge">{t("contact_sales_modal_header")}</Text>
          </Stack>
          <IconButton
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => setModalOpen(false)}
          />
        </Stack>
        <Stack horizontalAlign="center" tokens={{ childrenGap: 32 }}>
          <Text variant="mediumPlus">{t("contact_sales_modal_text_1")}</Text>
          <Text variant="mediumPlus">{t("contact_sales_modal_text_2")}</Text>
        </Stack>
        <Stack horizontalAlign="end" tokens={{ childrenGap: 32 }}>
          <DefaultButton
            iconProps={{ iconName: "mail" }}
            onClick={() => (window.location.href = "mailto:sales@dqc.ai")}
          >
            {t("contact_sales")}
          </DefaultButton>
        </Stack>
      </Stack>
    </Modal>
  );
};

const cancelIcon: IIconProps = { iconName: "Cancel" };
