import { InteractionFactsAndFigures } from "../types/interactions";
import {
  QualityPerformanceData,
  QualityFactsAndFigures,
  DatasetsQualityOverviewOverTime,
  DataQualityScoresOverTime,
  GamificationData,
  InteractionsPerDayData,
} from "../types/performance";
import { api_base, dqcApi } from "./dqcQuery";

export const performanceApi = dqcApi.injectEndpoints({
  endpoints: (builder) => ({
    getDataQualityScores: builder.query<QualityPerformanceData, void>({
      query: () => ({ url: `/${api_base}/performance/data-quality-score`, method: "get" }),
    }),
    getQualityFactsAndFigures: builder.query<QualityFactsAndFigures, {}>({
      query: () => ({
        url: `/${api_base}/performance/facts-and-figures-for-quality`,
        method: "get",
      }),
    }),
    getQualityOfDatasets: builder.query<DatasetsQualityOverviewOverTime, {}>({
      query: () => ({ url: `/${api_base}/performance/quality-of-datasets`, method: "get" }),
    }),
    getQualityScoresPerDimension: builder.query<DataQualityScoresOverTime, {}>({
      query: () => ({
        url: `/${api_base}/performance/data-quality-score-for-each-dimension`,
        method: "get",
      }),
    }),
    getGamification: builder.query<GamificationData, void>({
      query: () => ({ url: `/${api_base}/performance/gamification`, method: "get" }),
    }),
    getInteractions: builder.query<InteractionsPerDayData, void>({
      query: () => ({ url: `/${api_base}/performance/interactions-per-day`, method: "get" }),
    }),
    getInteractionFactsAndFigures: builder.query<InteractionFactsAndFigures, {}>({
      query: () => ({
        url: `/${api_base}/performance/facts-and-figures-for-interactions`,
        method: "get",
      }),
    }),
  }),
});

export const {
  useGetDataQualityScoresQuery,
  useGetQualityFactsAndFiguresQuery,
  useGetQualityOfDatasetsQuery,
  useGetQualityScoresPerDimensionQuery,
  useGetGamificationQuery,
  useGetInteractionsQuery,
  useGetInteractionFactsAndFiguresQuery,
} = performanceApi;
