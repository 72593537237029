import { IStackStyles, Spinner, Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useGetMyProfileQuery } from "../../api/profile";
import {
  useFetchLogoutDataQuery,
  useFetchProfileSettingsDataQuery,
  useWhoAmIQuery,
} from "../../api/profile";

import { useSearchParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { dqcTheme } from "../../helpers/commonStyles";
import wave from "../../assets/wave-bg.svg";
import { ProfileHeader } from "./profile/profile-head";
import { ProfileData } from "../../types/profile";
import { ProfileAbout } from "./profile/profile-about";
import { ProfileOffering } from "./profile/profile-offering";
import { useBoolean } from "@fluentui/react-hooks";
import { t } from "i18next";

import logo from "../../assets/logo-dqc-white.svg";

const stackStyle: IStackStyles = {
  root: {
    paddingLeft: "-2px",
    paddingRight: "0px",
    marginTop: 0,
  },
};

export const Profile = (): JSX.Element | null => {
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow");
  const session = useWhoAmIQuery({});
  const profileRequest = useGetMyProfileQuery({}, { refetchOnFocus: true });
  const { data: logoutData, isSuccess: logoutDataIsSuccess } = useFetchLogoutDataQuery({});
  const { data: profileData } = useFetchProfileSettingsDataQuery(flowId ?? skipToken, {
    refetchOnFocus: true,
  });
  const [tmpProfileData, setTmpProfileData] = useState<ProfileData>();
  const [editable, { toggle: toggleEditable }] = useBoolean(flowId ? true : false);

  useEffect(() => {
    if (session.isSuccess && session.data.identity?.traits) {
      setTmpProfileData({
        firstname: session.data.identity?.traits.name.first,
        lastname: session.data.identity?.traits.name.last,
        email: session.data.identity.traits.email,
      });
    }
    if (profileRequest && profileRequest.isSuccess && profileRequest.data) {
      setTmpProfileData({ ...tmpProfileData, profile: profileRequest.data });
    }
  }, [profileRequest.data, profileData, session]);

  if (!logoutDataIsSuccess)
    return <Spinner label={t("loading")} ariaLive={t("loading")} labelPosition="right" />;

  return (
    <Stack styles={stackStyle}>
      <Stack.Item
        styles={{
          root: {
            backgroundColor: dqcTheme.palette.themePrimary,
            color: dqcTheme.palette.white,
            backgroundImage: `url(${wave})`,
            backgroundSize: "cover",
            minHeight: 160,
            borderLeft: profileRequest.data?.accessPlatform ? "1px solid #ffffff" : "none",
          },
        }}
      >
        {profileRequest.isSuccess && profileRequest.data.accessPlatform === true ? (
          <span></span>
        ) : (
          <img src={logo} alt="DQC logo" height={90} />
        )}
      </Stack.Item>

      <Stack>
        <Stack.Item align={"center"} styles={{ root: { minWidth: "50%" } }}>
          <ProfileHeader
            profile={tmpProfileData}
            toggleEditable={toggleEditable}
            editable={editable}
            logoutUrl={logoutData.logout_url}
          />
          <ProfileAbout
            profile={tmpProfileData}
            toggleEditable={toggleEditable}
            editable={editable}
            session={session.data}
            setProfile={setTmpProfileData}
            settingsData={profileData}
          />
          <ProfileOffering
            profile={tmpProfileData}
            toggleEditable={toggleEditable}
            editable={editable}
            styles={{ root: { marginTop: 40 } }}
          />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};
