import { FunctionComponent, useEffect, useState, CSSProperties } from "react";
import { UploadColumnInfo, SharePointColumnInfo, ColumnMapping } from "../../types/upload";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getUploadColumnMapping, setUploadColumnMapping } from "../../store/slices/missionSlice";
import { useTranslation } from "react-i18next";

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
  root: { height: 100 },
};

const examplesStyles: CSSProperties = {
  display: "flex",
  flexDirection: "column",
};

export interface ColumnSelectorProps {
  uploadColumnInfo: UploadColumnInfo;
  columnsInSharePoint: SharePointColumnInfo[];
}

export const ColumnSelector: FunctionComponent<ColumnSelectorProps> = (
  props: ColumnSelectorProps
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<IDropdownOption[]>([]);
  const uploadColumnMapping = useAppSelector(getUploadColumnMapping);
  const [columnMappings, setColumnMappings] = useState<ColumnMapping[]>(uploadColumnMapping);
  const { uploadColumnInfo, columnsInSharePoint } = props;

  useEffect(() => {
    setColumnMappings(uploadColumnMapping);
  }, [uploadColumnMapping]);

  const columnSelected = (item: SharePointColumnInfo) => {
    const columnMappingsData = [
      ...columnMappings.filter(
        (c) =>
          c.uploadColumnInfo.name !== uploadColumnInfo.name &&
          c.sharepointColumnInfo.name !== item?.name
      ),
      {
        sharepointColumnInfo: item,
        uploadColumnInfo,
      },
    ];
    setColumnMappings(columnMappingsData);
    dispatch(setUploadColumnMapping(columnMappingsData));
  };

  useEffect(() => {
    const optionData = columnsInSharePoint.map((item) => {
      return { key: item.name, text: item.displayName, ...item };
    });
    setOptions(optionData);
  }, [columnsInSharePoint]);

  return (
    <div style={examplesStyles}>
      <Dropdown
        placeholder={t("add_task_select_option_placeholder")}
        label=""
        options={options}
        styles={dropdownStyles}
        onChange={(
          event: React.FormEvent<HTMLDivElement>,
          item?: IDropdownOption | SharePointColumnInfo | any
        ) => {
          columnSelected(item);
        }}
      />
      <p style={{ fontWeight: "bold" }}>{uploadColumnInfo.name}</p>
      {uploadColumnInfo.examples.map((example, index) => {
        return <p key={index}>{example}</p>;
      })}
    </div>
  );
};
