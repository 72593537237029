import {
  Dropdown,
  Icon,
  IDropdownOption,
  IIconStyles,
  Label,
  List,
  Modal,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useGetSurveyDetailQuery } from "../../api/survey";
import { contentStyles } from "../mission/tasks/CommonTaskStyles";
import { labelIconContainer, modalHeader, SpinnerStyles } from "../../helpers/commonStyles";
import { variablesReplaced } from "../../helpers/survey-helpers";
import { Choice } from "../../types/survey";

interface SurveyQuestionsModalState {
  modalState: boolean;
  surveyId: string;
  hideModal: () => void;
}

export const SurveyQuestionsModal: FunctionComponent<SurveyQuestionsModalState> = (
  props: SurveyQuestionsModalState
) => {
  const { t } = useTranslation();
  const surveyData = useGetSurveyDetailQuery(props.surveyId !== "" ? props.surveyId : skipToken);
  const iconStyle: Partial<IIconStyles> = {
    root: {
      fontSize: 16,
      marginRight: "2%",
      color: "#000000",
      cursor: "pointer",
    },
  };
  const modalStyle: React.CSSProperties = {
    padding: "20px",
  };
  return (
    <Modal
      isOpen={props.modalState}
      onDismiss={props.hideModal}
      containerClassName={contentStyles.container}
    >
      <div style={modalStyle}>
        <div style={labelIconContainer}>
          <Label styles={modalHeader}>{surveyData.data?.title}</Label>
          <Icon styles={iconStyle} iconName="cancel" onClick={props.hideModal} />
        </div>
        <Separator></Separator>
        <div style={{ padding: 10 }}>
          {surveyData.isLoading ? (
            <Spinner size={SpinnerSize.large} styles={SpinnerStyles} />
          ) : (
            <Stack>
              {surveyData.data?.fields.map((field, index) => (
                <Stack.Item key={field.id} style={{ marginTop: "5px" }}>
                  <Label>{field.title}</Label>
                  <List
                    items={field.properties.fields}
                    onRenderCell={(item, count) => {
                      return (
                        <>
                          <span style={{ color: "grey" }}>
                            Q {index + 1}-{Number(count) + 1}:{" "}
                          </span>
                          <span> {variablesReplaced(item?.title, surveyData.data?.variables)}</span>
                        </>
                      );
                    }}
                  />

                  {field.properties.choices ? (
                    <Dropdown
                      placeholder={t("choice")}
                      options={field.properties.choices.map((c: Choice): IDropdownOption => {
                        return { key: c.id, text: c.label };
                      })}
                    />
                  ) : null}
                </Stack.Item>
              ))}
            </Stack>
          )}
        </div>
      </div>
    </Modal>
  );
};
