import React, { memo, useCallback } from "react";
import { TopContainer } from "../../components/common/TopContainer";
import { useTranslation } from "react-i18next";
import { CockpitHeader } from "../../components/cockpit/CockpitHeader";
import {
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  IStackTokens,
  ResponsiveMode,
  Stack,
} from "@fluentui/react";
import { useState } from "react";
import {
  useGetDataAssetsQuery,
  useLoadCockpitTemplateMutation,
  useUpdateDataAssetsWithCustomOrderMutation,
} from "../../api/cockpit";
import { CockpitTile } from "../../components/cockpit/CockpitTile";
import { searchData, sortData } from "../../helpers/cockpit-helper";
import { useAppSelector } from "../../store/hooks";
import { selectSortOption } from "../../store/slices/cockpitSlice";
import { DataAsset, UpdatedDataAssetList } from "../../types/dataasset";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../../types/item-types";
import { useGetInstallationsQuery } from "../../api/installation";
import { CockpitTemplate } from "../../types/cockpit";
const stackToken: IStackTokens = { childrenGap: 25 };
const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { flexGrow: 1 },
};

export const Cockpit: React.FunctionComponent = memo(() => {
  const { t } = useTranslation();
  const { data, isLoading, isSuccess } = useGetDataAssetsQuery({ all: true });
  const dataAssets: DataAsset[] = data || [];
  const domains: DataAsset[] = dataAssets.filter((d) => d.type === "Domain");
  const [searchTerm, setSearchTerm] = useState("");
  const selectedSortOption = useAppSelector(selectSortOption);
  const [updateDataAssetsWithCustomOrder] = useUpdateDataAssetsWithCustomOrderMutation();
  let sortedAndFilteredDomains = [...domains];
  const [listItems, setListItems] = useState<DataAsset[]>([]);
  const installationData = useGetInstallationsQuery();
  const [loadCockpitTemplates] = useLoadCockpitTemplateMutation();
  const [loading, setLoading] = useState<boolean>(false);
  let cockpitTemplates: CockpitTemplate[] = [];
  let templateOptions: IDropdownOption[] = [];
  if (!installationData.isLoading && installationData.isSuccess) {
    cockpitTemplates = installationData.data.cockpit_templates;
    cockpitTemplates.forEach((template) => {
      templateOptions.push({ key: template.name, text: template.name });
    });
  }

  if (listItems.length > 0) {
    sortedAndFilteredDomains = [...listItems];
  }

  if (selectedSortOption !== "custom") {
    sortedAndFilteredDomains = sortData(sortedAndFilteredDomains, selectedSortOption);
  }

  if (searchTerm) {
    sortedAndFilteredDomains = searchData(sortedAndFilteredDomains, searchTerm, "title");
  }

  const updateDataAssets = async (data: UpdatedDataAssetList[]) => {
    await updateDataAssetsWithCustomOrder([
      {
        op: "modify",
        props: { update_custom_order: true },
        assets_custom_order: data,
      },
    ]).unwrap();
  };

  const dropCard = () => {
    const data: UpdatedDataAssetList[] = sortedAndFilteredDomains.map((item, index) => {
      return { id: item.id, custom_order: index + 1 };
    });
    updateDataAssets(data);
  };

  const findCard = useCallback(
    (id: string) => {
      const card = sortedAndFilteredDomains.find((item: DataAsset) => item.id === id) as DataAsset;
      return {
        card,
        index: sortedAndFilteredDomains.indexOf(card),
      };
    },
    [sortedAndFilteredDomains]
  );

  const moveCard = useCallback(
    (id: string, atIndex: number) => {
      const { card, index } = findCard(id);
      const copyListItems = [...sortedAndFilteredDomains];
      copyListItems.splice(index ? index : 0, 1);
      copyListItems.splice(atIndex ? atIndex : 0, 0, card);
      setListItems(copyListItems);
    },
    [findCard, sortedAndFilteredDomains]
  );

  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));

  const onTemplateSelected = async (selectedTemplateOption: IDropdownOption) => {
    setLoading(true);
    const selectedTemplate = cockpitTemplates.find((template: CockpitTemplate) => {
      return template.name === selectedTemplateOption.text;
    });
    if (selectedTemplate) {
      await loadCockpitTemplates(selectedTemplate);
    }
    setLoading(false);
  };

  return (
    <TopContainer title="Cockpit">
      <Stack>
        <CockpitHeader
          screen="cockpit"
          searchBoxText={t("search_cockpit")}
          buttonLabel="Add"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </Stack>
      {!isLoading && isSuccess && sortedAndFilteredDomains.length > 0 ? (
        <div ref={drop}>
          <Stack horizontal wrap tokens={stackToken}>
            {sortedAndFilteredDomains.length !== 0 &&
              sortedAndFilteredDomains.map((data, index) => (
                <CockpitTile
                  title={data.title}
                  useCaseCount={data.linked_use_cases.length}
                  familyProducts={data.linked_assets}
                  problems={data.problems}
                  value={data.value}
                  owner={data.owner || ""}
                  id={data.id}
                  moveCard={moveCard}
                  findCard={findCard}
                  dropCard={dropCard}
                  key={data.id}
                />
              ))}
          </Stack>
        </div>
      ) : isLoading ? null : (
        <Stack wrap tokens={stackToken}>
          <p className="centerTemplateText">
            {t("load_template_desc_line_1")}
            <br /> {t("load_template_desc_line_2")}
          </p>
          <Dropdown
            placeholder={loading ? t("loading") : t("load_template")}
            label=""
            className="centerTemplateDropdown"
            options={templateOptions}
            styles={dropdownStyles}
            responsiveMode={ResponsiveMode.large}
            onChange={(_, template) => {
              template && onTemplateSelected(template);
            }}
          />
        </Stack>
      )}
    </TopContainer>
  );
});
