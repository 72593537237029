import { IStackStyles } from "@fluentui/react";

export const SettingsLabelStyles = {
  root: {
    display: "flex",
    fontSize: 16,
    alignItems: "center",
    gap: 12,
  },
};

export const SidebarContainerStyles: IStackStyles = {
  root: {
    height: "100%",
    width: "350px",
    minWidth: "350px",
    padding: "16px",
    cursor: "pointer",
    border: "1px solid lightgrey",
    background: "white",
    zIndex: 2,
  },
};
