import { IImageProps, ImageFit, Stack, Text } from "@fluentui/react";
import { t } from "i18next";
import { ReactComponent as ColabLogo } from "../../assets/colab-logo.svg";

export const welcomeImageProps: IImageProps = {
  imageFit: ImageFit.center,
  width: 82,
  height: 82,
  styles: {
    root: {
      marginTop: 25,
    },
  },
};

export const Welcome = (): JSX.Element => {
  return (
    <Stack verticalAlign="center" horizontalAlign="center" style={{ margin: 20, marginTop: 200 }}>
      <ColabLogo />
      <h1>{t("welcome")}</h1>
      <Text style={{ textAlign: "center" }}>{t("colab_welcome_text")}</Text>
    </Stack>
  );
};
