import { PrimaryButton, Stack } from "@fluentui/react";

import { FunctionComponent } from "react";
import { Node } from "../../types/auth";
import React from "react";
import Tracking from "../../tracking/tracking";
import { useTranslation } from "react-i18next";

interface SocialLoginState {
  nodes: Node[];
  signUp?: boolean;
  enabled?: boolean;
}

export const socialLoginOIDCrg = /microsoft|google/i;

export const SocialLogins: FunctionComponent<SocialLoginState> = ({ nodes, signUp, enabled }) => {
  const { t } = useTranslation();
  const socialLogins = nodes.filter(
    (n) =>
      n.group === "oidc" &&
      n.attributes &&
      n.attributes.value &&
      n.attributes.value.match(socialLoginOIDCrg) // we can add more as we go along, google just as example added
  );

  return (
    <>
      <Stack.Item style={{ marginTop: "10px" }}>
        {socialLogins.map((oidc) => (
          <PrimaryButton
            autoFocus
            key={oidc.attributes.name}
            text={t(`${!signUp ? "sign_in" : "sign_up"}_${oidc.attributes.value}`)}
            iconProps={{
              iconName: oidc.attributes.value === "microsoft" ? "WindowsLogo" : "PermissionsSolid",
            }}
            style={{ width: "100%" }}
            type="submit"
            onClick={() => {
              const isSignInWithMicrosoft = oidc.attributes.value === "microsoft";
              if (isSignInWithMicrosoft) Tracking.trackLoginSignupEvent("Sign In Via Microsoft");
            }}
            value={oidc.attributes.value}
            name={oidc.attributes.name}
            disabled={!enabled}
          />
        ))}
      </Stack.Item>
    </>
  );
};
