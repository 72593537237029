import { FunctionComponent, MouseEvent, useEffect } from "react";
import { Checkbox, DefaultButton, PrimaryButton, Stack } from "@fluentui/react";
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { copyAndSort, filterColumns } from "../../../helpers/utils";

import { IssueForTable } from "../../../types/issue";
import { SelectionMap } from "../../../types/issue";
import { deepLink } from "../../../helpers/issue-helper";
import { useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { useTranslation } from "react-i18next";

export interface IssuesListProps {
  selection: SelectionMap;
  setSelection(selectionData: SelectionMap): void;
  allIssues: IssueForTable[];
  datasetUrn: string;
  missionId: string;
  loading: boolean;
  onSave: () => void;
}

export const IssuesList: FunctionComponent<IssuesListProps> = (props: IssuesListProps) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [allIssues, setAllIssuesList, allIssuesRef] = useState<IssueForTable[]>(props.allIssues);
  const [datasetUrn] = useState<string>(props.datasetUrn);

  useEffect(() => {
    setAllIssuesList(props.allIssues);
  }, [props.allIssues]);

  useEffect(() => {
    setColumns(getTableData(props.selection));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selection]);

  const columnDataArray = [
    { name: "issue", fieldName: "quality_dimension" },
    { name: "issue_row", fieldName: "row_info" },
    { name: "issues_columns", fieldName: "column_info" },
    { name: "issues_value", fieldName: "cell_value" },
    { name: "issues_comment", fieldName: "comment" },
    { name: "issues_ignore", fieldName: "ignore" },
  ];

  const onColumnSortClick = (ev: MouseEvent<HTMLElement>, column: IColumn): void => {
    const { newColumns, currColumn } = filterColumns(columns, column);

    const newItems: IssueForTable[] = copyAndSort(
      allIssuesRef.current,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    setColumns(newColumns);
    setAllIssuesList(newItems);
  };

  const getTableData = (selection: SelectionMap): IColumn[] => {
    const tempColumns: IColumn[] = [];
    columnDataArray.forEach((columnData, index) => {
      tempColumns.push({
        key: `column${index}`,
        name: t(columnData.name),
        fieldName: columnData.fieldName,
        minWidth: index === 4 ? 150 : 130,
        maxWidth: index === 4 ? 170 : 150,
        isRowHeader: index in [0] ? true : false,
        onColumnClick: onColumnSortClick,
        onRender: (item: IssueForTable) => {
          switch (index) {
            case 0:
              return <>{t(item.quality_dimension)}</>;
            case 1:
              return (
                <>
                  <a
                    href={deepLink(datasetUrn, item.row_info.split(" ")[0])}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.row_info}
                  </a>
                </>
              );
            case 2:
              return <>{item.column_info}</>;
            case 3:
              return <>{item.cell_value}</>;
            case 4:
              return <>{item.comment}</>;
            case 5:
              return (
                <Checkbox
                  checked={selection[item.id] ? true : false}
                  onChange={(_, checked) =>
                    props.setSelection({
                      ...selection,
                      [item.id]: !!checked,
                    })
                  }
                />
              );
          }
        },
      });
    });
    return tempColumns;
  };

  const [columns, setColumns] = useState<IColumn[]>(getTableData(props.selection));

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <Stack>
        <DetailsList
          items={allIssues}
          columns={columns}
          selectionMode={SelectionMode.none}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
        />
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <DefaultButton
          text={t("back")}
          onClick={() => {
            navigate(`/missions/${props.missionId}`);
          }}
        />
        <PrimaryButton text={props.loading ? t("saving") : t("save")} onClick={props.onSave} />
      </Stack>
    </Stack>
  );
};
