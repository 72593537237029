import { Alert, AddAlertAPIRequest } from "../../types/alert";
import { dqcApi } from "../dqcQuery";

export const alertsApi = dqcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAlerts: builder.query<Alert[], any>({
      query: () => ({ url: `/apiV2/alerts/`, method: "get" }),
      providesTags: ["Alerts"],
    }),
    getAllAlertsForRuleset: builder.query<Alert[], string>({
      query: (rulesetId) => ({ url: `/apiV2/alerts/?ruleset_id=${rulesetId}`, method: "get" }),
    }),
    addAlert: builder.mutation<Alert, AddAlertAPIRequest>({
      query: (alert) => ({ url: `/apiV2/alerts/`, method: "post", data: alert }),
      invalidatesTags: ["Alerts"],
    }),
    getAlert: builder.query<Alert, string>({
      query: (alertId) => ({ url: `/apiV2/alerts/${alertId}/`, method: "get" }),
    }),
  }),
});

export const {
  useAddAlertMutation,
  useGetAllAlertsQuery,
  useGetAllAlertsForRulesetQuery,
  useGetAlertQuery,
} = alertsApi;
