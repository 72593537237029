import {
  NumericCondition,
  TextCondition,
  FormatCondition,
  OtherCondition,
} from "../../../types/customChecks";
import { DQCTableValue } from "../../../types/fileUploader";
import { QuickAnalysisIssueTypes } from "../../../types/quickAnalysis";
import { TestFunctionName } from "../../../types/rules";

export const PROBABILITY_SCALE = ["low", "low", "medium", "high", "high"];

export const EMPTY_STRING_VALUES: string[] = ["", " ", '" "', '""'];

export const NAN_VALUES: string[] = ["N/A", "n/a", "NA", "na", "TBD", "tbd", "TBC", "tbc"];

export const EMPTY_CHAR_VALUES = ["-", "."];

export const NULL_VALUES = ["Null", "NULL", "null"];

export const EMPTY_CELL_VALUES: string[] = [
  ...EMPTY_STRING_VALUES,
  ...NAN_VALUES,
  ...EMPTY_CHAR_VALUES,
  ...NULL_VALUES,
];

export const OVERALL_QUALITY = "Overall quality";

export const TESTFUNCTION_TO_RULE_NAME: Record<TestFunctionName, string> = {
  stdDevOutlier: "No outlier",
  iqrOutlier: "No outlier",
  strPatterns: "String patterns",
  duplicates: "Unique",
  sensitiveTest: "No sensitive data",
  missingValues: "Mandatory",
  inconsistentDataType: "Consistent",
  columnCorrelation: "ColumnDefinition correlation",
  columnMatching: "Matching columns",
  customRule: "Custom rule",
  categoricalData: "Categorical data",
};

export const QUALITY_DIMENSION_TOOLTIPS: Record<QuickAnalysisIssueTypes, string> = {
  missing: "missing_tooltip",
  duplicate: "duplicate_tooltip",
  inconsistent: "inconsistent_tooltip",
  outlier: "outlier_tooltip",
  sensitive: "sensitive_tooltip",
  custom: "custom_rule_tooltip",
};
export const ISSUE_TYPE_ICON_MAP: Map<string, string> = new Map([
  ["missing", "missingIcon"],
  ["inconsistent", "inconsistentIcon"],
  ["outlier", "outlierIcon"],
  ["duplicate", "duplicatesIcon"],
  ["sensitive", "sensitiveIcon"],
  ["custom", "customChecksIcon"],
]);

export const ISSUE_TYPE_ICON_DISABLED_MAP: Map<QuickAnalysisIssueTypes, string> = new Map([
  ["missing", "missingIconDisabled"],
  ["inconsistent", "inconsistentIconDisabled"],
  ["outlier", "outlierIconDisabled"],
  ["duplicate", "duplicatesIconDisabled"],
  ["sensitive", "sensitiveIconDisabled"],
  ["custom", "customChecksIconDisabled"],
]);

const emptyRow: Record<number, DQCTableValue> = {
  0: { value: "" },
  1: { value: "" },
  2: { value: "" },
};
export const EMPTY_ROW_DATA: Record<number, DQCTableValue>[] = [
  emptyRow,
  emptyRow,
  emptyRow,
  emptyRow,
];

export const EMPTY_COLUMN_DEFS = [
  {
    headerName: "",
    field: "0.value",
    value: "",
    issues: [],
    width: 216,
    suppressMovable: true,
    resizable: true,
    cellDataType: "text",
  },
  {
    headerName: "",
    field: "1.value",
    value: "",
    issues: [],
    width: 216,
    suppressMovable: true,
    resizable: true,
    cellDataType: "text",
  },
  {
    headerName: "",
    field: "2.value",
    value: "",
    issues: [],
    width: 216,
    suppressMovable: true,
    resizable: true,
    cellDataType: "text",
  },
];
export const allDimensions: QuickAnalysisIssueTypes[] = [
  "missing",
  "inconsistent",
  "outlier",
  "duplicate",
  "sensitive",
  "custom",
];

export const ISSUE_TYPE_HELP_CENTER_LINK: Map<QuickAnalysisIssueTypes, string> = new Map([
  [
    "missing",
    "https://dqchelp.zendesk.com/hc/en-150/articles/6295328734365-How-to-add-a-missing-value",
  ],
  [
    "inconsistent",
    "https://dqchelp.zendesk.com/hc/en-150/articles/6295329917725-How-to-improve-an-inconsistent-value",
  ],
  [
    "outlier",
    "https://dqchelp.zendesk.com/hc/en-150/articles/6295331452061-How-to-deal-with-outliers",
  ],
  [
    "duplicate",
    "https://dqchelp.zendesk.com/hc/en-150/articles/6295382572189-How-to-deal-with-duplicates",
  ],
  [
    "sensitive",
    "https://dqchelp.zendesk.com/hc/en-150/articles/6295387098653-How-to-deal-with-sensitive-data",
  ],
]);

export const ALL_NUMERIC_CONDITIONS: NumericCondition[] = [
  "greater than",
  "greater than or equal",
  "less than",
  "less than or equal",
  "equals",
  "does not equal",
];
export const ALL_TEXT_CONDITIONS: TextCondition[] = [
  "equals",
  "does not equal",
  "starts with",
  "does not start with",
  "ends with",
  "does not end with",
  "contains",
  "max characters",
  "min characters",
  "does not contain",
  "regular expression",
  "special characters",
];
export const ALL_FORMAT_CONDITIONS: FormatCondition[] = [
  "is text",
  "is decimal number",
  "is whole number",
  "is mandatory",
  "is date",
];
export const ALL_OTHER_CONDITIONS: OtherCondition[] = [
  "unique",
  "sequential numbers",
  "empty",
  "advanced check",
];

export const DEFAULT_LAMBDA = "function lambda() { return []; }";
