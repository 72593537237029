import { FunctionComponent } from "react";
import { Label, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { SpinnerStyles, topHeading } from "../../helpers/commonStyles";
import { useGetInstallationsQuery } from "../../api/installation";
import { useGetSurveysQuery } from "../../api/survey";

import { TopContainer } from "../../components/common/TopContainer";
import { useTranslation } from "react-i18next";
import { SurveyList } from "../../components/surveys/survey-list";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export const Surveys: FunctionComponent = () => {
  const { t } = useTranslation();
  const installationData = useGetInstallationsQuery();
  const surveyData = useGetSurveysQuery(installationData.data?.workspaceId ?? skipToken);

  return (
    <TopContainer title={t("surveys_title")}>
      <Label styles={topHeading}>{t("surveys_lable")}</Label>
      {surveyData.isLoading ? (
        <Spinner size={SpinnerSize.large} styles={SpinnerStyles} />
      ) : (
        <main>
          <div>
            <PrimaryButton text={t("add_survey")} disabled={true} />
            <p>{t("survey_response_time")}</p>
          </div>
          <div>
            {surveyData.data && surveyData.data.length > 0 ? (
              <SurveyList surveys={surveyData.data} />
            ) : null}
          </div>
        </main>
      )}
    </TopContainer>
  );
};
