import {
  IButtonStyles,
  IContextualMenuProps,
  IIconProps,
  ITextFieldStyles,
  Stack,
  TextField,
  IconButton,
  PrimaryButton,
  IIconStyles,
} from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateUseCaseModal } from "./CreateUseCaseModal";
import { DeleteDataAssetsModal } from "./DeleteDataAssetsModal";
import { DeleteUseCasesModal } from "./DeleteUseCasesModal";
import { CreateDataAssetModal } from "./CreateAssetModal";
import { SortBy } from "./SortBy";
import { ShowFields } from "./ShowFields";
import { DomainFilter } from "./DomainFilter";

const searchBoxStyles: Partial<ITextFieldStyles> = {
  root: { width: 200, borderRadius: "4px", marginRight: "4%" },
};
const addButtonStyle: IButtonStyles = {
  root: {
    whiteSpace: "noWrap",
  },
};
const addButtonIcon: IIconProps = {
  iconName: "Add",
  style: {
    paddingRight: 4,
  },
};
const iconContainer: IIconStyles = {
  root: {
    position: "absolute",
    top: 10,
    right: 0,
  },
};

export interface CockpitHeaderProps {
  screen: "cockpit" | "assets" | "useCase";
  searchBoxText: string;
  searchTerm: string;
  setSearchTerm: (s: string) => void;
  buttonLabel: string;
}
export const CockpitHeader: React.FunctionComponent<CockpitHeaderProps> = ({
  screen,
  searchBoxText,
  searchTerm,
  setSearchTerm,
  buttonLabel,
}) => {
  const { t } = useTranslation();
  const [openDataAssetModal, setOpenDataAssetModal] = useState(false);
  const [openUseCaseModal, setOpenUseCaseModal] = useState(false);

  const [showDeleteDataAssetsModal, setShowDeleteDataAssetsModal] = useState(false);
  const [showDeleteUseCasesModal, setShowDeleteUseCasesModal] = useState(false);
  const searchIcon: IIconProps = {
    iconName: "Search",
  };
  const menuToopltipProps: IContextualMenuProps = {
    items: [
      {
        key: "deleteUseCaseModal",
        text: t("delete_use_cases_link_text"),
        onClick: () => {
          setShowDeleteUseCasesModal(true);
        },
      },
      {
        key: "deleteDataAssetModal",
        text: t("delete_data_assets_link_text"),
        onClick: () => {
          setShowDeleteDataAssetsModal(true);
        },
      },
    ],
    directionalHintFixed: true,
  };

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: "use-case",
        text: t("use-case"),
        iconProps: { iconName: "FileComment" },
        onClick: () => {
          setOpenUseCaseModal(true);
        },
      },
      {
        key: "asset",
        text: t("asset"),
        iconProps: { iconName: "Database" },
        onClick: () => {
          setOpenDataAssetModal(true);
        },
      },
    ],
  };

  return (
    <>
      <CreateUseCaseModal open={openUseCaseModal} handleClose={() => setOpenUseCaseModal(false)} />
      <CreateDataAssetModal
        open={openDataAssetModal}
        handleClose={() => setOpenDataAssetModal(false)}
      />

      <Stack className={screen === "cockpit" ? "mainContainer" : "mainContainerUseCase"}>
        {/* Search Field and Buttons */}
        <Stack.Item className="searchContainer">
          <Stack horizontal>
            <TextField
              name="search"
              value={searchTerm}
              placeholder={searchBoxText}
              iconProps={searchIcon}
              styles={searchBoxStyles}
              onChange={(e: any) => setSearchTerm(e.target.value)}
            />
            {screen === "assets" || screen === "useCase" ? (
              <PrimaryButton
                text={buttonLabel}
                styles={addButtonStyle}
                onClick={() =>
                  screen === "assets" ? setOpenDataAssetModal(true) : setOpenUseCaseModal(true)
                }
              />
            ) : (
              <PrimaryButton
                iconProps={addButtonIcon}
                styles={addButtonStyle}
                menuProps={menuProps}
              />
            )}
          </Stack>
        </Stack.Item>

        {/* Cockit sort */}
        {screen === "cockpit" && (
          <Stack className="cockpitContainer">
            <Stack.Item>
              <SortBy />
            </Stack.Item>

            <Stack.Item className="showFieldContainer">
              <ShowFields />
            </Stack.Item>
          </Stack>
        )}

        {/* Domain sort */}
        {screen === "useCase" && (
          <Stack.Item className="cockpitContainer">
            <DomainFilter />
          </Stack.Item>
        )}

        {/* Asset sort */}
        {screen === "assets" && (
          <Stack.Item className="cockpitContainer">
            <SortBy />
          </Stack.Item>
        )}
        {/* Mpre icon */}
        <Stack.Item styles={iconContainer}>
          <IconButton
            menuProps={menuToopltipProps}
            iconProps={{ iconName: "More" }}
            title="More"
            ariaLabel="More"
          />
        </Stack.Item>
      </Stack>

      {showDeleteDataAssetsModal ? (
        <DeleteDataAssetsModal
          showModal={showDeleteDataAssetsModal}
          setShowModal={setShowDeleteDataAssetsModal}
        />
      ) : null}
      {showDeleteUseCasesModal ? (
        <DeleteUseCasesModal
          showModal={showDeleteUseCasesModal}
          setShowModal={setShowDeleteUseCasesModal}
        />
      ) : null}
    </>
  );
};
