import * as React from "react";
import { IContextualMenuProps, IIconProps } from "@fluentui/react";
import { CommandButton } from "@fluentui/react/lib/Button";
import i18n from "../../i18n";
export interface IButtonExampleProps {
  disabled?: boolean;
  checked?: boolean;
}

const menuProps: IContextualMenuProps = {
  items: [
    {
      key: "emailMessage",
      text: i18n.t("contact_support"),
      iconProps: { iconName: "Mail" },
      onClick: () => {
        alert("open zendesk");
      },
    },
    {
      key: "openHelpdesk",
      text: i18n.t("support_button_helpcenter"),
      iconProps: { iconName: "UnknownSolid" },
      onClick: () => {
        window.open("https://dqchelp.zendesk.com/access/unauthenticated?theme=hc", "_blank");
      },
    },
  ],
};

const questionIcon: IIconProps = { iconName: "questionMarkIcon" };

export const HelpBtn: React.FunctionComponent<IButtonExampleProps> = (props) => {
  const { disabled, checked } = props;

  return (
    <CommandButton
      iconProps={questionIcon}
      text=""
      menuProps={menuProps}
      disabled={disabled}
      checked={checked}
    />
  );
};
