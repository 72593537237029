import React, { useState } from "react";
import { useGetAllUseCasesQuery, useDeleteUseCasesMutation } from "../../api/cockpit";
import { DeleteUseCasesModalProps } from "../../types/usecase";
import {
  DefaultButton,
  Icon,
  IStackStyles,
  Label,
  Modal,
  PrimaryButton,
  Stack,
  Checkbox,
  Separator,
  IStackTokens,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IIconStyles, mergeStyleSets } from "@fluentui/react";

export const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "40%",
  },
});

export const iconStyle: Partial<IIconStyles> = {
  root: {
    fontSize: 20,
    cursor: "pointer",
  },
};

const modalMainStyle: React.CSSProperties = {
  padding: "30px 20px 30px 20px",
};

const buttonContainer: React.CSSProperties = {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "4%",
};

const buttonSubContainer: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
};

const checkboxListStackTokens: IStackTokens = {
  childrenGap: 10,
};

const headerContainer: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 3% 10px 3%",
  },
};
const fileIcon: IIconStyles = {
  root: {
    fontSize: 20,
    marginLeft: 5,
    color: "#808080",
  },
};

export const DeleteUseCasesModal: React.FunctionComponent<DeleteUseCasesModalProps> = ({
  showModal,
  setShowModal,
}) => {
  const { t } = useTranslation();
  const { data, isLoading, isSuccess } = useGetAllUseCasesQuery();
  const [deleteUseCases] = useDeleteUseCasesMutation();
  const [selectedCheckboxList, setSelectedCheckboxList] = useState<string[]>([]);

  const renderCheckboxLabel = (label: string) => {
    return (
      <>
        <Icon iconName="FileComment" styles={fileIcon} />
        <span style={{ marginLeft: "5px" }}>{label}</span>
      </>
    );
  };

  const onCheckboxChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) => {
    ev &&
      (isChecked
        ? setSelectedCheckboxList([...selectedCheckboxList, ev.currentTarget.id])
        : setSelectedCheckboxList(
            selectedCheckboxList.filter((item) => item === ev.currentTarget.id)
          ));
  };

  const renderUseCases = () => {
    if (data && data.length > 0) {
      return data.map((item) => (
        <Checkbox
          id={item.id}
          key={item.id}
          name={item.title}
          onRenderLabel={() => renderCheckboxLabel(item.title)}
          onChange={onCheckboxChange}
        ></Checkbox>
      ));
    }
    return <span>{t("delete_use_cases_no_data")}</span>;
  };

  const onDelete = async () => {
    try {
      await deleteUseCases([{ op: "delete", ids: selectedCheckboxList }]).unwrap();
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        onDismiss={() => setShowModal(false)}
        containerClassName={contentStyles.container}
      >
        <Stack horizontal styles={headerContainer}>
          <Label>{t("delete_use_cases_title")}</Label>
          <Icon iconName="ChromeClose" onClick={() => setShowModal(false)} styles={iconStyle} />
        </Stack>
        <div style={modalMainStyle}>
          <Stack tokens={checkboxListStackTokens}>
            {!isLoading && isSuccess ? renderUseCases() : null}
          </Stack>
          <Separator />
          <span>{t("delete_use_cases_note")}</span>
          <div style={buttonContainer}>
            <div style={buttonSubContainer}>
              <PrimaryButton
                text={t("delete")}
                onClick={onDelete}
                styles={{
                  root: { marginRight: "4%", whiteSpace: "noWrap" },
                }}
                disabled={selectedCheckboxList.length === 0}
              />
              <DefaultButton
                text={t("cancel")}
                onClick={() => setShowModal(false)}
                styles={{ root: { marginRight: "2%" } }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
