import { ComboBox, IComboBox, IComboBoxOption, ILabelStyles, Label, Stack } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { selectShowFields, setShowFields } from "../../store/slices/cockpitSlice";
import { useAppSelector } from "../../store/hooks";
import { ShowFieldsOptions } from "../../types/cockpit";

const showFieldLabel: ILabelStyles = {
  root: {
    whiteSpace: "nowrap",
  },
};

export const ShowFields: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showFields = useAppSelector(selectShowFields);
  const showFieldsOptions: IComboBoxOption[] = [
    { key: "owner", text: t("owner"), styles: { root: { width: 200 } } },
    { key: "families-products", text: t("families-products"), styles: { root: { width: 200 } } },
    { key: "use-cases", text: t("use-cases"), styles: { root: { width: 200 } } },
    { key: "value", text: t("value"), styles: { root: { width: 200 } } },
    { key: "problems", text: t("problems"), styles: { root: { width: 200 } } },
  ];
  const handleChangeContent = (_: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => {
    const newShowFields = option?.selected
      ? [...showFields, option?.key.toString() as ShowFieldsOptions]
      : showFields.filter((key) => key !== option?.key);
    dispatch(setShowFields(newShowFields));
  };

  return (
    <Stack horizontal tokens={{ childrenGap: 10 }}>
      <Label styles={showFieldLabel} className="dropdownLabel">
        {t("show_fields")}:
      </Label>
      <ComboBox
        text={t("content")}
        multiSelect
        options={showFieldsOptions}
        selectedKey={showFields}
        onChange={handleChangeContent}
        className="showFieldDropdown"
      />
    </Stack>
  );
};
