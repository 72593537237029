import { IToggleStyles } from "@fluentui/react";
import { dqcPalette } from "../../../utils/colors";

export const getPillStyles = (severity: "warning" | "info"): Partial<IToggleStyles> => {
  return {
    pill: {
      backgroundColor:
        severity === "warning" ? dqcPalette.warningIssueFiltered : dqcPalette.issueFiltered,
      selectors: {
        ":hover": {
          backgroundColor:
            severity === "warning" ? dqcPalette.warningIssueFiltered : dqcPalette.issueFiltered,
        },
      },
    },
    root: { margin: 0 },
  };
};
