import { Stack, Checkbox } from "@fluentui/react";
import { t } from "i18next";
import { RuleCandidate, SensitiveTest } from "../../../../types/rules";
import { useState } from "react";
import { NameAndDescriptionInput } from "./../NameDescription";
import { RuleDetailModalFooter } from "./../RuleDetailModalFooter";
import { sensitiveCheckBoxStyles } from "./bodyStyles";
import { PiiCategory } from "../../../../types/quickAnalysis";
import { ALL_PII_CATEGORIES } from "../../../../helpers/predictRules/predictors/predictSensitiveRules";

const getisChecked = (qualityTest: SensitiveTest, category: PiiCategory): boolean =>
  qualityTest.meta.piiCategoriesToCheck.includes(category);

export const SensitiveRuleDetailBody = ({
  rule,
  toggleModal,
}: {
  rule: RuleCandidate;
  toggleModal: () => void;
}) => {
  const [localRule, setLocalRule] = useState<RuleCandidate>(rule);
  if (localRule.qualityTest.testFunctionName !== "sensitiveTest") return null;
  const localQualityTest = localRule.qualityTest;

  return (
    <>
      <Stack tokens={{ childrenGap: 12 }}>
        <NameAndDescriptionInput rule={localRule} updateRule={setLocalRule} />
        <Stack tokens={{ childrenGap: 8 }}>
          {ALL_PII_CATEGORIES.map((category) => (
            <Checkbox
              styles={sensitiveCheckBoxStyles}
              label={t(category)}
              key={`${rule.name}::${category}::checkbox`}
              checked={getisChecked(localQualityTest, category)}
              onChange={(_, isChecked) => {
                const newRule = updatePiiCategories(
                  localRule,
                  localQualityTest,
                  !!isChecked,
                  category
                );
                setLocalRule(newRule);
              }}
            />
          ))}
        </Stack>
      </Stack>
      <RuleDetailModalFooter rule={localRule} updateRule={setLocalRule} toggleModal={toggleModal} />
    </>
  );
};

const updatePiiCategories = (
  localRule: RuleCandidate,
  localQualityTest: SensitiveTest,
  isChecked: boolean,
  category: PiiCategory
): RuleCandidate => {
  let updatedCategories = localQualityTest.meta.piiCategoriesToCheck;
  if (!isChecked) {
    updatedCategories = localQualityTest.meta.piiCategoriesToCheck.filter(
      (value) => value !== category
    );
  } else {
    updatedCategories.push(category);
  }
  const newRule = {
    ...localRule,
    qualityTest: {
      ...localQualityTest,
      meta: {
        piiCategoriesToCheck: updatedCategories,
      },
    },
  };
  return newRule;
};
