import { FunctionComponent } from "react";
import { Link } from "@fluentui/react";
import Tracking from "../../../tracking/tracking";
import linkIcon from "../../../assets/welcome-screen/welcome-link-icon.svg";
import playIcon from "../../../assets/welcome-screen/welcome-play-icon.svg";
import { t } from "i18next";

export const LearnMore: FunctionComponent = () => {
  const spanStyle = { lineHeight: "1.5em", verticalAlign: "middle", marginLeft: 4 };
  return (
    <>
      <ul style={{ listStyle: "none", textAlign: "center", margin: "30 0 0 0", padding: 0 }}>
        <span style={{ ...spanStyle, color: "grey" }}>{t("learn-more")}</span>
        <li>
          <img src={playIcon} alt="play" style={{ verticalAlign: "middle" }} />
          <Link
            href="https://www.loom.com/share/d9f110eeb48c40fc898c097787516434"
            target="_blank"
            onClick={() => Tracking.trackLoginSignupEvent("Watch Short Video")}
          >
            <span style={spanStyle}>{t("watch-video")}</span>
          </Link>
        </li>
        <li>
          <img src={linkIcon} alt="link" style={{ verticalAlign: "middle" }} />
          <span style={spanStyle}>
            <Link href="https://dqc.ai" target="_blank">
              {t("dqc-website")}
            </Link>
          </span>
        </li>
      </ul>
    </>
  );
};
