import { MessageBar, MessageBarType, ProgressIndicator, Separator, Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  getFlowIdOrAcquire,
  useDoesEmailExistQuery,
  useFetchLoginDataQuery,
} from "../../api/profile";
import { loginStackStyle } from "../../helpers/commonStyles";
import { setFlowId } from "../../store/slices/app";
import { SignInData } from "../../types/auth";
import { EmailOrSSO } from "./email-or-sso";
import {
  BottomSectionSignInOrLogin,
  TopSectionSignInOrLogin,
} from "./office-welcome/topButtonSectionSignInOrLogin";
import { SocialLogins } from "./social-logins";

export const SignIn: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [showSpinner, setShowSpinner] = useState(false);
  const [displaySSO, { setTrue: showSSO, setFalse: showPW }] = useBoolean(true);
  const [emailToCheck, setEmailToCheck] = useState<string | undefined>(undefined);
  const emailExists = useDoesEmailExistQuery(emailToCheck ?? skipToken);

  const flowId = searchParams.get("flow");
  const signInData: SignInData | undefined = useFetchLoginDataQuery(flowId ?? skipToken).data;

  // in case the existing flowId is not valid we have to acquire a new one
  const newFlowId = getFlowIdOrAcquire();
  setFlowId(newFlowId!);

  useEffect(() => {
    signInData?.return_to && localStorage.setItem("returnTo", signInData?.return_to);
  }, [signInData]);

  useEffect(() => {
    if (emailExists.isSuccess && Boolean(emailExists.data) !== true && emailToCheck) {
      localStorage.setItem("email", emailToCheck);
      navigate("/signup");
    } else if (emailExists.isSuccess) {
      showPW();
    }
  }, [emailExists]);

  const ui = signInData?.ui;
  if (!ui) return null;
  const [csrf] = ui.nodes.filter((n) => n.attributes.name === "csrf_token");

  return (
    <Stack horizontalAlign="center" styles={loginStackStyle}>
      {showSpinner && (
        <ProgressIndicator label={t("loading")} styles={{ root: { width: "80%" } }} />
      )}
      {signInData?.ui?.messages?.length ? (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {signInData?.ui.messages
            .map((m) => (i18n.exists(String(m.id)) ? t(String(m.id)) : m.text))
            .join(";")}
        </MessageBar>
      ) : (
        <></>
      )}
      <TopSectionSignInOrLogin userExists={emailExists.data} emailString={emailToCheck} />
      <form
        style={{ width: "100%" }}
        action={signInData?.ui.action}
        method={signInData?.ui.method}
        onSubmit={() => {
          setShowSpinner(true);
        }}
      >
        <input
          title={csrf.attributes.name}
          type={"hidden"}
          name={csrf.attributes.name}
          value={csrf.attributes.value}
        ></input>
        {displaySSO ? (
          <>
            <SocialLogins nodes={ui.nodes} enabled={true} />
            <Separator styles={{ root: { marginTop: 20, marginBottom: 5 } }}>{t("or")}</Separator>
          </>
        ) : (
          <></>
        )}
        <EmailOrSSO
          nodes={ui.nodes}
          signUp={location.pathname === "/signup"}
          updateEmailChecked={(bool: boolean, emailStr) => {
            if (bool) showSSO();
            else setEmailToCheck(emailStr);
          }}
          enabled={true}
        />
      </form>
      <BottomSectionSignInOrLogin userExists={emailExists.data} emailString={emailToCheck} />
    </Stack>
  );
};
