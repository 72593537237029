import { UploadDataContainer } from "../../../types/fileUploader";
import { QuickAnalysisIssue } from "../../../types/quickAnalysis";
import { RuleCandidate } from "../../../types/rules";
import { addIssueId } from "../../quickAnalysis/qualityChecks/qualityUtils";

export const columnMatching = (
  dataContainer: UploadDataContainer,
  rule: RuleCandidate
): QuickAnalysisIssue[] => {
  const issues: QuickAnalysisIssue[] = [];
  if (rule.qualityTest.testFunctionName !== "columnMatching") return issues;
  const [column1, column2] = rule.columns;
  if (!column1 || !column2) {
    console.error("Wrong number of columns provided to columnMatching function");
    return issues;
  }
  dataContainer.data.forEach((row, rowIndex) => {
    const valuesMatch = row[column1.index].value === row[column2.index].value;
    if (valuesMatch) return;
    for (let column of [column1.index, column2.index])
      issues.push({
        row: rowIndex,
        column,
        comment: "column_matching_comment",
        severity: rule.severity,
        type: "inconsistent",
        id: "",
        rule_id: rule.id,
      });
  });
  return addIssueId(issues);
};
