import { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import { dqcRESTApi } from "../../api/dqcREST";
import { hostStyles, innerStackTokens } from "../../helpers/commonStyles";
import { Colleague } from "../../types/colleague";
import { Group } from "../../types/group";
import { useTranslation } from "react-i18next";
import { ConfirmationDialogProps } from "../../types/missions";
import { useBoolean } from "@fluentui/react-hooks";
import {
  DirectionalHint,
  Icon,
  IIconStyles,
  ILabelStyles,
  IStackItemStyles,
  IStackStyles,
  ITextFieldStyles,
  Label,
  Stack,
  TextField,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import {
  useAddNewColleagueMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
} from "../../api/companyAndGroups";
import { useGetIdentityConnectionsQuery } from "../../api/installation";

const headingContainer: IStackStyles = {
  root: {
    display: "flex",
    marginTop: "20px",
  },
};

const headerDiv: CSSProperties = {
  display: "flex",
  flexDirection: "column",
};

const headerLine: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const groupHeading: ILabelStyles = {
  root: {
    fontWeight: 700,
    fontSize: "18px",
    marginRight: "2%",
  },
};

const IconStyle: Partial<IIconStyles> = {
  root: {
    fontSize: 16,
    marginRight: "2%",
    color: "#000000",
    cursor: "pointer",
  },
};

const Container: IStackItemStyles = {
  root: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "center",
  },
};

const groupTextField: Partial<ITextFieldStyles> = {
  root: {
    width: "45%",
    margin: "1% 2% 2% 0%",
  },
};

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: {
    width: "45%",
  },
};

const colleaguesStyle: IStackStyles = {
  root: {
    cursor: "pointer",
    padding: "5px",
    textAlign: "left",
  },
};

const validationStyle: IStackStyles = {
  root: {
    marginBottom: "-10px",
    textAlign: "end",
  },
};

export interface GroupHeaderProps {
  group: Group;
  setConfirmationDialogProps(confirmationDialogProps: ConfirmationDialogProps): void;
}

export const GroupHeader: FunctionComponent<GroupHeaderProps> = ({
  group,
  setConfirmationDialogProps,
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [groupName, setGroupName] = useState(group.name);
  const [groupDescription, setGroupDescription] = useState(group.description);
  const [colleagues, setColleagues] = useState<Colleague[]>([]);
  const [colleagueName, setColleagueName] = useState<string>("");
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [identityFlag, setIdentityFlag] = useState<boolean>(false);
  const getIdentityConnections = useGetIdentityConnectionsQuery("");
  const [hideDeleteGroupDialog, { toggle: toggleHideDeleteGroupDialog }] = useBoolean(true);

  const [addNewColleague] = useAddNewColleagueMutation();
  const [updateGroup] = useUpdateGroupMutation();
  const [deleteGroup] = useDeleteGroupMutation();

  useEffect(() => {
    if (!getIdentityConnections.isLoading && getIdentityConnections.isSuccess) {
      if (getIdentityConnections.data.length === 0) {
        setValidationMessage(t("identity_connector_validation"));
        setIdentityFlag(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIdentityConnections]);

  useEffect(() => {
    setConfirmationDialogProps({
      showHideDialog: hideDeleteGroupDialog,
      toggleShowHideDialog: toggleHideDeleteGroupDialog,
      primaryButtonText: t("delete"),
      secondaryButtonText: t("cancel"),
      primaryButtonClicked: () => deleteGroupFunc(group.id),
      dialogTitle: t("group_delete_title"),
      confirmationText: t("group_delete_confirmation") + ` ${group.name}?`,
    });
    setGroupName(group.name);
    setGroupDescription(group.description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideDeleteGroupDialog, group]);

  const updateGroupFunc = async () => {
    if (!isEditing || !group.id) return;
    await updateGroup({
      id: group.id,
      name: groupName,
      description: groupDescription,
    });
    setIsEditing(!isEditing);
  };

  const deleteGroupFunc = async (id: string) => {
    await deleteGroup(id);
    toggleHideDeleteGroupDialog();
  };

  async function getColleaguesByKeyword(keyword: string) {
    setColleagueName(keyword);
    if (keyword) {
      const resp = await dqcRESTApi.get("/colleagues", {
        params: { q: encodeURIComponent(keyword) },
      });
      setColleagues(resp.data);
    } else {
      setColleagues([]);
    }
  }

  const userSelected = async (colleague: Colleague) => {
    await addNewColleague({ groupId: group.id, newColleague: colleague });
  };

  return (
    <>
      <Stack styles={validationStyle}>
        <span style={{ color: "red" }}>{validationMessage}</span>
      </Stack>
      <Stack horizontal tokens={innerStackTokens} styles={headingContainer}>
        <Stack.Item grow={1}>
          <div style={headerDiv}>
            <div style={headerLine}>
              {!isEditing ? (
                <Label styles={groupHeading}>{groupName}</Label>
              ) : (
                <>
                  <TextField
                    value={groupName}
                    onChange={(e: any) => setGroupName(e.target.value)}
                    styles={groupTextField}
                  />
                </>
              )}
              <TooltipHost
                content={t("edit")}
                delay={TooltipDelay.zero}
                directionalHint={DirectionalHint.bottomCenter}
                styles={hostStyles}
              >
                <Icon
                  styles={IconStyle}
                  iconName="Edit"
                  aria-label="editIcon"
                  onClick={() => (!isEditing ? setIsEditing(true) : updateGroupFunc())}
                />
              </TooltipHost>
              <TooltipHost
                content={t("edit")}
                delay={TooltipDelay.zero}
                directionalHint={DirectionalHint.bottomCenter}
                styles={hostStyles}
              >
                <Icon styles={IconStyle} iconName="Delete" onClick={toggleHideDeleteGroupDialog} />
              </TooltipHost>
            </div>
            {!isEditing ? (
              <Label>{groupDescription}</Label>
            ) : (
              <TextField
                value={groupDescription}
                onChange={(e: any) => setGroupDescription(e.target.value)}
                styles={textFieldStyles}
              />
            )}
          </div>
        </Stack.Item>
        <Stack.Item grow={1} styles={Container}>
          <span>
            <Label>{t("add_user")}:</Label>
          </span>
          <span style={{ marginLeft: "10px" }}>
            <Stack>
              <TextField
                value={colleagueName}
                onChange={(e: any) => getColleaguesByKeyword(e.target.value)}
                placeholder={t("group_search_users")}
                disabled={identityFlag}
                ariaLabel="colleagueName"
              />
            </Stack>
            {colleagues.map((colleague, index) => (
              <Stack
                key={index}
                styles={colleaguesStyle}
                className="responsible"
                onClick={() => {
                  if (!group.members.some((data) => data.identityId === colleague.identityId)) {
                    userSelected(colleague);
                    setColleagueName("");
                  }
                  setColleagues([]);
                }}
              >
                {colleague.displayName}
              </Stack>
            ))}
          </span>
        </Stack.Item>
      </Stack>
    </>
  );
};
