import { DirectionalHint, Icon, Stack, TooltipHost } from "@fluentui/react";
import {
  qualityIconStyle,
  getQualityLabelContainerStyles,
  qualityBadgeTextStyle,
} from "./QualityBadge.styles";
import { QualityScore } from "../../../types/quickAnalysis";
import { t } from "i18next";

export interface QualityBadgeProps {
  qualityScore: QualityScore;
}

export const QualityScoreColors = {
  A: "#107C10",
  B: "#8BC43D",
  C: "#FFB500",
  D: "#FF6815",
  E: "#FB281A",
};

export const QualityBadge = ({ qualityScore }: QualityBadgeProps) => {
  return (
    <TooltipHost content={"quality_badge_tooltip"} directionalHint={DirectionalHint.topCenter}>
      <Stack {...getQualityLabelContainerStyles(QualityScoreColors[qualityScore])}>
        <Icon iconName="qualityIcon" styles={qualityIconStyle} />
        <Stack horizontal styles={qualityBadgeTextStyle} tokens={{ childrenGap: 4 }}>
          <span>{t("quality")}:</span>
          <span>{qualityScore}</span>
        </Stack>
      </Stack>
    </TooltipHost>
  );
};
