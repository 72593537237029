import { IStackProps } from "@fluentui/react";
import { dqcPalette } from "../../../utils/colors";

export const getInfoIconContainerProps = (disabled: boolean): IStackProps => ({
  horizontal: true,
  verticalAlign: "center",
  horizontalAlign: "stretch",
  tokens: {
    childrenGap: 5,
  },
  styles: {
    root: {
      color: disabled ? dqcPalette.neutralTertiary : dqcPalette.infoIcon,
      fontSize: 14,
    },
  },
});

export const infoIssuesStackProps: IStackProps = {
  horizontal: true,
  verticalAlign: "center",
  horizontalAlign: "center",
  tokens: { childrenGap: 3 },
  styles: {
    root: {
      minWidth: 50,
    },
  },
};
