import axios from "axios";

export const api_base = "/api";
export const dqcRESTApi = axios.create({
  withCredentials: true,
  baseURL: api_base,
});

// Add a response interceptor
dqcRESTApi.interceptors.response.use(
  function (response: any) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response.status === 401) {
      window.location.replace("/signin");
    } else {
      return Promise.reject(error);
    }
  }
);
