// TODO: Format creditCardRegex to be  the IBAN Regex to make it atleast semi readable.
export const creditCardNumber =
  /^(3[47][0-9]{13}|(6541|6556)[0-9]{12}|389[0-9]{11}|3(?:0[0-5]|[68][0-9])[0-9]{11}|65[4-9][0-9]{13}|64[4-9][0-9]{13}|(6011|64[4-9]|65)[0-9]{12,15}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})|63[7-9][0-9]{13}|(?:2131|1800|35\d{3})\d{11}|9[0-9]{15}|(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}|(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))|(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}|(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}|(62[0-9]{14,17})|4[0-9]{12}(?:[0-9]{3})?|(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})|36[0-9]{12,17}|(40117[8-9]|431274|438935|451416|457393|45763[1-2]|504175|627780|636297|636368|65500[0-1]|65165[2-4]|65048[5-8]|506699|5067[0-6]\d|50677[0-8]|509\d{3})\d{10}|(606282\d{10}(\d{3})?)|(3841(0|4|6)0\d{13}))$/m;
export const phoneNumber =
  /( |^)((\+|\b0|\b00)(?:[1-9]{1,4}[ /\-_]?)(((?:[0-9]){4,13})|([0-9]{3}[ /\-_]?){2,3})[0-9]\b)|(\+[0-9]{1,3}\.[0-9]{4,14}(?:x.+)?\b)/;
export const ipAddress =
  /(?:\b(?:25[0-5]|2[0-4]\d|1\d\d|0\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|0\d\d|[1-9]\d|\d)){3}\b)|(?:\b(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?\b)/i;
export const usSocialSecurityNumber = /^\d{3}[ -.]\d{2}[ -.]\d{4}\b/;
export const passportDE = /^[CFGHJK0-9][0-9]{3}[CHJKLMNPRTVWXYZ0-9]{5}[0-9]$/;
export const emailAddress = new RegExp(
  /(?!.*[!#$%&'*+-/=?^ `@{|\\]{2})[a-zA-Z0-9]([\w!#$%&'*+-/=?^`{|\\]){0,62}@[a-zA-Z0-9][a-zA-Z0-9.-]{0,252}\.[a-zA-Z][a-zA-Z0-9]{1,62}/i
);
export const IBAN = new RegExp(
  [
    "(AL[0-9]{2}([0-9]{4}){2}([a-zA-Z0-9]{4}){4}|",
    "AT[0-9]{2}([0-9]{4}){4}|",
    "BH[0-9]{2}([a-zA-Z]{4}){1}([a-zA-Z0-9]{4}){3}([a-zA-Z0-9]{2})|",
    "BE[0-9]{2}([0-9]{4}){3}|",
    "BA[0-9]{2}([0-9]{4}){4}|",
    "BR[0-9]{2}([0-9]{4}){5}([0-9]{3})([a-zA-Z]{1})([a-zA-Z0-9]{1})|",
    "BG[0-9]{2}([a-zA-Z]{4}){1}([0-9]{4}){1}([0-9]{2})([a-zA-Z0-9]{2})([a-zA-Z0-9]{4}){1}([a-zA-Z0-9]{2})|",
    "CR[0-9]{2}([0-9]{4}){4}([0-9]{2})|",
    "HR[0-9]{2}([0-9]{4}){4}([0-9]{1})|",
    "CY[0-9]{2}([0-9]{4}){2}([a-zA-Z0-9]{4}){4}|",
    "CZ[0-9]{2}([0-9]{4}){5}|",
    "DK[0-9]{2}([0-9]{4}){3}([0-9]{2})|",
    "EE[0-9]{2}([0-9]{4}){4}|",
    "FO[0-9]{2}([0-9]{4}){3}([0-9]{2})|",
    "FI[0-9]{2}([0-9]{4}){3}([0-9]{2})|",
    "FR[0-9]{2}([0-9]{4}){2}([0-9]{2})([a-zA-Z0-9]{2})([a-zA-Z0-9]{4}){2}([a-zA-Z0-9]{1})([0-9]{2})|",
    "GE[0-9]{2}([a-zA-Z0-9]{2})([0-9]{2})([0-9]{4}){3}([0-9]{2})|",
    "DE[0-9]{2}([0-9]{4}){4}([0-9]{2})|",
    "GI[0-9]{2}([a-zA-Z]{4}){1}([a-zA-Z0-9]{4}){3}([a-zA-Z0-9]{3})|",
    "GR[0-9]{2}([0-9]{4}){1}([0-9]{3})([a-zA-Z0-9]{1})([a-zA-Z0-9]{4}){3}([a-zA-Z0-9]{3})|",
    "GL[0-9]{2}([0-9]{4}){3}([0-9]{2})|",
    "HU[0-9]{2}([0-9]{4}){6}|",
    "IS[0-9]{2}([0-9]{4}){5}([0-9]{2})|",
    "IE[0-9]{2}([a-zA-Z0-9]{4}){1}([0-9]{4}){3}([0-9]{2})|",
    "IL[0-9]{2}([0-9]{4}){4}([0-9]{3})|",
    "IT[0-9]{2}([a-zA-Z]{1})([0-9]{3})([0-9]{4}){1}([0-9]{3})([a-zA-Z0-9]{1})([a-zA-Z0-9]{4}){2}([a-zA-Z0-9]{3})|",
    "JO[0-9]{2}([a-zA-Z]{4}){1}([0-9]{4}){5}([0-9]{2})|",
    "XK[0-9]{2}([0-9]{4}){1}([0-9]{4}){2}([0-9]{2})([0-9]{2})|",
    "KW[0-9]{2}([a-zA-Z]{4}){1}([a-zA-Z0-9]{4}){5}([a-zA-Z0-9]{2})|",
    "LV[0-9]{2}([a-zA-Z]{4}){1}([a-zA-Z0-9]{4}){3}([a-zA-Z0-9]{1})|",
    "LI[0-9]{2}([0-9]{4}){1}([0-9]{1})([a-zA-Z0-9]{3})([a-zA-Z0-9]{4}){2}([a-zA-Z0-9]{1})|",
    "LT[0-9]{2}([0-9]{4}){4}|",
    "LU[0-9]{2}([0-9]{3})([a-zA-Z0-9]{1})([a-zA-Z0-9]{4}){3}|",
    "MK[0-9]{2}([0-9]{3})([a-zA-Z0-9]{1})([a-zA-Z0-9]{4}){2}([a-zA-Z0-9]{1})([0-9]{2})|",
    "MT[0-9]{2}([a-zA-Z]{4}){1}([0-9]{4}){1}([0-9]{1})([a-zA-Z0-9]{3})([a-zA-Z0-9]{4}){3}([a-zA-Z0-9]{3})|",
    "MC[0-9]{2}([0-9]{4}){2}([0-9]{2})([a-zA-Z0-9]{2})([a-zA-Z0-9]{4}){2}([a-zA-Z0-9]{1})([0-9]{2})|",
    "MD[0-9]{2}([a-zA-Z0-9]{2})([a-zA-Z0-9]{2})([a-zA-Z0-9]{4}){4}|",
    "ME[0-9]{2}([0-9]{4}){4}([0-9]{2})|",
    "NL[0-9]{2}([a-zA-Z]{4}){1}([0-9]{4}){2}([0-9]{2})|",
    "NO[0-9]{2}([0-9]{4}){2}([0-9]{3})|",
    "PK[0-9]{2}([a-zA-Z0-9]{4}){1}([0-9]{4}){4}|",
    "PS[0-9]{2}([a-zA-Z0-9]{4}){1}([0-9]{4}){5}([0-9]{1})|",
    "PL[0-9]{2}([0-9]{4}){6}|",
    "PT[0-9]{2}([0-9]{4}){5}([0-9]{1})|",
    "QA[0-9]{2}([a-zA-Z]{4}){1}([a-zA-Z0-9]{4}){5}([a-zA-Z0-9]{1})|",
    "RO[0-9]{2}([a-zA-Z]{4}){1}([a-zA-Z0-9]{4}){4}|",
    "SM[0-9]{2}([a-zA-Z]{1})([0-9]{3})([0-9]{4}){1}([0-9]{3})([a-zA-Z0-9]{1})([a-zA-Z0-9]{4}){2}([a-zA-Z0-9]{3})|",
    "SA[0-9]{2}([0-9]{2})([a-zA-Z0-9]{2})([a-zA-Z0-9]{4}){4}|",
    "RS[0-9]{2}([0-9]{4}){4}([0-9]{2})|",
    "SK[0-9]{2}([0-9]{4}){5}|",
    "SI[0-9]{2}([0-9]{4}){3}([0-9]{3})|",
    "ES[0-9]{2}([0-9]{4}){5}|",
    "SE[0-9]{2}([0-9]{4}){5}|",
    "CH[0-9]{2}([0-9]{4}){1}([0-9]{1})([a-zA-Z0-9]{3})([a-zA-Z0-9]{4}){2}([a-zA-Z0-9]{1})|",
    "TN[0-9]{2}([0-9]{4}){5}|",
    "TR[0-9]{2}([0-9]{4}){1}([0-9]{1})([a-zA-Z0-9]{3})([a-zA-Z0-9]{4}){3}([a-zA-Z0-9]{2})|",
    "AE[0-9]{2}([0-9]{3})([0-9]{1})([0-9]{4}){3}([0-9]{3})|",
    "GB[0-9]{2}([a-zA-Z]{4}){1}([0-9]{4}){3}([0-9]{2})|",
    "VA[0-9]{2}([0-9]{3})([0-9]{1})([0-9]{4}){3}([0-9]{2})|",
    "EG[0-9]{2}([0-9]{4}){2}([0-9]{17})|",
    "LB[0-9]{2}([0-9]{4})([a-zA-Z0-9]{20})|",
    "UA[0-9]{2}[0-9]{6}([a-zA-Z0-9]{19})|",
    "AD[0-9]{2}([0-9]{4}){2}([a-zA-Z0-9]{12})|",
    "BY[0-9]{2}([a-zA-Z]{4})([a-zA-Z0-9]{20})|",
    "DO[0-9]{2}([a-zA-Z0-9]{4})([0-9]{20})|",
    "GT[0-9]{2}[a-zA-Z]{4}([a-zA-Z0-9]{20})|",
    "VG[0-9]{2}([a-zA-Z0-9]{4}){1}([0-9]{4}){4})",
  ].join("")
);
export const username = /\b(user( ?name)?|login): \S+\b/;
export const password = /\b(pass(word|phrase)?|secret): \S+\b/;
export const credentials =
  /\b(login( cred(ential)?s| info(rmation)?)?|cred(ential)?s) ?:\s*\S+\s+\/?\s*\S+\b/;
