import { UploadDataContainer } from "../../../types/fileUploader";
import { QuickAnalysisIssue } from "../../../types/quickAnalysis";
import { RuleCandidateWithoutMeta } from "../../../types/rules";
import { EMPTY_CELL_VALUES } from "../../quickAnalysis/qualityChecks/constants";
import { addIssueId } from "../../quickAnalysis/qualityChecks/qualityUtils";

const escapeSpecialCharacters = (input: string): string => {
  let pattern = "";
  for (const element of input) {
    pattern += element.replace(/[^\w]/, "\\" + String(element));
  }
  return pattern;
};

/**
 * This function transforms a string into a pattern of the form:
 * All digits are replaced by 0
 * All letters are replaced by S
 * @param str
 * @returns
 */
export const getPatternFromString = (str: string) => {
  return str.trim().replace(/\d/g, "0").replace(/[A-Z]/g, "S").replace(/[a-z]/g, "s");
};

const transformDPattern = (inputPattern: string): string => {
  return inputPattern.replace(/(0)+/g, (match) => `\[0-9]{${match.length}}`);
};

const transformSPattern = (inputPattern: string): string => {
  return inputPattern.replace(/(S)+/g, (match) => `[A-Z]{${match.length}}`);
};

const transformsPattern = (inputPattern: string): string => {
  return inputPattern.replace(/(s)+/g, (match) => `[a-z]{${match.length}}`);
};
export const getRegexFromPattern = (input: string): RegExp => {
  let pattern = escapeSpecialCharacters(input); // - to \-
  pattern = transformDPattern(pattern); // 0000 to [0-9]{4}
  pattern = transformSPattern(pattern); // SSSS to [A-Z]{4}
  pattern = transformsPattern(pattern); // ssss to [a-z]{4}
  return new RegExp(pattern);
};

/**
 * This function checks if the values in the column match a certain pattern.
 * It transforms all string values into a pattern of the form:
 * All digits are replaced by 0
 * All letters are replaced by S
 * 0 -> [0-9] as regex with a length indicator e.g., [0-9]{2} for 2 digits
 * S -> [A-Z] as regex, also with a length indicator e.g., [A-Z]{3} for 3 letters
 * s -> [a-z] as regex, also with a length indicator e.g., [a-z]{3} for 3 letters
 * @param dataContainer
 * @param rule
 * @returns
 */
export const strPatterns = (
  dataContainer: UploadDataContainer,
  rule: RuleCandidateWithoutMeta
): QuickAnalysisIssue[] => {
  const issues: QuickAnalysisIssue[] = [];
  if (rule.qualityTest.testFunctionName !== "strPatterns") return issues;
  const regexPatternString = rule.qualityTest.meta.patternRegex;

  const columnIndex = rule.columns[0].index;
  const cells = dataContainer.data.map((row) => row[columnIndex]);
  cells.forEach((cell) => {
    const isCellEmpty = cell.type === "Empty" || EMPTY_CELL_VALUES.includes(String(cell.value));
    if (isCellEmpty) return;
    const regEx = new RegExp(regexPatternString);
    if (!regEx.test(cell.value.toString())) {
      issues.push({
        row: cell.row,
        column: cell.column,
        type: "inconsistent",
        comment: "inconsistent_comment",
        severity: rule.severity,
        id: "",
        rule_id: rule.id,
      });
    }
  });
  return addIssueId(issues);
};
