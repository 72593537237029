import { IStackItemStyles } from "@fluentui/react";

export const getAddressRendererStyle = (isSelected: boolean): React.CSSProperties => {
  return {
    display: "inline-block",
    fontSize: 10,
    textDecoration: isSelected ? "underline" : "none",
    fontWeight: isSelected ? 800 : 400,
    marginTop: 2,
  };
};

export const getStackStyle = (sideBarWidth: number): IStackItemStyles => {
  return {
    root: { overflow: "scroll", width: sideBarWidth },
  };
};
