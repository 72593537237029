import {
  Dropdown,
  Icon,
  IconButton,
  Label,
  Spinner,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { Link, useParams } from "react-router-dom";
import { TopContainer } from "../../components/common/TopContainer";
import { useGetAllDataConnectorsQuery } from "../../api/v2/dataConnectors";
import { useTranslation } from "react-i18next";
import { DataConnector, DataTable } from "../dataConnectors/dataConnectorTypes";
import { useEffect, useState } from "react";
import { RuleTableBasis } from "../../components/rules/RuleTableBasis";
import { ActiveRule, RuleCandidate } from "../../types/rules";
import { groupRules, searchGroups } from "../../store/slices/rules";
import { useGetRulesetsForDataTableQuery, useUpdateRulesetMutation } from "../../api/v2/rulesets";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Issue } from "../../types/issue";
import { useBoolean } from "@fluentui/react-hooks";
import { AddAlertPanel } from "./AddAlertPanel";
import { useGetAllAlertsForRulesetQuery } from "../../api/v2/alertsNotifications";

export const RulesForTables: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { tableid } = useParams();
  const { data: dataConnectors, isSuccess: connectorsLoaded } = useGetAllDataConnectorsQuery({});
  const { data: rulesets, isSuccess: rulesetsLoaded } = useGetRulesetsForDataTableQuery(
    tableid || skipToken
  );
  const { data: alerts, isSuccess: alertsLoaded } = useGetAllAlertsForRulesetQuery(
    (rulesets ? rulesets[0].id : undefined) ?? skipToken
  );
  const [updateRuleset] = useUpdateRulesetMutation();

  const [selectedConnector, setSelectedConnector] = useState<DataConnector | undefined>(undefined);
  const [selectedDataTable, setSelectedDataTable] = useState<DataTable | undefined>(undefined);

  const [searchValue, setSearchValue] = useState<string>("");
  const [rulesGroupedByColumn, setRulesGroupedByColumn] = useState<RuleCandidate[][]>([]);
  const [items, setItems] = useState<RuleCandidate[]>([]);
  const [issues, setIssues] = useState<Issue[]>([]);
  const [showAddAlertPanel, { toggle: toggleAddAlertPanel }] = useBoolean(false);

  useEffect(() => {
    if (!dataConnectors || !connectorsLoaded) return;
    const issues = dataConnectors
      ?.map((dataConnector) =>
        dataConnector.data_tables.find((dataTable) => dataTable.id === tableid)
      )
      .flat()
      .map((dataTable) => dataTable?.rules.filter((rule) => (rule as ActiveRule).isAccepted))
      .flat()
      .map((rule) => rule as ActiveRule)
      .map((rule) => rule.issues.filter((issue) => issue.rule_id === rule?.id))
      .flat();
    setIssues(issues);
    dataConnectors?.forEach((dataConnector) => {
      const table = dataConnector.data_tables.find((dataTable) => dataTable.id === tableid);
      if (table) {
        setSelectedConnector(dataConnector);
        setSelectedDataTable(table);
      }
    });
  }, [dataConnectors]);

  useEffect(() => {
    if (!selectedDataTable) return;
    const groupedRules = groupRules(selectedDataTable.rules);
    const rulesGroupedByColumn: RuleCandidate[][] = searchGroups(groupedRules, searchValue);

    setRulesGroupedByColumn(rulesGroupedByColumn);
    setItems(rulesGroupedByColumn.flatMap((rule) => rule));
  }, [selectedDataTable, searchValue]);

  if (!connectorsLoaded || !rulesetsLoaded || !alertsLoaded) return <Spinner />;

  return (
    <TopContainer title={`${selectedConnector?.name}: ${selectedDataTable?.name}`}>
      <Stack styles={{ root: { paddingTop: 16 } }}>
        <Stack
          styles={{
            root: {
              margin: "0 10px 0px 10px",
              position: "sticky",
              top: 0,
              zIndex: 1000,
              backgroundColor: "white",
              paddingTop: 15,
              paddingBottom: 20,
              height: 100,
            },
          }}
        >
          <Stack horizontal tokens={{ childrenGap: 32 }}>
            <Stack tokens={{ childrenGap: 8 }}>
              <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                <Stack.Item styles={{ root: { width: 100 } }}>
                  <Label>{t("ruleset")}: </Label>
                </Stack.Item>
                <Stack.Item>
                  <Dropdown
                    options={rulesets.map((ruleset) => ({ key: ruleset.id, text: ruleset.name }))}
                    defaultSelectedKey={rulesets[0].id}
                    styles={{ dropdown: { minWidth: 200 } }}
                  />
                </Stack.Item>
              </Stack>
              <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                <Stack.Item styles={{ root: { width: 100 } }}>
                  <Label>{t("CRON")}: </Label>
                </Stack.Item>
                <Stack.Item>
                  <Dropdown
                    options={[
                      {
                        key: "0 * * * *",
                        text: "0 * * * * | Every hour",
                      },
                      {
                        key: "0 0 * * *",
                        text: "0 0 * * * | Every day",
                      },
                      {
                        key: "0 0 * * 0",
                        text: "0 0 * * 0 | Every week",
                      },
                      {
                        key: "0 0 1 * *",
                        text: "0 0 1 * * | Every month",
                      },
                    ]}
                    defaultSelectedKey={rulesets[0].execution_config.CRON}
                    onChange={async (e, item) => {
                      if (!item) return;
                      await updateRuleset({
                        ...rulesets[0],
                        execution_config: { CRON: item.key.toString(), sampling: "random" },
                      });
                    }}
                    styles={{ dropdown: { minWidth: 200 } }}
                  />
                </Stack.Item>
              </Stack>
            </Stack>
            <Stack tokens={{ childrenGap: 8 }}>
              <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                <Icon iconName="Ringer" />
                <Stack.Item styles={{ root: { width: 150 } }}>
                  <Label>{t("connected_alerts")}: </Label>
                </Stack.Item>
                <Stack.Item>
                  <TooltipHost content={t("add_alert_tooltip")}>
                    <IconButton
                      iconProps={{ iconName: "CirclePlus" }}
                      alt={t("add_alert")}
                      onClick={toggleAddAlertPanel}
                    />
                  </TooltipHost>
                </Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 4 }}>
                  {alerts?.map((alert, index) => (
                    <Stack.Item key={alert.id}>
                      <Link key={alert.id} to={`/alerts/${alert.id}`}>
                        {alert.name}
                      </Link>
                      {index < alerts.length - 1 ? ", " : ""}
                    </Stack.Item>
                  ))}
                </Stack>
              </Stack>
              <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                <Icon iconName="WorkFlow" />
                <Stack.Item styles={{ root: { width: 150 } }}>
                  <Label>{t("connected_missions")}: </Label>
                </Stack.Item>
                <Stack.Item>
                  <TooltipHost content={t("add_mission_tooltip")}>
                    <IconButton iconProps={{ iconName: "CirclePlus" }} alt={t("add_mission")} />
                  </TooltipHost>
                </Stack.Item>
                <Stack.Item>
                  <Text>-</Text>
                </Stack.Item>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <RuleTableBasis
          rulesGroupedByColumn={rulesGroupedByColumn}
          items={items}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          issues={issues}
        />
      </Stack>
      {selectedDataTable && rulesets && (
        <AddAlertPanel
          showHidePanel={showAddAlertPanel}
          toggleShowHidePanel={toggleAddAlertPanel}
          selectedTable={selectedDataTable}
          selectedRuleset={rulesets[0]}
          availableRulesets={rulesets}
        />
      )}
    </TopContainer>
  );
};
