import { SortOption } from "../types/cockpit";
import { AssetUsecaseProblems, AssetUsecaseValue, DataAsset } from "../types/dataasset";

export function searchData<T>(data: T[], search: string, property: string): T[] {
  const key = property as keyof T;
  return data.filter((obj: T) => {
    return (
      typeof obj[key] === "string" &&
      (obj[key] as unknown as string).toLowerCase().includes(search.toLowerCase())
    );
  });
}

export function filterData<T>(data: T[], selectedKeys: string[], property: string): T[] {
  const key = property as keyof T;
  return data.filter((item: T) => {
    return selectedKeys.indexOf(item[key] as unknown as string) !== -1;
  });
}

export function sortData(data: DataAsset[], sortBy: SortOption): DataAsset[] {
  let sortedData: DataAsset[] = [...data];
  if (sortBy === "problems") {
    const order: Record<AssetUsecaseProblems, number> = {
      "Not assessed": 1,
      None: 2,
      Few: 3,
      Severe: 4,
    };
    sortedData.sort(
      (a, b) => (order[b.problems] || Number.MAX_VALUE) - (order[a.problems] || Number.MAX_VALUE)
    );
  } else if (sortBy === "value") {
    const order: Record<AssetUsecaseValue, number> = {
      "Not assessed": 1,
      Low: 2,
      Medium: 3,
      High: 4,
    };
    sortedData.sort(
      (a, b) => (order[b.value] || Number.MAX_VALUE) - (order[a.value] || Number.MAX_VALUE)
    );
  } else if (sortBy === "use-cases") {
    sortedData.sort(function (a, b) {
      return b.linked_use_cases.length - a.linked_use_cases.length;
    });
  }
  return sortedData;
}
