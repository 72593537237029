import {
  Icon,
  IDropdownOption,
  IDropdownProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  ITag,
  Label,
  Stack,
} from "@fluentui/react";
import React, { Dispatch, FunctionComponent, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { EditableDropdown } from "../../../components/common/EditableDropdown";
import { EditableTextField } from "../../../components/common/EditableTextField";
import {
  governanceOptions,
  problemOptions,
  typeOptions,
  valueOptions,
} from "../../../components/cockpit/dropdownOptions";
import { descDetailStyle, detailsContainer } from "../../../helpers/commonStyles";
import {
  AssetGovernance,
  AssetType,
  AssetUsecaseProblems,
  AssetUsecaseValue,
  DataAsset,
  Origin,
} from "../../../types/dataasset";
import { Origins } from "../../../components/cockpit/Origins";
import { SelectParentDataAsset } from "../../../components/cockpit/SelectParentDataAsset";
import { useBoolean } from "@fluentui/react-hooks";

interface DetailState {
  asset: DataAsset | undefined;
  setAsset: Dispatch<SetStateAction<DataAsset | undefined>>;
  origins: Origin[] | undefined;
  updateAsset: () => void;
}

const stackTokens: IStackTokens = {
  childrenGap: 5,
};

const lineStack: IStackStyles = {
  root: {
    marginBottom: 10,
  },
};

const labelItem: IStackItemStyles = {
  root: {
    minWidth: 130,
  },
};

const iconStyles = { marginRight: "8px" };

const dropdownStyles = { dropdown: { width: "100%" } };

const tagPickerStyles = { root: { width: "100%" } };

const optionText: React.CSSProperties = {
  marginLeft: 15,
  display: "inline-block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const onRenderOption = (option?: IDropdownOption): JSX.Element | null => {
  if (!option) return null;
  return (
    <div>
      <Stack horizontal>
        <span>{option.data.render ? option.data.render() : option.data.component}</span>
        <span style={optionText}>{option.text}</span>
      </Stack>
    </div>
  );
};

const onRenderTitle = (options?: IDropdownOption[]): JSX.Element | null => {
  let option: IDropdownOption;
  if (!options) return null;
  option = options[0];
  return (
    <Stack horizontal>
      <span>{option.data.render ? option.data.render() : option.data.component}</span>
      <span>{option.text}</span>
    </Stack>
  );
};

const onRenderPlaceholder = (props: IDropdownProps | undefined): JSX.Element => {
  return (
    <div className="dropdownExample-placeholder">
      <Icon style={iconStyles} iconName={"MessageFill"} aria-hidden="true" />
      <span>{props!.placeholder}</span>
    </div>
  );
};

export const Details: FunctionComponent<DetailState> = (props: DetailState) => {
  const { t } = useTranslation();

  // hack to propagate change
  const [onChangeTriggered, { toggle: setOnChangeTriggered }] = useBoolean(false);
  useEffect(() => {
    props.updateAsset();
  }, [onChangeTriggered]);

  if (!props.asset) return null;

  return (
    <div>
      <label style={{ ...descDetailStyle, marginBottom: 15 }}>{t("details")}</label>
      <Stack styles={detailsContainer}>
        <Stack horizontal tokens={stackTokens} styles={lineStack}>
          <Stack.Item styles={labelItem}>
            <Label>{t("type")}</Label>
          </Stack.Item>
          <Stack.Item grow={1} style={{ overflow: "hidden" }}>
            <EditableDropdown
              selectedKey={props.asset.type}
              placeholder={t("asset_type")}
              ariaLabel={t("select-type")}
              options={typeOptions}
              styles={dropdownStyles}
              onRenderTitle={onRenderTitle}
              onRenderPlaceholder={onRenderPlaceholder}
              onRenderOption={onRenderOption}
              onChange={(_event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                props.setAsset({
                  ...props.asset!,
                  type: item?.key as AssetType,
                });
              }}
              onBlur={() => {
                setOnChangeTriggered();
              }}
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal tokens={stackTokens} styles={lineStack}>
          <Stack.Item styles={labelItem}>
            <Label>{t("owner")}</Label>
          </Stack.Item>
          <Stack.Item grow={1} style={{ overflow: "hidden" }}>
            <EditableTextField
              id="ownerInput"
              value={props.asset.owner!}
              placeholder={t("owner")}
              onChange={(
                _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                props.setAsset({
                  ...props.asset!,
                  owner: newValue,
                });
              }}
              onBlur={() => {
                setOnChangeTriggered();
              }}
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal tokens={stackTokens} styles={lineStack}>
          <Stack.Item styles={labelItem}>
            <Label>{t("steward")}</Label>
          </Stack.Item>
          <Stack.Item grow={1} style={{ overflow: "hidden" }}>
            <EditableTextField
              id="stewardInput"
              value={props.asset.steward!}
              placeholder={t("steward")}
              onChange={(
                _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                props.setAsset({
                  ...props.asset!,
                  steward: newValue,
                });
              }}
              onBlur={() => {
                setOnChangeTriggered();
              }}
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal tokens={stackTokens} styles={lineStack}>
          <Stack.Item styles={labelItem}>
            <Label>{t("custodian")}</Label>
          </Stack.Item>
          <Stack.Item grow={1} style={{ overflow: "hidden" }}>
            <EditableTextField
              id="custodianInput"
              value={props.asset.custodian}
              placeholder={t("custodian")}
              onChange={(
                _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                props.setAsset({
                  ...props.asset!,
                  custodian: newValue,
                });
              }}
              onBlur={() => {
                setOnChangeTriggered();
              }}
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal tokens={stackTokens} styles={lineStack}>
          <Stack.Item styles={labelItem}>
            <Label>{t("value")}</Label>
          </Stack.Item>
          <Stack.Item grow={1} style={{ overflow: "hidden" }}>
            <EditableDropdown
              selectedKey={props.asset.value}
              placeholder={t("asset_value")}
              ariaLabel={t("select-value")}
              onRenderTitle={onRenderTitle}
              onRenderPlaceholder={onRenderPlaceholder}
              onRenderOption={onRenderOption}
              options={valueOptions}
              styles={dropdownStyles}
              onChange={(_event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                props.setAsset({
                  ...props.asset!,
                  value: item?.key as AssetUsecaseValue,
                });
                props.updateAsset();
                setOnChangeTriggered();
              }}
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal tokens={stackTokens} styles={lineStack}>
          <Stack.Item styles={labelItem}>
            <Label>{t("problems")}</Label>
          </Stack.Item>
          <Stack.Item grow={1} style={{ overflow: "hidden" }}>
            <EditableDropdown
              selectedKey={props.asset.problems}
              placeholder={t("problems")}
              ariaLabel={t("select-problem")}
              onRenderTitle={onRenderTitle}
              onRenderPlaceholder={onRenderPlaceholder}
              onRenderOption={onRenderOption}
              options={problemOptions}
              styles={dropdownStyles}
              onChange={(_event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                props.setAsset({
                  ...props.asset!,
                  problems: item?.key as AssetUsecaseProblems,
                });
                setOnChangeTriggered();
              }}
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal tokens={stackTokens} styles={lineStack}>
          <Stack.Item styles={labelItem}>
            <Label>{t("origins")}</Label>
          </Stack.Item>
          <Stack.Item grow={1} style={{ overflow: "hidden" }}>
            <Origins
              currentOrigins={props.asset.origins}
              styles={tagPickerStyles}
              onChange={(items?: ITag[]) => {
                props.setAsset({
                  ...props.asset!,
                  origins: items?.map((i) => ({ id: i.key, label: i.name } as Origin)) || [],
                });
              }}
              onBlur={props.updateAsset}
              hoverStyle={true}
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal tokens={stackTokens} styles={lineStack}>
          <Stack.Item styles={labelItem}>
            <Label>{t("governance")}</Label>
          </Stack.Item>
          <Stack.Item grow={1} style={{ overflow: "hidden" }}>
            <EditableDropdown
              selectedKey={props.asset.governance}
              placeholder={t("governance")}
              ariaLabel={t("select_governance")}
              onRenderTitle={onRenderTitle}
              onRenderPlaceholder={onRenderPlaceholder}
              onRenderOption={onRenderOption}
              options={governanceOptions}
              styles={dropdownStyles}
              onChange={(_event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                props.setAsset({
                  ...props.asset!,
                  governance: item?.key as AssetGovernance,
                });
                setOnChangeTriggered();
              }}
            />
          </Stack.Item>
        </Stack>
        <Stack>
          {props.asset?.type !== "Domain" && (
            <SelectParentDataAsset
              assetType={props.asset?.type!}
              selectedParentId={props.asset?.parent_asset_id}
              setSelectParentId={(id: string | undefined) => {
                props.setAsset({ ...props.asset!, parent_asset_id: id! });
                setOnChangeTriggered();
              }}
            />
          )}
        </Stack>
      </Stack>
    </div>
  );
};
