import { Dataset } from "../types/dataset";
import moment from "moment";

export const getDatasetName = (datasets: Dataset[], id: string) => {
  const dataset = datasets.find((ds) => ds.id === id);
  if (dataset) return dataset.name;
  return "";
};

export function getDatasetById(datasets: Dataset[], id: string) {
  return datasets.find((datasetObj) => {
    return datasetObj.id === id;
  });
}

export function extractEmails(text: string) {
  let emailList: string[] = [];

  // split text based on , or ;
  let separatedTexts = text.trim().split(/,|;/);

  separatedTexts.forEach((t) => {
    t = t.trim();
    //omit empty strings
    if (t !== "") {
      // check for spaces
      if (t.includes(" ")) {
        // split text based on spaces
        let spaceTexts = t.replace(/\s+/g, ",").split(",");
        emailList.push(...spaceTexts);
      } else emailList.push(t);
    }
  });

  return emailList;
}

export function validateEmails(text: string) {
  let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  let emails = extractEmails(text);

  for (let i = 0; i < emails.length; i++) {
    if (!emailPattern.test(emails[i])) {
      return false;
    }
  }

  return true;
}

// process fields such as reponsibleIds, message, start date timestamp and end date timestamp
export function processTaskFields(
  messageText: string | undefined,
  startDate: string,
  endDate: string | null
) {
  const message = messageText == null ? "" : messageText.trim();

  const startDateTS = moment(startDate).format();
  let endDateTS: string;
  if (endDate == null) {
    endDateTS = moment(startDateTS).add(1, "year").format();
  } else {
    endDateTS = moment(endDate).format();
  }

  return {
    message,
    startDateTS,
    endDateTS,
  };
}
