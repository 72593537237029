import { IIconStyles, IStackStyles } from "@fluentui/react";
import { dqcPalette } from "../../../utils/colors";

export const getIssueIcon = (type: "info" | "warning"): IIconStyles => ({
  root: {
    color: type === "warning" ? dqcPalette.warningIcon : dqcPalette.infoIcon,
  },
});

export const highlightedContainerStyles: IStackStyles = {
  root: {
    marginLeft: 20,
    marginTop: 6,
    marginBottom: 6,
    paddingTop: 8,
    paddingBottom: 8,
  },
};
