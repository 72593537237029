import { ApiSettings, InputFields } from "../../types/connection";
import { DefaultButton, Icon, Label, Modal, PrimaryButton, TextField } from "@fluentui/react";
import React, { useState } from "react";
import {
  buttonContainer,
  buttonSubContainer,
  iconStyle,
  modalHeader,
  modalSize,
} from "../../helpers/commonStyles";
import { useUpdateConnectionMutation, useUpdatePluginMutation } from "../../api/installation";

import { ModalProps } from "../../types/modal";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useTranslation } from "react-i18next";

const modalStyle: React.CSSProperties = {
  padding: "20px",
};

const textFieldContainer: React.CSSProperties = {
  marginBottom: "1%",
};

const labelIconContainer: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const CommonModal: React.FunctionComponent<ModalProps> = ({
  id,
  title,
  modalState,
  body,
  fieldData,
  inputFields,
  connectorType,
  connectorId,
  primaryButtonText,
  secondaryButtonText,
  secondaryButtonOnClick,
}: ModalProps) => {
  const { t } = useTranslation();
  const [textfieldData, setTextfieldData] = useState<ApiSettings>(fieldData ? fieldData : {});
  const [errorMessage, setErrorMessage] = useState("");
  const [updateConnection] = useUpdateConnectionMutation();
  const [updatePlugin] = useUpdatePluginMutation();

  const handleCloseModal = () => {
    secondaryButtonOnClick();
    setTextfieldData({});
  };

  const handleChangeInputField = (event: any) => {
    setTextfieldData({ ...textfieldData, [event.target.name]: event.target.value });
  };

  const configureFunction = async () => {
    if (fieldData) {
      updateConnectionFunc();
    } else {
      updatePluginFunc();
    }
  };

  const updateConnectionFunc = async () => {
    const connectionObj = {
      connection_type: connectorType ? connectorType.toUpperCase() : "",
      connection: {
        bf_id: id,
        activated: true,
        type: connectorType ? connectorType.toUpperCase() : "",
        bf_connector_id: connectorId ? connectorId : "",
        api_settings: textfieldData,
      },
    };
    try {
      const resp = await updateConnection(connectionObj).unwrap();
      if (resp) {
        handleCloseModal();
      } else {
        setErrorMessage(t("connector_error_message"));
      }
    } catch (error) {
      console.error("Error for update connection api", error);
      setErrorMessage(t("connector_error_message"));
    }
  };

  const updatePluginFunc = async () => {
    const pluginObj = {
      bf_id: id,
      activated: true,
    };
    try {
      const resp = await updatePlugin(pluginObj);
      if (resp) {
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error for update plugin api", error);
    }
  };

  const isDisabled = (): boolean => {
    if (!fieldData || !inputFields) return false;
    return inputFields.some((inputField) => {
      return (
        textfieldData[inputField.name as keyof ApiSettings] === "" ||
        textfieldData[inputField.name as keyof ApiSettings]?.includes("*********")
      );
    });
  };

  return (
    <Modal
      isOpen={modalState}
      onDismiss={() => handleCloseModal()}
      containerClassName={modalSize.container}
    >
      <div style={modalStyle}>
        <div style={labelIconContainer}>
          <Label styles={modalHeader}>{title}</Label>
          <Icon styles={iconStyle} iconName="cancel" onClick={() => handleCloseModal()} />
        </div>
        <ReactMarkdown children={body} remarkPlugins={[remarkGfm]} />
        {inputFields?.map((item: InputFields, index) => (
          <div style={textFieldContainer} key={index}>
            <TextField
              name={item.name}
              label={item.label}
              defaultValue={textfieldData[item.name as keyof ApiSettings]}
              placeholder={item.placeholder}
              required={item.required}
              onChange={handleChangeInputField}
            />
          </div>
        ))}
        <div>
          <span style={{ color: "red" }}>{errorMessage}</span>
        </div>
        <div style={buttonContainer}>
          <div style={buttonSubContainer}>
            <PrimaryButton
              text={primaryButtonText}
              onClick={() => configureFunction()}
              styles={{
                root: { backgroundColor: "#0078D4", marginRight: "4%", whiteSpace: "noWrap" },
              }}
              disabled={isDisabled()}
            />
            <DefaultButton
              text={secondaryButtonText}
              onClick={() => handleCloseModal()}
              styles={{ root: { marginRight: "2%" } }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
