import { Stack, TextField, Text } from "@fluentui/react";
import { t } from "i18next";
import { RuleCandidate } from "../../../../types/rules";
import { useState } from "react";
import { NameAndDescriptionInput } from "../NameDescription";
import { RuleDetailModalFooter } from "../RuleDetailModalFooter";
import { MODAL_DETAIL_LEFT_MARGIN, patternTextFieldStyles } from "./bodyStyles";

export const StrPatternRuleDetailBody = ({
  rule,
  toggleModal,
}: {
  rule: RuleCandidate;
  toggleModal: () => void;
}) => {
  const [localRule, setLocalRule] = useState<RuleCandidate>(rule);
  if (localRule.qualityTest.testFunctionName !== "strPatterns") return null;
  const localQualityTest = localRule.qualityTest;
  return (
    <>
      <Stack tokens={{ childrenGap: 12 }}>
        <NameAndDescriptionInput rule={localRule} updateRule={setLocalRule} />
        <Stack>
          <Stack horizontal>
            <Text styles={{ root: { width: MODAL_DETAIL_LEFT_MARGIN } }}>{t("pattern")}</Text>
            <TextField
              value={localQualityTest.meta.patternRegex}
              styles={patternTextFieldStyles}
              onChange={(_event, newValue?: string | undefined) => {
                if (!newValue) return;
                const newRule: RuleCandidate = {
                  ...localRule,
                  qualityTest: {
                    ...localQualityTest,
                    meta: { ...localQualityTest.meta, patternRegex: newValue },
                  },
                };
                setLocalRule(newRule);
              }}
            />
          </Stack>
        </Stack>
      </Stack>
      <RuleDetailModalFooter rule={localRule} updateRule={setLocalRule} toggleModal={toggleModal} />
    </>
  );
};
