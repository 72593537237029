import React, { useEffect, useState } from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { headerStyle, interactionContainer, subContainerStyle } from "../../helpers/commonStyles";
import { InteractionFactsAndFigures, InteractionState } from "../../types/interactions";
import { QualityFactsAndFigures } from "../../types/performance";
import { FactsAndFiguresItem } from "./FactsAndFiguresItem";
import {
  useGetInteractionFactsAndFiguresQuery,
  useGetQualityFactsAndFiguresQuery,
} from "../../api/performance";

const topContainer: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
};

const container: React.CSSProperties = {
  marginTop: "15%",
};

export const FactsAndFigures: React.FunctionComponent<InteractionState> = ({
  interaction,
}: InteractionState) => {
  const { t } = useTranslation();
  const [factsAndFigures, setFactsAndFigures] = useState<InteractionFactsAndFigures>(Object);
  const [loader, setLoader] = useState(true);
  const [factsAndFiguresQuality, setFactsAndFiguresQuality] =
    useState<QualityFactsAndFigures>(Object);
  const getFactsAndFigures = useGetInteractionFactsAndFiguresQuery("");
  const getQualityFactsAndFigures = useGetQualityFactsAndFiguresQuery("");

  useEffect(() => {
    if (interaction) {
      if (!getFactsAndFigures.isLoading && getFactsAndFigures.isSuccess) {
        setFactsAndFigures(getFactsAndFigures.data);
        setLoader(false);
      }
    } else {
      if (!getQualityFactsAndFigures.isLoading && getQualityFactsAndFigures.isSuccess) {
        setFactsAndFiguresQuality(getQualityFactsAndFigures.data);
        setLoader(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFactsAndFigures, getQualityFactsAndFigures]);

  return (
    <section style={subContainerStyle}>
      <h3 style={headerStyle}>{t("facts_and_figures")}</h3>
      {loader ? (
        <div style={interactionContainer}>
          <Spinner size={SpinnerSize.large} />
        </div>
      ) : (
        <div style={topContainer}>
          {interaction ? (
            <div style={container}>
              <FactsAndFiguresItem
                numbers={factsAndFigures.active_users}
                text={factsAndFigures?.active_users > 1 ? t("active_users") : t("active_user")}
              />
              <FactsAndFiguresItem
                numbers={factsAndFigures.messages_send}
                text={factsAndFigures?.messages_send > 1 ? t("messages_send") : t("message_send")}
              />
            </div>
          ) : (
            <div style={container}>
              <FactsAndFiguresItem
                numbers={factsAndFiguresQuality?.overall_data_issues_identified}
                text={t("overall_data_issues_identified")}
              />
              <FactsAndFiguresItem
                numbers={factsAndFiguresQuality?.currently_open_data_issues}
                text={t("currently_open_data_issues")}
              />
              <FactsAndFiguresItem
                numbers={factsAndFiguresQuality?.overall_data_issues_corrected}
                text={t("overall_data_issues_corrected")}
              />
            </div>
          )}
        </div>
      )}
    </section>
  );
};
