import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { QuickAnalysisIssue } from "../../types/quickAnalysis";
import { clearIssueNavigator, handleArrowPress } from "./issueNavigator";
import { clearAllFiles, loadFile, selectLatestUpload } from "./upload";
import { getIssuesFromWorker, getRuleCandidatesFromWorker } from "../../getQuickAnalysisFromWorker";
import { ColumnDefinition, UploadDataContainer } from "../../types/fileUploader";
import {
  QualityTest,
  QuickAnalysisRule,
  RuleCandidate,
  RuleCandidateWithoutMeta,
} from "../../types/rules";
import { OVERALL_QUALITY } from "../../helpers/quickAnalysis/qualityChecks/constants";
import { createColumnCorrelationRule } from "../../helpers/predictRules/predictors/predictColumnCorrelation";
import { getColumnMatchingRule } from "../../helpers/predictRules/predictors/predictColumnMatching";
import { getDuplicateRule } from "../../helpers/predictRules/predictors/predictDuplicateRules";
import { getInconsistentRule } from "../../helpers/predictRules/predictors/predictInconsistentRules";
import { getMissingRule } from "../../helpers/predictRules/predictors/predictMissingRules";
import { getOutlierRule } from "../../helpers/predictRules/predictors/predictOutlierRules";
import { getSensitiveRule } from "../../helpers/predictRules/predictors/predictSensitiveRules";
import { getStrPatternRule } from "../../helpers/predictRules/predictors/predictStrPatterns";
import { getEmptyRowsAndColumns } from "../../utils/getEmptyRowsAndColumns";
import { getCustomRule } from "../../helpers/predictRules/qualityRuleFunctions/customRules";
import { getCategoricalRule } from "../../helpers/predictRules/predictors/predictCategoricalRules";
import { isQuickAnalysisRule } from "../../utils/rules";

export type QuickAnalysisSlice = {
  // TODO think about analysis and upload failure states
  status: "selectFile" | "loadingFile" | "filePreview" | "analyzing" | "results" | "rules";
  sidebarView: "qualitySummary";
  issues: QuickAnalysisIssue[];
  activeFilter?: string;
  openRuleId?: string;
  isMessageBarVisible?: boolean;
};
const quickAnalysis: QuickAnalysisSlice = {
  status: "selectFile",
  issues: [],
  sidebarView: "qualitySummary",
  isMessageBarVisible: true,
};

type CreateRulePayload = {
  columns: ColumnDefinition[];
  dataContainer: UploadDataContainer;
  testFunctionName: QualityTest["testFunctionName"];
};
export const createRule = createAsyncThunk<RuleCandidateWithoutMeta | undefined, CreateRulePayload>(
  "rules/createRule",
  async ({ columns, dataContainer, testFunctionName }) => {
    if (testFunctionName === "columnCorrelation")
      return createColumnCorrelationRule(
        { column1: columns[0], column2: columns[1], correlation: 0.7 },
        dataContainer
      );
    if (testFunctionName === "columnMatching")
      return getColumnMatchingRule(columns[0], columns[1], dataContainer);
    if (testFunctionName === "duplicates") return getDuplicateRule(columns, dataContainer);
    if (testFunctionName === "inconsistentDataType")
      return getInconsistentRule(columns[0], dataContainer);
    if (testFunctionName === "missingValues") {
      const { emptyRows } = getEmptyRowsAndColumns(dataContainer.data);
      return getMissingRule(columns[0], dataContainer, emptyRows);
    }
    if (testFunctionName === "stdDevOutlier" || testFunctionName === "iqrOutlier") {
      const isNormalDistribution = testFunctionName === "stdDevOutlier";
      return getOutlierRule(columns[0], isNormalDistribution, dataContainer.fileName);
    }
    if (testFunctionName === "sensitiveTest") {
      return getSensitiveRule(columns[0], dataContainer.fileName);
    }
    if (testFunctionName === "strPatterns") {
      return getStrPatternRule(columns[0], dataContainer, 0);
    }
    if (testFunctionName === "customRule") {
      return getCustomRule(columns[0], dataContainer.fileName);
    }
    if (testFunctionName === "categoricalData") {
      return getCategoricalRule(columns[0], dataContainer);
    }
    return undefined;
  }
);

export const doRulesAnalysis = createAsyncThunk<
  { issues: QuickAnalysisIssue[]; rules: RuleCandidate[] },
  UploadDataContainer,
  { state: RootState }
>(
  "quickAnalysis/doRulesAnalysis",
  async (dataContainer: UploadDataContainer, { getState, dispatch }) => {
    const state: RootState = getState();
    dispatch(setQuickAnalysisStatus("analyzing"));
    const existingRules: RuleCandidate[] = [];
    Object.values(state.rules.entities).forEach((rule: RuleCandidate | undefined) => {
      if (!rule) return;
      if (!isQuickAnalysisRule(rule)) {
        throw new Error("A DeepAnalysisRule cannot be used for quick analysis.");
      } else if ((rule as QuickAnalysisRule).fileName === dataContainer.fileName) {
        existingRules.push(rule);
      }
    });
    const useExistingRules = !dataContainer.forceReload && existingRules.length > 0;
    const rules = useExistingRules
      ? existingRules
      : await getRuleCandidatesFromWorker(dataContainer);
    const activeRules = rules.filter((rule) => rule.isAccepted && !rule.deletedAt);
    const issues = await getIssuesFromWorker(dataContainer, activeRules);
    return {
      issues,
      rules,
    };
  }
);

export const endAnalysisAndClearEverything = createAsyncThunk<
  void,
  undefined,
  { state: RootState }
>("quickAnalysis/endAnalysisAndClearEverything", async (_, { getState, dispatch }) => {
  const state = getState();
  const latestUpload = selectLatestUpload(state);
  dispatch(setActiveFilter(undefined));
  dispatch(setQuickAnalysisStatus("selectFile"));
  dispatch(clearAllFiles());
  latestUpload && dispatch(clearIssueNavigator(latestUpload.fileName));
});

export const goBackToPreview = createAsyncThunk<void, undefined, { state: RootState }>(
  "quickAnalysis/goBackToPreview",
  async (_, { dispatch }) => {
    dispatch(setActiveFilter(undefined));
    dispatch(setQuickAnalysisStatus("filePreview"));
  }
);

export const quickAnalysisSlice = createSlice({
  name: "quickAnalysis",
  initialState: quickAnalysis,
  reducers: {
    setQuickAnalysisStatus: (state, { payload }: PayloadAction<QuickAnalysisSlice["status"]>) => {
      state.status = payload;
    },
    setActiveFilter: (state, { payload }: PayloadAction<QuickAnalysisSlice["activeFilter"]>) => {
      state.activeFilter = payload;
    },
    setSidebarView: (state, { payload }: PayloadAction<QuickAnalysisSlice["sidebarView"]>) => {
      state.sidebarView = payload;
    },
    setOpenRuleId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.openRuleId = payload;
    },
    setMessageBarVisibility: (state, { payload }: PayloadAction<boolean>) => {
      state.isMessageBarVisible = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadFile.pending, (state) => {
      state.status = "loadingFile";
    });
    // TODO make this smarter to show the user that the upload failed
    builder.addCase(loadFile.rejected, (state) => {
      state.status = "selectFile";
    });
    builder.addCase(loadFile.fulfilled, (state) => {
      state.status = "filePreview";
    });
    builder.addCase(handleArrowPress.fulfilled, (state, { payload }) => {
      if (payload) state.activeFilter = payload.meta?.type;
    });
    builder.addCase(doRulesAnalysis.fulfilled, (state, { payload }) => {
      state.status = "results";
      state.issues = payload.issues;
    });
    builder.addCase(createRule.fulfilled, (state, { payload }) => {
      state.openRuleId = payload?.id;
    });
    builder.addCase(doRulesAnalysis.rejected, (state, { error }) => {
      console.error(error);
      state.status = "selectFile";
    });
  },
});

const selectQuickAnalysiseSlice = (state: RootState) => state.quickAnalysis;

export const {
  setQuickAnalysisStatus,
  setActiveFilter,
  setSidebarView,
  setOpenRuleId,
  setMessageBarVisibility,
} = quickAnalysisSlice.actions;

export const selectLatestQuickAnalysisStatus = createSelector(
  selectQuickAnalysiseSlice,
  (quickAnalysisSlice) => quickAnalysisSlice.status
);
export const selectOpenRuleId = createSelector(
  selectQuickAnalysiseSlice,
  (quickAnalysisSlice) => quickAnalysisSlice.openRuleId
);
export const selectMessageBarVisibility = createSelector(
  selectQuickAnalysiseSlice,
  (quickAnalysisSlice) => quickAnalysisSlice.isMessageBarVisible
);

export const selectVisibleRowIndicesMap = createSelector(
  (state: RootState) => state.quickAnalysis,
  ({ issues, activeFilter }): Map<number, number> | undefined => {
    if (!activeFilter) return undefined;
    const visibleRowIndices: number[] = [];
    issues.forEach((issue) => {
      if (activeFilter === issue.type || activeFilter === OVERALL_QUALITY)
        !visibleRowIndices.includes(issue.row) && visibleRowIndices.push(issue.row);
    });
    visibleRowIndices.sort((indexA, indexB) => indexA - indexB);
    const visibleRowIndicesMap = new Map<number, number>();
    visibleRowIndices.forEach((rowIndex, visibleIndex) =>
      visibleRowIndicesMap.set(rowIndex, visibleIndex)
    );
    return visibleRowIndicesMap;
  }
);

export const selectSidebarView = createSelector(
  selectQuickAnalysiseSlice,
  (quickAnalysisSlice) => quickAnalysisSlice.sidebarView
);

export const selectLatestQuickAnalysisIssues = createSelector(
  selectQuickAnalysiseSlice,
  (quickAnalysisSlice) => quickAnalysisSlice.issues
);

export const selectLatestQuickAnalysisQualityIssues = createSelector(
  selectLatestQuickAnalysisIssues,
  (issues) => issues.filter((issue) => issue.type !== "sensitive")
);

const selectQualityDimension = (_: RootState, dimension: QuickAnalysisIssue["type"]) => dimension;
export const selectIssuesByDimension = createSelector(
  selectLatestQuickAnalysisIssues,
  selectQualityDimension,
  (issues, dimension) => {
    return issues.filter((issue) => issue.type === dimension);
  }
);

export const selectActiveFilter = createSelector(
  selectQuickAnalysiseSlice,
  (quickAnalysisSlice) => quickAnalysisSlice.activeFilter
);
