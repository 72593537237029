import { Autofill, ITag } from "@fluentui/react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useAddNewTagMutation, useGetAllTagsQuery } from "../../api/cockpit";
import { Tag } from "../../types/usecase";
import { TagPickerWithCreation } from "../common/TagPickerWithCreation";
import { onChangeAddNew, tagToAPIModel } from "./helpers";

interface TagsProps {
  currentTags?: Tag[];
  onChange?: ((items?: ITag[] | undefined) => void) | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement | Autofill> | undefined;
  hoverStyle?: boolean;
}

export const Tags: FunctionComponent<TagsProps> = (props: TagsProps) => {
  const { t } = useTranslation();
  const tagsData = useGetAllTagsQuery();
  const [addTag] = useAddNewTagMutation();

  return (
    <TagPickerWithCreation
      {...props}
      tags={tagsData.data ? tagsData.data.map(tagToAPIModel) : []}
      selectedItems={props.currentTags?.map((t) => ({ key: t.id, name: t.label }))}
      removeButtonAriaLabel={t("remove_tag")}
      selectionAriaLabel={t("select_tag")}
      onChange={(items?: ITag[]) => {
        onChangeAddNew(addTag, props.onChange, items);
      }}
      inputProps={
        props.currentTags && props.currentTags.length === 0
          ? { placeholder: t("tag_field_placeholder") }
          : undefined
      }
      hoverStyle={props.hoverStyle}
    />
  );
};
