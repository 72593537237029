import { CommandBar } from "@fluentui/react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

export type SidebarHeaderProps = {
  toggleSidebar: () => void;
};

export const SidebarHeader: FunctionComponent<SidebarHeaderProps> = ({ toggleSidebar }) => {
  const navigate = useNavigate();

  return (
    <CommandBar
      items={[
        {
          key: "DQC LOGO",
          text: "DQC",
          onClick: () => navigate("/colab"),
        },
      ]}
      farItems={[
        {
          key: "hide",
          text: "Hide/show sidebar",
          // This needs an ariaLabel since it's icon-only
          ariaLabel: "Hide/show sidebar",
          iconOnly: true,
          iconProps: { iconName: "DoubleChevronRight" },
          onClick: toggleSidebar,
        },
      ]}
      ariaLabel="Head"
      styles={{
        root: {
          padding: 0,
          borderBottom: "0.5px solid #CCC",
        },
      }}
    />
  );
};
