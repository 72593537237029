import { IDetailsListStyles, IStackStyles } from "@fluentui/react";

export const getRulesDetailsListStyles = (headerWidth: number): Partial<IDetailsListStyles> => {
  return {
    root: {
      overflowX: "unset",
      width: "100%",
      selectors: {
        "& [role=grid]": {
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          height: "55vh",
        },
        "[class^='headerCount-']": {
          display: "none",
        },
        ".ms-Stack": {
          display: "flex",
          alignItems: "center",
        },
        ".ms-DetailsRow-cell": {
          display: "flex",
          alignItems: "center",
        },
        ".ms-DetailsHeader-cellName": {
          textTransform: "uppercase",
          color: "grey",
        },
        ".ms-GroupHeader-title": {
          width: headerWidth + 160,
        },
      },
    },
    headerWrapper: {
      flex: "0 0 auto",
    },
    contentWrapper: {
      width: "100%",
      flex: "1 1 auto",
      overflowY: "auto",
      overflowX: "hidden",
    },
  };
};

export const cellStyle: IStackStyles = {
  root: { display: "flex", alignSelf: "center" },
};

export const textStyle: IStackStyles = {
  root: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};
