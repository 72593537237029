import {
  DefaultButton,
  DefaultPalette,
  ILabelStyles,
  IStackStyles,
  Icon,
  Text,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";

import ReactMarkdown from "react-markdown";
import { TileProps } from "../../types/tile";
import { iconStyle } from "../../helpers/commonStyles";
import remarkGfm from "remark-gfm";

const stackStyles: Partial<IStackStyles> = {
  root: {
    background: DefaultPalette.neutralLight,
    width: "350px",
    height: "320px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    display: "flex",
    padding: 20,
  },
};

const tileHeader: Partial<ILabelStyles> = {
  root: {
    fontWeight: 600,
    fontSize: "20px",
    color: "#000000",
  },
};

const headerStack: Partial<ILabelStyles> = {
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
};

const subTitleStyle: Partial<ILabelStyles> = {
  root: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#000000",
    margin: 0,
  },
};

const textBodyStyle: React.CSSProperties = {
  height: "180px",
  overflow: "auto",
};

const chipStyle: React.CSSProperties = {
  width: "auto",
  backgroundColor: "rgb(6, 15, 49)",
  color: "rgb(255, 255, 255)",
  padding: "8px",
  borderRadius: "25px",
  fontSize: "12px",
  fontWeight: 600,
};

const iconMap: Record<string, string> = {
  Alation: "ProductCatalog",
  "MS Teams": "TeamsLogo",
  SharePoint: "SharepointLogo",
  "MS Teams Bot": "ChatBot",
  excel: "ExcelLogo",
  gsheets: "Table",
  ClipboardList: "ClipboardList",
};

export const Tile = ({
  type,
  title,
  icon,
  category,
  subTitle,
  description,
  configureState,
  loader,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
  status,
}: TileProps): JSX.Element => {
  return (
    <Stack styles={stackStyles} tokens={{ childrenGap: 10 }}>
      <Stack horizontal verticalAlign="center" styles={headerStack}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 15 }}>
          <Icon
            styles={iconStyle}
            iconName={type === "Connections" ? iconMap[title] : iconMap[icon ? icon : ""]}
          />
          <Text styles={tileHeader}>{title}</Text>
        </Stack>
        {type === "Connections" ? (
          <Stack>
            <span style={chipStyle}>{category?.toUpperCase()}</span>
          </Stack>
        ) : null}
      </Stack>
      {subTitle ? <Text styles={subTitleStyle}>{subTitle}</Text> : null}
      <div style={textBodyStyle}>
        <ReactMarkdown children={description} remarkPlugins={[remarkGfm]} />
      </div>
      <Stack tokens={{ childrenGap: 10 }}>
        <PrimaryButton
          onClick={() => primaryButtonOnClick()}
          disabled={status === "disabled"}
          styles={
            status === "completed" || configureState ? { root: { backgroundColor: "#2EE500" } } : {}
          }
        >
          {loader ? (
            <Spinner size={SpinnerSize.large} />
          ) : configureState && type === "Plugins" ? (
            "Configure"
          ) : configureState && type === "Connections" ? (
            "Connected"
          ) : (
            primaryButtonText
          )}
        </PrimaryButton>
        <DefaultButton
          onClick={secondaryButtonOnClick}
          disabled={status === "disabled"}
          styles={status === "completed" ? { root: { backgroundColor: "#2EE500" } } : {}}
        >
          {secondaryButtonText}
        </DefaultButton>
      </Stack>
    </Stack>
  );
};
