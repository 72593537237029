import { ILabelStyles, IStackStyles, Stack } from "@fluentui/react";
import { Label } from "@fluentui/react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { dividerLine } from "../../helpers/commonStyles";
import { UseCase } from "../../types/usecase";
import { PersonaTooltip } from "../common/PersonaTooltip";
import { ProblemToolTip } from "../common/ProblemToolTip";
import { ValueToolTip } from "../common/ValueToolTip";

const subContainer: IStackStyles = {
  root: {
    padding: 10,
  },
};
const statusTitleStyle: ILabelStyles = {
  root: {
    fontSize: 14,
    marginRight: 10,
  },
};
const titleStyle: ILabelStyles = {
  root: {
    fontSize: 14,
    cursor: "pointer",
  },
};
const contentTitleStyle: ILabelStyles = {
  root: {
    padding: 5,
    fontSize: 14,
  },
};
const contentContainer: React.CSSProperties = {
  backgroundColor: "#F0DB8E",
  borderRadius: 3,
  marginRight: 10,
};

interface UseCaseListItemProps {
  data: UseCase;
  index: number;
  length: number;
}

export const UseCaseListItem: FunctionComponent<UseCaseListItemProps> = ({
  data,
  index,
  length,
}) => {
  const navigate = useNavigate();
  return (
    <Stack>
      <Stack key={index}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={subContainer}
          onClick={() => navigate(`/cockpit/use-cases/${data.id}`)}
        >
          <Stack>
            <Label styles={titleStyle}>{data.title}</Label>
          </Stack>
          <Stack horizontal verticalAlign="center">
            <Label styles={statusTitleStyle}>{data.status}</Label>
            {data.main_domain?.title ? (
              <div style={contentContainer}>
                <Label styles={contentTitleStyle}>{data.main_domain?.title}</Label>
              </div>
            ) : null}
            <ValueToolTip value={data?.value} />
            <ProblemToolTip value={data?.problems} />
            <PersonaTooltip name={data?.owner} />
          </Stack>
        </Stack>
        {index !== length - 1 ? <span style={dividerLine} /> : null}
      </Stack>
    </Stack>
  );
};
