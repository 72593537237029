import { Stack, Text, Dropdown, IDropdownOption } from "@fluentui/react";
import { t } from "i18next";
import { RuleCandidate } from "../../../../types/rules";
import { useState } from "react";
import { NameAndDescriptionInput } from "../NameDescription";
import { RuleDetailModalFooter } from "../RuleDetailModalFooter";
import { MODAL_DETAIL_LEFT_MARGIN } from "./bodyStyles";
import { UploadDQCDataType } from "../../../../types/fileUploader";

export const InconsistentRuleDetailBody = ({
  rule,
  toggleModal,
}: {
  rule: RuleCandidate;
  toggleModal: () => void;
}) => {
  const [localRule, setLocalRule] = useState<RuleCandidate>(rule);
  if (localRule.qualityTest.testFunctionName !== "inconsistentDataType") return null;
  const localQualityTest = localRule.qualityTest;
  const allUploadDataTypes: UploadDQCDataType["type"][] = [
    "Boolean",
    "Integer",
    "Double",
    "String",
    "Empty",
    "Date",
    "Categorical",
  ];
  const options: IDropdownOption<UploadDQCDataType["type"]>[] = allUploadDataTypes.map(
    (dataType) => ({
      key: `${rule.name}::${dataType}`,
      text: dataType,
      data: dataType,
    })
  );
  return (
    <>
      <Stack tokens={{ childrenGap: 12 }}>
        <NameAndDescriptionInput rule={localRule} updateRule={setLocalRule} />
        <Stack tokens={{ childrenGap: 8 }}>
          <Stack horizontal>
            <Text styles={{ root: { width: MODAL_DETAIL_LEFT_MARGIN } }}>
              {t("expected_data_type")}
            </Text>
            <Dropdown
              options={options}
              onChange={(_, option) => {
                if (!option || !allUploadDataTypes.includes(option.data)) return;
                const newRule: RuleCandidate = {
                  ...localRule,
                  qualityTest: { ...localQualityTest, meta: { dataType: option.data } },
                };
                setLocalRule(newRule);
              }}
              defaultSelectedKey={`${rule.name}::${localQualityTest.meta.dataType}`}
              styles={{ root: { width: "90%" }, title: { borderRadius: 4 } }}
            />
          </Stack>
        </Stack>
      </Stack>
      <RuleDetailModalFooter rule={localRule} updateRule={setLocalRule} toggleModal={toggleModal} />
    </>
  );
};
