import {
  Stack,
  TooltipHost,
  DirectionalHint,
  StackItem,
  Slider,
  Checkbox,
  Text,
} from "@fluentui/react";
import { t } from "i18next";
import { RuleCandidate, DuplicateTest } from "../../../../types/rules";
import { defaultTooltipProps, sliderProps } from "../../settings/Settings.styles";
import { useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { selectLatestUpload } from "../../../../store/slices/upload";
import { NameAndDescriptionInput } from "../NameDescription";
import { RuleDetailModalFooter } from "../RuleDetailModalFooter";
import { checkBoxStyles } from "./bodyStyles";

export const DuplicateRuleDetailBody = ({
  rule,
  toggleModal,
}: {
  rule: RuleCandidate;
  toggleModal: () => void;
}) => {
  const [localRule, setLocalRule] = useState<RuleCandidate>(rule);
  const sliderOnChange = (value: number) => {
    const qualityTest: DuplicateTest = {
      ...(localRule.qualityTest as DuplicateTest),
      meta: { ...localRule.qualityTest.meta, fuzzinessThreshold: value / 100 },
    };
    const newRule = { ...localRule, qualityTest: qualityTest };
    setLocalRule(newRule);
  };
  const sliderValueFormat = (value: number) => `${value}%`;
  const uploadedFile = useAppSelector(selectLatestUpload);
  return (
    <>
      <Stack tokens={{ childrenGap: 12 }}>
        <NameAndDescriptionInput rule={localRule} updateRule={setLocalRule} />
        <Stack style={{ cursor: "default" }}>
          <TooltipHost
            key={`tooltip_duplicate`}
            content="% of characters that can be different for two rows to still be considered as duplicates"
            tooltipProps={defaultTooltipProps}
            directionalHint={DirectionalHint.bottomCenter}
          >
            <StackItem>{t("check_duplicates")}</StackItem>
          </TooltipHost>
          <Stack horizontal {...sliderProps}>
            <Stack style={{ fontSize: "12px" }}>{t("exact")}</Stack>
            <Slider
              styles={{ root: { marginTop: "14px", width: "80%" } }}
              key={"duplicate_slider"}
              max={20}
              min={0}
              showValue={false}
              onChange={sliderOnChange}
              defaultValue={(localRule.qualityTest as DuplicateTest).meta.fuzzinessThreshold * 100}
              valueFormat={sliderValueFormat}
            />
            <Stack style={{ fontSize: "12px" }}>{t("fuzzy")}</Stack>
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 4 }} styles={{ root: { marginLeft: "16%" } }}>
          <Text>{t("duplicate_columns")}</Text>
          {uploadedFile?.columns.map((column) => (
            <Checkbox
              styles={checkBoxStyles}
              label={column.name}
              onChange={(_, isChecked?: boolean) => {
                if (!isChecked) {
                  const newColumns = localRule.columns.filter((c) => c.index !== column.index);
                  const newRule = { ...localRule, columns: newColumns };
                  setLocalRule(newRule);
                } else {
                  const newColumns = [...localRule.columns, column];
                  const newRule = { ...localRule, columns: newColumns };
                  setLocalRule(newRule);
                }
              }}
              key={`${rule.name}::${column.name}::checkbox`}
              checked={localRule.columns.map((c) => c.index).includes(column.index)}
            />
          ))}
        </Stack>
      </Stack>
      <RuleDetailModalFooter rule={localRule} updateRule={setLocalRule} toggleModal={toggleModal} />
    </>
  );
};
