import { AuthenticationResult, EventMessage, EventType, AuthError } from "@azure/msal-browser";
import { IMsalContext } from "@azure/msal-react";
import { PayloadAction } from "@reduxjs/toolkit";
import React from "react";
import {
  EmbedState,
  setMsGraphApiAccessToken,
  setEmbeddingError,
  setEmbedState,
  setUsername,
} from "../../store/slices/remarkSlice";

export const auth = (
  ctx: IMsalContext,
  powerBiApiUrl: string,
  reportId: string,
  scopeBase: string[],
  embedState: EmbedState,
  dispatch: React.Dispatch<React.SetStateAction<PayloadAction<string | undefined | EmbedState>>>
) => {
  const msalInstance = ctx.instance;
  const msalAccounts = ctx.accounts;
  const msalInProgress = ctx.inProgress;
  const isAuthenticated = ctx.accounts.length > 0;

  const eventCallback = msalInstance.addEventCallback((message: EventMessage) => {
    if (message.eventType === EventType.LOGIN_SUCCESS && !embedState?.accessToken) {
      const payload = message.payload as AuthenticationResult;
      tryRefreshUserPermissions(powerBiApiUrl, payload.accessToken);
    }
  });

  const loginRequest = {
    scopes: scopeBase,
    account: msalAccounts[0],
  };

  if (!isAuthenticated && msalInProgress === "none") {
    msalInstance.loginRedirect(loginRequest);
  } else if (isAuthenticated && embedState?.accessToken && !embedState.embedUrl) {
    getembedUrl(powerBiApiUrl, reportId, embedState.accessToken, dispatch);
    msalInstance.removeEventCallback(eventCallback!);
  } else if (
    isAuthenticated &&
    !embedState.accessToken &&
    !embedState.embedUrl &&
    msalInProgress === "none"
  ) {
    setUsername(msalAccounts[0].name!);

    // get access token silently from cached id-token
    // NOTE: This token is only for reading user's basic info
    // i.e. to display their names and profile pictures
    msalInstance
      .acquireTokenSilent({
        scopes: ["User.ReadBasic.All"],
        account: msalAccounts[0],
      })
      .then((response: AuthenticationResult) => {
        dispatch(setMsGraphApiAccessToken(response.accessToken));
      })
      .catch((err: AuthError) => {
        msalInstance.acquireTokenRedirect({
          scopes: ["User.ReadBasic.All"],
          account: msalAccounts[0],
        });
      });

    // NOTE: this accesstoken is for creating the embedding
    msalInstance
      .acquireTokenSilent(loginRequest)
      .then((response: AuthenticationResult) => {
        getembedUrl(powerBiApiUrl, reportId, response.accessToken, dispatch);
      })
      .catch((error: AuthError) => {
        // Refresh access token silently from cached id-token
        // Makes the call to handleredirectcallback
        if (
          error.errorCode === "consent_required" ||
          error.errorCode === "interaction_required" ||
          error.errorCode === "login_required"
        ) {
          msalInstance.acquireTokenRedirect(loginRequest);
        } else if (error.errorCode === "429") {
          dispatch(
            setEmbeddingError(
              "Our Service Token Server (STS) is overloaded, please try again in sometime"
            )
          );
        } else {
          dispatch(
            setEmbeddingError("There was some problem fetching the access token" + error.toString())
          );
        }
      });
  }
};

// Power BI REST API call to refresh User Permissions in Power BI
// Refreshes user permissions and makes sure the user permissions are fully updated
// https://docs.microsoft.com/rest/api/power-bi/users/refreshuserpermissions
const tryRefreshUserPermissions = (powerBiApiUrl: string, accessToken: string) => {
  fetch(powerBiApiUrl + "v1.0/myorg/RefreshUserPermissions", {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
    method: "POST",
  })
    .then(function (response) {
      if (response.ok) {
        // pass do nothing
      } else {
        // Too many requests in one hour will cause the API to fail
        if (response.status === 429) {
          console.error("Permissions refresh will be available in up to an hour.");
        } else {
          console.error(response);
        }
      }
    })
    .catch(function (error) {
      console.error("Failure in making API call." + error);
    });
};

const getembedUrl = (
  powerBiApiUrl: string,
  reportId: string,
  accessToken: string,
  dispatch: React.Dispatch<React.SetStateAction<PayloadAction<string | undefined | EmbedState>>>
) => {
  fetch(powerBiApiUrl + "v1.0/myorg/reports/" + reportId, {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
    method: "GET",
  })
    .then(function (response) {
      const errorMessage: string[] = [];
      errorMessage.push("Error occurred while fetching the embed URL of the report");
      errorMessage.push("Request Id: " + response.headers.get("requestId"));

      response
        .json()
        .then((body) => {
          // Successful response
          if (response.ok) {
            const embedUrl = body["embedUrl"];
            const name = body["name"];
            const id = body["id"];
            dispatch(
              setEmbedState({
                accessToken: accessToken,
                embedUrl: embedUrl,
                reportName: name,
                reportId: id,
              })
            );
          }
          // If error message is available
          else {
            // TODO simplify here to only dispatch the error message
            errorMessage.push("Error " + response.status + ": " + body.error.code);
            dispatch(setEmbeddingError(errorMessage.join("\n")));
          }
        })
        .catch(() => {
          errorMessage.push("Error " + response.status + ":  An error has occurred");
          dispatch(setEmbeddingError(errorMessage.join("\n")));
        });
    })
    .catch((error) => {
      // Error in making the API call
      dispatch(setEmbeddingError(error));
    });
};
