import {
  DefaultButton,
  IRenderFunction,
  ITextFieldProps,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Stack,
  TextField,
  Toggle,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { t } from "i18next";
import { FunctionComponent } from "react";
import { useDeleteMyProfileMutation } from "../../../api/profile";
import { ProfileSettingsData, Session } from "../../../types/auth";
import { ProfileComponentsProps } from "./profile-head";

type ProfileAboutProps = ProfileComponentsProps & {
  session?: Session;
  settingsData?: ProfileSettingsData;
};

export const ProfileAbout: FunctionComponent<ProfileAboutProps> = ({
  profile,
  editable,
  session,
  settingsData,
  setProfile,
  styles,
}) => {
  const [showFields, { toggle: toggleShowFields }] = useBoolean(false);
  const [deleteTrigger] = useDeleteMyProfileMutation();

  if (!profile || !session) return <></>;

  const showPW = session.authentication_methods?.filter((am) => am.method !== "oidc").length === 1;
  const customLabel: IRenderFunction<ITextFieldProps> = (props) => (
    <span id={props?.id} className="ms-ChoiceFieldLabel" style={{ minWidth: 140, lineHeight: 2.5 }}>
      {props?.label}
    </span>
  );

  if (editable && (!settingsData || !settingsData.ui))
    return <Spinner label={t("loading")} ariaLive={t("loading")} labelPosition="right" />;

  return (
    <Stack styles={styles}>
      <h3>{t("about")}</h3>
      <form action={settingsData?.ui.action} method={settingsData?.ui.method}>
        <input
          title={settingsData?.ui.nodes[0].attributes.name}
          type={"hidden"}
          name={settingsData?.ui.nodes[0].attributes.name}
          value={settingsData?.ui.nodes[0].attributes.value}
        ></input>
        {/* --- Email --- */}
        <TextField
          underlined
          readOnly={true}
          label={t("email")}
          name={settingsData?.ui.nodes[1].attributes.name}
          value={profile.email}
          onRenderLabel={customLabel}
        />
        {settingsData?.ui.nodes[1].messages?.length ? (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {settingsData?.ui.nodes[1].messages.map((m) => m.text).join(";")}
          </MessageBar>
        ) : (
          <></>
        )}

        {/* --- Firstname --- */}
        <TextField
          underlined
          readOnly={!editable}
          disabled={!editable}
          label={t("firstname")}
          name={settingsData?.ui.nodes[2].attributes.name}
          value={profile.firstname}
          onRenderLabel={customLabel}
          onChange={(_e, newValue?: string) => {
            if (setProfile) setProfile({ ...profile, firstname: newValue || "" });
          }}
        />
        {settingsData?.ui.nodes[2].messages?.length ? (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {settingsData?.ui.nodes[2].messages.map((m) => m.text).join(";")}
          </MessageBar>
        ) : (
          <></>
        )}

        {/* --- Lasstname --- */}
        <TextField
          underlined
          readOnly={!editable}
          disabled={!editable}
          label={t("lastname")}
          name={settingsData?.ui.nodes[3].attributes.name}
          value={profile.lastname}
          onChange={(_e, newValue?: string) => {
            if (setProfile) setProfile({ ...profile, lastname: newValue || "" });
          }}
          onRenderLabel={customLabel}
        />
        {settingsData?.ui.nodes[3].messages?.length ? (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {settingsData?.ui.nodes[3].messages.map((m) => m.text).join(";")}
          </MessageBar>
        ) : (
          <></>
        )}

        {showPW && (
          <>
            {/* Password field */}
            {!editable ? (
              <>
                <TextField
                  underlined
                  onRenderLabel={customLabel}
                  label={t("password")}
                  type="password"
                  disabled={!editable}
                  value={"********"}
                />
              </>
            ) : (
              <>
                <Toggle
                  label={t("edit-password")}
                  inlineLabel
                  checked={showFields}
                  onChange={toggleShowFields}
                  styles={{ root: { justifyContent: "flex-end", marginTop: 10 } }}
                />
                <TextField
                  underlined
                  onRenderLabel={customLabel}
                  label={t("password")}
                  type="password"
                  disabled={!showFields}
                  name={settingsData?.ui.nodes[5].attributes.name}
                  value={!showFields ? "*******" : undefined}
                  placeholder={t("new_password")}
                  canRevealPassword
                  revealPasswordAriaLabel={t("show_password")}
                />
                {settingsData?.ui.nodes[5].messages?.length ? (
                  <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                    {settingsData?.ui.nodes[5].messages.map((m) => m.text).join(";")}
                  </MessageBar>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}

        {editable && (
          <Stack styles={{ root: { width: 200 } }}>
            <PrimaryButton
              text={t("save")}
              styles={{ root: { marginTop: 10, marginBottom: 10 } }}
              type="submit"
              onClick={() => {}}
              value={settingsData?.ui.nodes[showFields ? 6 : 4].attributes.value}
              name={settingsData?.ui.nodes[showFields ? 6 : 4].attributes.name}
            />

            <DefaultButton
              text={t("delete_profile")}
              onClick={() => {
                const confirm = window.confirm(t("profile_delete_account_confirm"));
                if (confirm) deleteTrigger({});
              }}
              title={t("profile_delete_account")}
            />
          </Stack>
        )}
      </form>
    </Stack>
  );
};
