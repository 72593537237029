import { Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { createRef, FunctionComponent, LegacyRef, useEffect, useState } from "react";
import useResize from "../../helpers/resizer";
import { ColabError } from "../../components/colab/colabError";
import { useGetRemarksQuery } from "../../api/remark";
import { AddRemark } from "../../components/colab/insert-remark";
import { RemarksList } from "../../components/colab/remark-list";
import { sortRemarks } from "../../helpers/remarkUtils";
import { Remark, RemarksSortKey, ReportManager } from "../../types/colab";
import { useAppSelector } from "../../store/hooks";
import {
  selectEditRemarkId,
  selectEmbeddingError,
  selectMsGraphApiAccessToken,
} from "../../store/slices/remarkSlice";
import jwt_decode from "jwt-decode";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { SidebarHeader } from "../../components/colab/sidebar-header";
import { SidebarCommandBar } from "../../components/colab/sidebar-command-bar";
import { t } from "i18next";
import { Welcome } from "../../components/colab/welcome";
import { navigationPaths, TabBar } from "../../components/colab/tab-bar";
import { ComingSoon } from "../../components/colab/coming-soon";
import { Glossary } from "../../components/colab/glossary";
import { PowerBIEmbedding } from "../../components/colab/powerbi-embedding";

const COLLAPSED_SIDEBAR_WIDTH = 28;

export const ColabCore: FunctionComponent = () => {
  const [reportManager, setReportManager] = useState<ReportManager | undefined>();

  const editRemarkId = useAppSelector(selectEditRemarkId);
  const embeddingError = useAppSelector(selectEmbeddingError);

  const [addRemarkView, { toggle: toggleShowAddRemark }] = useBoolean(false);
  const [sidebarHidden, { toggle: toggleSidebar }] = useBoolean(false);
  // FIXME don't use this accessToken to get the userID
  const accessToken = useAppSelector(selectMsGraphApiAccessToken);
  const decoded: Record<string, string> = accessToken ? jwt_decode(accessToken) : {};
  const { data, isSuccess } = useGetRemarksQuery(
    accessToken ? { userId: decoded["oid"] } : skipToken
  );

  const remarks = data ? [...data] : [];
  const [sortKey, setSortKey] = useState<RemarksSortKey | undefined>();
  const [filterStatus, setFilterStatus] = useState<Remark["status"] | undefined>();
  const [currentTab, setCurrentTab] = useState<navigationPaths>("remarks");

  const sortedRemarks = sortRemarks(remarks, sortKey);
  const solvedRemarks = sortedRemarks.filter((remark) => remark.status === "SOLVED");
  const openRemarks = sortedRemarks.filter((remark) => remark.status === "OPEN");
  const { width, enableResize, isResizing } = useResize({
    minWidth: 360,
  });
  const reportRef: LegacyRef<HTMLDivElement> | undefined = createRef();
  useEffect(() => {
    if (reportManager?.reportName === "" || reportManager?.reportName === undefined) return;

    const lastReports = localStorage.getItem("lastReports");
    let reports: any = {};
    if (lastReports) {
      reports = JSON.parse(lastReports);
    }
    reports[reportManager?.reportName] = {
      date: new Date(),
      id: reportManager?.reportId!,
    };
    const jsonBody = JSON.stringify(reports);
    localStorage.setItem("lastReports", jsonBody);
  }, [reportManager]);

  if (embeddingError) {
    return <ColabError error={embeddingError} />;
  }

  return (
    <>
      <PowerBIEmbedding reportRef={reportRef} setReportManager={setReportManager} />
      <Stack
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          right: `${!sidebarHidden ? width : COLLAPSED_SIDEBAR_WIDTH}px`,
          width: `calc(100vw - ${width}px)`,
          height: "100vh",
        }}
      >
        <div
          id="reportContainer"
          ref={reportRef}
          style={{
            width: `calc(100vw - ${!sidebarHidden ? width : COLLAPSED_SIDEBAR_WIDTH}px)`,
            height: "99vh",
            border: "none",
          }}
        >
          Loading the report
        </div>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: `calc(100vw - ${!sidebarHidden ? width : COLLAPSED_SIDEBAR_WIDTH}px)`,
            height: "99vh",
            border: "none",
            display: !isResizing ? "none" : "block",
            zIndex: 999,
            backgroundColor: "rgba(255,255,255,0.5)",
          }}
        ></div>
      </Stack>
      <Stack
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          width: `${!sidebarHidden ? width : COLLAPSED_SIDEBAR_WIDTH}px`,
          height: "100vh",
          backgroundColor: "#fff",
        }}
      >
        {!sidebarHidden ? (
          <>
            <SidebarHeader toggleSidebar={toggleSidebar} />
            <TabBar currentTab={currentTab} setCurrentTab={setCurrentTab} />
            {currentTab === "remarks" && (
              <Stack>
                <SidebarCommandBar
                  disabled={!remarks.length}
                  filterStatus={filterStatus}
                  setFilterStatus={setFilterStatus}
                  sortKey={sortKey}
                  setSortKey={setSortKey}
                  toggleShowAddRemark={toggleShowAddRemark}
                />
                {isSuccess && !remarks.length && !addRemarkView ? (
                  <Welcome />
                ) : (
                  <Stack.Item styles={{ root: { paddingLeft: 10, paddingRight: 10 } }}>
                    {addRemarkView && !editRemarkId && reportManager && (
                      <AddRemark
                        reportManager={reportManager}
                        toggleShowAddRemark={toggleShowAddRemark}
                      />
                    )}

                    {remarks?.length === 0 ? (
                      <p>
                        Select a value or visual from the report. <br />
                        <u>Add your first remark</u> to start collaborating with your colleagues.{" "}
                        <br />
                      </p>
                    ) : (
                      <div style={{ height: "84vh", overflow: "auto" }}>
                        {filterStatus !== "SOLVED" && (
                          <RemarksList
                            remarks={openRemarks}
                            reportManager={reportManager}
                            dimension={t("open")}
                          />
                        )}
                        {filterStatus !== "OPEN" && (
                          <RemarksList
                            remarks={solvedRemarks}
                            reportManager={reportManager}
                            dimension={t("resolved")}
                          />
                        )}
                      </div>
                    )}
                  </Stack.Item>
                )}
              </Stack>
            )}
            {currentTab === "glossary" && <Glossary />}
            {currentTab !== "remarks" && currentTab !== "glossary" && <ComingSoon />}
          </>
        ) : (
          <div className="dqcsidebarlogo" onClick={toggleSidebar}>
            DQC
          </div>
        )}
        <div
          className={`embeddingSidebarResizer-${
            !isResizing ? (sidebarHidden ? "collapsed" : "normal") : "resizing"
          }`}
          onMouseDown={!sidebarHidden ? enableResize : () => {}}
          onClick={() => {
            if (sidebarHidden) toggleSidebar();
          }}
        ></div>
      </Stack>
    </>
  );
};
