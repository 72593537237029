import * as React from "react";

import { Bar, BarDatum } from "@nivo/bar";
import {
  DataQualityScoresOverTime,
  QualityOverview,
  QualityPerformanceData,
} from "../../types/performance";
import {
  QualityDimension,
  QualityDimensionKeys,
  QualityDimensionAggregate,
  QualityScore,
} from "../../types/quality";
import { Separator, Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import {
  useGetDataQualityScoresQuery,
  useGetQualityFactsAndFiguresQuery,
  useGetQualityOfDatasetsQuery,
  useGetQualityScoresPerDimensionQuery,
} from "../../api/performance";

import { QualityGauge } from "../../components/common/QualityGauge";
import { QualityItem } from "../../components/mission/quality/QualityItem";
import { TopContainer } from "../../components/common/TopContainer";
import { colorForQualityValue } from "../../helpers/commonStyles";
import { useTranslation } from "react-i18next";

export const Quality: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [qualityScore, setQualityScore] = useState<QualityPerformanceData>({
    quality_score: 5,
    nr_datasets: null,
    changed_score: null,
    changed_score_percentage: null,
    start_date: null,
  });
  const [performance, setPerformance] = useState<QualityOverview>({
    facts_and_figures: null,
    dimensions_over_time: null,
    datasets_over_time: null,
    performance_data: null,
  });
  const [qualityScores, setQualityScores] = useState<DataQualityScoresOverTime>({ data: [] });
  const [qualityData, setQualityData] = useState<BarDatum[]>([]);
  const getQualityFactsAndFigures = useGetQualityFactsAndFiguresQuery("");
  const getDataQualityScores = useGetDataQualityScoresQuery();
  const getQualityOfDatasets = useGetQualityOfDatasetsQuery("");
  const getQualityScoresPerDimension = useGetQualityScoresPerDimensionQuery("");
  const commonProps = {
    width: 600,
    height: 300,
    margin: { top: 60, right: 110, bottom: 60, left: 80 },
    data: qualityData,
    indexBy: "month",
    keys: ["A", "B", "C", "D", "E"],
    padding: 0.2,
    labelTextColor: "inherit:darker(1.4)",
    labelSkipWidth: 16,
    labelSkipHeight: 16,
  };

  useEffect(() => {
    if (!getQualityFactsAndFigures.isLoading && getQualityFactsAndFigures.isSuccess) {
      performance.facts_and_figures = getQualityFactsAndFigures.data;
      setPerformance(performance);
    }
    if (!getDataQualityScores.isLoading && getDataQualityScores.isSuccess) {
      setQualityScore(getDataQualityScores.data);
    }
    if (!getQualityOfDatasets.isLoading && getQualityOfDatasets.isSuccess) {
      let _data: BarDatum[] = getQualityOfDatasets.data.data_set_labels.map(
        (label: string, index: number) => {
          let returnValue: BarDatum = {};
          (Object.keys(QualityScore) as Array<keyof typeof QualityScore>).forEach((key) => {
            if (isNaN(Number(key) - 1)) return;
            returnValue[QualityScore[key]] = getQualityOfDatasets.data.data[Number(key) - 1][index];
            const colorKey: string = `${QualityScore[key]}Color`;
            returnValue[colorKey] = colorForQualityValue.get(Number(key)) || "#333";
          });
          returnValue.month = label;
          return returnValue;
        }
      );
      setQualityData(_data);
    }
    if (!getQualityScoresPerDimension.isLoading && getQualityScoresPerDimension.isSuccess) {
      setQualityScores(getQualityScoresPerDimension.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getDataQualityScores,
    getQualityFactsAndFigures,
    getQualityOfDatasets,
    getQualityScoresPerDimension,
  ]);

  const scores: Map<typeof QualityDimension[QualityDimensionKeys], QualityDimensionAggregate> =
    new Map<typeof QualityDimension[QualityDimensionKeys], QualityDimensionAggregate>();
  qualityScores?.data.forEach((dim) => {
    scores.set(dim.quality_dimension as typeof QualityDimension[QualityDimensionKeys], {
      quality_score: dim.quality_score,
      count: dim.count,
      dataset_details: [],
    });
  });

  return (
    <TopContainer title={t("quality")}>
      <p>
        {t("quality_dashboard_highlights")} {new Date().toLocaleString()}.
      </p>
      <h3>{t("facts_figures")}</h3>
      <Stack horizontal>
        <Stack.Item>
          <ul>
            <li>
              {performance?.facts_and_figures?.currently_open_data_issues} {t("issues_open")}
            </li>
            <li>
              {performance?.facts_and_figures?.overall_data_issues_corrected}{" "}
              {t("issues_corrected")}
            </li>
            <li>
              {qualityScore?.nr_datasets} {t("datasets_connected")}
            </li>
          </ul>
        </Stack.Item>
        <Stack.Item>
          <QualityGauge qualityScore={qualityScore?.quality_score} width="222" height="100" />
        </Stack.Item>
      </Stack>

      <Separator />
      <h3>{t("quality_over_time")}</h3>
      <div>
        {/** has to become a bar chart again! We want to get the number of data sets per dimension as well! */}
        <Bar {...commonProps} colors={({ id, data }) => String(data[`${id}Color`])} />
      </div>
      <Separator />
      <h3>{t("q_scores")}</h3>
      <div>
        {qualityScores?.data.map((dim, index) => (
          <QualityItem
            dimensionValue={dim.quality_dimension as typeof QualityDimension[QualityDimensionKeys]}
            index={index}
            scores={scores}
            missionId=""
            datasets={[
              { id: "1", name: "asd", urn: "", origin: "", created_at: "", updated_at: "" },
            ]}
            key={`qualitykey_${index}`}
            status={"done"}
          />
        ))}
      </div>
    </TopContainer>
  );
};
