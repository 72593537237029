import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import { FunctionComponent } from "react";
import { ConfirmationDialogProps } from "../../types/missions";

export const ConfirmationDialog: FunctionComponent<ConfirmationDialogProps> = ({
  showHideDialog,
  toggleShowHideDialog,
  primaryButtonClicked,
  primaryButtonText,
  secondaryButtonText,
  dialogTitle,
  confirmationText,
}) => {
  const dialogContentProps = {
    type: DialogType.normal,
    title: dialogTitle,
    subText: confirmationText,
  };

  const modalProps = {
    isBlocking: true,
  };

  return (
    <>
      <Dialog
        hidden={showHideDialog}
        onDismiss={toggleShowHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
        minWidth={500}
      >
        <DialogFooter>
          <PrimaryButton onClick={primaryButtonClicked} text={primaryButtonText} />
          <DefaultButton onClick={toggleShowHideDialog} text={secondaryButtonText} />
        </DialogFooter>
      </Dialog>
    </>
  );
};
