import { FunctionComponent } from "react";
import { Dataset } from "../../../types/dataset";
import { DatasetQualityDetail } from "../../../types/quality";
import { Label } from "@fluentui/react";
import { getDatasetName } from "../../../helpers/mission-helper";
import { useNavigate } from "react-router-dom";
import { getBarColorForDataset } from "../../../helpers/utils";

export interface QualityBarState {
  datasetDetails?: DatasetQualityDetail[];
  qualityDimension?: string;
  missionId?: string;
  datasets?: Dataset[];
}

export const QualityBar: FunctionComponent<QualityBarState> = ({
  datasetDetails,
  qualityDimension,
  missionId,
  datasets,
}) => {
  let navigate = useNavigate();
  if (!datasets) return null;
  return (
    <>
      {datasetDetails?.map((data, index) => (
        <Label
          key={index}
          className="bar-item font-smaller pointer tooltip"
          style={{
            backgroundColor: getBarColorForDataset(data.id, datasets),
          }}
          onClick={() => {
            navigate(`/missions/${missionId}/dataset/${data.id}/issues/${qualityDimension}`);
          }}
        >
          {data.count}
          <div className="bottom">
            <span>{getDatasetName(datasets ? datasets : [], data.id)}</span>
            <i />
          </div>
        </Label>
      ))}
    </>
  );
};
