import { FunctionComponent } from "react";
import { DefaultButton, IIconProps, IStackStyles, Label, Stack } from "@fluentui/react";
import { datasetQualityHeader, topHeading } from "../../../helpers/commonStyles";
import { useTranslation } from "react-i18next";

const addDatasetBtnBoxStyles: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
  },
};

export interface DatasetHeaderProps {
  setModalState(state: boolean): void;
}
export const DatasetHeader: FunctionComponent<DatasetHeaderProps> = ({ setModalState }) => {
  const { t } = useTranslation();
  const addIcon: IIconProps = { iconName: "Add" };

  return (
    <Stack styles={datasetQualityHeader}>
      <Stack>
        <Label styles={topHeading}>{t("datasets")}</Label>
      </Stack>
      <Stack styles={addDatasetBtnBoxStyles}>
        <DefaultButton
          text={t("add_dataset")}
          iconProps={addIcon}
          onClick={() => setModalState(true)}
        />
      </Stack>
    </Stack>
  );
};
