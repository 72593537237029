import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  SelectableOptionMenuItemType,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetAllUseCasesQuery } from "../../api/cockpit";
import { comboBoxStyles } from "../../helpers/commonStyles";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectDomainIds, setDomainIds } from "../../store/slices/cockpitSlice";

export const DomainFilter: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const useCasesQuery = useGetAllUseCasesQuery();
  const allUseCases = useCasesQuery.data || [];
  const domainIds = useAppSelector(selectDomainIds);
  const dispatch = useAppDispatch();

  const domainOptions: IComboBoxOption[] = allUseCases
    .filter((u) => u.main_domain?.id && u.main_domain?.title)
    .map((useCase) => {
      return { key: useCase.main_domain?.id || "", text: useCase.main_domain?.title || "" };
    });

  const options = [
    {
      key: "all",
      text: t("all"),
      itemType: SelectableOptionMenuItemType.SelectAll,
      styles: { root: { width: 200 } },
    },
    ...domainOptions,
  ];

  const handleChangeDomainOptions = (
    _event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ): void => {
    if (!option) return;
    const isAllSelected = options.length === domainIds?.length;
    const selected = option?.selected;

    if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
      isAllSelected
        ? dispatch(setDomainIds(undefined))
        : dispatch(setDomainIds(options.map((o) => o.key as string)));
    } else {
      const updatedDomainIds = selected
        ? [...(domainIds || []), option.key as string]
        : domainIds?.filter((k) => k !== option.key);

      updatedDomainIds?.length
        ? dispatch(setDomainIds(updatedDomainIds.filter((id) => id !== "all")))
        : dispatch(setDomainIds(undefined));
    }
  };
  return (
    <ComboBox
      text={t("domain-filter")}
      multiSelect
      options={options}
      selectedKey={domainIds || []}
      onChange={handleChangeDomainOptions}
      styles={comboBoxStyles}
    />
  );
};
