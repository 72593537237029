import { PrimaryButton, Separator, Stack, Text, Toggle } from "@fluentui/react";
import { t } from "i18next";
import { RuleCandidate } from "../../../types/rules";
import { setRule } from "../../../store/slices/rules";
import { useAppDispatch } from "../../../store/hooks";
import { getPillStyles } from "./modalStyles";
import { MODAL_DETAIL_LEFT_MARGIN } from "./detail-bodies/bodyStyles";
import { useUpdateRuleMutation } from "../../../api/v2/rules";
import { isQuickAnalysisRule } from "../../../utils/rules";

export const RuleDetailModalFooter = ({
  rule,
  updateRule,
  toggleModal,
}: {
  rule: RuleCandidate;
  updateRule: (rule: RuleCandidate) => void;
  toggleModal: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [updateRuleQuery] = useUpdateRuleMutation();
  return (
    <Stack>
      <Separator styles={{ root: { marginBottom: -4 } }} />
      <Stack
        horizontal
        horizontalAlign="end"
        styles={{
          root: {
            justifyContent: "space-between",
            marginLeft: MODAL_DETAIL_LEFT_MARGIN,
            alignItems: "center",
          },
        }}
      >
        <Stack
          horizontal
          tokens={{ childrenGap: 8 }}
          styles={{ root: { alignItems: "self-start" } }}
        >
          <Toggle
            styles={getPillStyles(rule.severity)}
            checked={rule.severity === "warning"}
            onChange={(_event, checked?: boolean | undefined) => {
              if (checked === undefined) return;
              updateRule({ ...rule, severity: checked ? "warning" : "info" });
            }}
          />
          <Text styles={{ root: { textDecoration: "underline" } }}>
            {rule.severity === "warning" ? t("warning_toggle_text") : t("info_toggle_text")}
          </Text>
        </Stack>
        <PrimaryButton
          styles={{ root: { borderRadius: 4, width: "24%" } }}
          onClick={async () => {
            try {
              if (isQuickAnalysisRule(rule)) dispatch(setRule(rule));
              else await updateRuleQuery(rule);
            } catch (e) {
              console.log(e);
            } finally {
              toggleModal();
            }
          }}
          text={t("save")}
        />
      </Stack>
    </Stack >
  );
};
