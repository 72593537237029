import {
  ILabelStyles,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Stack,
} from "@fluentui/react";

import { FunctionComponent } from "react";
import { MissionQuality } from "../../common/MissionQualityBadge";
import { QualityScore, QualityScoreKeys } from "../../../types/quality";
import { deselectDatasets } from "../../../store/slices/missionSlice";
import { useAppDispatch } from "../../../store/hooks";

const innerMainContentBox: IStackStyles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 12px",
    height: "auto",
    backgroundColor: "#2D3648 ",
    borderRadius: "4px",
    marginBottom: "3%",
  },
};

const stackItemStyles1: IStackItemStyles = {
  root: {
    color: "#FFFFFF",
    paddingLeft: "1%",
  },
};

const stackItemStyles2: IStackItemStyles = {
  root: {
    display: "flex",
    justifyContent: "flex-end",
  },
};

const datasetMainLabel: ILabelStyles = {
  root: {
    color: "#FFFFFF",
    fontWeight: 400,
    fontSize: "16px",
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};

export interface DatasetSubHeaderProps {
  qualityStatus?: "done" | "loading_data" | "analyzing_data" | "writing_results" | "failed";
  combinedQuality?: typeof QualityScore[QualityScoreKeys] | number;
  missionTitle?: string;
}

export const DatasetSubHeader: FunctionComponent<DatasetSubHeaderProps> = ({
  qualityStatus,
  combinedQuality,
  missionTitle,
}) => {
  const dispatch = useAppDispatch();
  return (
    <Stack
      horizontal
      tokens={stackTokens}
      styles={innerMainContentBox}
      onClick={() => dispatch(deselectDatasets())}
    >
      <Stack.Item grow={1} styles={stackItemStyles1}>
        <Label styles={datasetMainLabel}>{missionTitle}</Label>
      </Stack.Item>
      <Stack.Item grow={1} styles={stackItemStyles2}>
        <MissionQuality value={combinedQuality} status={qualityStatus}></MissionQuality>
      </Stack.Item>
    </Stack>
  );
};
