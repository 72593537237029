import { DuplicateIssue, QuickAnalysisIssue } from "../types/quickAnalysis";

/**
 * Sorts issues by their address field first by column then by row
 * @param a
 * @param b
 * @returns -1 or +1 depending on which issue is further top left
 */
export const sortIssueByAddress = (a: QuickAnalysisIssue, b: QuickAnalysisIssue): number => {
  const isNotSortDuplicates = a.type !== "duplicate" && b.type !== "duplicate";
  const rowDifference = a.row - b.row;
  const columnDifference = isNotSortDuplicates ? a.column - b.column : 0;
  return columnDifference === 0 ? rowDifference : columnDifference;
};

/**
 * Sorts two DuplicateIssues based on its groupID and address
 * @param issueA
 * @param issueB
 * @returns
 */
const sortDuplicateIssues = (issueA: DuplicateIssue, issueB: DuplicateIssue) => {
  if (issueA.groupId === issueB.groupId)
    return sortIssueByAddress(issueA as QuickAnalysisIssue, issueB as QuickAnalysisIssue);
  return issueA.groupId - issueB.groupId;
};

/**
 * Takes a list of issues and then returns the issues sorted by address
 * @param issues
 * @param dimension
 * @param sortedCustomChecks
 * @param isHorizontal
 * @returns
 */
export const sortIssuesByDimension = (issues: QuickAnalysisIssue[]): QuickAnalysisIssue[] => {
  return issues.sort((issueA, issueB) => {
    // duplicates are sorted by groupId then address
    if (issueA.type === "duplicate" && issueB.type === "duplicate")
      return sortDuplicateIssues(issueA, issueB);
    else return sortIssueByAddress(issueA, issueB);
  });
};
