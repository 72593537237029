import { Connection } from "../types/connection";
import { InstallationAttributes } from "../types/installation";
import { api_base, dqcApi } from "./dqcQuery";

export const installationApi = dqcApi.injectEndpoints({
  endpoints: (builder) => ({
    getInstallations: builder.query<InstallationAttributes | any, void>({
      query: () => ({ url: `/${api_base}/installations/`, method: "get" }),
      providesTags: ["Installation"],
      extraOptions: { maxRetries: 1 },
    }),
    updateConnection: builder.mutation({
      query: (connectionObj: {
        connection_type: string;
        connection: {
          bf_id: number;
          activated: boolean;
          type: string;
          bf_connector_id: string;
          api_settings: object;
        };
      }) => ({
        url: `/${api_base}/configurations/connections`,
        method: "PUT",
        data: connectionObj,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["Installation", "Connectors"],
    }),
    updatePlugin: builder.mutation({
      query: (pluginObj: { bf_id: number; activated: boolean }) => ({
        url: `/${api_base}/configurations/plugins`,
        method: "PUT",
        data: pluginObj,
      }),
      invalidatesTags: ["Installation"],
    }),
    getAllConfigurations: builder.query({
      query: () => ({ url: `/${api_base}/configurations/`, method: "get" }),
      providesTags: ["Installation"],
    }),
    getDatasourceConnections: builder.query<Connection[], {}>({
      query: () => ({
        url: `/${api_base}/configurations/datasource-connections`,
        method: "get",
      }),
      providesTags: ["Connectors"],
    }),
    getMessagingConnections: builder.query<Connection[], {}>({
      query: () => ({
        url: `/${api_base}/configurations/messaging-connections`,
        method: "get",
      }),
      providesTags: ["Connectors"],
    }),
    getIdentityConnections: builder.query<Connection[], {}>({
      query: () => ({
        url: `/${api_base}/configurations/identity-connections`,
        method: "get",
      }),
      providesTags: ["Connectors"],
    }),
  }),
});

export const {
  useGetInstallationsQuery,
  useUpdateConnectionMutation,
  useUpdatePluginMutation,
  useGetAllConfigurationsQuery,
  useGetDatasourceConnectionsQuery,
  useGetMessagingConnectionsQuery,
  useGetIdentityConnectionsQuery,
} = installationApi;
