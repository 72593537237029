import "./index.css";
import { App } from "./App";
import { mergeStyles } from "@fluentui/react";
import { render } from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Provider } from "react-redux";
import store from "./store/store";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./i18n";
//Sentry

if ((process.env.REACT_APP_ENV || "").match(/production|staging/)) {
  Sentry.init({
    dsn: "https://7cc5466ccb7d4bca97492c34cfaacce8@o1339608.ingest.sentry.io/6611737",
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_GIT_SHA || "localdevelopment",
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
// Inject some global styles
mergeStyles({
  ":global(body,html,#root)": {
    margin: 0,
    padding: 0,
    height: "100vh",
  },
});
const rootElement = document.getElementById("root");
render(
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <App />
    </Provider>
  </DndProvider>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
