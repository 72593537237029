import "./App.css";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Spinner, Stack, ThemeProvider, registerIcons } from "@fluentui/react";
import React, { useEffect } from "react";

import { getFlowIdOrAcquire, useWhoAmIQuery, useGetMyProfileQuery } from "./api/profile";
import { AllMissions } from "./routes/mission/all-missions";
import { Surveys } from "./routes/surveys/surveys";
import { BulkUpload } from "./routes/upload/BulkUpload";
import { Cockpit } from "./routes/cockpit/cockpit";
import { TenantSettings } from "./routes/company-info/company-info";
import { Connections } from "./routes/connections/connection";
import { ErrorView } from "./routes/users/error";
import { Interactions } from "./routes/interactions/interaction";
import { IssueView } from "./routes/issues/issue-view";
import { LearningJourneys } from "./routes/learning-journeys/learning-journeys";
import { ManageLicenses } from "./routes/licenses/manage-licenses";
import { MissionDetail } from "./routes/mission/mission-detail";
import { NewMission } from "./routes/mission/new-mission";
import { Overview } from "./routes/overview/overview";
import { Plugins } from "./routes/plugins/plugins";
import { Profile } from "./routes/users/profile";
import { QUISidebar } from "./Sidebar";
import { Quality } from "./routes/quality/quality";
import { Quickstart } from "./routes/quickstart/quickstart";
import { Remarks } from "./routes/remarks/remarks";
import { UserRecovery } from "./routes/users/userRecovery";
import { UserVerifcation } from "./routes/users/userVerification";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { setIdentityId } from "./store/slices/app";
import { useAppDispatch } from "./store/hooks";
import { SurveyDetail } from "./routes/surveys/survey";
import { SurveyForm } from "./routes/surveys/form";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Assets } from "./routes/cockpit/assets/assets";
import { Asset } from "./routes/cockpit/assets/asset";
import { UseCase } from "./routes/cockpit/use-cases/use-case";
import { UseCases } from "./routes/cockpit/use-cases/use-cases";
import { useGetInstallationsQuery } from "./api/installation";
import { MsalProvider } from "@azure/msal-react";
import { dqcTheme } from "./helpers/commonStyles";
import { ColabCore } from "./routes/colab/colab-core";
import { msalInstanceProvider } from "./components/colab/powerbi-embedding";
import { Colab } from "./routes/colab/colab";
import { SignIn } from "./routes/users/signin";
import { SignUp } from "./routes/users/signup";
import { isOffice } from "./api/dqcQuery";
import { QuickAnalysis } from "./routes/quick-analysis/QuickAnalysis";
import { MAIN_ICONS } from "./Icons";
import {
  innerStackItemStyles,
  innerStackTokens,
  stackItemStyles,
  stackItemStylesNav,
  stackStyles,
} from "./App.styles";
import { NotificationCenter } from "./components/notification-center/NotificationCenter";
import { DataConnectors } from "./routes/dataConnectors/DataConnectors";
import { RulesAndPredictionsOverview } from "./routes/rules-predictions-overview/RulesAndPredictionsOverview";
import { RulesForTables } from "./routes/rules-predictions-overview/RulesForTables";
import { AlertsOverview } from "./routes/alerts/AlertsOverview";
import { AlertDetail } from "./routes/alerts/AlertDetail";

initializeIcons(/* optional base url */);
registerIcons({
  icons: MAIN_ICONS,
});

const loadDefaultStart = (
  loading: boolean,
  withInstallation: boolean,
  canAccessPlatform: boolean = false
) => {
  if (loading) {
    return (
      <>
        <Spinner label="Loading" labelPosition="right" />
      </>
    );
  }
  return canAccessPlatform ? (
    <Quickstart hasInstallation={withInstallation} />
  ) : (
    <Navigate to="/profile" />
  );
};

export const App: React.FunctionComponent = () => {
  const surveyForm = window.location.href.match(/surveys\/form/) !== null;
  const embedding = window.location.href.match(/colab/) !== null;
  const dispatch = useAppDispatch();
  const flowId = new URLSearchParams(window.location.search).get("flow");
  const session = useWhoAmIQuery(!surveyForm ? {} : skipToken);
  const profileRequest = useGetMyProfileQuery(!surveyForm ? {} : skipToken);
  const installationData = useGetInstallationsQuery();
  const partOfInstallation = installationData.isSuccess && installationData !== undefined;
  const returnTo = isOffice() ? localStorage.getItem("addinUrl") : localStorage.getItem("returnTo");

  useEffect(() => {
    if (session.currentData?.active && returnTo && !flowId) {
      localStorage.removeItem("returnTo");
      window.location.replace(returnTo);
      return;
    }
    if (session.currentData?.active) {
      dispatch(setIdentityId(session.currentData?.identity?.id!));
      return;
    }
    if (session.isError || session.data?.error) {
      getFlowIdOrAcquire();
      return;
    }
  }, [session, dispatch, returnTo, flowId]);

  return (
    <ThemeProvider theme={dqcTheme}>
      <BrowserRouter>
        <Stack horizontal styles={stackStyles} tokens={innerStackTokens}>
          <Stack.Item
            disableShrink
            styles={{
              ...stackItemStylesNav,
              root: { borderRight: 0 },
            }}
            hidden={session.isError || !session.currentData?.active || surveyForm || embedding}
          >
            {profileRequest.data?.accessPlatform && (
              <QUISidebar
                userName={session.currentData?.identity?.traits.email!}
                hasSession={session.currentData?.active}
                hasInstallation={partOfInstallation}
                isLoading={session.isLoading || profileRequest.isLoading}
              />
            )}
          </Stack.Item>
          <Stack.Item grow styles={stackItemStyles}>
            <Stack grow styles={innerStackItemStyles}>
              <MsalProvider instance={msalInstanceProvider()}>
                <Routes>
                  <Route
                    path="/"
                    element={loadDefaultStart(
                      installationData.isLoading,
                      partOfInstallation,
                      profileRequest.data?.accessPlatform
                    )}
                  />
                  <Route path="dashboard" element={<Overview />} />
                  <Route path="dashboard/quality" element={<Quality />} />
                  <Route path="dashboard/interactions" element={<Interactions />} />
                  <Route path="surveys" element={<Surveys />} />
                  <Route path="surveys/:id" element={<SurveyDetail />} />
                  <Route path="surveys/form/:id" element={<SurveyForm />} />
                  <Route path="missions" element={<AllMissions />} />
                  <Route path="cockpit" element={<Cockpit />} />
                  <Route path="cockpit/use-cases" element={<UseCases />} />
                  <Route path="cockpit/use-cases/:id" element={<UseCase />} />
                  <Route path="cockpit/assets" element={<Assets />} />
                  <Route path="cockpit/assets/:id" element={<Asset />} />
                  <Route path="quick-analysis" element={<QuickAnalysis />} />
                  <Route path="missions/upload" element={<BulkUpload />} />
                  <Route path="missions/:id" element={<MissionDetail />} />
                  <Route path="missions/new" element={<NewMission />} />
                  <Route
                    path="/missions/:missionId/dataset/:datasetId/issues/:qualityDimension"
                    element={<IssueView />}
                  />
                  <Route
                    path="/missions/:missionId/dataset/:datasetId/issues"
                    element={<IssueView />}
                  />
                  <Route path="/data-connectors" element={<DataConnectors />} />
                  <Route path="/overview" element={<RulesAndPredictionsOverview />} />
                  <Route path="/overview/table/:tableid" element={<RulesForTables />} />
                  <Route path="connections" element={<Connections />} />
                  <Route path="plugins" element={<Plugins />} />
                  <Route path="learning-journeys" element={<LearningJourneys />} />
                  <Route path="/signin" element={<SignIn />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="remarks" element={<Remarks />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/error" element={<ErrorView />} />
                  <Route path="/user-verification" element={<UserVerifcation />} />
                  <Route path="/user-recovery" element={<UserRecovery />} />
                  <Route path="/settings" element={<TenantSettings />} />
                  <Route path="/licenses" element={<ManageLicenses />} />
                  <Route path="/colab/powerbi/report/:reportid" element={<ColabCore />} />
                  <Route path="/colab/*" element={<Colab />} />
                  <Route path="/alerts" element={<AlertsOverview />} />
                  <Route path="/alerts/:alertid" element={<AlertDetail />} />
                </Routes>
              </MsalProvider>
              <NotificationCenter />
            </Stack>
          </Stack.Item>
        </Stack>
      </BrowserRouter>
    </ThemeProvider>
  );
};
