import { FunctionComponent } from "react";
import { Remark as RemarkType, ReportManager } from "../../types/colab";
import { Separator, Stack } from "@fluentui/react";
import { Remark } from "./remark";

export interface RemarksListProps {
  remarks: RemarkType[];
  reportManager: ReportManager | undefined;
  dimension: string;
}

export const RemarksList: FunctionComponent<RemarksListProps> = ({
  remarks,
  reportManager,
  dimension,
}) => {
  return (
    <Stack tokens={{ childrenGap: 8 }}>
      {remarks.length > 0 ? (
        <Separator
          alignContent="start"
          className={dimension === "Open" ? "openSeparator" : "solvedSeparator"}
        >
          {dimension} ({remarks.length})
        </Separator>
      ) : null}
      {remarks.map((r) => (
        <Remark key={r.id} reportManager={reportManager!} remark={r} />
      ))}
    </Stack>
  );
};
