import { RuleCandidate } from "../../../types/rules";
import { UploadDataContainer } from "../../../types/fileUploader";
import { QuickAnalysisIssue } from "../../../types/quickAnalysis";
import { addIssueId } from "../../quickAnalysis/qualityChecks/qualityUtils";

export const categoricalData = (
  dataContainer: UploadDataContainer,
  rule: RuleCandidate
): QuickAnalysisIssue[] => {
  const issues: QuickAnalysisIssue[] = [];
  if (rule.qualityTest.testFunctionName !== "categoricalData") return issues;
  const { legalCategoricalValues } = rule.qualityTest.meta;
  const legaValuesSet = new Set(
    legalCategoricalValues.filter(({ isLegal }) => isLegal).map(({ value }) => value)
  );
  const columnIndex = rule.columns[0].index;
  const column = dataContainer.data.map((row) => row[columnIndex]);
  for (let cell of column) {
    if (cell.type === "Empty") continue;
    const isCellLegalValue = legaValuesSet.has(cell.value.toString());
    if (isCellLegalValue) continue;
    issues.push({
      row: cell.row,
      column: cell.column,
      comment: "categorical_data_comment",
      severity: rule.severity,
      type: "inconsistent",
      id: "",
      rule_id: rule.id,
    });
  }
  return addIssueId(issues);
};
