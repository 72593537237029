import { PrimaryButton, Stack, Text } from "@fluentui/react";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

interface ErrorProps {
  error: string;
}

export const ColabError: FunctionComponent<ErrorProps> = (errorProps: ErrorProps) => {
  const navigate = useNavigate();
  console.log(errorProps.error);
  return errorProps.error === "Access error" ? (
    <Stack tokens={{ childrenGap: 20 }}>
      <Text styles={{ root: { fontSize: 16, fontWeight: 600, marginTop: 20 } }}>
        You don't have permission to access the selected dashboard.
      </Text>
      <Text styles={{ root: { fontSize: 16, fontWeight: 600 } }}>
        Reach out to the dashboard creator to get access rights!
      </Text>
      <PrimaryButton
        text="Go back"
        iconProps={{ iconName: "back" }}
        style={{ width: 120, marginTop: 30 }}
        onClick={() => {
          navigate("/colab");
        }}
      ></PrimaryButton>
    </Stack>
  ) : (
    <Stack style={{ fontSize: 16, fontWeight: 600 }} tokens={{ childrenGap: 20 }}>
      <Text styles={{ root: { fontSize: 16, fontWeight: 600, marginTop: 20 } }}>
        An unexpected error occurred:
      </Text>
      <Text styles={{ root: { fontSize: 14 } }}>{errorProps.error}</Text>
      <Text styles={{ root: { fontSize: 16, fontWeight: 600 } }}>
        Please try again. If the error remains, contact us at support@dqc.ai
      </Text>
    </Stack>
  );
};
