import { ActionButton, Separator, Stack, TooltipHost } from "@fluentui/react";
import { t } from "i18next";
import { CustomTest, RuleCandidate } from "../../../../types/rules";
import { useState } from "react";
import { NameAndDescriptionInput } from "../NameDescription";
import { RuleDetailModalFooter } from "../RuleDetailModalFooter";
import { MODAL_DETAIL_LEFT_MARGIN } from "./bodyStyles";
import { Clause } from "../../../../types/customChecks";
import { getDefaultClause } from "../../../../helpers/predictRules/qualityRuleFunctions/customRules";
import { useAppSelector } from "../../../../store/hooks";
import { selectLatestUpload } from "../../../../store/slices/upload";
import { CustomRuleClause } from "./CustomRuleClause";

export const CustomRuleDetailBody = ({
  rule,
  toggleModal,
}: {
  rule: RuleCandidate;
  toggleModal: () => void;
}) => {
  const [localRule, setLocalRule] = useState<RuleCandidate>(rule);
  const uploadedFile = useAppSelector(selectLatestUpload);
  if (localRule.qualityTest.testFunctionName !== "customRule" || !uploadedFile) return null;
  const columnsTest = localRule.columns;
  const localQualityTest = localRule.qualityTest;
  const clauses: Clause[] = localQualityTest.meta.clauses;
  return (
    <>
      <Stack tokens={{ childrenGap: 12 }}>
        <NameAndDescriptionInput rule={localRule} updateRule={setLocalRule} />
        <Stack tokens={{ childrenGap: 8 }}>
          <Separator />
          {clauses.map((clause, index) => (
            <CustomRuleClause
              localQualityTest={localQualityTest}
              clause={clause}
              index={index}
              localColumns={columnsTest}
              localRule={localRule}
              setLocalRule={setLocalRule}
              key={`${clause.columnIndex}::${index}`}
            />
          ))}
        </Stack>
        <Stack
          horizontal
          verticalAlign="center"
          styles={{ root: { marginLeft: MODAL_DETAIL_LEFT_MARGIN } }}
        >
          <TooltipHost content={t("custom_check_add_condition")} directionalHint={12}>
            <ActionButton
              iconProps={{ iconName: "Add" }}
              text={t("add_condition")}
              styles={{
                textContainer: { fontSize: 12 },
                root: { marginTop: -12 },
              }}
              onClick={() => {
                const addedClause: Clause = { ...getDefaultClause(0)[0], operator: "and" };
                const updatedClauses = [...clauses, addedClause];
                const updatedColumns = [...columnsTest, uploadedFile.columns[0]];
                setLocalRule({
                  ...localRule,
                  columns: updatedColumns,
                  qualityTest: {
                    ...localQualityTest,
                    meta: { clauses: updatedClauses },
                  } as CustomTest,
                });
              }}
            />
          </TooltipHost>
        </Stack>
      </Stack>
      <RuleDetailModalFooter rule={localRule} updateRule={setLocalRule} toggleModal={toggleModal} />
    </>
  );
};
