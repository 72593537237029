import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataChange, SelectedData } from "../../types/colab";
import { RootState } from "../store";
import jwt_decode from "jwt-decode";

export interface EmbedState {
  accessToken: string | undefined;
  embedUrl: string | undefined;
  reportName: string;
  reportId: string;
}
interface RemarkSlice {
  msGraphApiAccessToken: string;
  allSelectedData: SelectedData;
  editRemarkId?: string;
  error?: string;
  userName?: string;
  currentSelectedData?: DataChange;
  currentSelectedVisual?: DataChange;
  embedState: EmbedState;
  microsoftUserId?: string;
}

const initialState: RemarkSlice = {
  msGraphApiAccessToken: "",
  allSelectedData: {},
  embedState: {
    accessToken: undefined,
    embedUrl: undefined,
    reportName: "",
    reportId: "",
  },
};

const MAX_TIME_BETWEEN_STATE_CHANGE = 200;

export const remarkSlice = createSlice({
  name: "remark",
  initialState,
  reducers: {
    setMsGraphApiAccessToken: (state: RemarkSlice, action: PayloadAction<string>) => {
      state.msGraphApiAccessToken = action.payload;
      const decoded: Record<string, string> = jwt_decode(action.payload);
      state.microsoftUserId = decoded["oid"];
    },
    setEmbeddingError: (state: RemarkSlice, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setEditRemarkId: (state: RemarkSlice, action: PayloadAction<string | undefined>) => {
      state.editRemarkId = action.payload;
    },
    setUsername: (state: RemarkSlice, action: PayloadAction<string | undefined>) => {
      state.userName = action.payload;
    },
    setSelectedData: (state: RemarkSlice, action: PayloadAction<SelectedData>) => {
      // TODO figure out what we are using selection for
      const { data, visual } = action.payload;
      const timing =
        Date.now() - (state.currentSelectedData ? state.currentSelectedData.lastChanged : 0);
      if (data) {
        if (data.type === "multiple" && data.value.length === 1) {
          state.currentSelectedData = {
            type: "single",
            value: data.value[0],
            lastChanged: data.lastChanged,
          };
        } else {
          state.currentSelectedData = data;
        }
      }
      if (visual) {
        const lastDataChange = state.currentSelectedData
          ? state.currentSelectedData.lastChanged
          : 0;

        state.currentSelectedVisual = visual;
        if (visual.lastChanged - lastDataChange < MAX_TIME_BETWEEN_STATE_CHANGE) return;

        state.currentSelectedData = visual;
      }
      if (data === undefined && visual === undefined && timing > MAX_TIME_BETWEEN_STATE_CHANGE) {
        state.allSelectedData = {};
        state.currentSelectedData = undefined;
      } else {
        state.allSelectedData = { ...state.allSelectedData, ...action.payload };
      }
    },
    setCurrentSelectedData: (state: RemarkSlice, action: PayloadAction<DataChange>) => {
      state.currentSelectedData = action.payload;
    },
    setEmbedState: (state: RemarkSlice, action: PayloadAction<EmbedState, string>) => {
      state.embedState = action.payload;
    },
  },
});

export const {
  setMsGraphApiAccessToken,
  setEmbeddingError,
  setEditRemarkId,
  setSelectedData,
  setUsername,
  setCurrentSelectedData,
  setEmbedState,
} = remarkSlice.actions;

const selectRemarkSlice = (state: RootState) => state.remark;
export const selectMsGraphApiAccessToken = createSelector(
  selectRemarkSlice,
  (remarkSlice) => remarkSlice.msGraphApiAccessToken
);
export const selectMicrosoftUserId = createSelector(
  selectRemarkSlice,
  (remarkSlice) => remarkSlice.microsoftUserId
);
export const selectEmbeddingError = createSelector(
  selectRemarkSlice,
  (remarkSlice) => remarkSlice.error
);
export const selectEditRemarkId = createSelector(
  selectRemarkSlice,
  (remarkSlice) => remarkSlice.editRemarkId
);
export const selectCurrentSelectedData = createSelector(
  selectRemarkSlice,
  (remarkSlice) => remarkSlice.currentSelectedData
);
export const selectCurrentSelectedVisual = createSelector(
  selectRemarkSlice,
  (remarkSlice) => remarkSlice.currentSelectedVisual
);
export const selectEmbeddingUsername = createSelector(
  selectRemarkSlice,
  (remarkSlice) => remarkSlice.userName
);
export const selectEmbedState = createSelector(
  selectRemarkSlice,
  (remarkSlice) => remarkSlice.embedState
);
