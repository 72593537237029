import { Icon } from "@fluentui/react";
import React from "react";

import { useAppDispatch } from "../../../store/hooks";
import { handleArrowPress } from "../../../store/slices/issueNavigator";

export const Arrow = ({ direction }: { direction: "Left" | "Right" }) => {
  const dispatch = useAppDispatch();
  return (
    <Icon
      iconName={`Chevron${direction}`}
      styles={{
        root: {
          fontSize: "10px",
          cursor: "pointer",
          userSelect: "none",
          marginLeft: 4,
          marginRight: 4,
        },
      }}
      onClick={() => dispatch(handleArrowPress(`Arrow${direction}`))}
    />
  );
};
