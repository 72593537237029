import React, { createElement } from "react";
import { ReactComponent as GitFork } from "../../assets/GitFork.svg";
import { ReactComponent as StackSimple } from "../../assets/StackSimple.svg";
import { ReactComponent as StackMultiple } from "../../assets/StackMultiple.svg";
import { ReactComponent as problemsNA } from "../../assets/problems-NA.svg";
import { ReactComponent as problemsNone } from "../../assets/problems-none.svg";
import { ReactComponent as problemsFew } from "../../assets/problems-few.svg";
import { ReactComponent as problemsSevere } from "../../assets/problems-severe.svg";
import { ReactComponent as valueHigh } from "../../assets/value-high.svg";
import { ReactComponent as valueMedium } from "../../assets/value-medium.svg";
import { ReactComponent as valueLow } from "../../assets/value-low.svg";
import { ReactComponent as valueNA } from "../../assets/value-NA.svg";
import { AssetType } from "../../types/dataasset";

export interface ProblemsProps {
  score?: number;
  width?: string;
  height?: string;
}
const listIconStyle: React.CSSProperties = {
  marginRight: 6,
  marginLeft: 6,
  marginTop: 4,
  cursor: "pointer",
};
export const assetTypeToIcons: Map<AssetType, number> = new Map();
assetTypeToIcons.set("Domain", 1);
assetTypeToIcons.set("Family", 2);
assetTypeToIcons.set("Product", 3);

export const ListIcon: React.FunctionComponent<ProblemsProps> = ({
  score = 1,
  width = "20",
  height = "20",
}) => {
  const ListSVGs = [
    GitFork, // domain
    StackSimple, // family
    StackMultiple, // product
    valueHigh, // high
    valueMedium, // medium
    valueLow, // low
    valueNA, // default
    problemsNA, // default
    problemsNone, // none
    problemsFew, // few
    problemsSevere, // severe
  ].map((val) => createElement(val, { width: `${width}`, height: `${height}px` }));
  return <div style={listIconStyle}>{ListSVGs[score - 1]}</div>;
};
