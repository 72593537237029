import { UploadDQCDataType, UploadNumberType } from "../../../types/fileUploader";
import { TextClause, NumericClause, FormatClause } from "../../../types/customChecks";

export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|\-[\]\\\/]/g, "\\$&"); // $& means the whole matched string
};

const isSpecialCharacters = (cell: UploadDQCDataType, clause: TextClause) => {
  const regexEscaped = escapeRegExp(clause.value ?? "");
  const whitespaced = regexEscaped.replace(/\•/g, "\\s");
  const specialCharacters = `^[A-Za-z0-9_${whitespaced}]{1,}$`;
  const valueToCheck = String(cell.value);
  const re = new RegExp(specialCharacters);
  return re.test(valueToCheck);
};

const extractStrings = (input: string): string[] => {
  const regex = /("[^"]+"|[^",\s]+)/g;
  const matched = input.match(regex);
  return matched ? matched.map((str) => str.replace(/"/g, "")) : [];
};

const isEqual = (cell: UploadDQCDataType, clause: TextClause | NumericClause) => {
  if (!clause.value) clause.value = "";
  else clause.value = clause.value.toString();
  const listOfValues = new Set(extractStrings(clause.value).map((str) => str.toUpperCase()));
  return listOfValues.has(String(cell.value).toUpperCase());
};

export const numericFunctionMap: Record<
  NumericClause["condition"],
  (cell: UploadNumberType, clause: NumericClause) => boolean
> = {
  "greater than": (cell, clause) => !clause.value || cell.value > clause.value,
  "greater than or equal": (cell, clause) => !clause.value || cell.value >= clause.value,
  "less than": (cell, clause) => !clause.value || cell.value < clause.value,
  "less than or equal": (cell, clause) => !clause.value || cell.value <= clause.value,
  equals: (cell, clause) => cell.value === clause.value,
  "does not equal": (cell, clause) => cell.value !== clause.value,
};

export const textFunctionMap: Record<
  TextClause["condition"],
  (cell: UploadDQCDataType, clause: TextClause) => boolean
> = {
  "starts with": (cell, clause) => cell.value.toString().startsWith(clause.value),
  "does not start with": (cell, clause) => !cell.value.toString().startsWith(clause.value),
  "ends with": (cell, clause) => cell.value.toString().endsWith(clause.value),
  "does not end with": (cell, clause) => !cell.value.toString().endsWith(clause.value),
  contains: (cell, clause) => cell.value.toString().includes(clause.value),
  "does not contain": (cell, clause) => !cell.value.toString().includes(clause.value),
  equals: (cell, clause) => isEqual(cell, clause),
  "does not equal": (cell, clause) => !isEqual(cell, clause),
  "special characters": (cell, clause) => isSpecialCharacters(cell, clause),
  "max characters": (cell, clause) => String(cell.value).length <= Number(clause.value),
  "min characters": (cell, clause) => String(cell.value).length >= Number(clause.value),
  "regular expression": (cell, clause) => {
    const regex = new RegExp(clause.value);
    return regex.test(cell.value.toString());
  },
};

const isFloat = (n: any) => {
  return Number(n) === n && n % 1 !== 0;
};
const isInt = (n: any) => {
  return Number(n) === n && n % 1 === 0;
};

export const formatFunctionMap: Record<
  FormatClause["condition"],
  (cell: UploadDQCDataType, clause: FormatClause) => boolean
> = {
  "is text": (cell) => cell.value.toString() === cell.value,
  "is decimal number": (cell) => isFloat(cell.value),
  "is whole number": (cell) => isInt(cell.value),
  "is mandatory": (cell) => !!cell.value && !!String(cell.value).trim(),
  "is date": (cell) => cell.type === "Date",
};
