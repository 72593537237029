import { IButtonStyles, IStackStyles, ITextStyles, MessageBarType } from "@fluentui/react";
import { NotificationPayload } from "../../store/slices/notificationCenter";

export const headerTextStyles: ITextStyles = {
  root: { fontWeight: 500, fontSize: 12, color: "black" },
};

const typeToMessageBarType: Record<NotificationPayload["type"], MessageBarType> = {
  info: MessageBarType.info,
  error: MessageBarType.error,
  warning: MessageBarType.warning,
  success: MessageBarType.success,
};

export const getMessageBarType = (type: NotificationPayload["type"]): MessageBarType => {
  return typeToMessageBarType[type];
};
export const notificationSize = { width: 220, height: 88 };

export const notificationButtonStyles: IButtonStyles = {
  root: {
    height: "100%",
    width: "50%",
    fontSize: 12,
  },
};

export const parentContainerStyle: IStackStyles = {
  root: {
    right: 4,
    bottom: 8,
    position: "absolute",
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    zIndex: 100,
  },
};
