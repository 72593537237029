import { ColumnDefinition, UploadDataContainer } from "../../../types/fileUploader";
import { RuleCandidateWithoutMeta } from "../../../types/rules";
import { getEmptyRowsAndColumns } from "../../../utils/getEmptyRowsAndColumns";
import { EMPTY_CELL_VALUES } from "../../quickAnalysis/qualityChecks/constants";

export const predictMissingRules = (
  dataContainer: UploadDataContainer
): RuleCandidateWithoutMeta[] => {
  const { data, columns } = dataContainer;
  const ruleCandidates: RuleCandidateWithoutMeta[] = [];
  const { emptyRows } = getEmptyRowsAndColumns(data);

  columns.forEach((column) => {
    const { dataType } = column;
    if (dataType === "Empty") return;
    ruleCandidates.push(getMissingRule(column, dataContainer, emptyRows));
  });

  return ruleCandidates;
};

export const getMissingRule = (
  column: ColumnDefinition,
  dataContainer: UploadDataContainer,
  emptyRows: Set<number>
): RuleCandidateWithoutMeta => {
  const { dataTypeFrequency } = column;
  const severity = dataTypeFrequency / dataContainer.data.length > 0.9 ? "warning" : "info";
  return {
    id: `${dataContainer.fileName}::missing::${column.index}`,
    columns: [column],
    dimension: "missing",
    severity,
    confidence: severity === "warning" ? 4 : 3,
    isAccepted: true,
    name: "Mandatory",
    description: "Tests for empty strings, 'NA' or 'TBD'",
    qualityTest: {
      testFunctionName: "missingValues",
      meta: {
        valuesConsideredMissing: EMPTY_CELL_VALUES,
        emptyRows: Array.from(emptyRows),
      },
    },
    fileName: dataContainer.fileName,
  };
};
