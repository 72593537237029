import * as React from "react";

import { IIconProps, Label } from "@fluentui/react";
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { FunctionComponent, useState } from "react";
import { copyAndSort, filterColumns } from "../../../helpers/utils";

import { Mission } from "../../../types/missions";
import { MissionQuality } from "../../common/MissionQualityBadge";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const missionLabel: React.CSSProperties = {
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
};

const descriptionStyle: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
};

export interface MissionsListState {
  missions: Mission[];
}

export const MissionsList: FunctionComponent<MissionsListState> = (props: MissionsListState) => {
  const { t } = useTranslation();
  const [allItems, setAllItems] = useState(props.missions);
  const statusIcons: Map<string, IIconProps> = new Map<string, IIconProps>();
  statusIcons.set("New", { iconName: "Add" });
  statusIcons.set("Running", { iconName: "Pause" });
  statusIcons.set("Done", { iconName: "Accept" });

  let navigate = useNavigate();

  const columnDataArray = [
    { name: t("new_mission_name_description"), fieldName: "name" },
    { name: t("new_mission_function"), fieldName: "function" },
    { name: t("new_mission_owner"), fieldName: "owner" },
    { name: t("new_mission_quality"), fieldName: "quality" },
    { name: t("new_mission_status"), fieldName: "status" },
  ];
  const onColumnSortClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { newColumns, currColumn } = filterColumns(columns, column);

    const newItems: Mission[] = copyAndSort(
      allItems,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    setColumns(newColumns);
    setAllItems(newItems);
  };

  const tempColumns: IColumn[] = [];
  columnDataArray.forEach((columnData, index) => {
    tempColumns.push({
      key: `column${index}`,
      name: columnData.name,
      fieldName: columnData.fieldName,
      minWidth: index === 0 ? 400 : 130,
      maxWidth: index === 0 ? 500 : 150,
      isRowHeader: index in [0] ? true : false,
      onColumnClick: onColumnSortClick,
      onRender: (item: Mission) => {
        if (index === 0) {
          return (
            <>
              <b style={missionLabel}>{item.name}</b>
              <br />
              <span style={descriptionStyle}>{item.description}</span>
            </>
          );
        } else if (index === 1) {
          return <span style={descriptionStyle}>{item.function}</span>;
        } else if (index === 2) {
          return <span style={descriptionStyle}>{item.owner}</span>;
        } else if (index === 3) {
          return (
            <>
              <MissionQuality value={item.quality} status={"done"}></MissionQuality>
            </>
          );
        } else if (index === 4) {
          return (
            <>
              <Label>{item.status}</Label>
            </>
          );
        }
      },
    });
  });
  const [columns, setColumns] = useState<IColumn[]>(tempColumns);

  return (
    <div>
      <DetailsList
        items={allItems}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        onActiveItemChanged={(item: Mission): void => navigate(`/missions/${item.id}`)}
      />
    </div>
  );
};
