import { IColumn, IGroup } from "@fluentui/react";
import { t } from "i18next";
import {
  Question,
  SelectQuestion,
  OpinionScaleQuestion,
  MultiSelectQuestion,
  SurveyDetail,
  Field,
  TextQuestion,
} from "../../types/survey";
export const HIDE_IF_LESS_THAN = 3;

export interface RenderItemChoices {
  top3: string[];
  totalCount: number;
}

export interface RenderItemNumeric {
  score: number;
  totalCount: number;
}
export interface ChoiceItem {
  cells: Record<string, RenderItemChoices>;
  question: string;
  type: "multiple_choice";
}
export interface NumberItem {
  cells: Record<string, RenderItemNumeric>;
  question: string;
  type: "opinion_scale" | "number" | "happiness";
}

export interface TextItem {
  response: string;
  groupName: string;
  totalCount: number;
}

export type Item = ChoiceItem | NumberItem;

export type GroupedResults = {
  columns: IColumn[];
  items: Item[];
  groups: IGroup[];
};

export type GroupedTextResults = {
  columns: IColumn[];
  items: TextItem[];
  groups: IGroup[];
};

export const getAllGroups = (
  groupBy: string,
  questions: Question[],
  surveyDetail: SurveyDetail,
  t: (s: string) => string
): GroupedResults => {
  const opinionGroup = groupResults(groupBy, questions, "opinion_scale");
  const numbersGroup = groupResults(groupBy, questions, "number");
  const choiceItems = getChoiceData(groupBy, questions, surveyDetail);
  const happinessItems: Item[] = getHappinessData(groupBy, questions, "opinion_scale");

  opinionGroup.groups.push(
    {
      key: "happiness",
      name: t("happiness"),
      startIndex: opinionGroup.items.length,
      count: happinessItems.length,
      isCollapsed: true,
    },
    {
      key: "statistics",
      name: t("statistics"),
      startIndex: opinionGroup.items.length + happinessItems.length,
      count: numbersGroup.items.length,
      isCollapsed: true,
    },
    {
      key: "choices",
      name: t("choices"),
      startIndex: opinionGroup.items.length + happinessItems.length + numbersGroup.items.length,
      count: choiceItems.length,
      isCollapsed: true,
    }
  );
  opinionGroup.items.push(...happinessItems, ...numbersGroup.items, ...choiceItems);
  return opinionGroup;
};

export const groupResults = (
  groupBy: string,
  questions: Question[],
  question_type: Item["type"]
): GroupedResults => {
  const responseMap = getResponseMap(groupBy, questions);

  const opininionQuestions = questions.filter(
    (q) => question_type === q.type
  ) as OpinionScaleQuestion[];

  const items: Item[] = opininionQuestions.map((q) => {
    const item: Item = {
      question: q.question_text,
      cells: {},
      type: question_type,
    };
    Object.entries(responseMap).forEach(([groupName, responseIds]) => {
      const sum = responseIds
        .map((responseId) => parseInt(q.responses[responseId]) - 1)
        .reduce((total, n) => total + n, 0);

      const score = sum / responseIds.length;
      item.cells[groupName] = {
        score,
        totalCount: responseIds.length,
      };
    });
    return item;
  });

  const columns: IColumn[] = [
    {
      name: t("at_a_glance"),
      key: "category",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    ...Object.entries(responseMap).map(([groupName, responseIds]) => {
      return {
        name: `${groupName} (${responseIds.length})`,
        key: groupName,
        minWidth: 120,
        maxWidth: 120,
      };
    }),
  ];

  const groups: IGroup[] = [];
  let startIndex = 0;
  let currentCategory =
    opininionQuestions && opininionQuestions.length >= 1
      ? opininionQuestions[0].category
      : undefined;
  opininionQuestions.forEach((q, index) => {
    if (q.category !== currentCategory) {
      groups.push({
        key: currentCategory!,
        name: currentCategory!,
        startIndex,
        count: index - startIndex,
        isCollapsed: true,
      });
      currentCategory = q.category;
      startIndex = index;
    }
  });
  groups.push({
    key: currentCategory!,
    name: currentCategory!,
    startIndex,
    count: opininionQuestions.length - startIndex,
    isCollapsed: true,
  });

  return {
    items,
    groups,
    columns,
  };
};

export const getColorGradientFromFraction = (fraction: number) => {
  const percentColors = [
    { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
    { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } },
  ];
  let i = 1;
  for (; i < percentColors.length - 1; i++) {
    if (fraction < percentColors[i].pct) {
      break;
    }
  }
  const lower = percentColors[i - 1];
  const upper = percentColors[i];
  const range = upper.pct - lower.pct;
  const rangePct = (fraction - lower.pct) / range;
  const pctLower = 1 - rangePct;
  const pctUpper = rangePct;
  const color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  };
  return "rgb(" + [color.r - 30, color.g - 30, color.b].join(",") + ")";
};

export const getResponseMap = (
  groupBy: string,
  questions: Question[]
): Record<string, string[]> => {
  const participantInfoQuestion: SelectQuestion = questions.find(
    (q) => q.question_text === groupBy
  ) as SelectQuestion;
  const responseMap: Record<string, string[]> = {};
  Object.entries(participantInfoQuestion.responses).forEach(
    ([responseId, responseValue]: [string, string]) => {
      if (!responseMap[responseValue]) return (responseMap[responseValue] = [responseId]);
      return responseMap[responseValue].push(responseId);
    }
  );
  return responseMap;
};

export const getChoiceData = (
  groupBy: string,
  questions: Question[],
  surveyDetail: SurveyDetail
): ChoiceItem[] => {
  const responseMap = getResponseMap(groupBy, questions);
  const multiSelectQuestions = questions.filter(
    (q) => q.type === "multiple_choice"
  ) as MultiSelectQuestion[];

  const questionRefToChoices: Record<string, string[]> = {};
  surveyDetail.fields.forEach((field: Field) => {
    if (field.properties.choices) {
      questionRefToChoices[field.ref] = field.properties.choices.map((choice) => choice.label);
    }
    if (field.properties.fields) {
      field.properties.fields.forEach((field) => {
        if (field.properties.choices) {
          questionRefToChoices[field.ref] = field.properties.choices.map((choice) => choice.label);
        }
      });
    }
  });

  return multiSelectQuestions.map((q) => {
    const cells: Record<string, RenderItemChoices> = {};
    Object.entries(responseMap).forEach(([groupName, responseIds]) => {
      const groupFrequencyMap: Record<string, number> = {};
      questionRefToChoices[q.ref].forEach((label) => (groupFrequencyMap[label] = 0));
      responseIds.forEach((responseId) => {
        if (q.responses[responseId]) {
          q.responses[responseId].forEach((choice) =>
            groupFrequencyMap[choice]
              ? groupFrequencyMap[choice]++
              : (groupFrequencyMap[choice] = 1)
          );
        }
      });
      const top3 = Object.entries(groupFrequencyMap)
        .sort((a: any, b: any) => b[1] - a[1])
        .slice(0, 3)
        .filter((groupFreqTuple) => groupFreqTuple[1] > 0)
        .map(
          ([choice, frequency]) =>
            `${choice}: ${((frequency / responseIds.length) * 100).toFixed(0)}%`
        );
      cells[groupName] = {
        top3,
        totalCount: responseIds.length,
      };
    });
    return {
      question: q.question_text,
      cells,
      type: "multiple_choice",
    };
  });
};

export const groupFreeTextResults = (
  groupBy: string,
  questions: Question[]
): GroupedTextResults => {
  const responseMap = getResponseMap(groupBy, questions);

  const textQuestions = questions.filter(
    (q) => q.type === "long_text" || q.type === "short_text"
  ) as TextQuestion[];

  const items2d: TextItem[][] = textQuestions.map((q) => {
    return Object.entries(responseMap).reduce((acc, [groupName, responseIds]) => {
      const nonEmptyResponsesInGroup = responseIds.filter(
        (responseId) => !!q.responses[responseId]
      );
      acc.push(
        ...nonEmptyResponsesInGroup.map((responseId) => {
          return {
            response: q.responses[responseId],
            totalCount: nonEmptyResponsesInGroup.length,
            groupName,
          };
        })
      );

      return acc;
    }, [] as TextItem[]);
  });

  const columns: IColumn[] = [
    {
      name: t("what_they_say"),
      key: "response",
      minWidth: 400,
      maxWidth: 400,
      isResizable: true,
    },
    {
      name: t("group"),
      key: "group",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const groups: IGroup[] = [];
  let startIndex = 0;
  textQuestions.forEach((q, i) => {
    groups.push({
      key: q.question_text,
      name: q.question_text,
      startIndex,
      count: items2d[i].length,
      isCollapsed: false,
    });
    startIndex += items2d[i].length;
  });
  const items = items2d.reduce((flatItems, i) => {
    flatItems.push(...i);
    return flatItems;
  }, [] as TextItem[]);
  return {
    items,
    groups,
    columns,
  };
};
function getHappinessData(
  groupBy: string,
  questions: Question[],
  question_type: Item["type"]
): Item[] {
  const responseMap = getResponseMap(groupBy, questions);

  const opinionQuestions = questions.filter(
    (q) => question_type === q.type
  ) as OpinionScaleQuestion[];

  const items: Item[] = [];
  const item: Item = {
    question: "Happiness Score",
    cells: {},
    type: "happiness",
  };

  Object.entries(responseMap).forEach(([groupName, responseIds]) => {
    let happiness = 0;
    let happinessCount = 0;
    responseIds.forEach((responseId) => {
      opinionQuestions.forEach((question: Question) => {
        happiness += Number(question.responses[responseId]) - 1;
        happinessCount++;
      });
    });
    const happinessScore = Math.round((happiness / (happinessCount * 4)) * 100);
    item.cells[groupName] = {
      score: happinessScore,
      totalCount: happinessCount,
    };
  });
  items.push(item);
  return items;
}
