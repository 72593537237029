import { IStackStyles, Label, Stack } from "@fluentui/react";
import React from "react";
import { useAppSelector } from "../../store/hooks";
import { selectShowFields } from "../../store/slices/cockpitSlice";
import { ShowFieldsOptions } from "../../types/cockpit";
import { AssetUsecaseProblems, AssetUsecaseValue } from "../../types/dataasset";
import { PersonaTooltip } from "../common/PersonaTooltip";
import { ProblemToolTip } from "../common/ProblemToolTip";
import { ValueToolTip } from "../common/ValueToolTip";

const mainContainer: IStackStyles = {
  root: {
    display: "flex",
    alignItems: "center",
    width: 370,
    height: 48,
    padding: 12,
    border: "1px solid #D3D3D3",
    boxShadow: "0px 3px #D3D3D3",
  },
};

type CockpitTitleHeaderProps = {
  title: string;
  problems: AssetUsecaseProblems;
  value: AssetUsecaseValue;
  owner: string;
};

export const CockpitTileHeader: React.FunctionComponent<CockpitTitleHeaderProps> = ({
  title,
  problems,
  value,
  owner,
}) => {
  const showFields: ShowFieldsOptions[] = useAppSelector(selectShowFields);

  return (
    <Stack horizontal horizontalAlign="space-between" styles={mainContainer}>
      <Stack.Item>
        <Label>{title}</Label>
      </Stack.Item>
      <Stack horizontal verticalAlign="center">
        {showFields.includes("value") ? <ValueToolTip value={value} /> : null}
        {showFields.includes("problems") ? <ProblemToolTip value={problems} /> : null}
        {showFields.includes("owner") ? <PersonaTooltip name={owner} /> : null}
      </Stack>
    </Stack>
  );
};
