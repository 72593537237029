import { GroupMap, Issue, IssueForTable, SelectionMap } from "../types/issue";

import i18n from "../i18n";

export function getComment(issue: Issue, groups: GroupMap): string {
  if (issue.quality_dimension === "NEW_RECORD") {
    return i18n.t("NEW_RECORD");
  } else if (issue.quality_dimension !== "DUPLICATES") {
    return i18n.t(`comment_for_${issue.details}`);
  }
  if (issue.group_id.length > 0) {
    const preText = i18n.t(`comment_for_${issue.details}`);
    return `${preText} ${groups[issue.group_id]?.join(", ")}`;
  }
  return "";
}

export function getIgnoredIssueIdsFromSelection(selection: SelectionMap) {
  let issues: string[] = [];
  for (const [issue_id, ignored] of Object.entries(selection)) {
    if (ignored) {
      issues.push(issue_id);
    }
  }
  return issues;
}

export function buildGroups(issues: Issue[]): GroupMap {
  let groups: GroupMap = {};
  issues.forEach((i) => {
    if (i.group_id !== null) {
      if (groups.hasOwnProperty(i.group_id)) {
        groups[i.group_id].push(i.row_info.id);
      } else {
        groups[i.group_id] = [i.row_info.id];
      }
    }
  });
  return groups;
}

export const mapToTableData = (issues: Issue[], groups: GroupMap): IssueForTable[] => {
  return issues.map((issue) => {
    if (issue.quality_dimension === "OUTDATED") {
      return {
        id: issue.id,
        quality_dimension: issue.quality_dimension,
        row_info: `${issue.row_info.id} ${issue.row_info.name == null ? "" : issue.row_info.name}`,
        column_info: `${issue.column_infos.map((c) => c.name).join(", ")}`,
        cell_value: issue.cell_value,
        comment: i18n.t("comment_for_OUTDATED"),
        row_id: issue.row_info.id,
        details: issue.details,
      };
    }
    return {
      id: issue.id,
      quality_dimension: issue.quality_dimension,
      row_info: `${issue.row_info.id} ${issue.row_info.name == null ? "" : issue.row_info.name}`,
      column_info: `${issue.column_infos.map((c) => c.name).join(", ")}`,
      cell_value: issue.cell_value,
      comment: getComment(issue, groups),
      row_id: issue.row_info.id,
      details: issue.details,
    };
  });
};

export function deepLink(urn?: string, id?: string): string {
  if (!urn || urn.length === 0) return "";
  const urn_parts = urn.split("/");
  const custom_domain = urn_parts[2];
  const site_name = urn_parts[4];
  const list_name = urn_parts[6];
  return `https://${custom_domain}/sites/${site_name}/Lists/${list_name}/DispForm.aspx?ID=${id}`;
}

export const filterIssuesBySearchTerm = (
  issues: IssueForTable[],
  searchTerm: string
): IssueForTable[] => {
  if (!searchTerm) return issues;
  const searchTermLower = searchTerm.toLowerCase();
  return issues.filter((issue) =>
    Object.values(issue).some((value) => {
      return value.toLowerCase().includes(searchTermLower);
    })
  );
};

export const transformIssues = (issues?: Issue[]): IssueForTable[] => {
  if (!issues) return [];
  const groups = buildGroups(issues);
  return mapToTableData(issues, groups);
};
