import Analytics from "analytics";
import mixpanelPlugin from "@analytics/mixpanel";
import { readAddInVersionToLocalStorage } from "../helpers/readAddInVersionFromLocalStorage";

export type LoginSignupEventType =
  | "Sign In Via Microsoft"
  | "Sign Up Via Microsoft"
  | "Sign In Via Mail"
  | "Sign Up Via Mail"
  | "Watch Short Video"
  | "Subscribe Now in Platform";

const defaultMeta = {
  platform: `${process.env.REACT_APP_NODE_ENV}`,
  version: `${process.env.REACT_APP_GIT_SHA}`,
};
class Tracking {
  private static analytics = Analytics({
    app: "platform-login",
    plugins: [
      mixpanelPlugin({
        token: process.env.REACT_APP_MIXPANEL_TOKEN,
      }),
    ],
  });

  static trackLoginSignupEvent(eventName: LoginSignupEventType, metadata?: any) {
    const addInVersion = readAddInVersionToLocalStorage();
    this.analytics.track(`${eventName} clicked`, { addInVersion, ...metadata, ...defaultMeta });
  }

  static identify(userId: string, traits?: any) {
    this.analytics.identify(userId, traits);
  }

  static resetVisitor() {
    this.analytics.reset();
  }

  static trackEvent(eventName: string, metadata?: any) {
    this.analytics.track(eventName, { ...metadata, ...defaultMeta });
  }
}

export default Tracking;
