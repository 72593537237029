import {
  DefaultButton,
  DetailsList,
  Dialog,
  IconButton,
  Label,
  Link,
  Pivot,
  PivotItem,
  PrimaryButton,
  SelectionMode,
  Separator,
  Spinner,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopContainer } from "../../components/common/TopContainer";
import { ColleaguesGroupsDetail } from "../../components/group/ColleaguesGroupsDetail";
import {
  useCreateTenantInviteMutation,
  useGetTenantSettingsQuery,
  useUpdateTenantSettingsMutation,
} from "../../api/v2/tenantSettings";

export const TenantSettings: FunctionComponent = () => {
  const { t } = useTranslation();
  const tenantSettings = useGetTenantSettingsQuery({});
  const [createTenantInvite] = useCreateTenantInviteMutation();
  const [updateTenantSettings] = useUpdateTenantSettingsMutation();
  const [isUpdating, setIsUpdating] = useState(false);
  const [tenantName, setTenantName] = useState(tenantSettings.data?.name ?? "");
  const [inviteEmail, setInviteEmail] = useState("");
  const [showInviteUserDialog, setShowInviteUserDialog] = useState(false);
  const showNewPlatform =
    process.env.REACT_APP_ENV === "staging" || process.env.REACT_APP_ENV === "development";

  useEffect(() => {
    if (tenantSettings.isSuccess) setTenantName(tenantSettings.data?.name ?? "");
  }, [tenantSettings]);

  if (!tenantSettings.isSuccess) return <Spinner />;

  return (
    <TopContainer title={t("tenant_settings")}>
      <Stack styles={{ root: { marginTop: 16 } }}>
        {showNewPlatform ? (
          <Pivot linkSize="large">
            <PivotItem headerText={t("company_info")}>
              <Stack styles={{ root: { marginTop: 32 } }} tokens={{ childrenGap: 16 }}>
                <Stack styles={{ root: { maxWidth: 400 } }} tokens={{ childrenGap: 8 }}>
                  <Stack horizontal>
                    <TextField
                      label={t("company_name")}
                      underlined
                      value={tenantName}
                      onChange={(e, newValue) => setTenantName(newValue ?? "")}
                      disabled={isUpdating}
                    />
                    <IconButton
                      iconProps={{ iconName: isUpdating ? "HourGlass" : "Save" }}
                      onClick={() => {
                        setIsUpdating(true);
                        const updateObject = {
                          id: tenantSettings.data.id,
                          name: tenantName,
                          domain: tenantSettings.data.domain,
                        };
                        updateTenantSettings(updateObject).then(() => setIsUpdating(false));
                      }}
                    />
                  </Stack>
                </Stack>
                <Separator />
                <Stack tokens={{ childrenGap: 8 }} horizontalAlign="end" horizontal>
                  <Text variant="small" styles={{ root: { lineHeight: 32 } }}>
                    {t("invite_user_description")}
                  </Text>
                  <DefaultButton
                    text={t("invite_user")}
                    onClick={() => {
                      setShowInviteUserDialog(!showInviteUserDialog);
                    }}
                  />
                </Stack>
                <DetailsList
                  selectionMode={SelectionMode.none}
                  items={tenantSettings.data?.users.map((user) => ({
                    key: user.id,
                    email: user.email,
                  }))}
                  columns={[
                    {
                      key: "email",
                      name: t("email"),
                      fieldName: "email",
                      minWidth: 100,
                      flexGrow: 1,
                      isResizable: true,
                    },
                    {
                      key: "actions",
                      name: "",
                      isIconOnly: true,
                      iconName: "PlayerSettings",
                      minWidth: 100,
                      maxWidth: 200,
                      isResizable: true,
                    },
                  ]}
                />
              </Stack>
            </PivotItem>
            <PivotItem headerText={t("api_tokens")}>
              <Stack styles={{ root: { marginTop: 32 } }} tokens={{ childrenGap: 16 }}>
                <Label>{t("upgrade_title")}</Label>
                <Text>{t("upgrade_text")}</Text>
                <Link href="mailto:sales@dqc.ai"> sales@dqc.ai</Link>.
              </Stack>
            </PivotItem>
            <PivotItem headerText={t("plan")}>
              <Stack styles={{ root: { marginTop: 32 } }} tokens={{ childrenGap: 16 }}>
                <Label>{t("change_plan_title")}</Label>
                <Text>{t("change_plan_text")}</Text>
                <Link href="mailto:sales@dqc.ai"> sales@dqc.ai</Link>.
              </Stack>
            </PivotItem>
          </Pivot>
        ) : (
          <ColleaguesGroupsDetail />
        )}
      </Stack>
      <Dialog
        hidden={!showInviteUserDialog}
        styles={{ root: { borderRadius: 4 } }}
        onDismiss={() => {}}
        dialogContentProps={{
          title: t("invite_user"),
          subText: t("invite_user_description"),
        }}
      >
        <Stack tokens={{ childrenGap: 8 }}>
          <TextField
            label={t("email")}
            value={inviteEmail}
            onChange={(_e, newValue) => setInviteEmail(newValue ?? "")}
          />
          <Stack horizontal horizontalAlign="space-evenly">
            <PrimaryButton
              text={t("invite_user")}
              onClick={async () => {
                try {
                  const data = await createTenantInvite(inviteEmail);
                  if (data) {
                    setShowInviteUserDialog(false);
                    setInviteEmail("");
                  }
                } catch (e) {
                  console.error(e);
                }
              }}
              styles={{ root: { borderRadius: 4 } }}
            />
            <DefaultButton
              text={t("cancel")}
              onClick={() => setShowInviteUserDialog(false)}
              styles={{ root: { borderRadius: 4 } }}
            />
          </Stack>
        </Stack>
      </Dialog>
    </TopContainer>
  );
};
