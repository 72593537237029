import React, { createElement } from "react";
import { ReactComponent as problemsNA } from "../../assets/problems-NA.svg";
import { ReactComponent as problemsNone } from "../../assets/problems-none.svg";
import { ReactComponent as problemsFew } from "../../assets/problems-few.svg";
import { ReactComponent as problemsSevere } from "../../assets/problems-severe.svg";
import {
  DirectionalHint,
  ILabelStyles,
  ITooltipProps,
  Label,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import { AssetUsecaseProblems } from "../../types/dataasset";
import { useTranslation } from "react-i18next";

export interface ProblemsProps {
  value: AssetUsecaseProblems | undefined;
  width?: string;
  height?: string;
}
const problemMouseOverIconStyle: React.CSSProperties = {
  marginBottom: 4,
};
const valueMouseOverLabelStyle: ILabelStyles = {
  root: {
    fontSize: 14,
    marginBottom: 4,
    paddingLeft: 15,
  },
};
export const problemTooltipProps: ITooltipProps = {
  calloutProps: {
    style: {
      width: 160,
      height: 135,
    },
  },
};
const problemIconStyle: React.CSSProperties = {
  marginRight: 10,
  marginLeft: 10,
  cursor: "pointer",
};
export const ProblemToolTip: React.FunctionComponent<ProblemsProps> = ({
  width = "100%",
  height = "8",
  value = 0,
}) => {
  const { t } = useTranslation();

  const problemMouseOverData = [
    {
      icon: React.createElement(problemsNA, { width: "100%" }),
      label: t("problems-na"),
    },
    {
      icon: React.createElement(problemsNone, { width: "100%" }),
      label: t("problems-none"),
    },
    {
      icon: React.createElement(problemsFew, { width: "100%" }),
      label: t("problems-few"),
    },
    {
      icon: React.createElement(problemsSevere, { width: "100%" }),
      label: t("problems-severe"),
    },
  ];

  const problemSVGs = [problemsNA, problemsNone, problemsFew, problemsSevere].map((val) =>
    createElement(val, { width: `${width}`, height: `${height}px` })
  );

  const problemIcon: Record<string, any> = {
    "Not assessed": problemSVGs[0],
    None: problemSVGs[1],
    Few: problemSVGs[2],
    Severe: problemSVGs[3],
  };
  return (
    <>
      <TooltipHost
        content={
          <div>
            {problemMouseOverData.map((data, index) => (
              <Stack horizontal verticalAlign="center" key={index}>
                <span style={problemMouseOverIconStyle}>{data.icon}</span>
                <Label styles={valueMouseOverLabelStyle}>{data.label}</Label>
              </Stack>
            ))}
          </div>
        }
        tooltipProps={problemTooltipProps}
        directionalHint={DirectionalHint.topCenter}
      >
        <div style={problemIconStyle}>{problemIcon[value]}</div>
      </TooltipHost>
    </>
  );
};
