import { IStackProps } from "@fluentui/react";
import { dqcTheme } from "../../../helpers/commonStyles";
import { dqcPalette } from "../../../utils/colors";

export const getWarningIssuesStackProps = (): IStackProps => ({
  tokens: { childrenGap: 5 },
  styles: {
    root: {
      minWidth: 40,
      paddingRight: 2,
      paddingLeft: 5,
      paddingTop: 4,
      paddingBottom: 4,
      border: "inherit",
      borderRadius: "inherit",
    },
  },
});

export const getWarningIconContainerProps = (disabled: boolean): IStackProps => ({
  tokens: {
    childrenGap: 5,
  },
  horizontal: true,
  verticalAlign: "center",
  horizontalAlign: "end",
  styles: {
    root: {
      color: disabled ? dqcTheme.palette.neutralTertiary : dqcPalette.warningIcon,
    },
  },
});
