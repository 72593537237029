import { CommandBarButton, Stack } from "@fluentui/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useWhoAmIQuery } from "../../api/profile";
import { commandBarDefault } from "../../helpers/commonStyles";
import {
  Author,
  CommentBase,
  CommentData,
  defaultEmoticons,
  SubjectType,
  TaggedUser,
} from "../../types/comments";
import { RichTextEditor } from "./RichTextEditor";
import { getTaggedUserId, getTaggedUserEmail } from "../../helpers/utils";
import { ProfilePicker } from "./ProfilePicker";
import { useAppSelector } from "../../store/hooks";
import { selectMsGraphApiAccessToken } from "../../store/slices/remarkSlice";
import { useGetMyUserQuery } from "../../api/users";

interface CommentReplyProps {
  showEditor: boolean;
  setShowEditor: (showEditor: boolean) => void;
  commentToUpdate: (comment: CommentData) => void;
  commentToAppend: (comment: CommentBase) => void;
  commentToCreate: (comment: CommentBase) => void;
  commentThreadId?: string;
  commentData?: CommentData;
  setEditCommentId?: (editCommentId: string) => void;
  subjectType: SubjectType;
}

export const CommentReply: React.FunctionComponent<CommentReplyProps> = ({
  showEditor,
  setShowEditor,
  commentToUpdate,
  commentToAppend,
  commentToCreate,
  commentThreadId,
  commentData,
  setEditCommentId,
  subjectType,
}) => {
  const [commentText, setCommentText] = useState<string>("");
  const [taggedUsers, setTaggedUsers] = useState<TaggedUser[]>([]);
  const accessToken = useAppSelector(selectMsGraphApiAccessToken);
  const { data: userData } = useGetMyUserQuery({ accessToken });
  const session = useWhoAmIQuery({});

  useEffect(() => {
    setCommentText(commentData?.body ? commentData.body : "");
    setTaggedUsers(commentData?.tagged_users ? commentData.tagged_users : []);
  }, [commentData]);

  let authorData: Author;

  if (userData) {
    authorData = {
      display_name: userData.displayName ? userData.displayName : "",
      id: getTaggedUserId(userData),
      email: getTaggedUserEmail(userData),
    };
  } else if (session.data?.identity) {
    authorData = {
      id: session.data?.identity.id,
      email: session.data?.identity.traits?.email,
      display_name: `${session.data?.identity.traits?.name?.first} ${session.data?.identity.traits?.name?.last}`,
      first_name: session.data?.identity.traits?.name?.first,
      last_name: session.data?.identity.traits?.name?.last,
    };
  } else {
    authorData = {
      display_name: "User name not set",
    };
  }

  const handleCommentData = () => {
    if (commentData && setEditCommentId) {
      const updatedComment = { ...commentData };
      updatedComment.body = commentText;
      updatedComment.tagged_users = taggedUsers.map((item) => {
        return {
          displayName: item.displayName,
          id: getTaggedUserId(item),
          email: getTaggedUserEmail(item),
        };
      });
      commentToUpdate(updatedComment);
      setEditCommentId("");
    } else {
      let newComment: CommentBase = {
        author: authorData,
        body: commentText,
        comment_props: {},
        emoticons: defaultEmoticons,
        tagged_users: taggedUsers.map((item) => {
          return {
            displayName: item.displayName,
            id: getTaggedUserId(item),
            email: getTaggedUserEmail(item),
          };
        }),
      };
      if (commentThreadId) {
        commentToAppend(newComment);
      } else {
        commentToCreate(newComment);
      }
      setShowEditor(false);
    }
  };
  if (!showEditor) return null;
  return (
    <Stack tokens={{ childrenGap: 4 }}>
      <Stack
        style={{
          border: "1px solid #C9C9C9",
          padding: 4,
        }}
      >
        <RichTextEditor
          toggleEditor={false}
          onChange={(description) => setCommentText(description)}
          value={commentText || ""}
          comment={true}
          enableDistractionFreeMode
        />
        <ProfilePicker
          taggedUsers={taggedUsers}
          setTaggedUsers={setTaggedUsers}
          placeholder={t("tag_user")}
          subjectType={subjectType}
        />
      </Stack>
      <Stack horizontal verticalAlign="center" horizontalAlign="end">
        <CommandBarButton
          styles={commandBarDefault}
          text={t("cancel")}
          onClick={() => (setEditCommentId ? setEditCommentId("") : setShowEditor(false))}
        />
        <CommandBarButton
          iconProps={{ iconName: "Send" }}
          styles={commandBarDefault}
          text={t("post")}
          disabled={commentText.length === 0}
          onClick={handleCommentData}
        />
      </Stack>
    </Stack>
  );
};
