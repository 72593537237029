import { Label, Stack } from "@fluentui/react";
import React from "react";
import { subTopicLabel } from "../../../helpers/commonStyles";
import { TopConfigMapValue } from "../../../types/missions";
import { ConfigureInputBox } from "./ConfigureInputBox";
import { useTranslation } from "react-i18next";
export interface ConfigureContentProps {
  topConfig: Record<string, TopConfigMapValue>;
  onFieldChange(field_value: number, field_key: string, fieldData: TopConfigMapValue): void;
  errorMessage: string;
}

export const ConfigureContent: React.FunctionComponent<ConfigureContentProps> = ({
  topConfig,
  onFieldChange,
  errorMessage,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {Object.entries(topConfig).map(([key, item]: [string, TopConfigMapValue]) => {
        return (
          <ConfigureInputBox
            key={`key_${key}`}
            fieldData={item}
            onChange={(value) => {
              onFieldChange(value, key, item);
            }}
          ></ConfigureInputBox>
        );
      })}
      {/* Error msg */}
      <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }}>
        <Label style={{ color: "red" }}>{errorMessage}</Label>
      </Stack>
      {/* Fourth row */}
      <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }}>
        <Label styles={subTopicLabel}>{t("edit_column_config_text")}</Label>
      </Stack>
    </>
  );
};
