import { DefaultPalette, IStackItemStyles, IStackStyles, IStackTokens } from "@fluentui/react";
import { dqcTheme } from "./helpers/commonStyles";

export const stackItemStyles: IStackItemStyles = {
  root: {
    background: dqcTheme.palette.neutralLight,
    color: dqcTheme.palette.black,
    display: "flex",
    height: "auto",
    justifyContent: "center",
    overflow: "hidden",
  },
};

export const innerStackTokens: IStackTokens = {
  childrenGap: 0,
  padding: 0,
};

export const stackStyles: Partial<IStackStyles> = {
  root: {
    background: DefaultPalette.neutralLight,
    position: "absolute",
    top: "0px",
    left: "0px",
    bottom: "0px",
    right: "0px",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
};

export const innerStackItemStyles: Partial<IStackStyles> = {
  root: {
    background: DefaultPalette.white,
    overflow: "auto",
    WebkitOverflowScrolling: "none",
    scrollbarWidth: "none",
    MsOverflowStyle: "none",
    borderLeft: "0px solid #FCFCFC",
  },
};

export const stackItemStylesNav: IStackItemStyles = {
  root: {
    borderRight: "1px solid #C3C3C3",
  },
};
