import { Dropdown, IconButton, IDropdownOption, IDropdownStyles, Stack } from "@fluentui/react";
import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useGetDataAssetsQuery } from "../../api/cockpit";
import { AssetType, DataAsset } from "../../types/dataasset";
import { assetTypeToIcons, ListIcon } from "../common/ListIcon";

const dropdownStyles: Partial<IDropdownStyles> = {
  root: {
    marginTop: "2%",
  },
};

type SelectParentDataAssetProps = {
  assetType: AssetType;
  selectedParentId: string | undefined;
  setSelectParentId: (id: string | undefined) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
};
export const SelectParentDataAsset: React.FunctionComponent<SelectParentDataAssetProps> = ({
  assetType,
  selectedParentId,
  setSelectParentId,
  onBlur,
}) => {
  const { t } = useTranslation();
  const dataAssetsQuery = useGetDataAssetsQuery({ all: true });
  const dataAssets: DataAsset[] = dataAssetsQuery.data || [];

  const parentOptionList: IDropdownOption[] = dataAssets
    .filter((item: DataAsset) => {
      if (assetType === "Product") return item.type === "Family" || item.type === "Domain";
      if (assetType === "Family") return item.type === "Domain";
      return false;
    })
    .map((item: DataAsset) => {
      return { key: item?.id, text: item?.title, id: item.type, data: item };
    });
  const onRenderDropDownOption = (option?: IDropdownOption<DataAsset>): JSX.Element | null => {
    if (!option || !option.data) return null;
    const iconIndex = assetTypeToIcons.get(option.data.type);
    return (
      <Stack horizontal>
        <ListIcon score={iconIndex} />
        <span>{option.text}</span>
      </Stack>
    );
  };

  const onRenderTitle = (options?: IDropdownOption<DataAsset>[]): JSX.Element => {
    if (!options || !options.length) return <></>;
    const selectedOption = options[0];
    return (
      <Stack horizontal>
        {onRenderDropDownOption(selectedOption)}
        <IconButton
          iconProps={{ iconName: "Cancel" }}
          onClick={(event) => {
            event.stopPropagation();
            setSelectParentId(undefined);
          }}
        />
      </Stack>
    );
  };

  return (
    <Dropdown
      label={t("select-parent-asset")}
      placeholder={t("select-parent-asset-placeholder")}
      styles={dropdownStyles}
      onChange={(event: FormEvent<HTMLDivElement>, item?: IDropdownOption | any) => {
        setSelectParentId(item.key);
      }}
      options={parentOptionList}
      selectedKey={selectedParentId || null}
      onRenderOption={onRenderDropDownOption}
      onRenderTitle={onRenderTitle}
      onBlur={onBlur}
    />
  );
};
