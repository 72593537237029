import { UploadDQCDataType } from "../types/fileUploader";

/**
 * Gets the indices of empty rows and columns inside a 2D-Array.
 * @param values The 2D-Array being checked
 * @returns An Object containing the indices of empty rows and columns
 */
export const getEmptyRowsAndColumns = (
  values: UploadDQCDataType[][]
): { emptyRows: Set<number>; emptyColumns: Set<number> } => {
  const emptyRows = new Set<number>();
  for (let i = 0; i < values.length; i++) {
    let isEmptyRow = true;
    for (let j = 0; j < values[0].length; j++) {
      if (values[i][j].type !== "Empty") {
        isEmptyRow = false;
        break;
      }
    }
    if (isEmptyRow && values[0].length) emptyRows.add(i);
  }

  const emptyColumns = new Set<number>();
  for (let j = 0; j < values[0].length; j++) {
    let isEmptyColumn = true;
    for (let i = 0; i < values.length; i++) {
      if (values[i][j].type !== "Empty") {
        isEmptyColumn = false;
        break;
      }
    }
    if (isEmptyColumn && values.length) emptyColumns.add(j);
  }

  return { emptyColumns, emptyRows };
};
