import { Survey, SurveyDetail, SurveyResponse } from "../types/survey";
import { api_base, dqcApi } from "./dqcQuery";

export const surveyApi = dqcApi.injectEndpoints({
  endpoints: (builder) => ({
    getSurveys: builder.query<Survey[], string>({
      query: (id: string) => ({
        url: `/${api_base}/surveys/workspaces/${id}`,
        method: "get",
      }),
      providesTags: ["Surveys"],
    }),
    getSurveyDetail: builder.query<SurveyDetail, string>({
      query: (id: string) => ({
        url: `/${api_base}/surveys/detail/${id}`,
        method: "get",
      }),
      providesTags: ["Survey"],
    }),
    getSurveyResponses: builder.query<SurveyResponse, string>({
      query: (id: string) => ({
        url: `/${api_base}/surveys/${id}`,
        method: "get",
      }),
    }),
    updateSurvey: builder.mutation({
      query: (surveyObj: { id: string; activated: boolean }) => ({
        url: `/${api_base}/configurations/surveys`,
        method: "PUT",
        data: surveyObj,
      }),
      invalidatesTags: ["Surveys"],
    }),
  }),
});

export const {
  useGetSurveysQuery,
  useGetSurveyDetailQuery,
  useGetSurveyResponsesQuery,
  useUpdateSurveyMutation,
} = surveyApi;
