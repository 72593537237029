import {
  IStyleFunctionOrObject,
  ITextFieldProps,
  ITextFieldStyleProps,
  ITextFieldStyles,
  TextField,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { FunctionComponent } from "react";

export const EditableTextField: FunctionComponent<ITextFieldProps> = (props: ITextFieldProps) => {
  const [inputVisible, { setTrue: showInput, setFalse: hideInput }] = useBoolean(false);
  const [mouseOver, { setTrue: mouseIsOver, setFalse: mouseIsOut }] = useBoolean(false);

  const field: any = props.styles ? (props.styles as ITextFieldStyles).field!.valueOf() : {};
  const titleEditStyles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = {
    ...props.styles!,
    field: {
      fontSize: !inputVisible ? (field && field["fontSize"] ? field["fontSize"] : "") : "",
      fontWeight: !inputVisible ? (field && field["fontWeight"] ? field["fontWeight"] : "") : "",
    },
    fieldGroup: [
      {
        backgroundColor: mouseOver && !inputVisible ? "#E8E9ED" : "white",
      },
    ],
  };
  return (
    <>
      <TextField
        {...props}
        borderless={!inputVisible}
        styles={titleEditStyles}
        onMouseOver={mouseIsOver}
        onMouseOut={mouseIsOut}
        onFocus={(event) => {
          if (props.onFocus) props.onFocus!(event);
          showInput();
        }}
        onBlur={(event) => {
          if (props.onBlur) props.onBlur!(event);
          hideInput();
        }}
      />
    </>
  );
};
