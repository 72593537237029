import { Icon, Separator, Stack, Text, TooltipHost } from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import {
  selectActiveFilter,
  selectIssuesByDimension,
  setActiveFilter,
} from "../../../store/slices/quickAnalysis";
import { t } from "i18next";
import { capitalizeFirstLetter } from "../../../utils/strings";
import {
  getHighLightedStyle,
  getInnerStackStyle,
  getQualityContainerStyle,
  getSeparatorStyle,
  getStackStyle,
  getTextStyle,
  iconStyle,
} from "./QualityDimension.styles";
import {
  ISSUE_TYPE_ICON_DISABLED_MAP,
  ISSUE_TYPE_ICON_MAP,
  QUALITY_DIMENSION_TOOLTIPS,
} from "../../../helpers/quickAnalysis/qualityChecks/constants";
import { defaultTooltipProps } from "../settings/Settings.styles";
import { WarningIssues } from "../slider/WarningIssues";
import { InfoIssues } from "../slider/InfoIssues";
import {
  handleClickOnQualityDimension,
  selectHighlightedIssue,
} from "../../../store/slices/issueNavigator";
import { IssueExplanation } from "../../../components/quick-analysis/slider/IssueExplanation";
import { DuplicateDetails } from "../slider/DuplicateDetails";
import { IssueSlider } from "../slider/IssueSlider";
const mytooltipprops = JSON.parse(JSON.stringify(defaultTooltipProps));
mytooltipprops.calloutProps.calloutMaxWidth = 120;

export interface QualityDimensionProps {
  qualityDimension: "missing" | "duplicate" | "inconsistent" | "outlier" | "sensitive" | "custom";
}

export const QualityDimension = ({ qualityDimension }: QualityDimensionProps) => {
  const iconName: string | undefined = ISSUE_TYPE_ICON_MAP.get(qualityDimension);
  const issuesForDimension = useAppSelector((state: RootState) =>
    selectIssuesByDimension(state, qualityDimension)
  );
  const highlightedIssue = useAppSelector(selectHighlightedIssue);
  const dispatch = useAppDispatch();
  const isEnabled = !!issuesForDimension.length;
  const activeFilter = useAppSelector(selectActiveFilter);
  const iconNameDisabled: string | undefined = ISSUE_TYPE_ICON_DISABLED_MAP.get(qualityDimension);
  const isActiveFilter = activeFilter === qualityDimension;

  const onQualityDimensionClick = () => {
    if (!isEnabled) return;
    dispatch(setActiveFilter(activeFilter === qualityDimension ? undefined : qualityDimension));
    dispatch(handleClickOnQualityDimension(qualityDimension));
  };

  return (
    <TooltipHost
      content={
        t(QUALITY_DIMENSION_TOOLTIPS[qualityDimension]) ||
        QUALITY_DIMENSION_TOOLTIPS[qualityDimension]
      }
      tooltipProps={mytooltipprops}
    >
      {qualityDimension === "sensitive" && <Separator styles={getSeparatorStyle} />}
      <Stack verticalFill styles={getStackStyle(isEnabled)}>
        <Stack verticalAlign="center" styles={getQualityContainerStyle(isActiveFilter)}>
          <Stack
            horizontal
            grow
            horizontalAlign="space-between"
            verticalAlign="center"
            styles={getInnerStackStyle}
            onClick={onQualityDimensionClick}
          >
            <Icon iconName={isEnabled ? iconName : iconNameDisabled} styles={iconStyle} />
            <Stack horizontal grow horizontalAlign="space-between" verticalAlign="center">
              <Text block nowrap styles={getTextStyle(isEnabled)}>
                {capitalizeFirstLetter(t(qualityDimension))}
              </Text>
              <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 15 }}>
                <>
                  <WarningIssues issues={issuesForDimension} />
                  <InfoIssues issues={issuesForDimension} />
                </>
              </Stack>
            </Stack>
          </Stack>
          {highlightedIssue?.type === qualityDimension && isActiveFilter && (
            <>
              <Stack styles={getHighLightedStyle}>
                {highlightedIssue.type === "duplicate" ? (
                  <DuplicateDetails issues={issuesForDimension} />
                ) : (
                  <IssueSlider qualityDimension={qualityDimension} />
                )}
              </Stack>
              <IssueExplanation issue={highlightedIssue} />
            </>
          )}
        </Stack>
      </Stack>
    </TooltipHost>
  );
};
