export const dqcPalette = {
  themePrimary: "#080F2F",
  themePrimaryHover: "#303550",
  themeHoverDark: "#00000014",
  themeSecondary: "#25FB95",
  secondaryLighter: "#D5F2D3",
  neutralLight: "#F4F3F4",
  neutralTertiary: "#a19f9d",
  neutralPrimary: "#323130",
  themeLighterAlt: "#ced3e6",
  themeLighter: "#C7E0F4",
  themeLight: "#818db9",
  themeTertiary: "#6270a2",
  themeDarkAlt: "#303f75",
  themeDark: "#1d2b5e",
  themeDarker: "#0f1c47",
  neutralLighterAlt: "#faf9f8",
  neutralLighter: "#f3f2f1",
  neutralQuaternaryAlt: "#e1dfdd",
  neutralQuaternary: "#d0d0d0",
  neutralSecondary: "#605e5c",
  neutralSecondaryAlt: "#8a8886",
  neutralPrimaryAlt: "#3b3a39",
  neutralDark: "#201f1e",
  black: "#000000",
  excelBlack: "#A6A6A6",
  white: "#ffffff",
  grey: "#808080",
  dqcBlue: "#060F31",
  issueFill: "#E3DEF2",
  issueFiltered: "#AEA0DC",
  infoIcon: "#7863C3",
  warningIssueFiltered: "#ff8457",
  warningIssueFill: "#ffc1aa",
  warningIcon: "#D83B01",
  issueSolved: "#7AF8A1",
  lightGreyBg: "#F5F5F5",
};

export const QualityScoreColors = {
  A: "#107C10",
  B: "#8BC43D",
  C: "#FFB500",
  D: "#FF6815",
  E: "#FB281A",
};

export const generateCSSVariablesFromColorPalette = (dqcPalette: { [color: string]: string }) => {
  Object.entries(dqcPalette).forEach((el) => {
    document.body.style.setProperty(`--${el[0]}`, `${el[1]}`);
  });
};
/**
 * @param color Color hexcode that should be lightened
 * @param amount Amount of lightening that is to be applied
 * @returns Hexcode of the lightened color
 */
export const lightenColor = (color: string, amount: number) => {
  if (color === "#000000") return dqcPalette.excelBlack; // we should not lighten black by 20% but set it to excel black color
  const isValidHexCode = /^#[0-9A-F]{6}$/i.test(color);
  if (!isValidHexCode) throw new Error("Invalid hexcode in lightenColor");
  const newColor =
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        ("0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substring(-2)
      );
  return newColor;
};
