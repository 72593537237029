import { UploadDataContainer } from "./types/fileUploader";

export const getDataContainerFromWorker = ({
  file,
}: {
  file: File;
}): Promise<UploadDataContainer> => {
  const loadedFilePromise = new Promise<UploadDataContainer>((resolve, reject) => {
    const worker = new Worker(new URL("./fileLoadWorker.ts", import.meta.url));
    worker.onmessage = (e: MessageEvent<UploadDataContainer>) => {
      if (!e.data) {
        console.error("Worker failed unexpectedly");
        reject("File load failed unexpectedly");
      }
      resolve(e.data);
    };
    worker.postMessage({
      file,
    });
  });
  return loadedFilePromise;
};
