import React from "react";
import { FactsAndFiguresItemProps } from "../../types/interactions";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import { FontIcon, ILabelStyles, Label } from "@fluentui/react";
const mainDiv: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
const numberText: ILabelStyles = {
  root: {
    fontSize: "14px",
  },
};

const iconClass = mergeStyles({
  marginTop: "3%",
  marginRight: "12px",
});
export const FactsAndFiguresItem: React.FunctionComponent<FactsAndFiguresItemProps> = ({
  numbers,
  text,
}: FactsAndFiguresItemProps) => {
  return (
    <div style={mainDiv}>
      <FontIcon aria-label="Tick Mark" className={iconClass} iconName="tickMarkIcon" />
      <Label styles={numberText}>
        {numbers}&nbsp;&nbsp;{text}
      </Label>
    </div>
  );
};
