import { Label, Link, Stack, TextField } from "@fluentui/react";
import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import {
  selectCurrentSelectedData,
  selectCurrentSelectedVisual,
} from "../../store/slices/remarkSlice";
import { GlossaryFakeSearchData } from "./glossaryFakeSearchData";
import { distance } from "fastest-levenshtein";
import { ReactComponent as ConfluenceLogo } from "../../assets/confluence-logo.svg";
import { SearchResult } from "../../types/colab";

export type GlossaryProps = {};

// @TODO / WARNING:
// This is just code for our recording and will be removed in the future //
// Extended to be truly functional!
export const Glossary: FunctionComponent<GlossaryProps> = () => {
  const currentSelectedData = useAppSelector(selectCurrentSelectedData);
  const currentSelectedVisual = useAppSelector(selectCurrentSelectedVisual);
  const [searchStr, setSearchStr] = useState("");
  const [fakeSearchResults, setFakeSearchResults] = useState<SearchResult[]>();

  useEffect(() => {
    if (currentSelectedData === undefined || currentSelectedVisual === undefined) {
      setSearchStr("");
      setFakeSearchResults([]);
      return;
    }
    let searchVal: string = getTextFromString(currentSelectedVisual.value);
    if (
      currentSelectedVisual.type === "single" &&
      currentSelectedVisual.visualName !== undefined &&
      currentSelectedVisual.visualName !== "Card"
    ) {
      searchVal = currentSelectedVisual.visualName;
    }
    setSearchStr(searchVal);
    setFakeSearchResults(filterResults(searchVal));
  }, [currentSelectedData, currentSelectedVisual]);

  return (
    <Stack styles={{ root: { margin: 10, paddingTop: 10 } }}>
      <TextField
        placeholder={t("glossary_search_placeholder")}
        styles={{ root: { marginBottom: 10 } }}
        value={searchStr}
        iconProps={{ iconName: "Search" }}
        onChange={(_e, newValue) => {
          setSearchStr(newValue || "");
          setFakeSearchResults(filterResults(newValue));
        }}
      />
      <p>{fakeSearchResults?.length === 0 && t("glossary_explanation")}</p>
      <Stack>
        {fakeSearchResults?.map((sr, index) => {
          return (
            <Stack.Item key={`search-result-${index}`} styles={{ root: { marginBottom: 20 } }}>
              <Stack>
                <Stack.Item>
                  <Label>
                    <ConfluenceLogo width={14} height={14} /> <Link>{sr.title}</Link>
                  </Label>
                </Stack.Item>
                <Stack.Item>{sr.description}</Stack.Item>
              </Stack>
            </Stack.Item>
          );
        })}
      </Stack>
    </Stack>
  );
};

// @TODO / WARNING:
// This is just code for our recording and will be removed in the future
function filterResults(filterValue: string | undefined) {
  return GlossaryFakeSearchData.filter((sr) => {
    const title = sr.title.split(" ")[0] || sr.title;
    const dis1 = distance(filterValue || "", title);
    const dis2 = distance(filterValue || "", sr.title);
    const dis3 = filterValue && sr.title.match(filterValue) !== null;
    return dis1 <= 2 || dis2 <= 2 || dis3;
  });
}

// @TODO / WARNING:
// This is just code for our recording and will be removed in the future
function getTextFromString(value: string | string[]): string {
  if (!value || value === undefined) {
    return "";
  }
  if (typeof value !== "string") value = value.join(" ");
  const returnStr = value.match(/([A-Z])\w+/gi)?.join(" ");
  return returnStr || "";
}
