import { QuickAnalysisIssue } from "../../types/quickAnalysis";
import { dqcPalette } from "../../utils/colors";
import { getMostImportantIssueForCell } from "./qualityChecks/analyze";
import { OVERALL_QUALITY } from "./qualityChecks/constants";

export type CellStyle = {
  backgroundColor: string;
  border?: string;
} | null;

export type RowStyle = {
  backgroundColor: string;
  borderTop?: string;
  borderBottom?: string;
} | null;

export const getCellStyle = (
  issues: QuickAnalysisIssue[],
  activeFilter?: string,
  selectedIssue?: QuickAnalysisIssue
): CellStyle | RowStyle => {
  const issue = getMostImportantIssueForCell(issues, activeFilter, selectedIssue);
  if (issue) {
    const isDuplicateIssue = selectedIssue?.type === "duplicate";
    const isIssueHighLighted = activeFilter === OVERALL_QUALITY || activeFilter === issue.type;

    if (isDuplicateIssue) {
      return getStyle(issues, issue, selectedIssue, activeFilter);
    }

    if (isIssueHighLighted) {
      return getStyle(issues, issue, selectedIssue, activeFilter);
    } else {
      const backgroundColor =
        issue.severity === "warning" ? dqcPalette.warningIssueFill : dqcPalette.issueFill;
      return { backgroundColor };
    }
  }
  return { backgroundColor: "white" };
};

type StyleObject = {
  backgroundColor: string;
  border?: string;
  borderTop?: string;
  borderBottom?: string;
};

const getStyle = (
  issues: QuickAnalysisIssue[],
  issue: QuickAnalysisIssue,
  selectedIssue: QuickAnalysisIssue | undefined,
  activeFilter: string | undefined
): StyleObject => {
  let isIssueBordered = false;
  if (selectedIssue) {
    const isSelectedIssueFoundInDataObject = issues.some(
      (issue: any) => issue.id === selectedIssue?.id
    );
    isIssueBordered = activeFilter === issue.type && isSelectedIssueFoundInDataObject;
  }
  const backgroundColor =
    issue.severity === "warning" ? dqcPalette.warningIssueFiltered : dqcPalette.issueFiltered;
  const border = isIssueBordered ? `1px solid ${dqcPalette.dqcBlue}` : "unset";

  const isDuplicateIssue = issue.type === "duplicate";
  if (isDuplicateIssue) return { backgroundColor, borderTop: border, borderBottom: border };
  return { backgroundColor, border };
};
