import missing from "./assets/missing_icon.svg";
import missingDisabled from "./assets/missing_icon_disabled.svg";
import outlier from "./assets/outlier_icon.svg";
import outlierDisabled from "./assets/outlier_icon_disabled.svg";

import sensitive from "./assets/sensitive_icon.svg";
import sensitiveDisabled from "./assets/sensitive_icon_disabled.svg";
import duplicates from "./assets/duplicates_icon.svg";
import duplicatesDisabled from "./assets/duplicates_icon_disabled.svg";
import inconsistent from "./assets/inconsistent_icon.svg";
import inconsistentDisabled from "./assets/inconsistent_icon_disabled.svg";
import quality from "./assets/quality.svg";
import tableSearch from "./assets/table_search.svg";
import backArrow from "./assets/back_arrow.svg";
import documentTable from "./assets/document_table.svg";
import customChecksIcon from "./assets/custom_checks_icon.svg";
import customChecksIconDisabled from "./assets/custom_checks_icon_disabled.svg";

import exportIcon from "./assets/export_icon.svg";

import { createElement } from "react";
import { ReactComponent as questionMark } from "./assets/questionMark.svg";
import { ReactComponent as tickMark } from "./assets/tick-mark.svg";
import { ReactComponent as wand } from "./assets/wand.svg";
import { ReactComponent as highlighted_analyze } from "./assets/highlighted_analyze.svg";

export const MAIN_ICONS = {
  missingIcon: <img src={missing} alt="missing" width="21" height="21" />,
  outlierIcon: <img src={outlier} alt="outlier" width="21" height="21" />,
  sensitiveIcon: <img src={sensitive} alt="sensitive" width="21" height="21" />,
  inconsistentIcon: <img src={inconsistent} alt="duplicates" width="21" height="21" />,
  duplicatesIcon: <img src={duplicates} alt="duplicates" width="21" height="21" />,
  missingIconDisabled: <img src={missingDisabled} alt="missing" width="21" height="21" />,

  outlierIconDisabled: <img src={outlierDisabled} alt="outlier" width="21" height="21" />,

  sensitiveIconDisabled: <img src={sensitiveDisabled} alt="sensitive" width="21" height="21" />,
  duplicatesIconDisabled: <img src={duplicatesDisabled} alt="duplicates" width="21" height="21" />,
  inconsistentIconDisabled: (
    <img src={inconsistentDisabled} alt="inconsistent" width="21" height="21" />
  ),
  tickMarkIcon: createElement(tickMark),
  questionMarkIcon: createElement(questionMark),
  qualityIcon: <img src={quality} alt="quality" width="20" height="20" />,
  wandIcon: createElement(wand),
  highlightedAnalyzeIcon: createElement(highlighted_analyze),
  tableSearchIcon: <img src={tableSearch} alt="table-search" width="20" height="20" />,
  backArrow: <img src={backArrow} alt="back-arrow" width="16" height="16" />,

  documentTable: <img src={documentTable} alt="document-table" width="16" height="16" />,
  customChecksIcon: <img src={customChecksIcon} alt="customRule" width="16" height="16" />,
  customChecksIconDisabled: (
    <img src={customChecksIconDisabled} alt="customRuleDisabled" width="16" height="16" />
  ),

  exportIcon: <img src={exportIcon} alt="export-icon" width="21" height="21" />,
};
