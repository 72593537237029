import {
  DefaultButton,
  Dropdown,
  Icon,
  IDropdownOption,
  IIconProps,
  Label,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { api_base } from "../../api/dqcREST";
import { useGetSurveyDetailQuery, useGetSurveyResponsesQuery } from "../../api/survey";
import { TopContainer } from "../../components/common/TopContainer";
import { SurveyFreeText } from "../../components/surveys/survey-free-text";
import { SurveyStatistics } from "../../components/surveys/survey-statistics";
import { iconStyle, SpinnerStyles } from "../../helpers/commonStyles";
import { variablesReplaced } from "../../helpers/survey-helpers";
import { Question } from "../../types/survey";

interface Item {
  ref: string;
  question_text: string;
  type: string;
  category: string;
  responses: any;
}
const PARTICIPANT_INFO_QUESTION = "ParticipantInfoQuestion";

export const SurveyDetail: FunctionComponent = () => {
  const excelIcon: IIconProps = { iconName: "ExcelLogo16" };
  const { t } = useTranslation();
  const params = useParams();
  const surveyData = useGetSurveyDetailQuery(params.id ?? skipToken);
  const responseData = useGetSurveyResponsesQuery(params.id ?? skipToken);
  let happiness = 0;
  let happinessCount = 0;
  const [selectedGroupBy, setSelectedGroupBy] = useState<string | undefined>();
  const participantInfoQuestions = responseData?.data?.questions
    .filter((q) => q.category === PARTICIPANT_INFO_QUESTION)
    .map((q) => variablesReplaced(q.question_text, surveyData.data?.variables));

  useEffect(() => {
    !selectedGroupBy &&
      participantInfoQuestions?.length &&
      setSelectedGroupBy(participantInfoQuestions[0]);
  }, [participantInfoQuestions, selectedGroupBy]);

  if (
    responseData.isLoading ||
    !responseData.data ||
    surveyData.isLoading ||
    !surveyData.data ||
    !selectedGroupBy
  )
    return <Spinner size={SpinnerSize.large} styles={SpinnerStyles} />;

  // calculate happiness score
  responseData.data.questions.forEach((i: Item) => {
    if (i.type !== "opinion_scale") return;
    for (const response in i.responses) {
      happiness += Number(i.responses[response]) - 1;
      happinessCount++;
    }
  });
  const happinessScore = Math.round((happiness / (happinessCount * 4)) * 100);
  const questions: Question[] = responseData.data.questions.map((q) => {
    return { ...q, question_text: variablesReplaced(q.question_text, surveyData.data?.variables) };
  });
  const groupResultsByOptions: IDropdownOption[] =
    participantInfoQuestions?.map((q) => {
      return {
        key: q,
        text: q,
      };
    }) || [];

  const columnSorting: Map<string, number> = new Map<string, number>();
  surveyData.data.fields
    .filter((f) => f.title === selectedGroupBy)[0]
    .properties.choices.forEach((c, i) => {
      columnSorting.set(c.label, i);
    });

  const downloadSurveyData = () => {
    window.open(`${api_base}/surveys/${params?.id}/survey-data/xlsx`, "_blank")!.focus();
  };
  return (
    <TopContainer title={t("survey_results")} backButton={true}>
      <>
        <h1>
          <Icon iconName="ClipboardList" styles={iconStyle} style={{ marginRight: "5px" }} />
          {surveyData.data?.title}
        </h1>
        <p>
          {t("survey_detail_explanation_1")}
          {moment(moment.utc(surveyData.data?.started_at)).local().format("DD.MM.YYYY")}
          {t("survey_detail_explanation_2")}
          {responseData.data?.total_responses}
          {t("survey_detail_explanation_3")}
        </p>
        <h1>
          <Icon iconName="ChatBot" styles={iconStyle} style={{ marginRight: "5px" }} />
          {happinessScore} / 100
        </h1>

        <main>
          <Stack>
            <Stack>
              <Stack>
                <Stack.Item>
                  <Label>{t("group_results_by")}:</Label>
                </Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                  <Stack.Item style={{ width: 200 }}>
                    <Dropdown
                      options={groupResultsByOptions}
                      selectedKey={selectedGroupBy}
                      onChange={(_, item: IDropdownOption<any> | undefined): void =>
                        item && setSelectedGroupBy(item.key.toString())
                      }
                    />
                  </Stack.Item>
                  <Stack.Item align="end">
                    <DefaultButton
                      text={t("download_results")}
                      iconProps={excelIcon}
                      onClick={() => downloadSurveyData()}
                    />
                  </Stack.Item>
                </Stack>
              </Stack>
            </Stack>
            <SurveyStatistics
              questions={questions}
              surveyData={surveyData.data}
              selectedGroupBy={selectedGroupBy}
              columnSorting={columnSorting}
            />

            <SurveyFreeText
              questions={questions}
              surveyData={surveyData.data}
              selectedGroupBy={selectedGroupBy}
              columnSorting={columnSorting}
            />
          </Stack>
        </main>
      </>
    </TopContainer>
  );
};
