import { Stack, Label, DirectionalHint, Persona, PersonaSize, TooltipHost } from "@fluentui/react";
import { IDQCPersonaProps, PeoplePickerMSGraph } from "../common/PeoplePickerMSGraph";
import { CreateRemark, Remark } from "../../types/colab";
import { t } from "i18next";
import { profileLabel } from "../../helpers/commonStyles";

export type AssigneesProps = {
  remark: CreateRemark | Remark;
  editMode?: boolean;
  setRemark?: (remark: Remark) => void;
};

export const Assignees = ({ remark, setRemark, editMode }: AssigneesProps): JSX.Element => {
  if (!editMode || !setRemark)
    return (
      <Stack wrap horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
        <Label styles={profileLabel}>{t("to")}</Label>
        {remark.assignees.map((assignee, index) => {
          return (
            <Stack key={`assignees${assignee.id}`}>
              <TooltipHost
                content={assignee.displayName}
                directionalHint={DirectionalHint.topCenter}
              >
                <Persona
                  text={assignee.displayName}
                  hidePersonaDetails={true}
                  size={PersonaSize.size24}
                />
              </TooltipHost>
            </Stack>
          );
        })}
      </Stack>
    );
  return (
    <Stack tokens={{ childrenGap: 5 }} style={{ marginBottom: 12 }} horizontal>
      <Stack.Item>
        <Label style={{ minWidth: 35 }}>{t("to")}</Label>
      </Stack.Item>
      <Stack.Item grow>
        <PeoplePickerMSGraph
          subjectType="remark"
          defaultSelectedItems={remark.assignees.map(
            (a): IDQCPersonaProps => ({ text: a.displayName, key: a.id, email: a.email })
          )}
          onChange={(items: IDQCPersonaProps[]) => {
            setRemark({
              ...remark,
              assignees: items.map((i) => ({ email: i.email, id: i.key, displayName: i.text })),
            } as Remark);
          }}
        />
      </Stack.Item>
    </Stack>
  );
};
