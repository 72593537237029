import { RuleCandidate } from "../../types/rules";
import { dqcApi } from "../dqcQuery";

export const rulesApi = dqcApi.injectEndpoints({
  endpoints: (builder) => ({
    updateRule: builder.mutation<RuleCandidate, RuleCandidate>({
      query: (rule) => ({ url: `/apiV2/rules/${rule.id}`, method: "put", data: rule }),
      invalidatesTags: ["Rules", "DataConnectorList", "Rulesets"],
    }),
  }),
});

export const { useUpdateRuleMutation } = rulesApi;
