import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  ChoiceGroup,
  ComboBox,
  DefaultButton,
  Dropdown,
  IChoiceGroupOption,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { DataTable } from "../dataConnectors/dataConnectorTypes";
import { RulesetApi } from "../../types/rules";
import { clone } from "lodash";
import TeamsLogo from "../../assets/teams-logo.svg";
import SlackLogo from "../../assets/slack-logo.svg";
import APILogo from "../../assets/rest-api-icon.svg";
import { NotificationChannel } from "../../types/notificationChannel";
import { AddAlertForm } from "../../types/alert";
import { useAddAlertMutation } from "../../api/v2/alertsNotifications";

export interface AddAlertDialogProps {
  showHidePanel: boolean;
  toggleShowHidePanel: () => void;
  selectedTable: DataTable;
  selectedRuleset: RulesetApi;
  availableRulesets: RulesetApi[];
}

const options: IChoiceGroupOption[] = [
  {
    key: "teams",
    imageSrc: TeamsLogo,
    imageAlt: "MS Teams",
    selectedImageSrc: TeamsLogo,
    imageSize: { width: 32, height: 32 },
    text: "Teams",
  },
  {
    key: "slack",
    imageSrc: SlackLogo,
    imageAlt: "Slack",
    selectedImageSrc: SlackLogo,
    imageSize: { width: 32, height: 32 },
    text: "Slack",
  },
  {
    key: "api",
    imageSrc: APILogo,
    imageAlt: "REST API",
    selectedImageSrc: APILogo,
    imageSize: { width: 32, height: 32 },
    text: "REST API",
  },
];

export const AddAlertPanel: FunctionComponent<AddAlertDialogProps> = ({
  selectedTable,
  selectedRuleset,
  availableRulesets,
  showHidePanel,
  toggleShowHidePanel,
}) => {
  const { t } = useTranslation();
  const [addAlert] = useAddAlertMutation();
  const [form, setForm] = useState<AddAlertForm>({
    name: "",
    ruleset_id: selectedRuleset.id,
    channelActivated: true,
    individualNotificationsActivated: false,
    selectedChannel: {
      id: "",
      name: "",
      url: "",
      type: "teams",
    },
  });
  const [availableChannels, setAvailableChannels] = useState<NotificationChannel[]>([
    {
      id: "1",
      name: "Slack",
      url: "https://hooks.slack.com/services/T01B4JZQX9M/B01B4K0JZ9M/1",
      type: "slack",
    },
  ]);

  return (
    <Panel
      isOpen={showHidePanel}
      type={PanelType.medium}
      onDismiss={toggleShowHidePanel}
      headerText={t("add_alert")}
      closeButtonAriaLabel={t("close")}
      onRenderFooterContent={() => (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <PrimaryButton
            text={t("save")}
            onClick={async () => {
              try {
                const res = await addAlert({
                  name: form.name,
                  ruleset_id: form.ruleset_id,
                  notification_channel: {
                    name: form.selectedChannel.name,
                    url: form.selectedChannel.url,
                    type: form.selectedChannel.type,
                  },
                });
                console.log(res);
                toggleShowHidePanel();
              } catch (e) {
                alert(e);
              }
            }}
          />
          <DefaultButton text={t("cancel")} onClick={toggleShowHidePanel} />
        </Stack>
      )}
      isFooterAtBottom={true}
    >
      <Separator />
      {/* ADD ALERT FORM */}
      <Stack tokens={{ childrenGap: 16 }}>
        <Text>
          {t("selected_table")} {selectedTable.name}
        </Text>
        <Text>{t("add_alert_explanation")}</Text>
        {/* INPUT[name] */}
        <Stack tokens={{ childrenGap: 8 }} horizontal>
          <Stack.Item styles={{ root: { minWidth: 104 } }}>
            <Label>{t("name")}</Label>
          </Stack.Item>
          <Stack.Item styles={{ root: { width: "100%" } }}>
            <TextField
              styles={{ root: { width: "100%" } }}
              placeholder={t("alert_name_placeholder")}
              value={form.name}
              onChange={(_e, newValue) => setForm({ ...form, name: newValue ?? "" })}
            />
          </Stack.Item>
        </Stack>
        {/* DROPDOWN[ruleset] */}
        <Stack tokens={{ childrenGap: 8 }} horizontal>
          <Stack.Item styles={{ root: { minWidth: 104 } }}>
            <Label>{t("ruleset")}</Label>
          </Stack.Item>
          <Stack.Item styles={{ root: { width: "100%" } }}>
            <Dropdown
              options={availableRulesets.map((ruleset) => ({
                key: ruleset.id,
                text: ruleset.name,
              }))}
              defaultSelectedKey={selectedRuleset.id}
              styles={{ root: { width: "100%" } }}
              onChange={(_e, item) => {
                if (item) {
                  setForm({ ...form, ruleset_id: item.key.toString() });
                }
              }}
            />
          </Stack.Item>
        </Stack>
        <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign="start">
          <Stack.Item styles={{ root: { minWidth: 104 } }} align="start">
            <Label>{t("notifications")}</Label>
          </Stack.Item>
          <Stack styles={{ root: { width: "100%" } }} tokens={{ childrenGap: 8 }}>
            {/* Checkboxes[channel] */}
            <Checkbox
              label={t("notify_channel")}
              checked={form.channelActivated}
              onChange={(_e, checked) => {
                setForm({ ...form, channelActivated: !!checked });
              }}
            />
            {/* Checkboxes[individuals] */}
            <Checkbox
              label={t("notify_individuals")}
              checked={form.individualNotificationsActivated}
              onChange={(_e, checked) => {
                setForm({ ...form, individualNotificationsActivated: !!checked });
              }}
              disabled={true}
            />
            {/* inputs for [channels] */}
            {form.channelActivated && (
              <Stack tokens={{ childrenGap: 4 }}>
                <Text variant="small">{t("notify_channel_explanation")}</Text>
                <ComboBox
                  label={t("webhook_name")}
                  placeholder={t("webhook_name_placeholder")}
                  options={availableChannels.map((channel) => ({
                    key: channel.id ?? "",
                    text: channel.name,
                    data: {
                      url: channel.url,
                      type: channel.type,
                    },
                  }))}
                  selectedKey={form.selectedChannel.id}
                  autoComplete="on"
                  allowFreeform
                  onChange={(_e, item, _index, value) => {
                    if (item) {
                      setForm({
                        ...form,
                        selectedChannel: {
                          id: item.key.toString(),
                          name: item.text,
                          url: item.data.url,
                          type: item.data.type,
                        },
                      });
                    } else if (value) {
                      const id = value.trim().toLowerCase().replace(" ", "_");
                      setForm({
                        ...form,
                        selectedChannel: {
                          id,
                          name: value,
                          url: "",
                          type: "teams",
                        },
                      });
                      setAvailableChannels([
                        ...availableChannels,
                        { id, name: value, url: "", type: "teams" },
                      ]);
                    }
                  }}
                />
                <TextField
                  label={t("webhook_url")}
                  placeholder={t("webhook_url_placeholder")}
                  description={t("webhook_url_description")}
                  value={form.selectedChannel.url}
                  onChange={(_e, newValue) => {
                    setForm({
                      ...form,
                      selectedChannel: {
                        ...form.selectedChannel,
                        url: newValue ?? "",
                      },
                    });
                    const changedChannels = clone(availableChannels);
                    changedChannels.find((channel) => channel.id === form.selectedChannel.id)!.url =
                      newValue ?? "";
                    setAvailableChannels(changedChannels);
                  }}
                />
                <ChoiceGroup
                  selectedKey={form.selectedChannel.type}
                  options={options}
                  onChange={(_e, option) => {
                    if (option) {
                      const newType = option.key.toString() as "teams" | "slack" | "api";
                      setForm({
                        ...form,
                        selectedChannel: {
                          ...form.selectedChannel,
                          type: newType,
                        },
                      });
                      const changedChannels = clone(availableChannels);
                      changedChannels.find(
                        (channel) => channel.id === form.selectedChannel.id
                      )!.type = newType;
                      setAvailableChannels(changedChannels);
                    }
                  }}
                />
                <Separator />
              </Stack>
            )}
            {/* inputs for [individuals] */}
            {form.individualNotificationsActivated && (
              <Stack tokens={{ childrenGap: 4 }}>
                <Separator />
                <TextField label={t("emails")} />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Panel>
  );
};
