import {
  DefaultButton,
  DefaultPalette,
  IStackStyles,
  Icon,
  PrimaryButton,
  Stack,
} from "@fluentui/react";

import { useState } from "react";
import { iconStyle } from "../../helpers/commonStyles";

const stackStyles: Partial<IStackStyles> = {
  root: {
    backgroundColor: "#FBFBFB",
    margin: 20,
    flex: 1,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    display: "flex",
  },
};

type TileProps = {
  title: string;
  icon: string;
  body: JSX.Element;
  primaryButtonText: string;
  primaryButtonOnClick: () => void;
  secondaryButtonText: string;
  secondaryButtonOnClick: () => void;
  status: "completed" | "ready" | "disabled";
};

const textBodyStyle: React.CSSProperties = {
  height: "130px",
};

export const Accordion = ({
  title,
  icon,
  body,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
  status,
}: TileProps): JSX.Element => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  return (
    <Stack styles={stackStyles} tokens={{ childrenGap: 10 }}>
      <Stack
        horizontal
        verticalAlign="center"
        style={{ marginLeft: 20 }}
        tokens={{ childrenGap: 20 }}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 20 }}
          styles={{ root: { cursor: "pointer" } }}
        >
          <Icon iconName={icon} styles={iconStyle} />
          <p style={{ fontWeight: 600, fontSize: "20px", lineHeight: "20px" }}>{title}</p>
        </Stack>
        <Stack
          grow
          horizontal
          horizontalAlign="end"
          verticalAlign="center"
          tokens={{ padding: 20, childrenGap: 20 }}
        >
          {status === "completed" && (
            <Icon iconName="BoxCheckmarkSolid" style={{ color: "#2EE500" }} styles={iconStyle} />
          )}
          <Icon iconName={collapsed ? "ChevronRight" : "ChevronDown"} styles={iconStyle} />
        </Stack>
      </Stack>
      <div
        style={{
          backgroundColor: status === "completed" ? "#2EE500" : DefaultPalette.neutralLight,
          height: 5,
          width: "100%",
        }}
      />
      {!collapsed && (
        <Stack tokens={{ childrenGap: 20, padding: 20 }}>
          <div style={textBodyStyle}>{body}</div>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <PrimaryButton
              text={primaryButtonText}
              onClick={() => primaryButtonOnClick()}
              disabled={status === "disabled"}
            />
            <DefaultButton
              text={secondaryButtonText}
              onClick={secondaryButtonOnClick}
              disabled={status === "disabled"}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
