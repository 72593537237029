import { IDropdownOption } from "@fluentui/react";
import { Report } from "powerbi-client";
import { TaggedUser } from "./comments";

export type PowerBIEmbeddingState = {
  type: "POWER_BI";
  selectedData: SelectedData;
  reportManager?: ReportManager;
  username?: string;
  error?: string;
};

export type EmbeddingState = PowerBIEmbeddingState;

export type SelectedData = {
  visual?: DataChange;
  data?: DataChange;
  selection?: DataChange;
};

export type DataChange = MultipleChange | SingleChange;

export type MultipleChange = {
  type: "multiple";
  value: string[];
  lastChanged: number;
};
export type SingleChange = {
  type: "single";
  value: string;
  lastChanged: number;
  visualName?: string;
};

export type ReportManager = {
  report: Report;
  reportName: string;
  reportId: string;
};

export type VisibilityOption = IDropdownOption & {
  key: CreateRemark["visibility"];
};

export type CreateRemark = {
  report_id: string;
  report_type: "POWER_BI" | "TABLEAU";
  original_field: string;
  changed_field: string;
  description: string;
  creator: string;
  assignees: TaggedUser[];
  visibility: "ALL" | "CREATOR" | "SPECIFIC";
  specific_visibilities: TaggedUser[];
  bookmark: string | undefined;
  status: "OPEN" | "SOLVED";
  thumbs_up: string[];
};

export type Remark = CreateRemark & {
  id: string;
  created_at: Date;
  updated_at: Date;
};

export type RemarksSortKeyStandard = "creator" | "date" | "upvotes";

export type RemarksSortKeyReversed = "creator_reversed" | "date_reversed" | "upvotes_reversed";

export type RemarksSortKey = RemarksSortKeyStandard | RemarksSortKeyReversed;

export type RemarkDataCells = {
  original: string[];
  changed: string[];
};

export const cellsInitiateState: RemarkDataCells = {
  original: [],
  changed: [],
};

export type SearchResult = {
  title: string;
  description: string;
};

export type ReportMetricsAPIResponseModel = {
  number_of_remarks: number;
  number_of_comments: number;
  tagged_users: TaggedUser[];
};

export type ReportMetricsDisplayModel = ReportMetricsAPIResponseModel & {
  id: string;
  name: string;
  last_access: string;
  link: string;
  date: any;
};

export interface ColabDashboardQuery {
  user_id: string;
  report_ids: string[];
}

export type ReportData = {
  date: string;
  id: string;
};

export type RemarkAddEditState = {
  multilines: boolean[];
  dataCells: RemarkDataCells;
  showErrorMessage: boolean;
};

export const remarkAddEditInitialState: RemarkAddEditState = {
  multilines: [],
  dataCells: cellsInitiateState,
  showErrorMessage: false,
};
