import React, { useRef } from "react";
import { IHeaderParams, Column, ColDef } from "ag-grid-community";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { UploadDQCDataType, UploadDataContainer } from "../../../types/fileUploader";
import {
  UpdatedColumn,
  selectLatestUpload,
  updateColumnsDataType,
} from "../../../store/slices/upload";
import {
  doRulesAnalysis,
  selectLatestQuickAnalysisStatus,
} from "../../../store/slices/quickAnalysis";
import { Dropdown, IDropdownOption, Label, Stack } from "@fluentui/react";
import { dqcPalette } from "../../../utils/colors";

export interface ICustomHeaderParams extends IHeaderParams {
  menuIcon: string;
  column: Column;
}

const allUploadDataTypes: UploadDQCDataType["type"][] = [
  "String",
  "Integer",
  "Double",
  "Boolean",
  "Unknown",
  "Empty",
  "Date",
  "Categorical",
  "GeoCoordinate",
];
const options: IDropdownOption[] = allUploadDataTypes.map((dataType) => ({
  key: dataType,
  text: dataType,
  data: dataType,
}));

export const DataGridHeader = (props: ICustomHeaderParams) => {
  const refButton = useRef(null);
  const linkRef = useRef(null);
  const colDef: ColDef = props.column.getColDef();
  const columnIndexString = colDef.field?.split(".")[0] || "";
  const columnIndex = parseInt(columnIndexString);
  const status = useAppSelector(selectLatestQuickAnalysisStatus);
  const areResultsVisible = status === "results";
  const onMenuClicked = () => {
    props.showColumnMenu(refButton.current!);
  };

  let menu = null;
  if (props.enableMenu) {
    menu = (
      <div ref={refButton} className="ag-header-cell-menu-button" onClick={() => onMenuClicked()}>
        <i className="ag-icon ag-icon-menu" />
      </div>
    );
  }

  const dispatch = useAppDispatch();

  const uploadContainer: UploadDataContainer | undefined = useAppSelector(selectLatestUpload);
  let columnDataType: UploadDQCDataType["type"] = "Unknown";
  const setUpdatedColumnDataType = (choiceKey: UploadDQCDataType["type"]) => {
    if (uploadContainer) {
      const fileName = uploadContainer.fileName;
      const updatedColumns: UpdatedColumn[] = uploadContainer.columns?.map((column, index) => {
        const isCategoricalData = choiceKey === "Categorical";
        const newColumn = { ...column, dataType: choiceKey, isCategoricalData };
        if (index !== columnIndex) {
          return column;
        }
        return newColumn;
      });
      dispatch(updateColumnsDataType({ fileName, columns: updatedColumns }));
      dispatch(doRulesAnalysis({ ...uploadContainer, columns: updatedColumns, forceReload: true }));
    }
  };
  if (uploadContainer) columnDataType = uploadContainer?.columns[columnIndex].dataType;

  return (
    <Stack
      className="ag-cell-label-container"
      styles={{
        root: {
          display: "flex",
          flexDirection: "column !important",
          padding: "unset !important",
        },
      }}
    >
      <div
        className="ag-header-cell-label"
        ref={linkRef}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Label className="ag-header-cell-text" styles={{ root: { padding: 12 } }}>
          {props.displayName}
        </Label>
        <Stack styles={{ root: { paddingRight: 12 } }}>{menu}</Stack>
      </div>
      <Stack
        styles={{
          root: {
            width: "100%",
            height: areResultsVisible ? "unset" : "50%",
            backgroundColor: dqcPalette.lightGreyBg,
            outline: "1px solid grey",
            padding: 4,
          },
        }}
      >
        {areResultsVisible && (
          <Dropdown
            selectedKey={columnDataType}
            options={options}
            onChange={(ev, option) => {
              if (option) {
                setUpdatedColumnDataType(option.key as UploadDQCDataType["type"]);
              }
            }}
            styles={{
              root: {
                width: "100%",
              },
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};
