import { QualityConfig } from "../types/quality";
import { GetSelectedColumns } from "../types/dataset";

export const columnArray: string[] = ["duplicate", "missing", "iqr", "sign", "stringlength"];

export const getSelectedColumns = (tempQualityConfig: QualityConfig): GetSelectedColumns => {
  const config = tempQualityConfig.config;
  const selectedDuplicateIds = config.duplicate.selected_columns.map((c) => c.id);
  const selectedMissingIds = config.missing.selected_columns.map((c) => c.id);
  const selectedIQRIds = config.outlier.iqr.selected_columns.map((c) => c.id);
  const selectedSignIds = config.outlier.sign.selected_columns.map((c) => c.id);
  const selectedStringLengthIds = config.outlier.string_length.selected_columns.map((c) => c.id);

  const all_column_infos = tempQualityConfig.all_column_infos;

  const selectedDuplicateColumns = all_column_infos.filter((c) =>
    selectedDuplicateIds?.includes(c.id)
  );
  const selectedMissingColumns = all_column_infos.filter((c) => selectedMissingIds?.includes(c.id));
  const selectedIQRColumns = all_column_infos.filter((c) => selectedIQRIds?.includes(c.id));
  const selectedSignColumns = all_column_infos.filter((c) => selectedSignIds?.includes(c.id));
  const selectedStringLengthColumns = all_column_infos.filter((c) =>
    selectedStringLengthIds?.includes(c.id)
  );

  return {
    selectedDuplicateColumns,
    selectedMissingColumns,
    selectedIQRColumns,
    selectedSignColumns,
    selectedStringLengthColumns,
  };
};
