import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteTaskMutation } from "../../../api/mission";
import { TaskDeleteDialogProps } from "../../../types/missions";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";

export const TaskDeleteDialog: FunctionComponent<TaskDeleteDialogProps> = ({
  taskId,
  allTasks,
  setAllTasks,
  hideDeleteTaskDialog,
  toggleHideDeleteTaskDialog,
}) => {
  const { t } = useTranslation();
  const [deleteTaskReq] = useDeleteTaskMutation();

  const deleteTask = async (id: string) => {
    const delTask = await deleteTaskReq(id);
    if (delTask) {
      const newTasks = allTasks?.filter((i) => {
        return i.id !== id;
      });
      setAllTasks(newTasks);
      toggleHideDeleteTaskDialog(false);
    }
  };

  return (
    <ConfirmationDialog
      showHideDialog={hideDeleteTaskDialog}
      toggleShowHideDialog={toggleHideDeleteTaskDialog}
      primaryButtonText={t("delete")}
      secondaryButtonText={t("cancel")}
      primaryButtonClicked={() => deleteTask(taskId ? taskId : "")}
      dialogTitle={t("confirmation_deletetask_title")}
      confirmationText={t("confirmation_deletetask_text")}
    ></ConfirmationDialog>
  );
};
