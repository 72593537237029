import {
  IStackItemStyles,
  IStackStyles,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useFetchRecoveryDataQuery } from "../../api/profile";
import logo from "../../assets/logo.svg";
import i18n from "../../i18n";

const stackStyle: IStackStyles = {
  root: {
    minWidth: "420px",
    "@media(max-width: 550px)": {
      minWidth: "320px",
    },
    "@media(max-width: 350px)": {
      minWidth: "260px",
    },
    margin: "auto",
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    padding: "10px",
  },
};

const containerStyle: React.CSSProperties = {
  width: "100%",
  textAlign: "center",
};

const goBacklinkStyle: React.CSSProperties = {
  textAlign: "center",
  display: "inline-block",
  margin: "0 auto",
};

export const UserRecovery = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  if (!searchParams.get("flow")) {
    window.location.replace(`${window.location.origin}/auth/self-service/recovery/browser`);
  }
  const recoveryData = useFetchRecoveryDataQuery(searchParams.get("flow") ?? skipToken).currentData;

  if (!recoveryData?.ui) return null;
  const [csrf, email, submit] = recoveryData?.ui.nodes;

  return (
    <>
      <form action={recoveryData?.ui.action} method={recoveryData?.ui.method}>
        <Stack>
          <Stack horizontalAlign="center" styles={stackStyle}>
            <Stack.Item align="stretch" styles={stackItemStyles}>
              <img className="App-logo" src={logo} alt="logo" />
              <p>{t("recover_your_account")}</p>
              {recoveryData?.ui?.messages?.length ? (
                <>
                  <MessageBar
                    messageBarType={
                      recoveryData.ui.messages[0]?.type === "info"
                        ? MessageBarType.info
                        : MessageBarType.warning
                    }
                    isMultiline={true}
                  >
                    {recoveryData?.ui.messages
                      .map((m) => (i18n.exists(String(m.id)) ? t(String(m.id)) : m.text))
                      .join(";")}
                  </MessageBar>
                  <div style={containerStyle}>
                    <a href="/" style={goBacklinkStyle}>
                      Go back to login
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <input
                    title={csrf.attributes.name}
                    type={"hidden"}
                    name={csrf.attributes.name}
                    value={csrf.attributes.value}
                  ></input>
                  <TextField
                    label={t("email")}
                    autoFocus
                    iconProps={{ iconName: "MailAttached" }}
                    name={email.attributes.name}
                  />
                  {email.messages?.length ? (
                    <MessageBar
                      messageBarType={
                        email.messages[0].type === "info"
                          ? MessageBarType.info
                          : MessageBarType.warning
                      }
                      isMultiline={true}
                    >
                      {email.messages.map((m) => m.text).join(";")}
                    </MessageBar>
                  ) : (
                    <></>
                  )}
                  <br />
                  <PrimaryButton
                    text={t("recover_btn")}
                    type="submit"
                    id="recoverSubmitBtn"
                    value={submit.attributes.value}
                    name={submit.attributes.name}
                  />
                </>
              )}
            </Stack.Item>
          </Stack>
        </Stack>
      </form>
    </>
  );
};
