import { IStackStyles, Label, Stack } from "@fluentui/react";
import * as React from "react";

const editLabelContainer: IStackStyles = {
  root: {
    width: "40%",
  },
};

type TaskModalFieldContainerProps = {
  label: string;
  children: any;
};
export const TaskModalFieldContainer: React.FunctionComponent<TaskModalFieldContainerProps> = (
  props: TaskModalFieldContainerProps
) => {
  return (
    <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }}>
      <Stack.Item styles={editLabelContainer}>
        <Label>{props.label}</Label>
      </Stack.Item>
      <Stack.Item grow={1}>{props.children}</Stack.Item>
    </Stack>
  );
};
