import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";

export type AppSlice = {
  flowId?: string;
  identityId?: string;
};

const initialState: AppSlice = {};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setFlowId: (state: AppSlice, action: PayloadAction<string>) => {
      state.flowId = action.payload;
    },
    setIdentityId: (state: AppSlice, action: PayloadAction<string>) => {
      state.identityId = action.payload;
    },
  },
});

export const { setFlowId, setIdentityId } = appSlice.actions;

const selectAppSlice = (state: RootState) => state.app;

export const selectFlowId = createSelector(selectAppSlice, (app) => app.flowId);

export const selectIdentityId = createSelector(selectAppSlice, (app) => app.identityId);
