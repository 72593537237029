import { Bar, BarDatum, BarLegendProps } from "@nivo/bar";
import React from "react";
import { useTranslation } from "react-i18next";
import { numbers } from "../../helpers/chartUtils";
import { headerStyle, mainContainerStyle } from "../../helpers/commonStyles";
const theme = {
  axis: {
    fontSize: "14px",
    tickColor: "#eee",
    ticks: {
      line: {
        stroke: "#555555",
      },
      text: {
        fill: "#000000",
      },
    },
    legend: {
      text: {
        fill: "#aaaaaa",
      },
    },
  },
  grid: {
    line: {
      stroke: "#555555",
    },
  },
};

const legends: BarLegendProps[] = [
  {
    dataFrom: "keys",
    anchor: "bottom-right",
    direction: "column",
    justify: false,
    translateX: 120,
    translateY: 0,
    itemsSpacing: 2,
    itemWidth: 100,
    itemHeight: 20,
    itemDirection: "left-to-right",
    itemOpacity: 0.85,
    itemTextColor: "#000000",
    symbolSize: 20,
    effects: [
      {
        on: "hover",
        style: {
          itemOpacity: 1,
        },
      },
    ],
  },
];

export const BarChart: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const DATA_COUNT = 7;
  const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };
  let contributorData: number[] = numbers(NUMBER_CFG);
  let userData: number[] = numbers(NUMBER_CFG);
  let barData: BarDatum[] = [];
  const contrbutorLabels = ["Finance", "Sales", "Product", "Marketing", "Sust."];

  contrbutorLabels.forEach((label, index) => {
    return barData.push({
      label: label,
      contributors: contributorData[index],
      users: userData[index],
    });
  });

  return (
    <section style={mainContainerStyle}>
      <h3 style={headerStyle}>{t("top_contributors_user")}</h3>
      <Bar
        width={530}
        height={225}
        margin={{ top: 50, right: 125, bottom: 25, left: 40 }}
        data={barData}
        keys={["contributors", "users"]}
        indexBy="label"
        label={""}
        labelTextColor="inherit:darker(2.4)"
        labelSkipWidth={12}
        labelSkipHeight={12}
        enableGridX={false}
        colors={({ id }) => (id === "contributors" ? "#4D49BE" : "#70CD39")}
        theme={theme}
        legends={legends}
      />
    </section>
  );
};
