import { Dropdown, IDropdownOption, Stack, Text } from "@fluentui/react";
import { t } from "i18next";
import { CategoricalDataTest, Category, RuleCandidate } from "../../../../types/rules";
import { useState } from "react";
import { NameAndDescriptionInput } from "../NameDescription";
import { RuleDetailModalFooter } from "../RuleDetailModalFooter";
import { MODAL_DETAIL_LEFT_MARGIN } from "./bodyStyles";

export const CategoricalDataDetailBody = ({
  rule,
  toggleModal,
}: {
  rule: RuleCandidate;
  toggleModal: () => void;
}) => {
  const [localRule, setLocalRule] = useState<RuleCandidate>(rule);
  if (localRule.qualityTest.testFunctionName !== "categoricalData") return null;
  const localQualityTest: CategoricalDataTest = localRule.qualityTest;
  const dropDownOptions: IDropdownOption<Category>[] =
    localQualityTest.meta.legalCategoricalValues.map(({ value, frequency }) => ({
      key: value,
      text: `${value} (${frequency})`,
    }));
  const selectedKeys = localQualityTest.meta.legalCategoricalValues
    .filter(({ isLegal }) => isLegal)
    .map(({ value }) => value);
  return (
    <>
      <Stack tokens={{ childrenGap: 12 }}>
        <NameAndDescriptionInput rule={localRule} updateRule={setLocalRule} />
        <Stack tokens={{ childrenGap: 8 }}>
          <Stack horizontal verticalAlign="center">
            <Stack
              styles={{
                root: { marginLeft: MODAL_DETAIL_LEFT_MARGIN, marginRight: 8, flexGrow: 1 },
              }}
            >
              <Text block nowrap>
                {t("valid_categories")}
              </Text>
            </Stack>
            <Dropdown
              options={dropDownOptions}
              onChange={(_, option?: IDropdownOption<Category>) => {
                if (!option) return;
                const legalCategoricalValues = toggleLegalCategory(
                  localQualityTest.meta.legalCategoricalValues,
                  option.key.toString(),
                  !!option.selected
                );
                const newQualityTest: CategoricalDataTest = {
                  ...localQualityTest,
                  meta: { legalCategoricalValues },
                };
                const newRule: RuleCandidate = {
                  ...localRule,
                  qualityTest: newQualityTest,
                };
                setLocalRule(newRule);
              }}
              styles={{
                title: { borderRadius: 4 },
                root: { overflow: "hidden", minwidth: 300, maxWidth: 300, width: 300 },
              }}
              multiSelect
              selectedKeys={selectedKeys}
            />
          </Stack>
        </Stack>
      </Stack>
      <RuleDetailModalFooter rule={localRule} updateRule={setLocalRule} toggleModal={toggleModal} />
    </>
  );
};

const toggleLegalCategory = (
  categories: Category[],
  value: string,
  selected: boolean
): Category[] => {
  return categories.map((c) => (c.value === value ? { ...c, isLegal: selected } : c));
};
