import { Stack, Text } from "@fluentui/react";
import { Image, ImageFit } from "@fluentui/react/lib/Image";
import pluginGif from "../../assets/analyzing.gif";
import { t } from "i18next";
import { useAppSelector } from "../../store/hooks";
import { selectLatestQuickAnalysisStatus } from "../../store/slices/quickAnalysis";

export const Loader = () => {
  const status = useAppSelector(selectLatestQuickAnalysisStatus);
  return (
    <Stack
      verticalAlign="center"
      horizontalAlign="center"
      styles={{ root: { height: "100%" } }}
      tokens={{ childrenGap: 40 }}
    >
      <Image
        styles={{ root: { margin: "auto", marginTop: 180 } }}
        imageFit={ImageFit.contain}
        width={80}
        src={pluginGif}
        alt="loading icon"
      />
      <Text>{t(status)}</Text>
    </Stack>
  );
};
