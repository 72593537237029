import {
  DefaultButton,
  IconButton,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  useAddDataAssetToUseCaseMutation,
  useAddNewOriginMutation,
  useGetAllOriginsQuery,
  useGetAssetQuery,
  useUpdateAssetMutation,
} from "../../../api/cockpit";
import { TopContainer } from "../../../components/common/TopContainer";
import {
  descDetailStyle,
  itemStyleDetail,
  itemStyleMain,
  linkItemsContainer,
  numericalSpacingStackTokens,
  SpinnerStyles,
} from "../../../helpers/commonStyles";
import { Details } from "./details";
import { UseCaseList } from "../../../components/cockpit/UseCaseList";
import { useBoolean } from "@fluentui/react-hooks";
import { DataAsset, Origin } from "../../../types/dataasset";
import { EditableTextField } from "../../../components/common/EditableTextField";
import { RichTextEditor } from "../../../components/common/RichTextEditor";
import { SelectLinkedUseCases } from "../../../components/cockpit/SelectLinkedUseCases";
import { AssetList } from "../../../components/cockpit/AssetList";
import { SelectChildrenDataAssets } from "../../../components/cockpit/SelectChildrenDataAssets";
import { CommentWrapper } from "../../../components/common/CommentWrapper";
import { useGetCommentsQuery } from "../../../api/comments";

export const Asset: FunctionComponent = () => {
  const { t } = useTranslation();

  const params = useParams();
  const assetData = useGetAssetQuery(params.id ?? skipToken);
  const originsData = useGetAllOriginsQuery();
  const [addOrigin] = useAddNewOriginMutation();
  const [updateAsset] = useUpdateAssetMutation();
  const [addAssetToUseCase] = useAddDataAssetToUseCaseMutation();
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [asset, setAsset] = useState<DataAsset>();
  const [loaded, { setTrue: hasLoaded }] = useBoolean(false);
  const [linkAssetsVisible, { setTrue: showAssetLinking, setFalse: hideAssetLinking }] =
    useBoolean(false);
  const [linkUseCasesVisible, { setTrue: showUseCasesLinking, setFalse: hideUseCasesLinking }] =
    useBoolean(false);
  const [newlyLinkedUseCaseIDs, setNewlyLinkedUseCaseIDs] = useState<string[]>([]);
  const { data } = useGetCommentsQuery(params.id ?? skipToken, {
    pollingInterval: 10000,
  }); // Added interval for comments api so that we have latest comments getting updated on frontend for consistency.
  const updateAssetCall = () => {
    asset && asset.id && updateAsset(asset);
  };

  useEffect(() => {
    if (assetData.isSuccess && assetData.data) {
      setAsset(assetData.data);
      setValue(assetData.data.description ? assetData.data.description : "");
      setTitle(assetData.data.title);
      return;
    }
  }, [assetData.isSuccess, assetData.data]);

  useEffect(() => {
    if (assetData.isSuccess && asset && assetData.data?.parent_asset_id !== asset?.parent_asset_id)
      return updateAssetCall();

    if (assetData.data !== asset && loaded) {
      const newOrigins = asset?.origins?.filter((a: Origin) => a.id.match(/^temp-/));
      let updateCalls: any[] = [];
      if (newOrigins) {
        newOrigins.forEach((orig: Origin) => {
          updateCalls.push(addOrigin(orig));
        });
      }
      if (updateCalls.length > 0) {
        Promise.all(updateCalls)
          .then((results) => {
            asset &&
              asset.origins &&
              asset.origins.length > 0 &&
              updateAsset({
                ...asset,
                origins: [
                  ...asset.origins?.filter((a: Origin) => !a.id.match(/^temp-/)),
                  ...results.map((r) => r.data),
                ],
              });
          })
          .catch(() => {
            console.error("Update failed");
          });
      }
    } else if (assetData.data !== asset) {
      hasLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, assetData.data, loaded]);

  return (
    <TopContainer title={t("asset_detail_title")} backButton={true}>
      {assetData.isLoading || assetData.isLoading ? (
        <Spinner size={SpinnerSize.large} styles={SpinnerStyles} />
      ) : (
        <>
          <EditableTextField
            id="assetTitle"
            value={title}
            placeholder={t("title")}
            onBlur={updateAssetCall}
            onChange={(
              _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) => {
              if (newValue) {
                setTitle(newValue);
                asset && setAsset({ ...asset, title: newValue });
              }
            }}
            styles={{
              root: { marginTop: "18px", marginBottom: "4px" },
              field: { fontSize: "18px", fontWeight: "900" },
            }}
          />
          <Stack horizontal tokens={numericalSpacingStackTokens}>
            <section style={itemStyleMain}>
              <label
                style={{
                  ...descDetailStyle,
                  marginLeft: 8,
                }}
              >
                {t("description")}
              </label>
              <RichTextEditor
                id="editor"
                toggleEditor={true}
                value={value}
                onChange={(newValue) => {
                  if (newValue) {
                    setValue(newValue);
                    asset &&
                      setAsset({
                        ...asset,
                        description: newValue,
                      });
                  }
                }}
                onSave={() => {
                  updateAssetCall();
                }}
                onCancel={() => {
                  if (assetData && assetData.data) {
                    setValue(assetData.data?.description ? assetData.data?.description : "");
                    setAsset({ ...assetData.data });
                  }
                }}
              />
              {asset && asset.type && asset.type !== "Product" && (
                <>
                  <Separator />
                  <Stack styles={linkAssetsVisible ? undefined : linkItemsContainer}>
                    <label style={{ ...descDetailStyle, marginLeft: 10 }}>
                      {t("assets_linked")}
                    </label>
                    {linkAssetsVisible ? (
                      <>
                        <Stack tokens={{ childrenGap: 6 }} style={{ marginLeft: 10 }}>
                          <SelectChildrenDataAssets
                            assetType={asset?.type}
                            selectedChildrenIds={asset?.child_asset_ids || []}
                            setSelectedChildrenIds={(ids: string[]) => {
                              setAsset({ ...asset, child_asset_ids: ids });
                            }}
                          />

                          <Stack
                            horizontal
                            styles={{ root: { marginBottom: 10 } }}
                            tokens={{ childrenGap: 15 }}
                          >
                            <PrimaryButton
                              text={t("save")}
                              onClick={() => {
                                updateAsset({
                                  ...asset!,
                                }).then(() => {
                                  hideAssetLinking();
                                });
                              }}
                            />
                            <DefaultButton text={t("cancel")} onClick={hideAssetLinking} />
                          </Stack>
                        </Stack>
                      </>
                    ) : (
                      <IconButton
                        iconProps={{ iconName: "Add" }}
                        title={t("add")}
                        ariaLabel={t("add")}
                        onClick={showAssetLinking}
                      />
                    )}
                  </Stack>
                  {asset?.linked_assets && (
                    <AssetList dataAssets={asset?.linked_assets} isDragAndDrop={false} />
                  )}
                </>
              )}
              <Separator />
              <Stack styles={linkUseCasesVisible ? undefined : linkItemsContainer}>
                <label style={{ ...descDetailStyle, marginLeft: 10 }}>
                  {t("use_cases_linked")}
                </label>
                {linkUseCasesVisible ? (
                  <>
                    <Stack tokens={{ childrenGap: 6 }} style={{ marginLeft: 10 }}>
                      <SelectLinkedUseCases
                        linkedIds={newlyLinkedUseCaseIDs}
                        setLinkedIds={setNewlyLinkedUseCaseIDs}
                      />
                      <Stack
                        horizontal
                        styles={{ root: { marginTop: "1%", marginBottom: "1%" } }}
                        tokens={{ childrenGap: 5 }}
                      >
                        <Stack
                          horizontal
                          styles={{ root: { marginBottom: 10 } }}
                          tokens={{ childrenGap: 15 }}
                        >
                          <PrimaryButton
                            text={t("save")}
                            onClick={() => {
                              Promise.all(
                                newlyLinkedUseCaseIDs.map((id) =>
                                  addAssetToUseCase({ dataAssetId: asset!.id, useCaseId: id })
                                )
                              ).then((results) => {
                                setNewlyLinkedUseCaseIDs([]);
                              });
                            }}
                          />
                          <DefaultButton text={t("cancel")} onClick={hideUseCasesLinking} />
                        </Stack>
                      </Stack>
                    </Stack>
                  </>
                ) : (
                  <IconButton
                    iconProps={{ iconName: "Add" }}
                    title={t("add")}
                    ariaLabel={t("add")}
                    onClick={showUseCasesLinking}
                  />
                )}
              </Stack>
              <UseCaseList useCases={asset?.linked_use_cases} />
              <Separator />
              {asset && (
                <CommentWrapper
                  comments={data?.comments || []}
                  subjectId={asset.id}
                  subjectType="asset"
                  commentThreadId={data?.id}
                />
              )}
            </section>
            <section style={itemStyleDetail}>
              <Details
                asset={asset}
                setAsset={setAsset}
                origins={originsData.data}
                updateAsset={updateAssetCall}
              />
            </section>
          </Stack>
        </>
      )}
    </TopContainer>
  );
};
