import React, { useEffect, useState } from "react";
import { SearchBox, Spinner, Stack } from "@fluentui/react";
import {
  filterIssuesBySearchTerm,
  getIgnoredIssueIdsFromSelection,
  transformIssues,
} from "../../helpers/issue-helper";
import {
  useGetIssuesQuery,
  useGetMissionByIdQuery,
  useUpdateIssuesMutation,
} from "../../api/mission";
import { useNavigate, useParams } from "react-router-dom";

import { IssuesList } from "../../components/mission/datasets/IssuesList";
import { SelectionMap } from "../../types/issue";
import { TopContainer } from "../../components/common/TopContainer";
import { setPollingInterval } from "../../store/slices/qualitySummarySlice";
import { useAppDispatch } from "../../store/hooks";
import { useTranslation } from "react-i18next";
import { useWhoAmIQuery } from "../../api/profile";

export const IssueView: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { missionId, datasetId, qualityDimension } = useParams();
  let navigate = useNavigate();
  const session = useWhoAmIQuery({});
  const email = session.currentData?.identity?.traits.email;
  const missionRequest = useGetMissionByIdQuery(missionId!);
  const mission = missionRequest.data;
  const dataset = missionRequest.data?.datasets.find((dataset) => dataset.id === datasetId);
  const issues = useGetIssuesQuery({ missionId, datasetId, qualityDimension }).data;
  const [selection, setSelection] = useState<SelectionMap>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [updateIssuesReq] = useUpdateIssuesMutation();

  useEffect(() => {
    if (issues) {
      let selectionData: Record<string, boolean> = {};
      issues.forEach((v) => {
        if (v.ignored === true) selectionData[v.id] = true;
      });
      setSelection(selectionData);
    }
  }, [issues]);

  const onSave = async () => {
    if (mission?.id && dataset?.id) {
      try {
        setLoading(true);
        const ignored_issue_ids: string[] = getIgnoredIssueIdsFromSelection(selection);
        const all_issues_ids = issues?.map((i) => i.id);
        const unignored_issue_ids = all_issues_ids?.filter((i) => !ignored_issue_ids.includes(i));

        const res = await updateIssuesReq({
          missionId: mission?.id,
          data: {
            ignored_issue_ids,
            unignored_issue_ids,
            user_id: email,
          },
          dataset_id: dataset?.id,
        });

        if (res) {
          dispatch(setPollingInterval(1000));
          navigate(`/missions/${mission?.id}`);
        }
      } catch (e) {
        console.log("Error", e);
      }
      setLoading(false);
    }
  };
  const issuesForTable = filterIssuesBySearchTerm(transformIssues(issues), searchTerm);

  if (missionRequest.isLoading)
    return (
      <TopContainer title={t("issue_details")}>
        <Spinner />
      </TopContainer>
    );
  return (
    <TopContainer title={t("issue_details")}>
      <main style={{ width: "95%" }}>
        <Stack>
          <h2>{mission?.title}</h2>
          <h3>
            {dataset?.name} {t("issues_text")}
          </h3>
          <SearchBox
            placeholder="Search"
            onChange={(event?: React.ChangeEvent<HTMLInputElement>, searchTerm?: string) => {
              setSearchTerm(searchTerm || "");
            }}
          />
        </Stack>
        <div>
          {mission && issuesForTable.length > 0 && dataset?.urn ? (
            <IssuesList
              selection={selection}
              setSelection={setSelection}
              allIssues={issuesForTable}
              datasetUrn={dataset?.urn}
              missionId={missionId!}
              loading={loading}
              onSave={onSave}
            />
          ) : null}
        </div>
      </main>
    </TopContainer>
  );
};
