import { Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Widget } from "@typeform/embed-react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetSurveyDetailQuery } from "../../api/survey";
import { SpinnerStyles } from "../../helpers/commonStyles";

export const SurveyForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const params = useParams();
  const surveyData = useGetSurveyDetailQuery(params.id ?? skipToken);
  if (surveyData.isLoading || !surveyData.data)
    return <Spinner size={SpinnerSize.large} styles={SpinnerStyles} />;
  return (
    <>
      {surveyData.data?.activated ? (
        <div style={{ height: "100%" }}>
          <Widget id={params.id!} style={{ width: "100%", height: "100%" }} />
        </div>
      ) : (
        <Stack style={{ marginLeft: 100, marginTop: 50 }}>
          <h1>{t("survey_stopped_title")}</h1>
          <Text style={{ fontSize: 16 }}>{t("survey_stopped_explanation")}</Text>
        </Stack>
      )}
    </>
  );
};
