import { IStackProps, IStackStyles, ITextStyles, ITooltipProps } from "@fluentui/react";
import { dqcPalette } from "../../../utils/colors";
import { MODAL_DETAIL_LEFT_MARGIN } from "../rules/detail-bodies/bodyStyles";

export const MODAL_DETAIL_RIGHT_MARGIN = "10%";

export const CollapsedSettingsContainer: IStackStyles = {
  root: {
    padding: 4,
    borderRadius: 8,
    backgroundColor: dqcPalette.white,
    cursor: "pointer",
  },
};
export const ExpandedSettingsContainer: IStackStyles = {
  root: {
    padding: 4,
    borderRadius: 8,
    backgroundColor: dqcPalette.neutralLight,
  },
};

export const settingsMessageBarStandardStyle = {
  text: { fontSize: 10 },
  root: {
    marginTop: 5,
    marginLeft: 10,
    width: "calc(100% - 20px)",
  },
};

export const defaultTooltipProps: ITooltipProps = {
  styles: {
    content: {
      color: "#FFFFFF",
      textAlign: "left",
    },
  },
  calloutProps: {
    styles: {
      beak: { display: "none" },
      beakCurtain: { background: "#7C7878" },
      calloutMain: { background: "#7C7878" },
    },
    calloutMaxWidth: 280,
  },
};

export const lightGreyText: ITextStyles = {
  root: {
    fontWeight: 400,
    fontSize: 12,
    color: dqcPalette.neutralSecondary,
  },
};

export const sliderProps: IStackProps = {
  horizontal: true,
  styles: {
    root: {
      marginBottom: "6px",
      alignItems: "baseline",
      justifyContent: "space-between",
      marginLeft: MODAL_DETAIL_LEFT_MARGIN,
      marginRight: MODAL_DETAIL_RIGHT_MARGIN,
    },
  },
};
