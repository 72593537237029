import {
  DefaultButton,
  FontIcon,
  IStackItemStyles,
  Link,
  PrimaryButton,
  Spinner,
  Stack,
} from "@fluentui/react";

import { CopyLicenseKey } from "./copy-license-key";
import { FunctionComponent } from "react";
import { ProfileComponentsProps } from "./profile-head";
import Tracking from "../../../tracking/tracking";
import { dqcTheme } from "../../../helpers/commonStyles";
import moment from "moment";
import { t } from "i18next";
import { useSearchParams } from "react-router-dom";

const stripe_billing_link = process.env.REACT_APP_STRIPE_BILLING;
const itemStyle: IStackItemStyles = {
  root: { height: "32px", display: "inline-flex", alignItems: "center", columnGap: "5px" },
};

export const ProfileOffering: FunctionComponent<ProfileComponentsProps> = ({ profile, styles }) => {
  const [searchParams] = useSearchParams();
  const successPage = searchParams.get("success");
  const TWO_WEEKS_IN_MILLISECONDS = 14 * 24 * 60 * 60 * 1000;
  const twoWeeksAgo = new Date(Date.now() - TWO_WEEKS_IN_MILLISECONDS);

  if (!profile)
    return <Spinner label={t("loading")} ariaLive={t("loading")} labelPosition="right" />;

  let isOnTrialPeriod = profile.profile && profile.profile.trialStart > twoWeeksAgo;
  if (profile.profile?.license === true) isOnTrialPeriod = false;

  const ReloadButton = () => {
    if (profile.profile?.license || profile.profile?.corporateAccount || successPage) return <></>;
    return (
      <Link
        styles={{ root: { fontSize: "0.8em" } }}
        onClick={() => {
          window.location.reload();
        }}
      >
        {t("stripe-reload")}
      </Link>
    );
  };
  const LicenseButton = () => {
    if (profile.profile?.license || profile.profile?.corporateAccount)
      return <PrimaryButton text={t("manage_subscription")} href={stripe_billing_link} />;
    return (
      <PrimaryButton
        text={t("subscribe_now")}
        onClick={() => {
          Tracking.trackLoginSignupEvent("Subscribe Now in Platform");
          window.location.href = "/licenses";
        }}
        href="/licenses"
      />
    );
  };

  const trialTimeStrFromNow = moment(profile.profile?.trialStart).add(2, "weeks").fromNow();
  const { license, corporateAccount } = profile.profile || {
    license: undefined,
    corporateAccount: undefined,
  };
  const extraSpace = "    ";
  const UserSpecificOfferDisplay = () => {
    return (
      <Stack.Item styles={itemStyle}>
        {!license && !isOnTrialPeriod ? (
          <FontIcon aria-label="Cancel" iconName="Cancel" style={{ color: dqcTheme.palette.red }} />
        ) : (
          <FontIcon
            aria-label="Accept"
            iconName="Accept"
            style={{ color: dqcTheme.palette.green }}
          />
        )}
        {`${extraSpace}${t("offer-excel-assistant")}`}
        <Stack styles={{ root: { display: "inline", marginLeft: "15%" } }}>
          {isOnTrialPeriod && ` ${t("profile_free_trial_1")} ${trialTimeStrFromNow}`}
          {!license && !isOnTrialPeriod && (
            <span style={{ color: dqcTheme.palette.red }}>{` ${t(
              "trial-expired"
            )} ${trialTimeStrFromNow}`}</span>
          )}
          {corporateAccount ? (
            <DefaultButton
              disabled
              styles={{ root: { scale: "0.7" } }}
              text={t("corporate_account")}
            />
          ) : (
            <>
              <LicenseButton />
              <ReloadButton />
            </>
          )}
        </Stack>
        {license && profile.profile?.licenseKey && (
          <CopyLicenseKey licenseKey={profile.profile?.licenseKey} />
        )}
      </Stack.Item>
    );
  };
  const LoadingSpinner = () => {
    return (
      <Stack.Item>
        <Spinner label={t("loading")} ariaLive={t("loading")} labelPosition="right" />
      </Stack.Item>
    );
  };

  return (
    <Stack styles={styles}>
      <h3>{t("dqc-offering")}</h3>
      {profile && profile.profile ? <UserSpecificOfferDisplay /> : <LoadingSpinner />}
    </Stack>
  );
};
