import { Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";

export const ThankYouForPurchase = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Stack
      style={{
        backgroundColor: "lightgreen",
        padding: 10,
        borderColor: "darkgreen",
        borderWidth: 4,
        borderRadius: 4,
        marginTop: 10,
      }}
    >
      <h3>{t("thank_you_for_the_purchase")}</h3>
      <p>{t("reload_excel_add_in")}</p>
    </Stack>
  );
};
