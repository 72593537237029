import { RefObject, useContext, useEffect } from "react";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalContext } from "@azure/msal-react";
import { auth } from "./powerbi-auth-layer";
import { service, factories, models, IEmbedConfiguration, Report } from "powerbi-client";
import { ReportManager } from "../../types/colab";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectEmbeddingError,
  selectEmbedState,
  setEmbeddingError,
  setSelectedData,
} from "../../store/slices/remarkSlice";

const powerbi = new service.Service(
  factories.hpmFactory,
  factories.wpmpFactory,
  factories.routerFactory
);

const clientId: string = "9d4a07dc-8b71-45f1-86d0-716ffea0795b";
const authorityUrl: string = "https://login.microsoftonline.com/common/";
const scopeBase: string[] = ["https://analysis.windows.net/powerbi/api/Report.Read.All"];
const powerBiApiUrl: string = "https://api.powerbi.com/";

const instanceProvider = new PublicClientApplication({
  auth: {
    clientId: clientId,
    authority: authorityUrl,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
});

export const msalInstanceProvider = () => {
  return instanceProvider;
};

interface PowerBIEmbeddingState {
  reportRef: RefObject<HTMLDivElement>;
  setReportManager: (reportManager: ReportManager) => void;
}

export const PowerBIEmbedding: FunctionComponent<PowerBIEmbeddingState> = ({
  reportRef,
  setReportManager,
}) => {
  const { reportid } = useParams();
  const dispatch = useAppDispatch();
  const msal = useContext(MsalContext);
  const isAuthenticated = msal.accounts.length > 0;
  const embedState = useAppSelector(selectEmbedState);
  const embeddingError = useAppSelector(selectEmbeddingError);

  let reportContainer: HTMLElement;

  useEffect(() => {
    reportContainer = reportRef["current"]!;
    if (!reportid) {
      dispatch(setEmbeddingError("The URL does not contain a correctly formatted report id"));
      return;
    }
    try {
      if (!embeddingError) {
        auth(msal, powerBiApiUrl, reportid, scopeBase, embedState, dispatch);
      }
    } catch (e) {
      console.error(e);
    }

    if (
      isAuthenticated &&
      embedState &&
      embedState.embedUrl !== undefined &&
      embedState.embedUrl !== ""
    ) {
      const embedConfiguration: IEmbedConfiguration = {
        type: "report",
        tokenType: models.TokenType.Aad,
        accessToken: embedState.accessToken,
        embedUrl: embedState.embedUrl,
        id: reportid,
        settings: {
          //background: models.BackgroundType.Transparent,
          bars: {
            actionBar: {
              visible: true,
            },
          },
        },
      };

      const report = powerbi.embed(reportContainer, embedConfiguration);
      setReportManager({
        report: report as Report,
        reportName: embedState.reportName,
        reportId: embedState.reportId,
      });

      interface Target {
        table: string;
        column: string;
      }

      interface Identity {
        target: Target;
        equals: string;
      }

      interface Value {
        target: Target;
        value: number;
        formattedValue: string;
      }

      interface DataPoint {
        identity: Identity[];
        values: Value[];
      }

      interface EventDetail {
        visual: any;
        dataPoints: DataPoint[];
      }
      [
        "loaded",
        "buttonClicked",
        "commandTriggered",
        "dataHyperlinkClicked",
        "dataSelected",
        "pageChanged",
        "rendered",
        "selectionChanged",
        "visualClicked",
      ].forEach((eventName) => {
        report.off(eventName);
        report.on(eventName, async (event) => {
          let eventDetail: any;
          eventDetail = event.detail as EventDetail;
          // for deselecting
          if (eventDetail && eventDetail.dataPoints === null) {
            return dispatch(setSelectedData({}));
          }

          let currentVisual;
          const page = await (report as Report).getActivePage();
          const visuals = await page.getVisuals();
          switch (event.type) {
            case "dataSelected":
              // two possible options (A) when getting a dataSelected event, in cases of line-charts
              // for example, we can receive multiple dataPoints, for this, we are able to display
              // the text, as seen below
              if (eventDetail && eventDetail.dataPoints && eventDetail.dataPoints.length > 1) {
                const dataSelected = (eventDetail as EventDetail).dataPoints.map((value) => {
                  const label = value.identity[0].equals;
                  const dataValue = value.values[0].formattedValue;
                  return `${label}: ${dataValue}`;
                });
                return dispatch(
                  setSelectedData({
                    data: {
                      type: "multiple",
                      value: dataSelected,
                      lastChanged: Date.now(),
                    },
                  })
                );
              }

              // second option (B), e.g., for tree-charts, we only receive 1 dataPoint entry, for
              // such cases, we have to export the underlyingData (time consuming) and match with
              // the selector
              currentVisual = visuals.filter((v) => v.name === eventDetail.visual.name);
              const underlyingData = await currentVisual[0].exportData(
                models.ExportDataType.Summarized,
                30000
              );

              if (eventDetail && eventDetail.dataPoints && eventDetail.dataPoints[0]) {
                const selector = eventDetail.dataPoints[0].identity
                  .map((i: any) => i.equals)
                  .join(",");

                const dataSelected = underlyingData.data
                  .split(/\n/gi)
                  .filter((i) => i.match(selector));

                dispatch(
                  setSelectedData({
                    data: {
                      type: "multiple",
                      value: dataSelected,
                      lastChanged: Date.now(),
                    },
                  })
                );
              }
              break;
            case "visualClicked":
              currentVisual = visuals.filter((v) => v.name === eventDetail.visual.name);
              const underlyingDataVisual = await currentVisual[0].exportData(
                models.ExportDataType.Summarized,
                3
              );

              dispatch(
                setSelectedData({
                  visual: {
                    type: "single",
                    value: underlyingDataVisual.data,
                    lastChanged: Date.now(),
                    visualName: currentVisual[0].title,
                  },
                })
              );

              break;
            case "selectionChanged":
              currentVisual = visuals.filter((v) => v.name === eventDetail.visual?.name);
              if (!currentVisual || !currentVisual[0]) break;
              const underlyingDataSelection = await currentVisual[0]?.exportData(
                models.ExportDataType.Summarized,
                3
              );

              dispatch(
                setSelectedData({
                  selection: {
                    type: "single",
                    value: underlyingDataSelection.data,
                    lastChanged: Date.now(),
                  },
                })
              );
              break;
            case "buttonClicked":
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const bookmarks = await (report as Report).bookmarksManager.getBookmarks();
              // do nothing for now
              break;
            default:
          }
        });
      });
      return () =>
        [
          "loaded",
          "buttonClicked",
          "commandTriggered",
          "dataHyperlinkClicked",
          "dataSelected",
          "pageChanged",
          "rendered",
          "selectionChanged",
          "visualClicked",
        ].forEach((eventName) => {
          report.off(eventName);
        });
    }
  }, [isAuthenticated, embedState, msal]);

  return <></>;
};
