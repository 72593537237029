import {
  Stack,
  DefaultButton,
  PrimaryButton,
  TextField,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { RichTextEditor } from "../../components/common/RichTextEditor";
import { containerBorder } from "../../helpers/commonStyles";
import { Remark, remarkAddEditInitialState, RemarkAddEditState } from "../../types/colab";
import { Visibility } from "./visibility";
import { Assignees } from "./assignees";
import {
  FIELD_SEPARATOR,
  showDiffHTML,
  SINGLE_LINE_TEXTFIELD_LENGTH,
} from "../../helpers/remarkUtils";
import { useUpdateRemarkMutation } from "../../api/remark";
import { setEditRemarkId } from "../../store/slices/remarkSlice";
import { useAppDispatch } from "../../store/hooks";
import { t } from "i18next";
interface EditRemarkProps {
  onCancel: () => void;
  remark: Remark;
}

export const EditRemark: FunctionComponent<EditRemarkProps> = ({ onCancel, remark }) => {
  const [updateRemark] = useUpdateRemarkMutation();
  const dispatch = useAppDispatch();
  const [localRemark, setLocalRemark] = useState<Remark>(remark);
  const [editRemarkState, setEditRemarkState] =
    useState<RemarkAddEditState>(remarkAddEditInitialState);
  const onChange = (
    _event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    newValue?: string | undefined
  ): void => {
    const changedFields: string[] = [...editRemarkState.dataCells.changed];
    changedFields[index] = newValue ? newValue : "";
    setEditRemarkState({
      ...editRemarkState,
      dataCells: { ...editRemarkState.dataCells, changed: changedFields },
    });
    handleTextFieldsSize(index);
  };

  useEffect(() => {
    setLocalRemark({
      ...localRemark,
    });
    const originalFields: string[] = remark.original_field.split(FIELD_SEPARATOR);
    const changedFields: string[] = remark.changed_field.split(FIELD_SEPARATOR);

    setEditRemarkState({
      ...editRemarkState,
      dataCells: {
        ...editRemarkState.dataCells,
        original: originalFields,
        changed: changedFields,
      },
    });
    handleTextFieldsSize(-1, changedFields);
  }, [remark]);

  const handleTextFieldsSize = (index: number, splitValues?: string[]) => {
    let multilinesState: boolean[] = [...editRemarkState.multilines];
    if (index > -1) {
      //Update the multiline state at an index based on char length
      multilinesState[index] =
        editRemarkState.dataCells.changed[index] &&
        editRemarkState.dataCells.changed[index].length > SINGLE_LINE_TEXTFIELD_LENGTH
          ? true
          : false;
    } else if (splitValues && splitValues.length > 0) {
      //Initialisizing the multiline state
      multilinesState = [];
      splitValues.forEach((changedField) => {
        multilinesState.push(
          changedField && changedField.length > SINGLE_LINE_TEXTFIELD_LENGTH ? true : false
        );
      });
    }
    setEditRemarkState({
      ...editRemarkState,
      multilines: multilinesState,
    });
  };
  return (
    <Stack style={{ marginBottom: 12 }}>
      <Stack horizontal horizontalAlign="space-between" className="selectionChange">
        <Stack style={{ width: "49%" }}>
          {editRemarkState.dataCells.original.map((changedDataCell, index) => {
            return (
              <div
                key={`original-${index}`}
                className="changedText"
                dangerouslySetInnerHTML={{
                  __html: showDiffHTML(
                    editRemarkState.dataCells.original[index],
                    editRemarkState.dataCells.changed[index]
                  ),
                }}
              />
            );
          })}
        </Stack>
        <Stack style={{ width: "49%" }}>
          {editRemarkState.dataCells.changed.map((originalDataCell, index) => {
            return (
              <TextField
                key={`changed-${index}`}
                value={editRemarkState.dataCells.changed[index]}
                onChange={(e, newValue) => {
                  onChange(e, index, newValue);
                }}
                className="originalCell"
                autoAdjustHeight={true}
                multiline={editRemarkState.multilines[index]}
                borderless={false}
              />
            );
          })}
        </Stack>
      </Stack>
      <Stack.Item styles={containerBorder} style={{ marginTop: 10 }}>
        <RichTextEditor
          toggleEditor={false}
          onChange={(description) => setLocalRemark({ ...localRemark, description: description! })}
          value={localRemark.description || ""}
          comment={true}
          enableDistractionFreeMode={true}
        />
      </Stack.Item>
      <Visibility remark={localRemark} setRemark={setLocalRemark} editMode />
      <Assignees remark={localRemark} setRemark={setLocalRemark} editMode />

      <Stack horizontal horizontalAlign="end">
        <DefaultButton text="Cancel" onClick={onCancel} styles={{ root: { marginRight: 10 } }} />
        <PrimaryButton
          text="Submit"
          onClick={async () => {
            const updatedRemark = {
              ...localRemark,
              original_field: editRemarkState.dataCells.original.join(FIELD_SEPARATOR),
              changed_field: editRemarkState.dataCells.changed.join(FIELD_SEPARATOR),
            };
            try {
              await updateRemark({
                remark: updatedRemark,
                remarkId: updatedRemark.id || "",
              }).unwrap();
              dispatch(setEditRemarkId(undefined));
            } catch (error) {
              setEditRemarkState({
                ...editRemarkState,
                showErrorMessage: true,
              });
            }
          }}
        />
      </Stack>
      {editRemarkState.showErrorMessage && (
        <Stack.Item style={{ marginTop: "10px" }}>
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            onDismiss={() => {
              setEditRemarkState({ ...editRemarkState, showErrorMessage: false });
            }}
            dismissButtonAriaLabel="Close"
          >
            {t("something_went_wrong")}
          </MessageBar>
        </Stack.Item>
      )}
    </Stack>
  );
};
