import {
  IStackItemStyles,
  IStackStyles,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useFetchVerificationDataQuery } from "../../api/profile";
import logo from "../../assets/logo.svg";

const stackStyle: IStackStyles = {
  root: {
    minWidth: "420px",
    "@media(max-width: 550px)": {
      minWidth: "320px",
    },
    "@media(max-width: 350px)": {
      minWidth: "260px",
    },
    margin: "auto",
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    padding: "10px",
  },
};

export const UserVerifcation = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  if (!searchParams.get("flow")) {
    window.location.replace(`${window.location.origin}/auth/self-service/verification/browser`);
  }
  const verificationData = useFetchVerificationDataQuery(searchParams.get("flow")!).currentData;

  if (!verificationData?.ui) return null;
  const [csrf, email, submit] = verificationData?.ui.nodes;

  return (
    <>
      <form action={verificationData?.ui.action} method={verificationData?.ui.method}>
        <Stack>
          <Stack horizontalAlign="center" styles={stackStyle}>
            <Stack.Item align="stretch" styles={stackItemStyles}>
              <img className="App-logo" src={logo} alt="logo" />
              <p>{t("verify_your_acount")}</p>
              {verificationData?.ui?.messages?.length ? (
                <MessageBar
                  messageBarType={
                    verificationData.ui.messages[0]?.type === "info"
                      ? MessageBarType.info
                      : MessageBarType.warning
                  }
                  isMultiline={true}
                >
                  {verificationData?.ui.messages.map((m) => m.text).join(";")}
                </MessageBar>
              ) : (
                <></>
              )}
              <input
                title={csrf.attributes.name}
                type={"hidden"}
                name={csrf.attributes.name}
                value={csrf.attributes.value}
              ></input>
              <TextField
                label={t("email")}
                autoFocus
                iconProps={{ iconName: "MailAttached" }}
                name={email.attributes.name}
              />
              {email.messages?.length ? (
                <MessageBar
                  messageBarType={
                    email.messages[0].type === "info" ? MessageBarType.info : MessageBarType.warning
                  }
                  isMultiline={true}
                >
                  {email.messages.map((m) => m.text).join(";")}
                </MessageBar>
              ) : (
                <></>
              )}
              <br />
              <PrimaryButton
                text={t("verify_account")}
                type="submit"
                id="verifySubmitBtn"
                value={submit.attributes.value}
                name={submit.attributes.name}
              />
            </Stack.Item>
          </Stack>
        </Stack>
      </form>
    </>
  );
};
