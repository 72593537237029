export const capitalizeFirstLetter = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const toProperCase = (word: string) => {
  word = word.replace(/_/g, " ");
  return word[0].toUpperCase() + word.substring(1).toLowerCase();
};
/**
 * Returns an object mapping all unique values of `strings` to their frequency
 * @param strings
 * @returns
 */
export const getFrequencyMap = (strings: string[]): Record<string, number> => {
  return strings.reduce((frequencyMap: { [key: string]: number }, s: string) => {
    frequencyMap[s] ? ++frequencyMap[s] : (frequencyMap[s] = 1);
    return frequencyMap;
  }, {});
};

/**
 * Returns the most frequent string in the array of strings and its frequency
 * @param strings
 * @returns
 */
export const getMostFrequentTuple = (strings: string[]): [string, number] => {
  const occurrences = getFrequencyMap(strings);
  return Object.entries(occurrences).reduce(
    ([accFormat, accFrequency], [format, frequency]) => {
      if (accFrequency && accFrequency >= frequency) return [accFormat, accFrequency];
      return [format, frequency];
    },
    ["", 0]
  );
};

export const getMostFrequent = (strings: string[]): string => {
  return getMostFrequentTuple(strings)[0];
};

export type MostFrequentStringArray = {
  mostFrequent: string;
  frequency: number;
};
export const getMostFrequentString = (strings: string[]): MostFrequentStringArray => {
  const [mostFrequent, frequency] = getMostFrequentTuple(strings);
  return {
    mostFrequent,
    frequency,
  };
};

/**
 * Lowercases the input string, removes:
 * any multiple spaces
 * leading/trailing spaces
 * all punctuation
 *
 * @param s
 * @returns
 */
export const normalizeString = (s: string): string => {
  s = s.toLowerCase(); // Convert to lowercase
  s = s.replace(/\W+/g, " "); // Remove punctuation
  s = s.replace(/\s+/g, " "); // Remove extra spaces
  return s.trim(); // Remove leading and trailing spaces
};

let canvas: HTMLCanvasElement | undefined;

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export const getTextWidth = (text: string, font: string): number => {
  // re-use canvas object for better performance
  canvas = canvas ?? document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) return 0;
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
};
