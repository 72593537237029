import { IStackItemStyles, IStackStyles, MessageBar, MessageBarType, Stack } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { getErrorId, useFetchErrorDataQuery } from "../../api/profile";
import logo from "../../assets/logo.svg";
import { SingleError } from "../../types/auth";

const stackStyle: IStackStyles = {
  root: {
    minWidth: "420px",
    "@media(max-width: 550px)": {
      minWidth: "320px",
    },
    "@media(max-width: 350px)": {
      minWidth: "260px",
    },
    margin: "auto",
  },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    padding: "10px",
  },
};

export const ErrorView = () => {
  const { t } = useTranslation();
  const errorData = useFetchErrorDataQuery(getErrorId());

  return (
    <Stack>
      <Stack horizontalAlign="center" styles={stackStyle}>
        <Stack.Item align="stretch" styles={stackItemStyles}>
          <img className="App-logo" src={logo} alt="logo" />
          <p>An error occured</p>
          {errorData.isSuccess &&
            errorData.currentData! instanceof Array &&
            errorData.currentData?.map((err) => (
              <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                {err.message}
              </MessageBar>
            ))}
          {errorData.isSuccess && errorData.currentData! instanceof Object && (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
              <u>{(errorData.currentData as SingleError).error.message}</u>
              <br />
              {(errorData.currentData as SingleError).error.reason}
            </MessageBar>
          )}

          {errorData.isError && <MessageBar>{t("error_not_found")}</MessageBar>}
        </Stack.Item>
      </Stack>
    </Stack>
  );
};
