import { IStackItemStyles } from "@fluentui/react";
import { dqcPalette } from "../../../utils/colors";

export const getDuplicateFirstRowStyle = (isSelected: boolean): IStackItemStyles => {
  return {
    root: {
      width: 100,
      fontSize: 12,
      marginTop: 2,
      textDecoration: isSelected ? "underline" : "unset",
      fontWeight: isSelected ? "bold" : "unset",
      color: isSelected ? dqcPalette.themePrimary : "unset",
    },
  };
};
