import { FunctionComponent, CSSProperties } from "react";
import { Icon, IIconStyles } from "@fluentui/react";

const subContainerStyle: CSSProperties = {
  background: "rgba(0, 177, 64, 0.1)",
  border: "0.5px solid rgba(0, 177, 64, 0.1)",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "4px 8px",
  gap: "8px",
  width: "150px",
  height: "32px",
};

const highScoreStyle: CSSProperties = {
  display: "inline",
  fontWeight: "600",
  color: "#00b140",
  alignSelf: "center",
  fontSize: "14px",
};

const lowScoreStyle: CSSProperties = {
  display: "inline",
  fontWeight: "600",
  color: "#e44646",
  alignSelf: "center",
  fontSize: "14px",
};

const UpIconStyle: Partial<IIconStyles> = {
  root: {
    color: "#00b140",
  },
};

const DownIconStyle: Partial<IIconStyles> = {
  root: {
    color: "#e44646",
  },
};

export interface OvertimeChangeState {
  score: number;
  percentage: number;
}

export const OvertimeChange: FunctionComponent<OvertimeChangeState> = ({ score, percentage }) => {
  return (
    <div style={subContainerStyle}>
      {score >= 0 ? (
        <Icon styles={UpIconStyle} iconName="Up" />
      ) : (
        <Icon styles={DownIconStyle} iconName="Down" />
      )}
      <p style={score >= 0 ? highScoreStyle : lowScoreStyle}>
        <span>{score.toFixed(2)}</span>&nbsp;
        <span>({percentage.toFixed(2)}%)</span>
      </p>
    </div>
  );
};
