import { useCallback, useEffect, useState } from "react";

type UseResizeProps = {
  minWidth: number;
};

type UseResizeReturn = {
  width: number;
  enableResize: () => void;
  isResizing: boolean;
};

const useResize = ({ minWidth }: UseResizeProps): UseResizeReturn => {
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(minWidth);

  const enableResize = useCallback(() => {
    setIsResizing(true);
  }, [setIsResizing]);

  const disableResize = useCallback(() => {
    setIsResizing(false);
  }, [setIsResizing]);

  const resize = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (isResizing) {
        const newWidth = window.visualViewport!.width - e.clientX;
        if (newWidth >= minWidth) {
          setWidth(newWidth);
        }
      }
    },
    [minWidth, isResizing, setWidth]
  );

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", disableResize);

    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", disableResize);
    };
  }, [disableResize, resize]);

  return { width, enableResize, isResizing };
};

export default useResize;
