import {
  MissingIssue,
  DuplicateIssue,
  InconsistentIssue,
  OutlierIssue,
  PiiIssue,
  QuickAnalysisIssue,
  CustomCheckIssue,
} from "../../../types/quickAnalysis";

export const addIssueId = (
  issues: (
    | MissingIssue
    | DuplicateIssue
    | InconsistentIssue
    | OutlierIssue
    | PiiIssue
    | CustomCheckIssue
  )[]
): QuickAnalysisIssue[] => {
  return issues.map((issue) => {
    const column: number = issue.type !== "duplicate" ? issue.column : issue.columns[0];
    return {
      ...issue,
      id: `${issue.row}::${column}::${issue.type}::${issue.rule_id}`,
      isIgnored: !!issue?.isIgnored,
    };
  });
};
