import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { MissionData } from "../../types/missions";
import { ColumnMapping } from "../../types/upload";

// Define a type for the slice state
interface MissionDetailState {
  mission: MissionData;
  selectedDatasets: string[];
  uploadColumnMapping: ColumnMapping[];
}

// Define the initial state using that type
const initialState: MissionDetailState = {
  mission: {
    title: "",
    description: "",
    owner: "",
    function: "",
    id: "",
    tasks: [],
    created_at: "",
    updated_at: "",
    datasets: [],
    status: "",
    combined_quality: null,
  },
  selectedDatasets: [],
  uploadColumnMapping: [],
};

export const missionDetailSlice = createSlice({
  name: "missionDetail",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setMission: (state, action: PayloadAction<MissionData>) => {
      state.mission = action.payload;
    },
    setSelectedDataset: (state, action: PayloadAction<string>) => {
      if (state.selectedDatasets.includes(action.payload)) {
        const datasetIndex = state.selectedDatasets.indexOf(action.payload);
        state.selectedDatasets.splice(datasetIndex, 1);
      } else state.selectedDatasets.push(action.payload);
    },
    deselectDatasets: (state) => {
      state.selectedDatasets = [];
    },
    setUploadColumnMapping: (state, action: PayloadAction<ColumnMapping[]>) => {
      state.uploadColumnMapping = action.payload;
    },
  },
});

export const { setMission, setSelectedDataset, deselectDatasets, setUploadColumnMapping } =
  missionDetailSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getMissionDetail = (state: RootState) => state.missionDetail.mission;
export const getUploadColumnMapping = (state: RootState) => state.missionDetail.uploadColumnMapping;

export default missionDetailSlice.reducer;
