import { FunctionComponent, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";
import { useGetInteractionsQuery } from "../../../api/performance";
import { InteractionsData } from "../../../types/performance";
import { Serie } from "@nivo/line";
import { headerStyle, mainContainerStyle } from "../../../helpers/commonStyles";

const chartStyle: CSSProperties = {
  height: "250px",
};

const infoStyle: CSSProperties = {
  display: "flex",
  height: "224px",
  alignItems: "center",
  justifyContent: "center",
};

const textStyle: CSSProperties = {
  fontWeight: "500",
  fontSize: "40px",
  margin: "0",
  color: "#292929",
};

const getChartData = (respdata: InteractionsData[]) => {
  let chartSeries: Serie[] = [];

  if (respdata && respdata.length > 0) {
    const data = respdata.map((interaction: InteractionsData) => {
      return { x: moment(interaction.day).format("DD-MM-YYYY"), y: interaction.count };
    });
    chartSeries = [
      {
        id: "interactions",
        data: data,
      },
    ];
  }

  return chartSeries;
};

export const InteractionsChart: FunctionComponent = () => {
  const { t } = useTranslation();
  const { isLoading, isSuccess, data } = useGetInteractionsQuery();

  let chartData: Serie[];

  if (isSuccess) {
    chartData = getChartData(data.interactions_per_day);
  }

  const renderContent = () => {
    if (isLoading) {
      return (
        <span style={infoStyle}>
          <Spinner size={SpinnerSize.large} />
        </span>
      );
    }

    if (chartData.length === 0) {
      return (
        <div style={infoStyle}>
          <span style={textStyle}>{t("performance_no_interactions_data")}</span>
        </div>
      );
    }

    return (
      <div style={chartStyle}>
        <>
          <ResponsiveLine
            data={chartData}
            colors={["#0053a3"]}
            margin={{ top: 50, right: 25, bottom: 25, left: 40 }}
            xScale={{
              type: "time",
              format: "%d-%m-%Y",
              useUTC: false,
              precision: "day",
            }}
            xFormat="time:%d-%m-%Y"
            yScale={{
              type: "linear",
              stacked: false,
            }}
            axisLeft={{
              legend: "count",
              legendOffset: -35,
              legendPosition: "middle",
            }}
            axisBottom={{
              format: "%d %b",
            }}
            enablePointLabel={true}
            pointLabelYOffset={-12}
            pointSize={8}
            pointBorderWidth={1}
            pointBorderColor={{
              from: "color",
              modifiers: [["darker", 0.3]],
            }}
            useMesh={true}
          />
        </>
      </div>
    );
  };

  return (
    <section style={mainContainerStyle}>
      <h3 style={headerStyle}>{t("performance_interactions")}</h3>
      {renderContent()}
    </section>
  );
};
