import {
  Checkbox,
  DetailsList,
  DetailsListLayoutMode,
  DirectionalHint,
  IColumn,
  Text,
  Icon,
  IconButton,
  SelectionMode,
  Stack,
  TooltipHost,
  Overlay,
  IGroup,
  DetailsListBase,
  Dialog,
  DefaultButton,
  PrimaryButton,
  IRenderFunction,
  IDetailsHeaderProps,
  IDetailsColumnRenderTooltipProps,
  GroupHeader,
  IGroupHeaderProps,
  IGroupRenderProps,
} from "@fluentui/react";
import { t } from "i18next";
import { useState, useRef, useEffect, Dispatch, SetStateAction } from "react";
import { ISSUE_TYPE_ICON_MAP } from "../../helpers/quickAnalysis/qualityChecks/constants";
import { setRule, deleteAllRules } from "../../store/slices/rules";
import { RuleCandidate } from "../../types/rules";
import {
  textStyle,
  getRulesDetailsListStyles,
} from "../quick-analysis/rule-overview/RuleOverview.styles";
import { RuleOverviewHeaderBar } from "../quick-analysis/rule-overview/RuleOverviewHeaderBar";
import { AddRuleManually } from "../quick-analysis/rules/AddRuleManually";
import { RuleDetailModal } from "../quick-analysis/rules/RuleDetailModal";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectOpenRuleId, setOpenRuleId } from "../../store/slices/quickAnalysis";
import { Issue } from "../../types/issue";
import { DeepAnalysisIssue, QuickAnalysisIssue } from "../../types/quickAnalysis";
import { useUpdateRuleMutation } from "../../api/v2/rules";
import { isQuickAnalysisRule } from "../../utils/rules";

type RuleTableBasisProps = {
  rulesGroupedByColumn: RuleCandidate[][];
  items: RuleCandidate[];
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  issues: Issue[] | QuickAnalysisIssue[];
};

export const RuleTableBasis = ({
  rulesGroupedByColumn,
  items,
  searchValue,
  setSearchValue,
  issues,
}: RuleTableBasisProps) => {
  const dispatch = useAppDispatch();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const detailsListRef = useRef<DetailsListBase>(null);
  const [detailsHeaderWidth, setDetailsHeaderWidth] = useState<number>(0);
  const openRuleId = useAppSelector(selectOpenRuleId);
  const [updateRuleQuery] = useUpdateRuleMutation();

  let currentIndex = 0;
  const groups: IGroup[] = rulesGroupedByColumn.map((group) => {
    const columnNames: string = group[0].columns.map((column) => column.name).join(", ");
    const result = {
      key: `RuleCandidateColumn::${group[0].id}`,
      name: columnNames,
      startIndex: currentIndex,
      count: group.length,
      showEmptyGroups: false,
    };
    currentIndex = currentIndex + group.length;
    return result;
  });
  const probability_scale = ["low", "low", "medium", "high", "high"];

  useEffect(() => {
    const detailsHeader = document.querySelectorAll(".ms-DetailsHeader-cell")[1];

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === detailsHeader) {
          const newDetailsHeaderWidth = entry.contentRect.width;
          setDetailsHeaderWidth(newDetailsHeaderWidth);
        }
      }
    });

    resizeObserver.observe(detailsHeader);

    return () => {
      resizeObserver.unobserve(detailsHeader);
    };
  }, []);

  const onRenderItemColumn = (item: RuleCandidate, index?: number, column?: IColumn) => {
    if (!column || index === undefined) return;
    if (column?.key === "name")
      return (
        <Stack horizontal styles={textStyle}>
          <Icon
            iconName={ISSUE_TYPE_ICON_MAP.get(item.dimension)}
            styles={{
              root: {
                display: "flex",
                marginRight: 12,
              },
            }}
          />
          <Text>{item.name}</Text>
        </Stack>
      );
    if (column?.key === "description")
      return (
        <TooltipHost content={item.description} directionalHint={DirectionalHint.topLeftEdge}>
          <Stack horizontal styles={textStyle}>
            <Text>{item.description}</Text>
          </Stack>
        </TooltipHost>
      );
    if (column?.key === "confidence")
      return (
        <Stack horizontal styles={textStyle}>
          <Text>{t(probability_scale[item.confidence - 1]).toUpperCase()}</Text>
        </Stack>
      );
    if (column?.key === "health") {
      let issueCount = 0;
      if (isQuickAnalysisRule(item)) {
        issueCount = issues
          .map((issue) => (issue as QuickAnalysisIssue).rule_id)
          .filter((rule_id) => rule_id === item.id).length;
      } else {
        issueCount = issues
          .map((issue) => (issue as DeepAnalysisIssue).rule_id)
          .filter((rule_id) => rule_id === item.id && item.isAccepted).length;
      }

      return (
        <Stack horizontal>
          <Icon iconName={issueCount ? "WarningSolid" : "CheckMark"} />
          {issueCount}
        </Stack>
      );
    }
    if (column?.key === "mission")
      return (
        <Stack horizontal>
          <Icon iconName="WorkFlow" />
          {/* TODO implement */}
        </Stack>
      );
    if (column?.key === "alert")
      return (
        <Stack horizontal>
          <Icon iconName="Ringer" />
          {/* TODO implement */}
        </Stack>
      );
    if (column?.key === "buttons")
      return (
        <>
          <Stack horizontal styles={{ root: { display: "flex", justifySelf: "flex-end" } }}>
            <Checkbox
              onChange={async (_, isChecked?: boolean) => {
                if (isQuickAnalysisRule(item))
                  dispatch(setRule({ ...item, isAccepted: !!isChecked }));
                else await updateRuleQuery({ ...item, isAccepted: !!isChecked });
              }}
              checked={item.isAccepted}
            />
            <TooltipHost content={t("more_actions")}>
              <IconButton
                iconProps={{ iconName: "MoreVertical" }}
                onRenderMenuIcon={() => null}
                menuProps={{
                  shouldFocusOnMount: true,
                  items: [
                    {
                      key: "edit",
                      text: t("edit"),
                      iconProps: { iconName: "Edit" },
                      onClick: () => {
                        dispatch(setOpenRuleId(item.id));
                      },
                    },
                    {
                      key: "delete",
                      text: t("delete"),
                      iconProps: { iconName: "Delete" },
                      onClick: () => {
                        dispatch(
                          setRule({
                            ...item,
                            deletedAt: new Date().toISOString(),
                          })
                        );
                      },
                    },
                  ],
                }}
              />
            </TooltipHost>
          </Stack>
          {!!openRuleId && (
            <Overlay>
              <RuleDetailModal rule={item} />
            </Overlay>
          )}
        </>
      );
  };

  const columns: IColumn[] = [
    {
      key: "column",
      name: t("column"),
      fieldName: "column",
      minWidth: 50,
      maxWidth: 80,
      isResizable: true,
    },
    {
      key: "name",
      name: t("rule"),
      fieldName: "name",
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "description",
      name: t("description"),
      fieldName: "description",
      minWidth: 50,
      maxWidth: 350,
      isResizable: true,
    },
    {
      key: "confidence",
      name: t("probability"),
      fieldName: "confidence",
      minWidth: 50,
      maxWidth: 90,
      isResizable: true,
    },
    {
      key: "health",
      name: t("health"),
      fieldName: "health",
      minWidth: 90,
      maxWidth: 90,
    },
    {
      key: "mission",
      name: t("mission"),
      fieldName: "mission",
      minWidth: 90,
      maxWidth: 90,
    },
    {
      key: "alert",
      name: t("alert"),
      fieldName: "alert",
      minWidth: 90,
      maxWidth: 90,
    },
    {
      key: "buttons",
      name: "",
      fieldName: "buttons",
      minWidth: 100,
      maxWidth: 100,
    },
  ];
  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
      return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (
      tooltipHostProps
    ) => <TooltipHost {...tooltipHostProps} />;
    return defaultRender!({
      ...props,
      onRenderColumnHeaderTooltip,
    });
  };

  const groupHeaderComponent = (props?: IGroupHeaderProps): JSX.Element => (
    <TooltipHost content={props?.group?.name} directionalHint={DirectionalHint.rightCenter}>
      <GroupHeader {...props} />
    </TooltipHost>
  );

  const groupProps: IGroupRenderProps = {
    onRenderHeader: groupHeaderComponent,
  };

  return (
    <Stack styles={{ root: { display: "flex", margin: 20 } }}>
      <RuleOverviewHeaderBar
        detailsListRef={detailsListRef}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setShowDialog={setShowDialog}
      />
      <DetailsList
        componentRef={detailsListRef}
        items={items}
        columns={columns}
        groups={groups}
        groupProps={groupProps}
        selectionMode={SelectionMode.none}
        isHeaderVisible={true}
        layoutMode={DetailsListLayoutMode.justified}
        onRenderItemColumn={onRenderItemColumn}
        onRenderDetailsHeader={onRenderDetailsHeader}
        styles={getRulesDetailsListStyles(detailsHeaderWidth)}
        onShouldVirtualize={() => false}
      />
      <Stack styles={{ root: { marginTop: 20 } }}>
        <AddRuleManually />
      </Stack>
      <Dialog
        styles={{ main: { borderRadius: 4 } }}
        hidden={!showDialog}
        onDismiss={() => setShowDialog(false)}
        dialogContentProps={{
          title: t("delete_rules_title"),
          subText: t("delete_rules_subtext"),
          styles: {
            content: { textAlign: "center" },
            subText: { fontSize: 16, fontWeight: 500, color: "black" },
          },
        }}
        children={
          <Stack horizontal horizontalAlign="space-evenly">
            <PrimaryButton
              text={t("delete")}
              onClick={() => {
                dispatch(deleteAllRules());
                setShowDialog(false);
              }}
              styles={{ root: { borderRadius: 4 } }}
            />
            <DefaultButton
              text={t("cancel")}
              onClick={() => setShowDialog(false)}
              styles={{ root: { borderRadius: 4 } }}
            />
          </Stack>
        }
      />
    </Stack>
  );
};
