import React from "react";
import { ColumnInfo } from "../../../types/quality";
import { useTranslation } from "react-i18next";
export interface ConfigureCheckboxProps {
  index: number;
  columnInfo: ColumnInfo;
  onChangeHandle: (index: number, columnInfo: ColumnInfo, columnName: string) => void;
  isBoxChecked: (index: number, columnName: string) => boolean;
  columnName: string;
}

export const ConfigureCheckbox: React.FunctionComponent<ConfigureCheckboxProps> = ({
  index,
  columnInfo,
  onChangeHandle,
  isBoxChecked,
  columnName,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <input
        type="checkbox"
        name={`selected${columnName}`}
        placeholder={t(`edit_column_config_placeholder_${columnName}`)}
        onChange={() => onChangeHandle(index, columnInfo, columnName)}
        checked={isBoxChecked(index, columnName)}
      />
    </>
  );
};
