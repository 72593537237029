import React, { FormEvent, useEffect, useState } from "react";
import { AddDatasetModalProps } from "../../../types/dataset";
import { useGetDatasourceConnectionsQuery } from "../../../api/installation";
import {
  DefaultButton,
  ILabelStyles,
  ITextFieldStyles,
  Modal,
  PrimaryButton,
  TextField,
  Label,
  Icon,
  Dropdown,
  IDropdownOption,
} from "@fluentui/react";
import {
  modalHeader,
  modalSize,
  iconStyle,
  divider,
  buttonContainer,
  buttonSubContainer,
  labelIconContainer,
} from "../../../helpers/commonStyles";
import { useTranslation } from "react-i18next";
import { Connection } from "../../../types/connection";
import { useAddNewDatasetMutation } from "../../../api/mission";

const subTitleStyle: Partial<ILabelStyles> = {
  root: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#000000",
  },
};

const modalStyle: React.CSSProperties = {
  padding: "20px",
};

const textFieldContainer: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "3%",
};

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: {
    width: "55%",
  },
};

export const AddDatasetModal: React.FunctionComponent<AddDatasetModalProps> = ({
  modalState,
  secondaryButtonOnClick,
  mission,
}) => {
  const { t } = useTranslation();
  const [datasetUrl, setDatasetUrl] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [connectionId, setConnectionId] = useState<number>(0);
  const [connectorOptions, setConnectorOptions] = useState<IDropdownOption[]>([]);
  const [datasourceFlag, setDatasourceFlag] = useState<boolean>(false);
  const allDatasourceConnections = useGetDatasourceConnectionsQuery("");

  let origin: string = "sharepoint";

  const [addNewDataset] = useAddNewDatasetMutation();

  useEffect(() => {
    if (!allDatasourceConnections.isLoading && allDatasourceConnections.isSuccess) {
      if (allDatasourceConnections.data.length === 0) {
        setValidationMessage(t("datasource_connector_validation"));
        setDatasourceFlag(true);
      } else {
        const data = allDatasourceConnections.data.map((item: Connection) => {
          return { key: item.id, text: item.bf_connector_id.replace(/_/g, " ") };
        });
        setConnectorOptions(data);
      }
    }
  }, [allDatasourceConnections]);

  function validateSharePointURL(sharepointUrl: string): boolean {
    const regex = new RegExp("sites/(.*)/Lists");
    return regex.test(sharepointUrl);
  }

  const addDataset = async () => {
    if (datasetUrl.slice(0, 10).match(/(postgresql)|(snowflake)|(sql)|(mysql)|(mssql)/)) {
      // @TODO: add validator in the future
      // pass
    } else if (!validateSharePointURL(datasetUrl) || connectionId === 0) {
      setValidationMessage("dataset validation error");
      return;
    }

    setValidationMessage("");
    try {
      const connection_id = connectionId;
      const name = datasetUrl;
      const urn = datasetUrl;
      const connected_mission = mission?.id ? mission?.id : "";
      const response = await addNewDataset({
        connection_id,
        name,
        urn,
        origin,
        connected_mission,
      }).unwrap();
      if (response) {
        handleCloseModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseModal = () => {
    secondaryButtonOnClick();
  };

  return (
    <Modal
      isOpen={modalState}
      onDismiss={() => handleCloseModal()}
      containerClassName={modalSize.container}
    >
      <div style={modalStyle}>
        <div style={labelIconContainer}>
          <Label styles={modalHeader}>{t("add_dataset")}</Label>
          <Icon styles={iconStyle} iconName="cancel" onClick={() => handleCloseModal()} />
        </div>
        <hr style={divider} />
        <div>
          <div style={textFieldContainer}>
            <Label styles={subTitleStyle}>{t("add_dataset_urn")}</Label>
            <TextField
              value={datasetUrl}
              placeholder={t("add_dataset_urn_placeholder")}
              onChange={(e: any) => setDatasetUrl(e.target.value)}
              styles={textFieldStyles}
            />
          </div>
          <div style={textFieldContainer}>
            <Label styles={subTitleStyle}>{t("select_connector")}</Label>
            <Dropdown
              placeholder={t("Select_an_option")}
              options={connectorOptions}
              onChange={(event: FormEvent<HTMLDivElement>, item?: IDropdownOption | any) => {
                setConnectionId(item?.key);
              }}
              styles={textFieldStyles}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <span style={{ color: "red" }}>{validationMessage}</span>
          </div>
        </div>
        <div style={buttonContainer}>
          <div style={buttonSubContainer}>
            <PrimaryButton
              text={t("add")}
              onClick={addDataset}
              styles={{
                root: { marginRight: "4%", whiteSpace: "noWrap" },
              }}
              disabled={datasourceFlag}
            />
            <DefaultButton
              text={t("cancel")}
              onClick={() => handleCloseModal()}
              styles={{ root: { marginRight: "2%" } }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
