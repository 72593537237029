import { Icon, IIconStyles, ILabelStyles, IStackStyles, Label, Stack } from "@fluentui/react";
import { ListIcon } from "../common/ListIcon";
import { PersonaTooltip } from "../common/PersonaTooltip";
import { ProblemToolTip } from "../common/ProblemToolTip";
import { ValueToolTip } from "../common/ValueToolTip";
import { useNavigate } from "react-router-dom";
import { DataAsset } from "../../types/dataasset";

const chevronIconStyle: IIconStyles = {
  root: {
    fontSize: 16,
    marginRight: 8,
    cursor: "pointer",
  },
};

const chevronIconStyleDisabled: IIconStyles = {
  root: {
    fontSize: 16,
    marginRight: 8,
  },
};

const labelStyle: ILabelStyles = {
  root: {
    fontSize: 14,
    cursor: "pointer",
  },
};

export interface DetailListProps {
  data: DataAsset;
  index: number;
  indexValue?: number;
  subContainer: IStackStyles;
  ListIconScore: number;
  functioncloseAsset?: (index: number) => void;
}

const iconToShow = (children: boolean, index?: number, toIndex?: number) => {
  if (children) {
    return index === toIndex ? "ChevronDownMed" : "ChevronRight";
  }
  return undefined;
};

export const DataAssetListItem: React.FunctionComponent<DetailListProps> = ({
  data,
  index,
  indexValue,
  subContainer,
  ListIconScore,
  functioncloseAsset,
}) => {
  let navigate = useNavigate();
  return (
    <Stack horizontal horizontalAlign="space-between" verticalAlign="center" styles={subContainer}>
      <Stack horizontal verticalAlign="center">
        <Icon
          iconName={iconToShow(data.linked_assets.length > 0, indexValue, index)}
          styles={data.linked_assets?.length > 0 ? chevronIconStyle : chevronIconStyleDisabled}
          onClick={() =>
            functioncloseAsset && data.linked_assets?.length > 0 ? functioncloseAsset(index) : null
          }
        />
        <ListIcon score={ListIconScore} />
        <Label styles={labelStyle} onClick={() => navigate(`/cockpit/assets/${data.id}`)}>
          {data.title}
        </Label>
      </Stack>
      <Stack horizontal>
        <ValueToolTip value={data.value} />
        <ProblemToolTip value={data.problems} />
        <PersonaTooltip name={data.owner || ""} />
      </Stack>
    </Stack>
  );
};
