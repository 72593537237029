import React, { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import { TopContainer } from "../../components/common/TopContainer";
import { TextField } from "@fluentui/react/lib/TextField";
import { dqcRESTApi } from "../../api/dqcREST";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PrimaryButton, DefaultButton, Label } from "@fluentui/react";
import { ColumnMapping, UploadPreviewModel, UploadReportModel } from "../../types/upload";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getUploadColumnMapping, setUploadColumnMapping } from "../../store/slices/missionSlice";
import { UploadPreview } from "./UploadPreview";
import { UploadReport } from "./UploadReport";
import { useTranslation } from "react-i18next";

const mainStyle: CSSProperties = {
  width: "80%",
  height: "250px",
};

const uploadPreviewStyle: CSSProperties = {
  width: "500px",
};

const textFieldStyle: CSSProperties = {
  marginTop: "10px",
};

const mainButtonStyle: CSSProperties = {
  marginTop: "30px",
  float: "right",
  display: "flex",
};

const PrimaryButtonStyle: CSSProperties = {
  marginLeft: "20px",
};

export const BulkUpload: FunctionComponent = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [sharepointURL, setSharepointURL] = useState(searchParams.get("dataset_urn"));
  const [file, setFile] = useState<File>();
  const [uploadPreview, setUploadPreview] = useState<UploadPreviewModel>();
  const [uploadReport, setUploadReport] = useState<UploadReportModel>();
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [uploadToSharepointProgress, setUploadToSharepointProgress] = useState<boolean>(false);
  const uploadColumnMapping = useAppSelector(getUploadColumnMapping);
  const [columnMappings, setColumnMappings] = useState<ColumnMapping[]>(uploadColumnMapping);
  const mission_id = searchParams.get("mission_id");

  useEffect(() => {
    setColumnMappings(uploadColumnMapping);
  }, [uploadColumnMapping]);

  const uploadToDQC = async () => {
    setUploadInProgress(true);
    const formData = new FormData();
    formData.append("sharepointURL", sharepointURL ? sharepointURL : "");
    formData.append("csvFile", file ? file : "");
    try {
      const response = await dqcRESTApi.post("/uploads/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setUploadPreview(response.data);
    } catch (error: any) {
      alert(error.response.data.detail);
      console.error(error);
      setUploadPreview(undefined);
    } finally {
      setUploadInProgress(false);
    }
  };

  const uploadToSharepoint = async () => {
    setUploadToSharepointProgress(true);
    try {
      if (!uploadPreview) throw new Error("upload_error_noupload");
      const response = await dqcRESTApi.post(
        `/uploads/confirm_upload/${uploadPreview.uploadKey}/`,
        {
          columnMappings,
          sharepointURL,
        }
      );
      setUploadReport(response.data);
      setUploadToSharepointProgress(false);
    } catch (error) {
      console.error(error);
      setUploadReport(undefined);
    }
  };

  const resetUploadData = () => {
    setUploadReport(undefined);
    setUploadPreview(undefined);
    dispatch(setUploadColumnMapping([]));
  };

  const navigateToMissionDetail = () => {
    navigate(`/missions/${mission_id}`);
  };

  return (
    <TopContainer title={t("add_dataset_upload")}>
      <main>
        <div style={mainStyle}>
          <h2>{t("add_dataset_upload")}</h2>
          <div>
            <TextField
              label={t("upload_sharepoint")}
              value={sharepointURL ? sharepointURL : ""}
              onChange={(e) => {
                setSharepointURL((e.target as HTMLInputElement).value);
                resetUploadData();
              }}
            />
          </div>
          <div style={textFieldStyle}>
            <Label>{t("upload_file")}</Label>
            <input
              title={t("upload_csv")}
              type="file"
              accept=".csv"
              onChange={(e) => {
                setFile((e.target as HTMLInputElement).files?.[0]);
                resetUploadData();
              }}
            />
          </div>
          <div style={mainButtonStyle}>
            <div>
              <DefaultButton text={t("back")} onClick={navigateToMissionDetail} />
            </div>
            {!uploadInProgress && !uploadPreview && sharepointURL ? (
              <div style={PrimaryButtonStyle}>
                <PrimaryButton
                  text={t("new_mission_next")}
                  disabled={!file}
                  onClick={uploadToDQC}
                />
              </div>
            ) : uploadInProgress ? (
              <div style={PrimaryButtonStyle}>
                <PrimaryButton text={t("loading")} />
              </div>
            ) : null}
          </div>
        </div>
        <div style={uploadPreviewStyle}>
          {uploadPreview ? <UploadPreview uploadPreview={uploadPreview} /> : null}
          {columnMappings.length &&
          uploadPreview &&
          !uploadReport &&
          !uploadToSharepointProgress ? (
            <div>
              <PrimaryButton text={t("upload_button_upload")} onClick={uploadToSharepoint} />
            </div>
          ) : uploadToSharepointProgress ? (
            <div>
              <PrimaryButton text={t("upload_button_loading")} />
            </div>
          ) : null}
          {uploadReport ? (
            <>
              <PrimaryButton text={t("done")} onClick={navigateToMissionDetail} />
              <UploadReport uploadReport={uploadReport} />
            </>
          ) : null}
        </div>
      </main>
    </TopContainer>
  );
};
