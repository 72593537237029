import { FunctionComponent, CSSProperties } from "react";
import { UploadPreviewModel } from "../../types/upload";
import { ColumnSelector } from "./ColumnSelector";

const columnStyles: CSSProperties = {
  display: "flex",
  flexDirection: "row",
};

const examplesStyles: CSSProperties = {
  display: "flex",
  flexDirection: "column",
};

export interface UploadPreviewProps {
  uploadPreview: UploadPreviewModel;
}

export const UploadPreview: FunctionComponent<UploadPreviewProps> = (props: UploadPreviewProps) => {
  return (
    <div style={columnStyles}>
      {props.uploadPreview.columnsInUpload.map((uploadColumnInfo, index) => {
        return (
          <div style={examplesStyles} key={index}>
            <ColumnSelector
              uploadColumnInfo={uploadColumnInfo}
              columnsInSharePoint={props.uploadPreview.columnsInSharePoint}
            />
          </div>
        );
      })}
    </div>
  );
};
