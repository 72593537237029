import { Stack, PrimaryButton, DefaultButton, IStackTokens } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { Editor } from "@tinymce/tinymce-react";
import { t } from "i18next";
import { FunctionComponent } from "react";

interface RichtTextEditorProps {
  id?: string;
  value: string;
  onChange?: (newValue: string) => void;
  onSave?: () => void;
  onCancel?: () => void;
  toggleEditor: boolean;
  height?: number;
  comment?: boolean;
  enableDistractionFreeMode?: boolean;
}

export const RichTextEditor: FunctionComponent<RichtTextEditorProps> = (
  props: RichtTextEditorProps
) => {
  const [showRichTextEditor, { toggle: toggleRichTextEditor }] = useBoolean(false);
  const [mouseOver, { setTrue: mouseIsOver, setFalse: mouseIsOut }] = useBoolean(false);

  const numericalSpacingStackTokens: IStackTokens = {
    childrenGap: 10,
  };

  const EditorView = (<div className={props.enableDistractionFreeMode ? "mce-inlineEdit" : ""}>
    <Editor
      id={props.id}
      tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
      value={props.value}
      inline={props.enableDistractionFreeMode}
      init={{
        height: props.height || 175,
        min_height: 120,
        padding: 30,
        skin: props.enableDistractionFreeMode ? undefined : "snow",
        menubar: false,
        placeholder: t("please_specify"),

        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "preview",
          "help",
          "wordcount",
          "emoticons",
          "autoresize",
        ],
        toolbar: props.comment
          ? "bold italic forecolor bullist numlist | emoticons"
          : "blocks | " +
            "bold italic forecolor" +
            " bullist numlist | " +
            "link image table autolink help|" +
            "code|emoticons",
        content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}
      onEditorChange={(newValue, _editor) => {
        props.onChange!(newValue);
      }}
    /></div>
  );

  const ToggleEditor = showRichTextEditor ? (
    <>
      {EditorView}
      <Stack horizontal tokens={numericalSpacingStackTokens} style={{ marginTop: 10 }}>
        <PrimaryButton
          text={t("save")}
          allowDisabledFocus
          onClick={() => {
            props.onSave!();
            toggleRichTextEditor();
          }}
        />
        <DefaultButton
          text={t("cancel")}
          allowDisabledFocus
          onClick={() => {
            props.onCancel!();
            toggleRichTextEditor();
          }}
        />
      </Stack>
    </>
  ) : (
    <div
      dangerouslySetInnerHTML={{ __html: props.value }}
      contentEditable={true}
      onFocus={toggleRichTextEditor}
      onMouseOver={mouseIsOver}
      onMouseOut={mouseIsOut}
      style={{
        padding: "4px 10px 4px 10px",
        border: "none",
        backgroundColor: mouseOver ? "#E8E9ED" : "white",
        marginTop: 11,
      }}
    ></div>
  );

  return <>{props.toggleEditor ? ToggleEditor : EditorView}</>;
};
