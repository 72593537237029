import { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import { IStackStyles, Label, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import {
  QualityDimensionAggregate,
  QualityDimension,
  QualitySummary,
  QualityDimensionKeys,
} from "../../../types/quality";
import { datasetQualityHeader, topHeading } from "../../../helpers/commonStyles";
import {
  getQualityScores,
  getQualitySummaryByDatasetId,
} from "../../../helpers/quality-score-helper";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useGetMissionByIdQuery, useGetQualitySummaryByMissionIdQuery } from "../../../api/mission";

import { MissionData } from "../../../types/missions";
import { QualityItem } from "./QualityItem";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setPollingInterval } from "../../../store/slices/qualitySummarySlice";

const innerMainContentBoxStyles: IStackStyles = {
  root: {
    height: "310px",
    border: "1px solid #cec6c6",
    borderRadius: "3px",
    padding: "5px",
  },
};

const spanStyle: CSSProperties = {
  fontSize: "larger",
  fontWeight: "600",
  padding: "126px",
};
export interface QualityListState {
  quality?: QualitySummary | undefined;
  mission?: MissionData | undefined;
}

export const QualitySection: FunctionComponent<QualityListState> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [scores, setScores] = useState(
    new Map<typeof QualityDimension[QualityDimensionKeys], QualityDimensionAggregate>()
  );
  const params = useParams();
  const pollingInterval = useAppSelector((state) => state.qualitySummary.pollingInterval);
  const qualitySummary = useGetQualitySummaryByMissionIdQuery(params.id!, {
    pollingInterval: pollingInterval,
  });
  const missionDetail = useGetMissionByIdQuery(params.id ?? skipToken);
  const selectedDatasetsForQuality = useAppSelector(
    (state) => state.missionDetail.selectedDatasets
  );

  useEffect(() => {
    if (
      qualitySummary.isSuccess &&
      qualitySummary?.data?.status === "done" &&
      qualitySummary?.data.dataset_quality_summaries
    ) {
      dispatch(setPollingInterval(0));
      if (selectedDatasetsForQuality) {
        setScores(
          getQualityScores(
            getQualitySummaryByDatasetId(
              qualitySummary?.data.dataset_quality_summaries,
              selectedDatasetsForQuality
            )
          )
        );
      } else {
        setScores(getQualityScores(qualitySummary?.data.dataset_quality_summaries));
      }
    } else if (qualitySummary.isError) {
      dispatch(setPollingInterval(0));
    } else {
      dispatch(setPollingInterval(1000));
      return;
    }
  }, [selectedDatasetsForQuality, qualitySummary, dispatch]);

  return (
    <>
      <Stack styles={datasetQualityHeader}>
        <Stack>
          <Label styles={topHeading}>{t("quality")}</Label>
        </Stack>
      </Stack>
      <Stack styles={innerMainContentBoxStyles}>
        {qualitySummary.isFetching ||
        qualitySummary?.isLoading ||
        qualitySummary?.data?.status === "loading_data" ? (
          <Spinner size={SpinnerSize.large} style={{ margin: "130px" }} />
        ) : (
          <>
            {missionDetail && qualitySummary?.data?.status === "done" ? (
              <div>
                {Object.entries(QualityDimension).map(([, dimensionValue], index) => (
                  <QualityItem
                    dimensionValue={dimensionValue}
                    index={index}
                    scores={scores}
                    missionId={missionDetail?.data?.id!}
                    datasets={missionDetail?.data?.datasets!}
                    key={`qualitykey_${index}`}
                    status={qualitySummary?.data?.status!}
                  ></QualityItem>
                ))}
              </div>
            ) : (
              <span style={spanStyle}>{t("quality_get_started")}</span>
            )}
          </>
        )}
      </Stack>
    </>
  );
};
