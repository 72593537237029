import {
  Stack,
  CommandBar,
  Text,
  DefaultButton,
  ICommandBarItemProps,
  SearchBox,
  DetailsListBase,
} from "@fluentui/react";
import { t } from "i18next";
import { doRulesAnalysis } from "../../../store/slices/quickAnalysis";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectLatestUpload } from "../../../store/slices/upload";
import { Dispatch, RefObject, SetStateAction, useMemo } from "react";
import { useNavigate } from "react-router-dom";

type RuleOverviewHeaderProps = {
  detailsListRef: RefObject<DetailsListBase>;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
};

export const RuleOverviewHeaderBar = ({
  detailsListRef,
  searchValue,
  setSearchValue,
  setShowDialog,
}: RuleOverviewHeaderProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const uploadedFile = useAppSelector(selectLatestUpload);

  const BackButton = useMemo(() => {
    return (
      <DefaultButton
        text="Back"
        iconProps={{ iconName: "backArrow", styles: { root: { margin: 0 } } }}
        styles={{ root: { border: "none", paddingLeft: 0 } }}
        onClick={() => {
          uploadedFile && dispatch(doRulesAnalysis(uploadedFile));
          !uploadedFile && navigate("/overview");
        }}
      />
    );
  }, [uploadedFile]);

  const SearchBar = useMemo(() => {
    return (
      <SearchBox
        placeholder={t("searchbar_search")}
        value={searchValue}
        onChange={(_, newValue) => {
          if (newValue === undefined) return;
          setSearchValue(newValue);
        }}
        styles={{
          root: { borderRadius: 4 },
          field: { width: 300 },
          clearButton: { width: 0 },
        }}
        showIcon={true}
      />
    );
  }, [searchValue]);
  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "ruleOverviewBackButton",
      commandBarButtonAs: () => BackButton,
    },
    {
      key: "ruleOverviewSearchBar",
      commandBarButtonAs: () => SearchBar,
    },
  ];

  const overFlowItems: ICommandBarItemProps[] = [
    {
      key: "deleteAllRules",
      text: t("delete_all_rules"),
      onClick: () => {
        setShowDialog(true);
      },
    },
  ];

  return (
    <Stack
      styles={{
        root: {
          position: "sticky",
          zIndex: 1000,
          backgroundColor: "white",
          top: 100,
        },
      }}
    >
      <Text styles={{ root: { fontWeight: 700, fontSize: 16 } }}>{t("rules")}</Text>
      <CommandBar
        items={commandBarItems}
        overflowItems={overFlowItems}
        styles={{
          root: {
            paddingLeft: 0,
            alignItems: "center",
            justifyContent: "center",
          },
          primarySet: { columnGap: "20px" },
        }}
      />
    </Stack>
  );
};
