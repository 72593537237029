import { QualityDimension } from "../types/quality";

export const GET_CARD_RESOURCE_TEXT =
  "Dear Team, please look into this dataset and investigate/complete outlied values.\nMany thanks!";

export const GET_NEW_RECORD_RESOURCE_TEXT =
  "Is there any company or competitor activity that your colleagues should know about? Click on The Lookout button below to add your insights! For each new record added, earn an additional 50 points!";

export const isNewRecordDimension = (dimension: string | null) => {
  return dimension === QualityDimension.NEW_RECORD;
};

export const getResourceByDimension = (dimension: string | null) => {
  // if dimension is "New Record"
  if (isNewRecordDimension(dimension)) {
    //return New Record content
    return GET_NEW_RECORD_RESOURCE_TEXT;
  }

  return GET_CARD_RESOURCE_TEXT;
};
