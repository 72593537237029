import {
  ActionButton,
  CheckboxVisibility,
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  DirectionalHint,
  IColumn,
  IDetailsGroupDividerProps,
  IDetailsHeaderProps,
  IDetailsListStyles,
  IGroup,
  IRenderFunction,
  Stack,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { hostStyles } from "../../helpers/commonStyles";
import { Question, SurveyDetail } from "../../types/survey";
import {
  getAllGroups,
  getColorGradientFromFraction,
  GroupedResults,
  HIDE_IF_LESS_THAN,
  Item,
  RenderItemNumeric,
  RenderItemChoices,
} from "./helpers";

const BaseCellStyle = {
  padding: 3,
  justifyContent: "center",
};

type SurveyStatisticsProps = {
  questions: Question[];
  surveyData: SurveyDetail;
  selectedGroupBy: string;
  columnSorting: Map<string, number>;
};

export const SurveyStatistics = ({
  questions,
  surveyData,
  selectedGroupBy,
  columnSorting,
}: SurveyStatisticsProps): JSX.Element => {
  const { t } = useTranslation();

  const [groupedResults, setGroupedResults] = useState<GroupedResults>(
    getAllGroups(selectedGroupBy, questions, surveyData, t)
  );

  useEffect(() => {
    setGroupedResults(getAllGroups(selectedGroupBy, questions, surveyData, t));
  }, [selectedGroupBy]);

  const onColumnResize = (column?: IColumn | undefined) => {
    setGroupedResults({
      ...groupedResults,
      columns: groupedResults.columns.map((c) =>
        c.key === column?.key ? { ...c, maxWidth: column.currentWidth } : c
      ),
    });
  };
  const _onRenderDetailsHeaderWithTextWrap = (
    props: IDetailsHeaderProps | undefined,
    _defaultRender?: IRenderFunction<IDetailsHeaderProps>
  ) => {
    if (!props || !_defaultRender) {
      return null;
    }
    return _defaultRender({
      ...props,
      styles: {
        root: {
          selectors: {
            ".ms-DetailsHeader-cell": {
              whiteSpace: "normal",
              textOverflow: "clip",
              lineHeight: "normal",
            },
            ".ms-DetailsHeader-cellTitle": {
              height: "100%",
              alignItems: "center",
            },
          },
        },
      },
    });
  };

  const onClickGroupHeaderFactory = (group: IGroup): (() => void) => {
    return () => {
      setGroupedResults({
        ...groupedResults,
        groups: groupedResults.groups.map((g) =>
          g.key === group.key ? { ...g, isCollapsed: !group.isCollapsed } : g
        ),
      });
    };
  };

  const _onRenderGroupHeader = (props: IDetailsGroupDividerProps | undefined) => {
    if (!props?.group) return <Stack horizontal></Stack>;
    const { group } = props;
    const column0 = groupedResults.columns[0];
    if (props.group.key === "statistics" || props.group.key === "choices")
      return renderGroupName(group, column0);
    const itemsInGroup = groupedResults.items.slice(
      group.startIndex,
      group.startIndex + group.count
    );
    if (
      !itemsInGroup.every(
        (item) =>
          item.type === "number" || item.type === "opinion_scale" || item.type === "happiness"
      )
    )
      return null;
    const headerRowItem = aggregateItemsInGroup(itemsInGroup, group);
    return (
      <Stack horizontal verticalAlign="center">
        {groupedResults.columns.map((column, index) => {
          if (index === 0) return renderGroupName(group, column);
          return (
            <Stack
              style={{ width: column.maxWidth, marginLeft: 20, padding: "6px 8px 6px 12px" }}
              key={`${column.key}`}
            >
              {_onRenderColumn(headerRowItem, index, column, true)}
            </Stack>
          );
        })}
      </Stack>
    );
  };
  const renderGroupName = (group: IGroup, column: IColumn) => {
    return (
      <Stack horizontal verticalAlign="center" key={group.key}>
        <ActionButton
          data-is-focusable="false"
          type="button"
          className="ms-GroupHeader-expand expand-251"
          iconProps={{
            iconName: group.isCollapsed ? "ChevronRight" : "ChevronDown",
            style: { fontSize: 12, marginLeft: 6, marginRight: 6 },
          }}
          disabled={group.name === t("happiness")}
          onClick={onClickGroupHeaderFactory(group)}
        ></ActionButton>

        <Stack
          style={{
            width: (column.maxWidth || 0) + 1,
            padding: "6px 8px 6px 12px",
          }}
        >
          <div style={{ fontSize: 14, fontWeight: 600 }}>
            {group.name} {group.name !== t("happiness") ? `(${group.count})` : ""}
          </div>
        </Stack>
      </Stack>
    );
  };

  const _onRenderColumn = (
    item: Item,
    _index: number | undefined,
    column: IColumn | undefined,
    isHeading: boolean = false
  ): JSX.Element => {
    if (column?.key === "category") return renderQuestionText(item);

    const cellValue = item.cells[column?.key || ""];

    if (item.type !== "happiness" && cellValue.totalCount < HIDE_IF_LESS_THAN)
      return renderHiddenResponses(column, isHeading, t);

    if (item.type === "opinion_scale" || item.type === "number" || item.type === "happiness") {
      return renderHeatmapCell(item, column, isHeading);
    }
    const RenderItemChoices = cellValue as RenderItemChoices;

    return (
      <Stack key={`${item.question}::${column?.key}`}>
        {(RenderItemChoices?.top3 || []).map((topChoice, i) => (
          <Stack key={`${column?.key}::${topChoice}:${i}`}>{topChoice}</Stack>
        ))}
      </Stack>
    );
  };

  return (
    <DetailsList
      checkboxVisibility={CheckboxVisibility.hidden}
      items={groupedResults.items}
      groups={groupedResults.groups}
      columns={groupedResults.columns.sort(
        (a, b) => columnSorting.get(a.key)! - columnSorting.get(b.key)!
      )}
      ariaLabelForSelectAllCheckbox="Toggle selection for all items"
      ariaLabelForSelectionColumn="Toggle selection"
      checkButtonAriaLabel="select row"
      checkButtonGroupAriaLabel="select section"
      onRenderDetailsHeader={_onRenderDetailsHeaderWithTextWrap}
      groupProps={{
        onRenderHeader: _onRenderGroupHeader,
        isAllGroupsCollapsed: true,
      }}
      onRenderItemColumn={_onRenderColumn}
      compact={true}
      onColumnResize={onColumnResize}
      layoutMode={DetailsListLayoutMode.fixedColumns}
      constrainMode={ConstrainMode.unconstrained}
      styles={gridStyles}
    />
  );
};

const aggregateItemsInGroup = (items: Item[], group: IGroup): Item => {
  const summedItem = items.reduce((itemTotal, item) => {
    Object.entries(item.cells).forEach(([groupName, value]: [string, RenderItemNumeric]) => {
      itemTotal[groupName]
        ? (itemTotal[groupName] += value.score)
        : (itemTotal[groupName] = value.score);
    });
    return itemTotal;
  }, {} as Record<string, number>);
  const headerRowItem: Item = {
    cells: {},
    question: group.name,
    type: group.name === "Happiness" ? "happiness" : "opinion_scale",
  };

  Object.entries(summedItem).forEach(([groupName, value]) => {
    const averageScore = value / items.length;
    headerRowItem.cells[groupName] = {
      score: averageScore,
      totalCount: group.name === "Happiness" ? 1 : items[0]?.cells[groupName]?.totalCount || 0,
    };
  });
  return headerRowItem;
};

const renderQuestionText = (item: Item): JSX.Element => {
  return (
    <TooltipHost
      tooltipProps={{
        onRenderContent: () => {
          return <p>{item.question}</p>;
        },
      }}
      delay={TooltipDelay.zero}
      directionalHint={DirectionalHint.bottomLeftEdge}
      styles={hostStyles}
    >
      <div style={{ cursor: "default" }}>{item.question}</div>
    </TooltipHost>
  );
};

const renderHiddenResponses = (
  column: IColumn | undefined,
  isHeading: boolean = false,
  t: (key: string) => string
): JSX.Element => {
  return (
    <Stack style={{ width: column?.maxWidth }} horizontalAlign="center">
      <Stack
        style={{
          ...BaseCellStyle,
          width: (column?.maxWidth || 0) - (isHeading ? 0 : 10),
          backgroundColor: "grey",
          color: "white",
          justifyContent: "center",
          cursor: "default",
        }}
        horizontal
      >
        {`< ${HIDE_IF_LESS_THAN} ${t("responses")}`}
      </Stack>
    </Stack>
  );
};

const renderHeatmapCell = (item: Item, column: IColumn | undefined, isHeading: boolean = false) => {
  const renderItemNumeric = item.cells[column?.key || ""] as RenderItemNumeric | undefined;
  if (!renderItemNumeric) return <></>;

  const backgroundColor =
    item.type === "happiness"
      ? getColorGradientFromFraction(renderItemNumeric.score / 100)
      : getColorGradientFromFraction((renderItemNumeric.score - 1) / 4);

  const style =
    item.type === "opinion_scale" || item.type === "happiness"
      ? {
          ...BaseCellStyle,
          backgroundColor: backgroundColor,
          width: (column?.maxWidth || 0) - (isHeading ? 0 : 10),
        }
      : BaseCellStyle;

  return (
    <Stack style={{ width: column?.maxWidth }} horizontalAlign="center">
      <Stack style={style} horizontal>
        {item.type !== "happiness"
          ? renderItemNumeric.score.toFixed(1)
          : `${renderItemNumeric.score} / 100`}
      </Stack>
    </Stack>
  );
};

const gridStyles: Partial<IDetailsListStyles> = {
  root: {
    overflowX: "scroll",
    selectors: {
      "& [role=grid]": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        height: "50vh",
      },
    },
  },
  headerWrapper: {
    flex: "0 0 auto",
  },
  contentWrapper: {
    flex: "1 1 auto",
    overflowY: "auto",
    overflowX: "hidden",
  },
};
