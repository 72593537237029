import { QualitySummary } from "./QualitySummary";
import { Stack } from "@fluentui/react";
import { SidebarContainerStyles } from "./AnalysisSidebar.styles";
// import { ExcelBox } from "./ExcelBox";

export const AnalysisSidebar = () => {
  return (
    <Stack tokens={{ childrenGap: 4 }} styles={SidebarContainerStyles}>
      <QualitySummary />
      {/* <ExcelBox /> */}
    </Stack>
  );
};
