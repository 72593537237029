import {
  Stack,
  ChoiceGroup,
  Separator,
  Dropdown,
  TextField,
  ITextFieldProps,
  Link,
  Text,
  IconButton,
} from "@fluentui/react";
import { t } from "i18next";
import {
  ifMonospaceRequired,
  replaceStringIfRequired,
} from "../../../../helpers/predictRules/qualityRuleFunctions/customRules";
import { Clause, ClauseType, booleanOperator, ConditionType } from "../../../../types/customChecks";
import { CustomTest, RuleCandidate } from "../../../../types/rules";
import { MODAL_DETAIL_LEFT_MARGIN, ChoiceGroupStyles } from "./bodyStyles";
import { useAppSelector } from "../../../../store/hooks";
import { selectLatestUpload } from "../../../../store/slices/upload";
import { Dispatch, SetStateAction } from "react";
import { ColumnDefinition } from "../../../../types/fileUploader";
import { getColumnOptions, getConditionOptions, getErrorMessage } from "./getOptionsAndErrors";

type ClauseProps = {
  localQualityTest: CustomTest;
  clause: Clause;
  localRule: RuleCandidate;
  localColumns: ColumnDefinition[];
  index: number;
  setLocalRule: Dispatch<SetStateAction<RuleCandidate>>;
};
export const CustomRuleClause = ({
  localQualityTest,
  clause,
  localRule,
  localColumns,
  index,
  setLocalRule,
}: ClauseProps): JSX.Element => {
  // This cant be a const because we optionally have to remove the operator
  let clauses = localQualityTest.meta.clauses;
  const uploadedFile = useAppSelector(selectLatestUpload);
  let precedingClauses = clauses.slice(0, index);
  let succeedingClauses = clauses.slice(index + 1, clauses.length);
  const precedingColumns = localColumns.slice(0, index);
  const succeedingColumns = localColumns.slice(index + 1, localRule.columns.length);
  const showInputField: boolean =
    clause.type === "text" || clause.type === "number" || clause.condition === "advanced check";
  const updateLocalRule = (updatedClauses: Clause[], updatedRuleColumns?: ColumnDefinition[]) => {
    setLocalRule({
      ...localRule,
      columns: updatedRuleColumns ?? localRule.columns,
      qualityTest: {
        ...localQualityTest,
        meta: { clauses: updatedClauses },
      } as CustomTest,
    });
  };
  const onRenderDescription = (props: ITextFieldProps | undefined): JSX.Element => {
    if (props?.description === "special characters")
      return (
        <Text variant="small">
          {t("about-special-characters")}{" "}
          <Link href="https://dqchelp.zendesk.com/hc/en-150/articles/9718243231773">
            {t("about-special-characters-link-txt")}
          </Link>
          ?
        </Text>
      );
    return <></>;
  };
  return (
    <Stack horizontal styles={{ root: { marginLeft: MODAL_DETAIL_LEFT_MARGIN } }}>
      <Stack tokens={{ childrenGap: 8 }} styles={{ root: { width: "100%" } }}>
        {clause.operator && (
          <Stack horizontal verticalAlign="center">
            <ChoiceGroup
              options={[
                { key: "and", text: "and" },
                { key: "or", text: "or" },
              ]}
              selectedKey={clause.operator}
              onChange={(_, option) => {
                const updatedClause: Clause = {
                  ...clause,
                  operator: option?.key as booleanOperator,
                };
                const updatedClauses = [...precedingClauses, updatedClause, ...succeedingClauses];
                updateLocalRule(updatedClauses);
              }}
              styles={ChoiceGroupStyles}
            />
            <Separator />
          </Stack>
        )}
        <Dropdown
          options={getColumnOptions(uploadedFile?.columns, index)}
          onChange={(_, option) => {
            if (!option) return;
            const updatedClause: Clause = {
              ...clause,
              columnIndex: option.data.index,
            };
            const updatedClauses = [...precedingClauses, updatedClause, ...succeedingClauses];
            const updatedRuleColumns = [...precedingColumns, option.data, ...succeedingColumns];
            updateLocalRule(updatedClauses, updatedRuleColumns);
          }}
          selectedKey={`${clause.columnIndex}::${index}`}
          styles={{
            root: { width: "100%" },
            title: { borderRadius: 4 },
            dropdownItemsWrapper: { maxHeight: "70vh" },
          }}
        />
        <Dropdown
          options={getConditionOptions(t)}
          onChange={(_, option) => {
            if (!option) return;
            const newCondition = String(option?.key).split("::")[1] as ConditionType;
            const updatedClause = {
              ...clause,
              type: option.data as ClauseType,
              condition: newCondition,
              isDefaultClause: false,
            };
            const updatedClauses = [...precedingClauses, updatedClause, ...succeedingClauses];
            // @ts-ignore
            updateLocalRule(updatedClauses);
          }}
          selectedKey={`${clause.type}::${clause.condition}`}
          styles={{
            root: { width: "100%" },
            title: { borderRadius: 4 },
            dropdownItemsWrapper: { maxHeight: "65vh" },
          }}
        />
        {showInputField && (
          <Stack horizontal horizontalAlign="space-between" grow={1}>
            {clause.type === "text" && (
              <TextField
                prefix={t(`prefix-${clause.condition}`) || undefined}
                description={clause.condition}
                onRenderDescription={onRenderDescription}
                placeholder={t(`placeholder-${clause.condition}`) || ""}
                value={clause.value}
                styles={{
                  root: {
                    fontFamily: ifMonospaceRequired(clause.condition),
                    width: "100%",
                  },
                  fieldGroup: { borderRadius: 4 },
                }}
                onChange={(_, text) => {
                  text = replaceStringIfRequired(clause.condition, text?.toString());
                  const updatedClause: Clause = {
                    ...clause,
                    value: text,
                    isDefaultClause: false,
                  };
                  const updatedClauses = [...precedingClauses, updatedClause, ...succeedingClauses];
                  updateLocalRule(updatedClauses);
                }}
                onGetErrorMessage={() => getErrorMessage(clause)}
                onFocus={() => {
                  const updatedClause = { ...clause, isDefaultClause: false };
                  const updatedClauses = [...precedingClauses, updatedClause, ...succeedingClauses];
                  updateLocalRule(updatedClauses);
                }}
                multiline={!!clause.value && clause.value.length > 30}
                validateOnFocusOut
              />
            )}
            {clause.type === "number" && (
              <TextField
                value={!clause.value && clause.value !== 0 ? "" : clause.value.toString()}
                onChange={(_, text) => {
                  const value = text ? parseFloat(text) : undefined;
                  const updatedClause = { ...clause, value };
                  const updatedClauses = [...precedingClauses, updatedClause, ...succeedingClauses];
                  updateLocalRule(updatedClauses);
                }}
                styles={{ fieldGroup: { borderRadius: 4 } }}
                onFocus={() => {
                  const updatedClause = { ...clause, isDefaultClause: false };
                  const updatedClauses = [...precedingClauses, updatedClause, ...succeedingClauses];
                  updateLocalRule(updatedClauses);
                }}
                onGetErrorMessage={() => getErrorMessage(clause)}
                validateOnFocusOut
              />
            )}
          </Stack>
        )}
      </Stack>
      <Stack styles={{ root: { marginLeft: 16 } }}>
        <IconButton
          iconProps={{ iconName: "Cancel" }}
          onClick={() => {
            const updatedColumns = [...precedingColumns, ...succeedingColumns];
            let updatedClauses = [...precedingClauses, ...succeedingClauses];
            if (updatedClauses[0]?.operator) updatedClauses[0].operator = undefined;
            updateLocalRule(updatedClauses, updatedColumns);
          }}
        />
      </Stack>
    </Stack>
  );
};
