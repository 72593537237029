import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@fluentui/react";
import { Separator } from "@fluentui/react/lib/Separator";
import { TopContainer } from "../../components/common/TopContainer";
import { QualityScore } from "../../components/dashboard/overview/QualityScore";
import { InteractionsChart } from "../../components/dashboard/overview/InteractionsChart";
import { LearningJourneys } from "../../components/dashboard/overview/LearningJourneys";
import { Gamification } from "../../components/dashboard/overview/Gamification";
import { DataSatisfaction } from "../../components/dashboard/overview/DataSatisfaction";
import { flexStackStyles } from "../../helpers/commonStyles";

export const Overview: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <TopContainer title={t("performance_overview")}>
      <main>
        <Stack horizontal styles={flexStackStyles}>
          <QualityScore />
          <InteractionsChart />
        </Stack>
        <Separator />
        <Stack horizontal styles={flexStackStyles}>
          <LearningJourneys />
          <Gamification />
          <DataSatisfaction />
        </Stack>
      </main>
    </TopContainer>
  );
};
