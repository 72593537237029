import {
  DirectionalHint,
  IIconStyles,
  ILabelStyles,
  IStackStyles,
  IStackTokens,
  ITooltipProps,
  Icon,
  Label,
  Stack,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { QualityScore, QualityScoreKeys, QualitySummary } from "../../../types/quality";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { Dataset } from "../../../types/dataset";
import { MissionData } from "../../../types/missions";
import { MissionQuality } from "../../common/MissionQualityBadge";
import { getBarColorForDataset } from "../../../helpers/utils";
import { hostStyles } from "../../../helpers/commonStyles";
import i18n from "../../../i18n";
import { setSelectedDataset } from "../../../store/slices/missionSlice";
import { useNavigate } from "react-router-dom";

const datasetTopic: ILabelStyles = {
  root: {
    marginLeft: "3%",
    fontWeight: 400,
    fontSize: "16px",
  },
};

const subContainer: IStackStyles = {
  root: {
    padding: "4px 12px",
    height: "45px",
    width: "-webkit-fill-available",
    borderRadius: "4px",
    background: "#F0F0F0",
  },
};

const mainContainer: IStackStyles = {
  root: {
    display: "flex",
    marginLeft: "2%",
    flexDirection: "row",
    height: "45px",
    marginBottom: "15px",
  },
};

const arrowIconContainer: IStackStyles = {
  root: {
    height: "45px",
  },
};

const stackItemStyles3: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "50%",
  },
};

const stackItemStyles4: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginRight: "-14px",
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};

const iconStyle: Partial<IIconStyles> = {
  root: {
    fontSize: 16,
    marginRight: "5%",
    color: "#0D3896",
    cursor: "pointer",
  },
};

const uploadTooltipProps: ITooltipProps = {
  onRenderContent: () => <>{i18n.t("upload")}</>,
};

const configureTooltipProps: ITooltipProps = {
  onRenderContent: () => <>{i18n.t("configure_duplicates")}</>,
};

const showAllTooltipProps: ITooltipProps = {
  onRenderContent: () => <>{i18n.t("show_issues")}</>,
};

export interface DatasetProps {
  dataset: Dataset;
  mission: MissionData;
  quality: QualitySummary | undefined;
  setOpenModal(isOpen: boolean): void;
  setDataset(dataset: Dataset): void;
  qualityStatus:
    | "done"
    | "loading_data"
    | "analyzing_data"
    | "writing_results"
    | "failed"
    | undefined;
}

export const DatasetItem: FunctionComponent<DatasetProps> = (datasetProps: DatasetProps) => {
  const [dataset, setDataset] = useState<Dataset>(datasetProps.dataset);
  const [mission, setMission] = useState<MissionData>(datasetProps.mission);
  const [quality, setQuality] = useState<QualitySummary | undefined>(datasetProps.quality);
  const selectedDatasetsForQuality = useAppSelector(
    (state) => state.missionDetail.selectedDatasets
  );

  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  useEffect(() => {
    setQuality(datasetProps.quality);
    setMission(datasetProps.mission);
    setDataset(datasetProps.dataset);
  }, [datasetProps]);

  const getQualityScoreForDataset = (
    quality: QualitySummary | undefined,
    dataset: Dataset
  ): typeof QualityScore[QualityScoreKeys] | undefined => {
    if (!quality?.dataset_quality_summaries) return undefined;
    return quality.dataset_quality_summaries.filter((d) => d.dataset_id === dataset.id)[0]
      ?.quality_score;
  };

  const loadUpload = (dataset_urn: string, mission_id: string) => {
    navigate(`/missions/upload?dataset_urn=${dataset_urn}&mission_id=${mission_id}`);
  };

  return (
    <Stack
      styles={mainContainer}
      key={dataset.id}
      onClick={() => dispatch(setSelectedDataset(dataset.id))}
    >
      <Stack styles={arrowIconContainer}>
        <img src="/arrow-ds.svg" alt="arrow" style={{ height: "30px" }} />
      </Stack>
      <Stack horizontal tokens={stackTokens} styles={subContainer}>
        <Stack.Item grow={3} styles={stackItemStyles3}>
          <div
            style={{
              height: "100%",
              width: "6px",
              backgroundColor: !selectedDatasetsForQuality.includes(dataset.id)
                ? "#909090"
                : getBarColorForDataset(dataset.id, mission.datasets),
            }}
          ></div>
          <Label styles={datasetTopic}>{dataset.name}</Label>
        </Stack.Item>
        <Stack.Item grow={1} styles={stackItemStyles4}>
          <Stack>
            <TooltipHost
              tooltipProps={uploadTooltipProps}
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.bottomCenter}
              styles={hostStyles}
            >
              <Icon
                styles={iconStyle}
                iconName="FabricNewFolder"
                onClick={() => {
                  loadUpload(dataset.urn, mission.id);
                }}
                aria-label={i18n.t("upload")}
              />
            </TooltipHost>
          </Stack>
          <Stack>
            <TooltipHost
              tooltipProps={configureTooltipProps}
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.bottomCenter}
              styles={hostStyles}
            >
              <Icon
                styles={iconStyle}
                iconName="Settings"
                onClick={() => {
                  datasetProps.setOpenModal(true);
                  datasetProps.setDataset(dataset);
                }}
                aria-label={i18n.t("configure_duplicates")}
              />
            </TooltipHost>
          </Stack>
          <Stack>
            <TooltipHost
              tooltipProps={showAllTooltipProps}
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.bottomCenter}
              styles={hostStyles}
            >
              <Icon
                styles={iconStyle}
                iconName="Warning"
                onClick={() => {
                  navigate(`/missions/${mission.id}/dataset/${dataset.id}/issues`);
                }}
                aria-label={i18n.t("show_issues")}
              />
            </TooltipHost>
          </Stack>
          <Stack>
            <MissionQuality
              value={getQualityScoreForDataset(quality, dataset)}
              status={datasetProps.qualityStatus}
            />
          </Stack>
        </Stack.Item>
      </Stack>
    </Stack>
  );
};
