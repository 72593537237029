import { Colleague } from "../types/colleague";
import { Group, UpdateGroup } from "../types/group";
import { api_base, dqcApi } from "./dqcQuery";

export const companyAndGroupsApi = dqcApi.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query<Group[], string>({
      query: () => ({ url: `/${api_base}/groups/`, method: "get" }),
      providesTags: ["GroupList"],
    }),
    addGroup: builder.mutation({
      query: (newGroup: { name: string; description: string }) => ({
        url: `/${api_base}/groups/`,
        method: "POST",
        data: newGroup,
      }),
      invalidatesTags: ["GroupList"],
    }),
    updateGroup: builder.mutation({
      query: (updateGroupObj: UpdateGroup) => ({
        url: `/${api_base}/groups/${updateGroupObj.id}/`,
        method: "PUT",
        data: updateGroupObj,
      }),
      invalidatesTags: ["GroupList"],
    }),
    deleteGroup: builder.mutation({
      query: (groupId: string) => ({
        url: `/${api_base}/groups/${groupId}/`,
        method: "delete",
      }),
      invalidatesTags: ["GroupList"],
    }),
    deleteMember: builder.mutation({
      query: (member: { groupId: string; id: string }) => ({
        url: `/${api_base}/groups/${member.groupId}/colleague/${member.id}/`,
        method: "delete",
      }),
      invalidatesTags: ["GroupList"],
    }),
    addNewColleague: builder.mutation({
      query: (colleagueObj: { groupId: string; newColleague: Colleague }) => ({
        url: `/${api_base}/groups/${colleagueObj.groupId}/colleague/`,
        method: "PUT",
        data: colleagueObj.newColleague,
      }),
      invalidatesTags: ["GroupList"],
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useAddGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useDeleteMemberMutation,
  useAddNewColleagueMutation,
} = companyAndGroupsApi;
