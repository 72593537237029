import { IStackItemStyles } from "@fluentui/react";

export const choiceGroupStyle: IStackItemStyles = {
  root: {
    ".ms-ChoiceField": {
      flex: 1,
    },
    ".ms-ChoiceFieldGroup-flexContainer": {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      marginLeft: 48,
    },
    ".ms-ChoiceField-wrapper": {
      minWidth: "20em",
    },
  },
};
