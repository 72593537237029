import { ActionButton, DefaultButton, IStackStyles, Label, Persona, Stack } from "@fluentui/react";
import { FunctionComponent } from "react";
import { dqcTheme } from "../../../helpers/commonStyles";
import { getInitialsFromDisplayName, getInitialsFromMail } from "../../../helpers/utils";
import { ProfileData } from "../../../types/profile";
import { t } from "i18next";

const COIN_SIZE = 150;

export type ProfileComponentsProps = {
  profile?: ProfileData;
  editable?: boolean;
  toggleEditable?: () => void;
  setProfile?: (profile: ProfileData) => void;
  logoutUrl?: string;
  styles?: Partial<IStackStyles>;
};

export const ProfileHeader: FunctionComponent<ProfileComponentsProps> = ({
  profile,
  editable,
  logoutUrl,
  styles,
}) => {
  if (!profile) return <></>;
  return (
    <Stack styles={{ root: { height: 100 }, ...styles }}>
      <Persona
        imageInitials={
          profile.firstname
            ? getInitialsFromDisplayName(`${profile.firstname || ""} ${profile.lastname || ""}`)
            : getInitialsFromMail(profile.email || "")
        }
        initialsColor={dqcTheme.palette.neutralLight}
        initialsTextColor={dqcTheme.palette.blackTranslucent40}
        coinSize={COIN_SIZE}
        styles={{
          root: { position: "relative", top: -(COIN_SIZE / 2) },
        }}
        coinProps={{
          styles: { initials: { border: "2px solid white" } },
        }}
      />
      <Stack
        horizontal
        styles={{ root: { position: "relative", top: -(COIN_SIZE * 2), width: "100%" } }}
        tokens={{ childrenGap: 5 }}
        horizontalAlign="end"
      >
        <ActionButton
          iconProps={{ iconName: "SignOut" }}
          href={logoutUrl}
          text={t("logout")}
          styles={{
            root: {
              color: dqcTheme.palette.white,
            },
            icon: {
              color: dqcTheme.palette.accent,
            },
            rootHovered: {
              color: dqcTheme.palette.accent,
            },
            iconHovered: {
              color: dqcTheme.palette.white,
            },
          }}
        />
      </Stack>
      <Stack
        horizontal
        styles={{ root: { position: "relative", top: -(COIN_SIZE + 30), width: "100%" } }}
        tokens={{ childrenGap: 5 }}
        horizontalAlign="end"
      >
        <Stack.Item align="stretch" grow={1} styles={{ root: { marginLeft: 200 } }}>
          <Label styles={{ root: { fontSize: 20 } }}>
            {profile.firstname} {profile.lastname}
          </Label>
        </Stack.Item>
        <Stack.Item align="end">
          {editable && (
            <DefaultButton
              text={t("cancel")}
              onClick={() => {
                window.location.href = "/profile";
              }}
            />
          )}
          {!editable && (
            <DefaultButton
              text={t("edit-profile")}
              onClick={() => {
                window.location.href = "/auth/self-service/settings/browser";
              }}
            />
          )}
        </Stack.Item>
      </Stack>
    </Stack>
  );
};
