import {
  IconButton,
  IContextualMenuProps,
  Persona,
  PersonaSize,
  Stack,
  Text,
} from "@fluentui/react";
import moment from "moment";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useWhoAmIQuery } from "../../api/profile";
import { useGetProfilePictureByIdQuery } from "../../api/users";
import { commandBarDefault, thinBorder } from "../../helpers/commonStyles";

import { useAppSelector } from "../../store/hooks";
import { selectMicrosoftUserId, selectMsGraphApiAccessToken } from "../../store/slices/remarkSlice";
import { CommentData } from "../../types/comments";
import { ThumbsUp } from "../colab/thumbs-up";

interface CommentTileProps {
  comment: CommentData;
  setEditCommentId: (editCommentId: string) => void;
  commentToDelete: (commentId: string) => void;
  commentToUpdate: (comment: CommentData) => void;
}

export const CommentTile: FunctionComponent<CommentTileProps> = ({
  comment,
  setEditCommentId,
  commentToDelete,
  commentToUpdate,
}) => {
  const { t } = useTranslation();
  const session = useWhoAmIQuery({});
  const accessToken = useAppSelector(selectMsGraphApiAccessToken);
  const { data: imageUrl } = useGetProfilePictureByIdQuery({
    accessToken,
    userId: comment.author.id || "",
  });

  const moreProps: IContextualMenuProps = {
    items: [
      {
        key: "delete",
        text: t("delete"),
        onClick: () => {
          commentToDelete(comment.id);
        },
      },
      {
        key: "edit",
        text: t("edit"),
        onClick: () => {
          setEditCommentId(comment.id);
        },
      },
    ],
  };
  // TODO unify user IDs https://dqcai.atlassian.net/browse/DDP-1518
  const microsoftUserId = useAppSelector(selectMicrosoftUserId) || "";
  // TODO this is a crutch until we unify Microsoft auth with our own.
  const myIds = [session?.data?.identity?.id, microsoftUserId];
  const isUserCommentAuthor = myIds.includes(comment.author.id);
  const userHasLiked = !!comment.emoticons["👍"]?.some((userId) => myIds.includes(userId));
  const onClickThumbsUp = () => {
    const updatedComment: CommentData = { ...comment };
    // Send only the latest emoticon which user has clicked. Backend will update it according to current data.
    updatedComment.emoticons = {
      "👍": [microsoftUserId],
    };
    commentToUpdate(updatedComment);
  };
  return (
    <Stack styles={thinBorder}>
      <Stack styles={commandBarDefault}>
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
          <Stack horizontal verticalAlign="center">
            <Persona
              imageUrl={imageUrl}
              size={PersonaSize.size40}
              coinSize={30}
              text={comment.author.display_name}
              secondaryText={moment.utc(comment.created_at).fromNow()}
            />
          </Stack>
          {isUserCommentAuthor && (
            <Stack>
              <IconButton
                menuProps={moreProps}
                onRenderMenuIcon={() => null}
                iconProps={{ iconName: "MoreVertical" }}
                title="More"
                ariaLabel="More"
              />
            </Stack>
          )}
        </Stack>

        {comment?.tagged_users?.length && (
          <Stack style={{ marginTop: 4, marginBottom: -20 }}>
            <Text style={{ color: "blue", paddingRight: "5px" }}>
              {comment?.tagged_users?.map((item) => `@${item.displayName}`).join(" ")}
            </Text>
          </Stack>
        )}
      </Stack>

      <Stack horizontal style={{ marginLeft: 8, marginRight: 8 }}>
        <Stack horizontal style={{ flexGrow: 1 }}>
          <div dangerouslySetInnerHTML={{ __html: comment.body }} />
        </Stack>

        <ThumbsUp
          isActive={userHasLiked}
          count={comment.emoticons["👍"]?.length || 0}
          onClick={onClickThumbsUp}
        />
      </Stack>
    </Stack>
  );
};
