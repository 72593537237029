import { ConfigurationContent } from "../../types/tile";
import { ApiSettings, ConnectionValues, InputFields } from "../../types/connection";
import { TileData } from "../../types/tile";
import { ILabelStyles, Label, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import {
  backgroundStyles,
  centerDiv,
  innerStackTokens,
  stackStyle,
  topHeading,
} from "../../helpers/commonStyles";
import { useGetAllConfigurationsQuery, useGetInstallationsQuery } from "../../api/installation";

import { CommonModal } from "../../components/common/CommonModal";
import { TopContainer } from "../../components/common/TopContainer";
import { useTranslation } from "react-i18next";
import { TileRoot } from "../../components/common/TileRoot";
import { defaultTextfieldData } from "../../helpers/connections-helper";

const connectionTopic: Partial<ILabelStyles> = {
  root: {
    display: "inline",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "40px",
    color: "#000000",
  },
};

export const Connections: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [tileId, setTileId] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [connectorType, setConnectorType] = useState("");
  const [connectorId, setConnectorId] = useState("");
  const [fieldsData, setFieldsData] = useState<ApiSettings>();
  const [inputFields, setInputFields] = useState<InputFields[]>([]);
  const [configData, setConfigData] = useState<ConfigurationContent[]>([]);
  const [loader, setLoader] = useState(false);
  const [connectionData, setConnectionData] = useState<ConnectionValues[]>([]);
  const installationData = useGetInstallationsQuery();
  const allConfigurations = useGetAllConfigurationsQuery("");

  useEffect(() => {
    setLoader(true);
    if (!installationData.isLoading && installationData.isSuccess) {
      let identity: TileData[] = [];
      let datasource: TileData[] = [];
      let messaging: TileData[] = [];
      installationData.data.connectors.data.forEach((item: TileData) => {
        if (item.attributes.connector_type === "identity") {
          identity.push(item);
        } else if (item.attributes.connector_type === "datasource") {
          datasource.push(item);
        } else {
          messaging.push(item);
        }
      });
      setConnectionData([
        { heading: `${t("identity")}`, dataArray: identity },
        { heading: `${t("datasource")}`, dataArray: datasource },
        { heading: `${t("messaging")}`, dataArray: messaging },
      ]);
    }
    if (!allConfigurations.isLoading && allConfigurations.isSuccess) {
      setConfigData(allConfigurations.data.connections);
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationData, allConfigurations]);

  const handlePrimaryButtonClick = (
    id: number,
    title: string,
    modalContent: string,
    fields: InputFields[],
    connectorType: string,
    connectorId: string
  ) => {
    let data = configData.filter((data) => data.bf_id === id);
    if (data.length > 0) {
      setFieldsData(data[0]?.api_settings);
    } else {
      setFieldsData(defaultTextfieldData[connectorId]);
    }
    setTileId(id);
    setInputFields(fields);
    setModalHeading(title);
    setModalContent(modalContent);
    setConnectorType(connectorType);
    setConnectorId(connectorId);
    setModal(true);
  };

  return (
    <TopContainer title={t("connection_title")}>
      {modal ? (
        <CommonModal
          id={tileId}
          title={modalHeading}
          modalState={modal}
          body={modalContent}
          fieldData={fieldsData}
          inputFields={inputFields}
          connectorType={connectorType}
          connectorId={connectorId}
          primaryButtonText={t("add_token")}
          secondaryButtonText={t("cancel")}
          secondaryButtonOnClick={(): void => {
            setModal(false);
          }}
        />
      ) : null}
      <Label styles={topHeading}>
        {t("connection_label_1")}
        <br />
        {t("connection_label_2")}
      </Label>
      <Stack styles={backgroundStyles}>
        {connectionData.map((item: ConnectionValues, index) => (
          <div key={index}>
            {item.dataArray.length !== 0 ? (
              <Label styles={connectionTopic}>{item.heading}</Label>
            ) : null}
            <Stack horizontal wrap tokens={innerStackTokens} styles={stackStyle}>
              {item.dataArray && item.dataArray.length !== 0 ? (
                item.dataArray.map((data: TileData, index) => (
                  <div key={index}>
                    <TileRoot
                      type={"Connections"}
                      item={data}
                      loader={loader}
                      configData={configData.some((config) => config.bf_id === data.id)}
                      primaryButtonText={t("activate_plugin")}
                      primaryButtonOnClick={() =>
                        handlePrimaryButtonClick(
                          data.id,
                          data.attributes.title,
                          data.attributes.modal_content,
                          data.attributes.input_fields.fields,
                          data.attributes.connector_type,
                          data.attributes.input_fields.connector_id
                        )
                      }
                      secondaryButtonText={t("learn_more")}
                      secondaryButtonOnClick={() => window.open(data.attributes.learn_more_link)}
                    />
                  </div>
                ))
              ) : loader ? (
                <div style={centerDiv}>
                  <Spinner size={SpinnerSize.large} />
                </div>
              ) : null}
            </Stack>
          </div>
        ))}
      </Stack>
    </TopContainer>
  );
};
