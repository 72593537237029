import {
  Icon,
  IDropdownOption,
  IDropdownProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  ITag,
  Label,
  Stack,
} from "@fluentui/react";
import React, { Dispatch, FunctionComponent, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  DataAssetDropDownData,
  problemOptions,
  valueOptions,
} from "../../../components/cockpit/dropdownOptions";
import { AssetUsecaseProblems, AssetUsecaseValue } from "../../../types/dataasset";
import { UseCase, Tag } from "../../../types/usecase";
import { EditableDropdown } from "../../../components/common/EditableDropdown";
import { EditableTextField } from "../../../components/common/EditableTextField";
import { descDetailStyle, detailsContainer } from "../../../helpers/commonStyles";
import { Tags } from "../../../components/cockpit/Tags";
import { SelectMainDomain } from "../../../components/cockpit/SelectMainDomain";
import { useBoolean } from "@fluentui/react-hooks";

interface DetailState {
  useCase?: UseCase;
  tags?: Tag[];
  setUseCase: Dispatch<SetStateAction<UseCase>>;
  updateUseCase: () => void;
}

const stackTokens: IStackTokens = {
  childrenGap: 5,
};

const lineStack: IStackStyles = {
  root: {
    marginBottom: 10,
  },
};

const labelItem: IStackItemStyles = {
  root: {
    minWidth: 130,
  },
};

const iconStyles = { marginRight: "8px" };

const dropdownStyles = { dropdown: { width: "100%" } };

const optionText: React.CSSProperties = {
  marginLeft: 15,
  display: "inline-block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const onRenderOption = (option?: IDropdownOption): JSX.Element | null => {
  if (!option) return null;
  return (
    <div>
      <Stack horizontal>
        <span>{option.data.render()}</span>
        <span style={optionText}>{option.text}</span>
      </Stack>
    </div>
  );
};

const onRenderTitle = (options?: IDropdownOption[]): JSX.Element | null => {
  let option: IDropdownOption;
  if (!options) return null;
  option = options[0];
  return (
    <div>
      <Stack horizontal>
        <span>{option.data.render()}</span>
        <span>{option.text}</span>
      </Stack>
    </div>
  );
};

const onRenderPlaceholder = (props: IDropdownProps | undefined): JSX.Element => {
  return (
    <div className="dropdownExample-placeholder">
      <Icon style={iconStyles} iconName={"MessageFill"} aria-hidden="true" />
      <span>{props?.placeholder}</span>
    </div>
  );
};

export const Details: FunctionComponent<DetailState> = (props: DetailState) => {
  const { t } = useTranslation();

  // hack to propagate change
  const [onChangeTriggered, { toggle: setOnChangeTriggered }] = useBoolean(false);
  useEffect(() => {
    props.updateUseCase();
  }, [onChangeTriggered]);

  if (!props.useCase) return null;
  return (
    <div>
      <label style={{ ...descDetailStyle, marginBottom: 15 }}>{t("details")}</label>
      <Stack styles={detailsContainer}>
        <Stack horizontal tokens={stackTokens} styles={lineStack}>
          <Stack.Item styles={labelItem}>
            <Label>{t("owner")}</Label>
          </Stack.Item>
          <Stack.Item grow={1} style={{ overflow: "hidden" }}>
            <EditableTextField
              value={props.useCase.owner}
              placeholder={t("owner")}
              onChange={(
                _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                props.useCase &&
                  props.setUseCase({
                    ...props.useCase,
                    owner: newValue,
                  });
              }}
              onBlur={() => {
                setOnChangeTriggered();
              }}
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal tokens={stackTokens} styles={lineStack}>
          <Stack.Item styles={labelItem}>
            <Label>{t("value")}</Label>
          </Stack.Item>
          <Stack.Item grow={1} style={{ overflow: "hidden" }}>
            <EditableDropdown
              selectedKey={props.useCase.value}
              placeholder={t("value")}
              ariaLabel={t("select-value")}
              onRenderTitle={onRenderTitle}
              onRenderPlaceholder={onRenderPlaceholder}
              onRenderOption={onRenderOption}
              options={valueOptions}
              styles={dropdownStyles}
              onChange={(_event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                props.useCase &&
                  props.setUseCase({
                    ...props.useCase,
                    value: item?.key as AssetUsecaseValue,
                  });
                setOnChangeTriggered();
              }}
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal tokens={stackTokens} styles={lineStack}>
          <Stack.Item styles={labelItem}>
            <Label>{t("problems")}</Label>
          </Stack.Item>
          <Stack.Item grow={1} style={{ overflow: "hidden" }}>
            <EditableDropdown
              selectedKey={props.useCase.problems}
              placeholder={t("problems")}
              ariaLabel={t("select-problem")}
              onRenderTitle={onRenderTitle}
              onRenderPlaceholder={onRenderPlaceholder}
              onRenderOption={onRenderOption}
              options={problemOptions}
              styles={dropdownStyles}
              onChange={(
                _event: React.FormEvent<HTMLDivElement>,
                item?: IDropdownOption<DataAssetDropDownData>
              ) => {
                props.useCase &&
                  props.setUseCase({
                    ...props.useCase,
                    problems: item?.key as AssetUsecaseProblems,
                  });
                setOnChangeTriggered();
              }}
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal tokens={stackTokens} styles={lineStack}>
          <Stack.Item styles={labelItem}>
            <Label>{t("tags")}</Label>
          </Stack.Item>
          <Stack.Item grow={1} style={{ overflow: "hidden" }}>
            <Tags
              currentTags={props.useCase.tags}
              onChange={(items?: ITag[]) => {
                if (props.useCase) {
                  const updateObj: UseCase = {
                    ...props.useCase,
                    tags: items?.map((i) => ({ id: i.key, label: i.name } as Tag)) || [],
                  };
                  props.setUseCase(updateObj);
                  setOnChangeTriggered();
                }
              }}
              onBlur={props.updateUseCase}
              hoverStyle={true}
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal tokens={stackTokens} styles={lineStack}>
          <Stack.Item styles={labelItem}>
            <Label>{t("main_domain")}</Label>
          </Stack.Item>
          <Stack.Item grow={1} style={{ overflow: "hidden" }}>
            <SelectMainDomain
              setSelectMainDomainId={(id) => {
                props.useCase && props.setUseCase({ ...props.useCase, main_domain_id: id });
                setOnChangeTriggered();
              }}
              selectedMainDomainId={props.useCase?.main_domain?.id}
              showLabel={false}
              showDetailStyle={true}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </div>
  );
};
