import { Stack } from "@fluentui/react";
import { CockpitHeader } from "../../../components/cockpit/CockpitHeader";
import { TopContainer } from "../../../components/common/TopContainer";
import { useGetDataAssetsQuery } from "../../../api/cockpit";
import { useState } from "react";
import { selectSortOption } from "../../../store/slices/cockpitSlice";
import { useAppSelector } from "../../../store/hooks";
import { searchData, sortData } from "../../../helpers/cockpit-helper";
import { useTranslation } from "react-i18next";
import { AssetList } from "../../../components/cockpit/AssetList";

export const Assets = () => {
  const { t } = useTranslation();
  const { data, isLoading, isSuccess } = useGetDataAssetsQuery({ parent: "none", all: true });
  const assets = data || [];
  const selectedSortOption = useAppSelector(selectSortOption);
  const [searchTerm, setSearchTerm] = useState("");

  let sortedAndFilteredAssets = [...assets];
  sortedAndFilteredAssets = sortData(sortedAndFilteredAssets, selectedSortOption);
  if (searchTerm) {
    sortedAndFilteredAssets = searchData(sortedAndFilteredAssets, searchTerm, "title");
  }

  return (
    <TopContainer title="Assets">
      <Stack>
        {!isLoading && isSuccess ? (
          <CockpitHeader
            screen="assets"
            searchBoxText={t("search_data_assets")}
            buttonLabel={t("add_assets")}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        ) : null}
      </Stack>
      <Stack>
        <AssetList dataAssets={sortedAndFilteredAssets} />
      </Stack>
    </TopContainer>
  );
};
