import type {
  QualityDimension,
  QualityDimensionKeys,
  DatasetQualitySummary,
  QualityDimensionAggregate,
  IssueCountModel,
} from "../types/quality";

export const getQualityScores = (dataset_quality_summaries: DatasetQualitySummary[]) => {
  let scores = new Map<typeof QualityDimension[QualityDimensionKeys], QualityDimensionAggregate>();

  dataset_quality_summaries.forEach((quality_summary: DatasetQualitySummary) => {
    quality_summary.issue_counts.forEach((issue_count: IssueCountModel) => {
      let aggregate: QualityDimensionAggregate;
      if (scores.has(issue_count.quality_dimension)) {
        aggregate = scores.get(issue_count.quality_dimension)!;
        aggregate.quality_score += issue_count.quality_score;
        aggregate.count += issue_count.count;
      } else {
        aggregate = {
          count: issue_count.count,
          quality_score: issue_count.quality_score,
          dataset_details: [],
        };
      }
      aggregate.dataset_details.push({
        id: quality_summary.dataset_id,
        count: issue_count.count,
      });
      scores.set(issue_count.quality_dimension, aggregate);
    });
  });

  scores.forEach(
    (aggregate: QualityDimensionAggregate, key: typeof QualityDimension[QualityDimensionKeys]) => {
      aggregate.quality_score = Math.ceil(
        aggregate.quality_score / dataset_quality_summaries.length
      );
      scores.set(key, aggregate);
    }
  );
  return scores;
};

export const getQualitySummaryByDatasetId = (
  qualitySummary: DatasetQualitySummary[],
  datasetIds: string[]
) => {
  const datasets = qualitySummary.filter((datasetQS) => datasetIds.includes(datasetQS.dataset_id));
  if (datasets.length === 0) return qualitySummary;
  return datasets;
};
