import { IDropdownOption, DropdownMenuItemType } from "@fluentui/react";
import { t } from "i18next";
import {
  ALL_NUMERIC_CONDITIONS,
  ALL_TEXT_CONDITIONS,
  ALL_FORMAT_CONDITIONS,
  ALL_OTHER_CONDITIONS,
} from "../../../../helpers/quickAnalysis/qualityChecks/constants";
import { ClauseType, Clause } from "../../../../types/customChecks";
import { ColumnDefinition } from "../../../../types/fileUploader";

export const getConditionOptions = (t: (key: string) => string): IDropdownOption<ClauseType>[] => {
  return [
    { key: "numeric", text: "Numeric", itemType: DropdownMenuItemType.Header, data: "number" },
    ...ALL_NUMERIC_CONDITIONS.map((c) => {
      return { key: `number::${c}`, text: t(`rule-${c}`), data: "number" as ClauseType };
    }),
    { key: "text", text: "Text", itemType: DropdownMenuItemType.Header },
    ...ALL_TEXT_CONDITIONS.map((c) => {
      return { key: `text::${c}`, text: t(`rule-${c}`), data: "text" as ClauseType };
    }),
    { key: "text", text: "Format", itemType: DropdownMenuItemType.Header },
    ...ALL_FORMAT_CONDITIONS.map((c) => {
      return { key: `format::${c}`, text: t(`rule-${c}`), data: "format" as ClauseType };
    }),
    { key: "text", text: "Other", itemType: DropdownMenuItemType.Header },
    ...ALL_OTHER_CONDITIONS.map((c) => {
      return { key: `other::${c}`, text: t(`rule-${c}`), data: "other" as ClauseType };
    }),
  ];
};

export const getErrorMessage = (clause: Clause): string | undefined => {
  const emptyNumberField = clause.type === "number" && clause.value === undefined;
  const emptyTextField =
    clause.type === "text" && !clause.value && clause.condition !== "special characters";
  if (clause.isDefaultClause) return;
  if (emptyNumberField) return t("number_field_blank");
  if (emptyTextField) return t("text_field_blank");
};

export const getColumnOptions = (
  columns: ColumnDefinition[] | undefined,
  conditionIndex: number
): IDropdownOption<ColumnDefinition>[] =>
  columns?.map((column) => ({
    key: `${column.index}::${conditionIndex}`,
    text: column.name,
    data: column,
  })) ?? [];
