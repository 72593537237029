import { ColumnDefinition, UploadDataContainer } from "../../../types/fileUploader";
import { DuplicateTest, RuleCandidateWithoutMeta } from "../../../types/rules";

const qualityDuplicateTest: DuplicateTest = {
  testFunctionName: "duplicates",
  meta: {
    fuzzinessThreshold: 0,
  },
};

export const predictDuplicateRules = (
  dataContainer: UploadDataContainer
): RuleCandidateWithoutMeta[] => {
  return [getDuplicateRule(dataContainer.columns, dataContainer)];
};

export const getDuplicateRule = (
  columns: ColumnDefinition[],
  dataContainer: UploadDataContainer
): RuleCandidateWithoutMeta => {
  return {
    id: `${dataContainer.fileName}::duplicate::exact::${columns.map((c) => c.index).join(":")}`,
    columns,
    dimension: "duplicate",
    severity: "warning",
    confidence: 4,
    isAccepted: true,
    name: "Unique",
    description: "Ensures rows are unique across columns listed",
    qualityTest: qualityDuplicateTest,
    fileName: dataContainer.fileName,
  };
};
