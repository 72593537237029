import { FunctionComponent } from "react";
import { t } from "i18next";

export const WelcomeUser: FunctionComponent = () => {
  const spanStyle = { lineHeight: "1.5em", verticalAlign: "middle", marginLeft: 4 };
  return (
    <>
      <p style={{ listStyle: "none", textAlign: "center", margin: "30 0 0 0", padding: 0 }}>
        <b style={spanStyle}>{t("welcome-user")}</b>
        <br />
        <span style={spanStyle}>{t("welcome-user-explanation")}</span>
      </p>
    </>
  );
};
