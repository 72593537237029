import {
  Modal,
  TextField,
  Dropdown,
  IDropdownOption,
  IStackTokens,
  Stack,
  DefaultButton,
  IButtonStyles,
  Icon,
  Label,
  IDropdownStyles,
  ITextFieldStyles,
  ITag,
  PrimaryButton,
} from "@fluentui/react";
import React, { useState } from "react";
import { iconStyle, modalArea } from "../../helpers/commonStyles";
import { useCreateDataAssetMutation } from "../../api/cockpit";
import { getDefaultDataAsset } from "./helpers";
import {
  DataAssetDropDownData,
  typeOptions,
  valueOptions,
  problemOptions,
  governanceOptions,
  onRenderDropDownOption,
  onRenderTitle,
} from "./dropdownOptions";
import { CreateDataAsset, Origin } from "../../types/dataasset";
import { RichTextEditor } from "../common/RichTextEditor";
import { SelectParentDataAsset } from "./SelectParentDataAsset";
import { SelectChildrenDataAssets } from "./SelectChildrenDataAssets";
import { SelectLinkedUseCases } from "./SelectLinkedUseCases";
import { Origins } from "./Origins";
import { useTranslation } from "react-i18next";

const addButtonStyle: IButtonStyles = {
  root: {
    width: "139px",
    height: "32px",
    borderRadius: "2px",
    padding: 10,
    marginTop: "5%",
  },
};
const cancelButtonStyle: IButtonStyles = {
  root: {
    width: "139px",
    height: "32px",
    color: "#201F1E",
    borderRadius: "2px",
    padding: 10,
    marginTop: "5%",
  },
};
const fieldStyle: React.CSSProperties = {
  padding: "20px",
};
const field: Partial<IDropdownStyles> = {
  root: {
    marginTop: "2%",
  },
};
const fieldText: Partial<ITextFieldStyles> = {
  root: {
    marginTop: "2%",
  },
};

const sectionStackTokens: IStackTokens = { childrenGap: 10 };

type CreateDataAssetError = {
  title?: string;
  type?: string;
};

type CreateDataAssetModalProps = {
  open: boolean;
  handleClose: () => void;
};
export const CreateDataAssetModal: React.FunctionComponent<CreateDataAssetModalProps> = ({
  open,
  handleClose,
}) => {
  const { t } = useTranslation();
  const [asset, setAsset] = useState<CreateDataAsset>(getDefaultDataAsset());
  const [createDataAsset] = useCreateDataAssetMutation();
  const [error, setError] = useState<CreateDataAssetError>({});

  // Create Asset Api call
  const createAsset = async () => {
    const res = await createDataAsset(asset).unwrap();
    if (res) {
      handleClose();
    }
  };
  const onChangeTextField = (e: any) => {
    setAsset({
      ...asset,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "title" && e.target.value === "")
      setError({ ...error, title: "Title cannot be left blank" });
    else setError({ ...error, title: "" });
  };
  const onChangeDropDown = (_: any, option?: IDropdownOption<DataAssetDropDownData>) => {
    if (!option?.data) return;
    setAsset({
      ...asset,
      [option.data.field]: option.key,
    });
  };
  return (
    <>
      <Modal isOpen={open} containerClassName={modalArea.container}>
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
          <h2>{t("add-data-asset")}</h2>
          <Icon styles={iconStyle} iconName="cancel" onClick={handleClose} />
        </Stack>
        <div style={fieldStyle}>
          <TextField
            label={t("title")}
            name="title"
            placeholder={t("title_placeholder")}
            invalid={error.title ? true : false}
            errorMessage={error.title ? <span>{error.title}</span> : ""}
            onChange={onChangeTextField}
            required
          />
          <Label>{t("description")}</Label>
          <RichTextEditor
            toggleEditor={false}
            onChange={(description) => setAsset({ ...asset, description })}
            value={asset.description || ""}
            height={200}
          />
          <TextField
            label={t("owner")}
            placeholder={t("select-owner")}
            name="owner"
            onChange={onChangeTextField}
            styles={fieldText}
          />
          <Dropdown
            label={t("type")}
            placeholder={t("select-type")}
            styles={field}
            options={typeOptions}
            onRenderOption={onRenderDropDownOption}
            selectedKey={asset.type}
            onChange={onChangeDropDown}
            onRenderTitle={onRenderTitle}
            required
            errorMessage={error.type ? t("please-select-type") : undefined}
          />

          <Dropdown
            label={t("value")}
            placeholder={t("select-type")}
            styles={field}
            options={valueOptions}
            onRenderOption={onRenderDropDownOption}
            selectedKey={asset.value}
            onChange={onChangeDropDown}
            onRenderTitle={onRenderTitle}
          />

          <Dropdown
            label={t("problems")}
            placeholder={t("select-problem")}
            styles={field}
            options={problemOptions}
            onRenderOption={onRenderDropDownOption}
            selectedKey={asset.problems}
            onChange={onChangeDropDown}
            onRenderTitle={onRenderTitle}
          />
          <TextField
            styles={fieldText}
            label={t("steward")}
            name="steward"
            placeholder={t("select-steward")}
            onChange={onChangeTextField}
          />

          <TextField
            styles={fieldText}
            label={t("custodian")}
            name="custodian"
            placeholder={t("select_custodian")}
            onChange={onChangeTextField}
          />

          <Label styles={field}>{t("origins")}</Label>
          <Origins
            currentOrigins={asset.origins}
            onChange={(items?: ITag[]) =>
              setAsset({
                ...asset,
                origins: items?.map((i) => ({ id: i.key, label: i.name } as Origin)) || [],
              })
            }
          />
          <Dropdown
            label={t("governance")}
            placeholder={t("select_governance")}
            styles={field}
            options={governanceOptions}
            onRenderOption={onRenderDropDownOption}
            selectedKey={asset.governance}
            onChange={onChangeDropDown}
            onRenderTitle={onRenderTitle}
          />
          {asset.type !== "Domain" && (
            <SelectParentDataAsset
              assetType={asset.type}
              selectedParentId={asset.parent_asset_id}
              setSelectParentId={(id: string | undefined) => {
                setAsset({ ...asset, parent_asset_id: id });
              }}
            />
          )}
          {asset.type !== "Product" && (
            <SelectChildrenDataAssets
              assetType={asset.type}
              selectedChildrenIds={asset.child_asset_ids || []}
              setSelectedChildrenIds={(ids: string[]) => {
                setAsset({ ...asset, child_asset_ids: ids });
              }}
            />
          )}
          <SelectLinkedUseCases
            linkedIds={asset.linked_use_case_ids || []}
            setLinkedIds={(ids: string[]) => {
              setAsset({ ...asset, linked_use_case_ids: ids });
            }}
            styles={field}
          />

          <Stack horizontal reversed={true} tokens={sectionStackTokens}>
            <DefaultButton text={t("cancel")} onClick={handleClose} styles={cancelButtonStyle} />
            <PrimaryButton
              text={t("add")}
              onClick={createAsset}
              styles={addButtonStyle}
              disabled={!asset.title}
            />
          </Stack>
        </div>
      </Modal>
    </>
  );
};
