import { Modal, Stack, Text, Icon } from "@fluentui/react";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import Editor from "@monaco-editor/react";
import { useAppSelector } from "../../store/hooks";
import { selectAllRulesByFileName } from "../../store/slices/rules";
import { RuleCandidate } from "../../types/rules";
import { useState } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
export const DBTTestModal = ({
  isModalOpen,
  setModalOpen,
}: {
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
}) => {
  const [selectedRuleIndex, setSelectedRuleIndex] = useState(0);
  const rules = useAppSelector(selectAllRulesByFileName);
  const ruleTransformed = transformRuleToDBT(rules[selectedRuleIndex]);
  return (
    <Modal isOpen={isModalOpen} onDismiss={() => setModalOpen(false)} isBlocking={false}>
      <Stack
        verticalFill
        verticalAlign="space-between"
        styles={{ root: { minHeight: 400, minWidth: 600, margin: 8 } }}
        tokens={{ childrenGap: 20 }}
      >
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
          styles={{ root: { margin: 4 } }}
        >
          <Icon
            iconName="ChevronLeft"
            styles={{
              root: {
                fontSize: "10px",
                cursor: "pointer",
                userSelect: "none",
                marginLeft: 4,
                marginRight: 4,
              },
            }}
            onClick={() =>
              setSelectedRuleIndex((selectedRuleIndex - 1 + rules.length) % rules.length)
            }
          />
          <Text>
            Showing rule {selectedRuleIndex + 1} of {rules.length}
          </Text>
          <Icon
            iconName="ChevronRight"
            styles={{
              root: {
                fontSize: "10px",
                cursor: "pointer",
                userSelect: "none",
                marginLeft: 4,
                marginRight: 4,
              },
            }}
            onClick={() => setSelectedRuleIndex((selectedRuleIndex + 1) % rules.length)}
          />
        </Stack>
        <Editor height="60vh" language="sql" value={ruleTransformed} />
        <Stack horizontal horizontalAlign="end" styles={{ root: { margin: 8 } }}>
          <PrimaryButton
            onClick={() => {
              const zip = new JSZip();
              rules.forEach((rule, index) =>
                zip.file(`Rule-${index + 1}.sql`, transformRuleToDBT(rule))
              );
              zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(content, "rules.zip");
              });
            }}
          >
            Download all
          </PrimaryButton>
        </Stack>
      </Stack>
    </Modal>
  );
};

const transformRuleToDBT = (rule: RuleCandidate): string => {
  if (rule.qualityTest.testFunctionName === "missingValues") {
    const ruleTemplate = `-- tests/missing_values_test.sql

  version: 2
  
  {{ config(materialized='view') }}
  
  -- Replace 'my_schema' with the schema containing the table 'my_table'
  -- Replace 'my_table' with the table you want to test
  -- Replace 'my_column' with the column you want to check for missing values
  
  SELECT
      COUNT(*) AS num_missing_values
  FROM
      {{ ref('my_schema.my_table') }}
  WHERE
      {{ "${rule.columns[0].name}" }} IS NULL;`;
    return ruleTemplate;
  } else {
    const ruleTemplate = `-- tests/duplicate_rows_test.sql

    version: 2
    
    {{ config(materialized='view') }}
    
    -- Replace 'my_schema' with the schema containing the table 'my_table'
    -- Replace 'my_table' with the table you want to test
    
    -- First, count the total number of rows in the table
    WITH total_rows AS (
        SELECT COUNT(*) AS total_count
        FROM {{ ref('my_schema.my_table') }}
    ),
    
    -- Then, count the number of unique rows in the table
    unique_rows AS (
        SELECT COUNT(*) AS unique_count
        FROM {{ ref('my_schema.my_table') }}
    )
    
    -- Compare the counts to check for duplicate rows
    SELECT
        CASE
            WHEN total_count > unique_count THEN 1
            ELSE 0
        END AS duplicate_rows_found
    FROM total_rows, unique_rows;
    `;
    return ruleTemplate;
  }
};
