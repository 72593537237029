import { Icon, IIconStyles, ILabelStyles, IStackItemStyles, Label, Stack } from "@fluentui/react";
import { topbar } from "../../helpers/commonStyles";
import { FunctionComponent } from "react";
import { HelpBtn } from "./HelpBtn";
import { dqcPalette } from "../../utils/colors";

export interface IHeaderProps {
  title?: string;
  backButton?: boolean;
}

const iconClass: IStackItemStyles = {
  root: {
    marginRight: "15px",
  },
};
const iconStyle: IIconStyles = {
  root: {
    padding: "5px 0px 0px 30px",
    cursor: "pointer",
  },
};
const h2TopbarStyle: ILabelStyles = {
  root: {
    display: "inline",
    fontSize: "20px",
    paddingLeft: "15px",
    lineHeight: "40px",
    fontWeight: 400,
    color: dqcPalette.white,
  },
};

export const Header: FunctionComponent<IHeaderProps> = (props) => {
  const { title } = props;
  return (
    <Stack horizontal styles={topbar}>
      <Stack.Item grow={2}>
        {props.backButton ? (
          <Icon iconName="SkypeArrow" styles={iconStyle} onClick={() => window.history.back()} />
        ) : null}
        <Label styles={h2TopbarStyle}>{title}</Label>
      </Stack.Item>
      <Stack.Item styles={iconClass}>
        <HelpBtn />
      </Stack.Item>
    </Stack>
  );
};
