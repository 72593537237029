import {
  DefaultPalette,
  IDropdownStyles,
  IIconStyles,
  ILabelStyles,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  ITooltipHostStyles,
  mergeStyleSets,
  createTheme,
  IPivotProps,
} from "@fluentui/react";
import { CSSProperties } from "react";
import { QualityScore, QualityScoreKeys } from "../types/quality";
import { dqcPalette } from "../utils/colors";

export const dqcTheme = createTheme({
  palette: {
    themePrimary: "#060F31",
    themeLighterAlt: "#ced3e6",
    themeLighter: "#a5afcf",
    themeLight: "#818db9",
    themeTertiary: "#6270a2",
    themeSecondary: "#47568b",
    themeDarkAlt: "#303f75",
    themeDark: "#1d2b5e",
    themeDarker: "#0f1c47",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#F4F3F4",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralSecondaryAlt: "#8a8886",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
    accent: "#25FC95",
  },
});

export const topbar: IStackStyles = {
  root: {
    background: dqcPalette.dqcBlue,
    width: "100%",
    display: "flex",
    alignItems: "center",
    minHeight: "50px",
    borderBottom: "1px solid #C3C3C3",
  },
};

export const descDetailStyle: React.CSSProperties = {
  fontSize: 14,
  color: "#5E5E5E",
};

export const missionCommentHeadStyle: React.CSSProperties = {
  fontSize: 16,
  color: "#000000",
  fontWeight: 400,
};

export const h2TopbarStyle: Partial<ILabelStyles> = {
  root: {
    display: "inline",
    fontSize: "20px",
    paddingLeft: "30px",
    lineHeight: "40px",
    fontWeight: 700,
  },
};

export const growStackBody: Partial<IStackStyles> = {
  root: { overflow: "hidden" },
};

export const mainBodyStack: Partial<IStackStyles> = {
  root: {
    padding: "0px 30px 15px 30px",
    overflow: "auto",
  },
};
export const stackStyle: Partial<IStackStyles> = {
  root: {
    marginTop: "1%",
    marginBottom: "4%",
  },
};

export const topHeading: Partial<ILabelStyles> = {
  root: {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "24px",
    color: "#000000",
    marginBottom: "2%",
  },
};

export const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block", marginRight: "2%" },
};

export const colorForQualityValue = new Map<
  (typeof QualityScore)[QualityScoreKeys] | number,
  string
>([
  [QualityScore.A, "#00A350"],
  [QualityScore.B, "#8BC43D"],
  [QualityScore.C, "#FFB500"],
  [QualityScore.D, "#FF6815"],
  [QualityScore.E, "#FB281A"],
]);

export const SpinnerStyles: IStackStyles = {
  root: {
    marginTop: "22%",
  },
};

export const backgroundStyles: IStackItemStyles = {
  root: {
    background: DefaultPalette.white,
    display: "flex",
    justifyContent: "center",
    marginTop: "3%",
  },
};

export const modalHeader: Partial<ILabelStyles> = {
  root: {
    fontWeight: 600,
    fontSize: "20px",
    color: "#000000",
    lineHeight: "24px",
  },
};

export const datasetQualityHeader: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2%",
  },
};

export const modalSize = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "50%",
  },
});

export const subTopicLabel: ILabelStyles = {
  root: {
    fontSize: "16px",
    fontWeight: 500,
  },
};

export const iconStyle: Partial<IIconStyles> = {
  root: {
    fontSize: 20,
    cursor: "pointer",
    marginRight: 20,
  },
};

export const divider: React.CSSProperties = {
  color: "#000000",
  backgroundColor: "#000000",
  height: 3,
};

export const buttonContainer: React.CSSProperties = {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "4%",
};

export const buttonSubContainer: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
};

export const labelIconContainer: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const innerStackTokens: IStackTokens = {
  childrenGap: 45,
};

export const centerDiv: React.CSSProperties = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const interactionLabels: ILabelStyles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
    fontSize: "20px",
  },
};

export const interactionContainer: React.CSSProperties = {
  marginTop: "15%",
};

export const flexStackStyles: Partial<IStackStyles> = {
  root: {
    flexWrap: "wrap",
    gap: "30px 45px",
  },
};

export const mainContainerStyle: CSSProperties = {
  width: "532px",
  height: "275px",
  border: "1px solid #cccccc",
  padding: "15px",
};

export const headerStyle: CSSProperties = {
  margin: "0",
};

export const subContainerStyle: React.CSSProperties = {
  width: "400px",
  height: "275px",
  border: "1px solid #cccccc",
  padding: "15px",
};

export const dividerLine: React.CSSProperties = {
  border: "0.1px solid rgba(0, 0, 0, 0.3)",
};

export const modalArea = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "50%",
    maxHeight: "90%",
    border: "1px solid #000000",
    borderRadius: "2px",
    padding: "25px",
    overflow: "hidden",
  },
});

export const footerStyle: Partial<IStackStyles> = {
  root: {
    padding: 5,
    color: "grey",
    textAlign: "center",
  },
};
export const comboBoxStyles: Partial<IDropdownStyles> = {
  root: {
    cursor: "pointer",
    width: 200,
    borderRadius: "4px",
    marginBottom: 20,
  },
};
export const modalHeading: React.CSSProperties = {
  paddingLeft: 20,
};
export const itemStyleMain: React.CSSProperties = {
  width: "65%",
};
export const itemStyleDetail: React.CSSProperties = {
  width: "30%",
};
export const numericalSpacingStackTokens: IStackTokens = {
  childrenGap: 30,
};
export const linkItemsContainer: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
};
export const detailsContainer: IStackStyles = {
  root: {
    border: "1px solid #CCC",
    padding: 10,
    marginTop: 10,
  },
};

export const containerBorder: IStackStyles = {
  root: {
    width: "100%",
    marginTop: 10,
    border: "1px solid #C9C9C9",
    marginBottom: 10,
  },
};
export const thinBorder: IStackStyles = {
  root: {
    border: "1px solid #C9C9C9",
  },
};
export const loginStackStyle: IStackStyles = {
  root: {
    paddingTop: "10px",
    width: "360px",
    "@media(max-width: 550px)": {
      width: "320px",
    },
    "@media(max-width: 350px)": {
      width: "260px",
    },
    margin: "auto",
  },
};
export const loginStackItemStyles: IStackItemStyles = {
  root: {
    padding: "10px",
  },
};
export const commandBarDefault: ILabelStyles = {
  root: {
    padding: 8,
  },
};
export const RESOLVED_REMARK_BG_COLOR = "rgba(36,108,29,0.1)";

export const profileLabel: ILabelStyles = {
  root: {
    fontSize: 14,
    minWidth: 35,
  },
};

export const pivotSelectedProps: IPivotProps = {
  "aria-label": "navigation tabs",
  linkSize: "large",
  linkFormat: "tabs",
  headersOnly: true,
  styles: {
    link: {
      fontSize: "14px",
      color: dqcTheme.palette.white,
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      backgroundColor: dqcTheme.palette.themePrimary,
      paddingRight: "10px",
      fontWeight: 600,
      width: "120px",
      height: 40,
    },
    root: {
      justifyContent: "space-evenly",
      background: dqcTheme.palette.themePrimary,
      paddingLeft: "12px",
      paddingTop: "4px",
      fontSize: "14px",
      fontWeight: 600,
      display: "flex",
      marginBottom: 1,
    },
  },
};
