import {
  ILabelStyles,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Stack,
} from "@fluentui/react";
import {
  QualityDimensionAggregate,
  QualityDimension,
  QualityDimensionKeys,
  QualitySummary,
} from "../../../types/quality";

import { Dataset } from "../../../types/dataset";
import { FunctionComponent } from "react";
import { MissionData } from "../../../types/missions";
import { MissionQuality } from "../../common/MissionQualityBadge";
import { QualityBar } from "./QualityBar";
import { useTranslation } from "react-i18next";

const stackMainContainer: IStackStyles = {
  root: {
    justifyContent: "center",
    alignItems: "center",
    height: "49px",
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 4,
  padding: 8,
};

const stackItemFirst: IStackItemStyles = {
  root: {
    alignItems: "center",
    display: "flex",
    maxWidth: "40%",
  },
};

const stackItemSecond: IStackItemStyles = {
  root: {
    alignItems: "center",
    display: "flex",
    maxWidth: "12%",
  },
};

const stackItemFourth: IStackItemStyles = {
  root: {
    height: 33,
    maxWidth: "50%",
    display: "flex",
    flexDirection: "row",
  },
};

const dimensionValueStyle: ILabelStyles = {
  root: {
    fontWeight: 700,
    fontSize: "16px",
    paddingLeft: "2%",
  },
};

export interface QualityListState {
  quality?: QualitySummary | undefined;
  mission?: MissionData | undefined;
}

export interface QualityItemProps {
  dimensionValue: typeof QualityDimension[QualityDimensionKeys];
  index: number;
  scores: Map<typeof QualityDimension[QualityDimensionKeys], QualityDimensionAggregate>;
  missionId: string;
  datasets: Dataset[];
  status: "done" | "loading_data" | "analyzing_data" | "writing_results" | "failed" | undefined;
}
export const QualityItem: FunctionComponent<QualityItemProps> = ({
  dimensionValue,
  index,
  scores,
  missionId,
  datasets,
  status,
}) => {
  const { t } = useTranslation();
  return (
    <div key={`qualitykey_${index}`}>
      {dimensionValue !== QualityDimension.UNDEFINED &&
      dimensionValue !== QualityDimension.NEW_RECORD ? (
        <Stack
          horizontal
          tokens={stackTokens}
          styles={stackMainContainer}
          key={`qualitykey_${index}`}
        >
          <Stack.Item grow={3} styles={stackItemFirst}>
            <div>
              <MissionQuality
                value={scores.get(dimensionValue) ? scores?.get(dimensionValue)?.quality_score : 1}
                status={status}
              />
            </div>
            <Label styles={dimensionValueStyle}>{t(dimensionValue)}</Label>
          </Stack.Item>
          <Stack.Item grow={1} styles={stackItemSecond}>
            <Label>
              {scores && scores.get(dimensionValue)?.count
                ? `${scores.get(dimensionValue)?.count} ${t("issues_text")}`
                : `0 ${t("issues_text")}`}
            </Label>
          </Stack.Item>
          <Stack.Item grow={4} styles={stackItemFourth}>
            {scores.get(dimensionValue) ? (
              <QualityBar
                datasetDetails={scores.get(dimensionValue)?.dataset_details}
                qualityDimension={dimensionValue}
                missionId={missionId}
                datasets={datasets}
              />
            ) : (
              <Label
                className="bar-item font-smaller pointer tooltip"
                style={{ backgroundColor: "lightgrey" }}
              />
            )}
          </Stack.Item>
        </Stack>
      ) : null}
    </div>
  );
};
