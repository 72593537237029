import { FunctionComponent, useState } from "react";
import { CommandBarButton, Stack } from "@fluentui/react";
import { t } from "i18next";
import { commandBarDefault, missionCommentHeadStyle } from "../../helpers/commonStyles";
import { CommentBase, CommentData, SubjectType } from "../../types/comments";
import { CommentTile } from "./CommentTile";
import { CommentReply } from "./CommentReply";
import {
  useAppendCommentMutation,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
} from "../../api/comments";

interface CommentWrapperState {
  comments: CommentData[];
  subjectType: SubjectType;
  subjectId: string;
  commentThreadId?: string;
}

export const CommentWrapper: FunctionComponent<CommentWrapperState> = ({
  comments,
  subjectId,
  subjectType,
  commentThreadId,
}) => {
  const [showEditor, setShowEditor] = useState(comments.length === 0);
  const [editCommentId, setEditCommentId] = useState("");
  const [deleteComment] = useDeleteCommentMutation();
  const [updateComment] = useUpdateCommentMutation();
  const [appendComment] = useAppendCommentMutation();
  const [createComment] = useCreateCommentMutation();
  const [visibleCommentCount, setVisibleCommentCount] = useState(10);
  const commentToDelete = (commentId: string) => {
    commentThreadId &&
      deleteComment({
        commentThreadId: commentThreadId,
        subjectId: subjectId,
        subjectType: subjectType,
        commentId: commentId,
      });
  };
  const commentToUpdate = (comment: CommentData) => {
    commentThreadId &&
      updateComment({
        comment: comment,
        commentThreadId: commentThreadId,
        subjectId: subjectId,
        subjectType: subjectType,
        commentId: comment.id,
      });
  };
  const commentToAppend = (comment: CommentBase) => {
    commentThreadId &&
      appendComment({
        comment: comment,
        commentThreadId: commentThreadId,
        subjectId: subjectId,
        subjectType: subjectType,
      });
  };
  const commentToCreate = (comment: CommentBase) => {
    createComment({
      comment: comment,
      subjectId: subjectId,
      subjectType: subjectType,
    });
  };
  const visibleComments = comments.slice(0, visibleCommentCount);
  const showMore = visibleCommentCount < comments.length;
  return (
    <Stack tokens={{ childrenGap: 4 }}>
      {subjectType === "mission" && (
        <label style={{ ...missionCommentHeadStyle, marginBottom: 15 }}>
          {t("comments_heading")}
        </label>
      )}
      {comments.length > 0 ? (
        <Stack tokens={{ childrenGap: 4 }}>
          {visibleComments.map((comment: CommentData, index: number) => (
            <Stack key={index}>
              {editCommentId !== comment.id ? (
                <CommentTile
                  comment={comment}
                  setEditCommentId={setEditCommentId}
                  commentToDelete={commentToDelete}
                  commentToUpdate={commentToUpdate}
                />
              ) : (
                <CommentReply
                  showEditor={true}
                  setShowEditor={setShowEditor}
                  commentThreadId={commentThreadId}
                  commentData={comment}
                  setEditCommentId={setEditCommentId}
                  commentToUpdate={commentToUpdate}
                  commentToAppend={commentToAppend}
                  commentToCreate={commentToCreate}
                  subjectType={subjectType}
                />
              )}
            </Stack>
          ))}
        </Stack>
      ) : null}
      {showMore && (
        <Stack>
          <CommandBarButton
            styles={{ ...commandBarDefault }}
            text={t("show_more")}
            onClick={() => setVisibleCommentCount(visibleCommentCount + 10)}
          />
        </Stack>
      )}
      {showEditor ? (
        <CommentReply
          showEditor={showEditor}
          setShowEditor={setShowEditor}
          commentThreadId={commentThreadId}
          commentToUpdate={commentToUpdate}
          commentToAppend={commentToAppend}
          commentToCreate={commentToCreate}
          subjectType={subjectType}
        />
      ) : (
        <Stack horizontal horizontalAlign="end">
          <CommandBarButton
            iconProps={{ iconName: "Reply" }}
            styles={commandBarDefault}
            text={t("comment_button_label")}
            onClick={() => setShowEditor(true)}
          />
        </Stack>
      )}
    </Stack>
  );
};
