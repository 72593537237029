import { CommandBar, IContextualMenuItem } from "@fluentui/react";
import { t } from "i18next";
import { FunctionComponent } from "react";
import { dqcTheme } from "../../helpers/commonStyles";
import { useAppDispatch } from "../../store/hooks";
import { setEditRemarkId } from "../../store/slices/remarkSlice";
import { Remark, RemarksSortKey, RemarksSortKeyStandard } from "../../types/colab";

export type SidebarCommandBarProps = {
  disabled: boolean;
  filterStatus?: Remark["status"];
  setFilterStatus: (status: Remark["status"] | undefined) => void;
  sortKey?: RemarksSortKey;
  setSortKey: (sortKey: RemarksSortKey) => void;
  toggleShowAddRemark: () => void;
};

export const SidebarCommandBar: FunctionComponent<SidebarCommandBarProps> = ({
  disabled,
  filterStatus,
  setFilterStatus,
  sortKey,
  setSortKey,
  toggleShowAddRemark,
}) => {
  const dispatch = useAppDispatch();

  const remarkSortKeys: RemarksSortKeyStandard[] = ["date", "creator", "upvotes"];
  return (
    <CommandBar
      items={[
        {
          key: "status",
          text: t("filter"),
          disabled,
          iconProps: { iconName: "Filter" },
          subMenuProps: {
            items: [
              {
                key: "all",
                text: t("all"),
                canCheck: true,
                checked: filterStatus === undefined,
                preferMenuTargetAsEventTarget: true,
                onClick: setFilterOnClickFactory(undefined, setFilterStatus),
              },
              {
                key: "open",
                text: t("open"),
                canCheck: true,
                checked: filterStatus === "OPEN",
                preferMenuTargetAsEventTarget: true,
                onClick: setFilterOnClickFactory("OPEN", setFilterStatus),
              },
              {
                key: "resolved",
                text: t("resolved"),
                canCheck: true,
                checked: filterStatus === "SOLVED",
                preferMenuTargetAsEventTarget: true,
                onClick: setFilterOnClickFactory("SOLVED", setFilterStatus),
              },
            ],
          },
        },
        {
          key: "sort",
          text: t(sortKey || "sort"),
          disabled,
          iconProps: { iconName: "Sort" },
          subMenuProps: {
            items: remarkSortKeys.map((remarkSortKey) =>
              getContextualSortMenuOptions(remarkSortKey, sortKey, setSortKey)
            ),
          },
        },
      ]}
      farItems={[
        {
          key: "newItem",
          text: "Add",
          iconProps: { iconName: "Add" },
          buttonStyles: {
            icon: { color: dqcTheme.palette.themePrimary },
            root: {
              backgroundColor: dqcTheme.palette.accent,
              color: dqcTheme.palette.themePrimary,
            },
            label: {
              fontWeight: 700,
            },
          },
          onClick: () => {
            dispatch(setEditRemarkId(undefined));
            toggleShowAddRemark();
          },
        },
      ]}
      ariaLabel="New Remark"
      primaryGroupAriaLabel="New"
      farItemsGroupAriaLabel="New"
      styles={{ root: { padding: 0 } }}
    />
  );
};

const setFilterOnClickFactory = (
  status: Remark["status"] | undefined,
  setFilterStatus: (status: Remark["status"] | undefined) => void
) => {
  return (
    ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined
  ) => {
    ev?.persist();
    setFilterStatus(status);
  };
};

const setSortKeyOnClickFactory = (
  sortKey: RemarksSortKeyStandard,
  currentSortKey: RemarksSortKey | undefined,
  setSortKey: (sortKey: RemarksSortKey) => void
) => {
  const newSortKey: RemarksSortKey = `${sortKey}${sortKey === currentSortKey ? "_reversed" : ""}`;
  return (
    ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined
  ) => {
    ev?.persist();
    setSortKey(newSortKey);
  };
};

const getContextualSortMenuOptions = (
  sortKey: RemarksSortKeyStandard,
  currentSortKey: RemarksSortKey | undefined,
  setSortKey: (sortKey: RemarksSortKey) => void
): IContextualMenuItem => {
  return {
    key: sortKey,
    text: t(sortKey),
    preferMenuTargetAsEventTarget: true,
    onClick: setSortKeyOnClickFactory(sortKey, currentSortKey, setSortKey),
  };
};
