import {
  DirectionalHint,
  FontIcon,
  ITooltipProps,
  Spinner,
  SpinnerSize,
  Stack,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import { colorForQualityValue, hostStyles } from "../../helpers/commonStyles";

import i18n from "../../i18n";
import { QualityScore, QualityScoreKeys } from "../../types/quality";

const qualityTooltipProps: ITooltipProps = {
  onRenderContent: () => (
    <ul style={{ margin: 10, padding: 0 }}>
      <li>A - {i18n.t("quality_a")}</li>
      <li>B - {i18n.t("quality_b")}</li>
      <li>C - {i18n.t("quality_c")}</li>
      <li>D - {i18n.t("quality_d")}</li>
      <li>E - {i18n.t("quality_e")}</li>
    </ul>
  ),
};

const qualityFailedTooltipProps: ITooltipProps = {
  onRenderContent: () => {
    return (
      <Stack>
        <h3>{i18n.t("analysis_failed_heading")}</h3>
        <p>{i18n.t("analysis_failed_body")}</p>
        <p>{i18n.t("analysis_failed_hint")}</p>
      </Stack>
    );
  },
};

export interface QualityProps {
  value?: typeof QualityScore[QualityScoreKeys] | number;
  status?: "done" | "loading_data" | "analyzing_data" | "writing_results" | "failed";
}
export const MissionQuality: FunctionComponent<QualityProps> = (qualityProps: QualityProps) => {
  let qualityScoreMap = ["A", "B", "C", "D", "E"];
  let [value, setValue] = useState(qualityProps.value);
  useEffect(() => {
    setValue(qualityProps.value);
  }, [qualityProps.value]);

  if (qualityProps.status === "failed") {
    return (
      <TooltipHost
        tooltipProps={qualityFailedTooltipProps}
        delay={TooltipDelay.zero}
        directionalHint={DirectionalHint.bottomCenter}
        styles={hostStyles}
      >
        <FontIcon
          iconName="Error"
          aria-label="Analysis failed"
          style={{ fontSize: 30, color: "tomato" }}
        />
      </TooltipHost>
    );
  }
  if (qualityProps.status === undefined || !qualityProps.value) {
    return null;
  }
  if (qualityProps.status !== "done") {
    return <Spinner size={SpinnerSize.large} />;
  }

  return (
    <TooltipHost
      tooltipProps={qualityTooltipProps}
      delay={TooltipDelay.zero}
      directionalHint={DirectionalHint.bottomCenter}
      styles={hostStyles}
    >
      <Persona
        text={qualityScoreMap[value ? value - 1 : 0]}
        hidePersonaDetails={true}
        size={PersonaSize.size32}
        initialsColor={colorForQualityValue.get(value || 5)}
      />
    </TooltipHost>
  );
};
