import { ActionButton } from "@fluentui/react";
import React from "react";
import { ConfigurationMapValue } from "../../../types/missions";
import { useTranslation } from "react-i18next";
const actionButtonStyle: React.CSSProperties = {
  color: "blue",
  left: "-10px",
};

export interface SelectDeselectButtonProps {
  columnName: string;
  selectAllColumns: (columnName: string) => void;
  configuration: Record<string, ConfigurationMapValue>;
}

export const SelectDeselectButton: React.FunctionComponent<SelectDeselectButtonProps> = ({
  columnName,
  selectAllColumns,
  configuration,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <ActionButton onClick={() => selectAllColumns(columnName)} style={actionButtonStyle}>
        {configuration[columnName].toggle
          ? t("edit_column_config_deselect_all")
          : t("edit_column_config_select_all")}
      </ActionButton>
    </>
  );
};
