import { Stack } from "@fluentui/react";
import { selectLatestUpload } from "../../store/slices/upload";
import { DataGrid } from "./datagrid/DataGrid";
import { AnalysisSidebar } from "./analysis-sidebar/AnalysisSidebar";
import { useAppSelector } from "../../store/hooks";

export const AnalysisResult = () => {
  const uploadedFile = useAppSelector(selectLatestUpload);
  if (!uploadedFile) return null;
  return (
    <Stack horizontal styles={{ root: { margin: 20 } }}>
      <DataGrid styles={{ height: "calc(100vh - 200px)", width: "100vw" }} />
      <AnalysisSidebar />
    </Stack>
  );
};
