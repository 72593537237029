import { Stack } from "@fluentui/react";
import { useAppSelector } from "../../store/hooks";
import { selectLatestQuickAnalysisStatus } from "../../store/slices/quickAnalysis";
import { Loader } from "../../components/quick-analysis/Loader";
import { FileDragAndDrop } from "../../components/quick-analysis/FileDragAndDrop";

export const QuickAnalysisDragAndDrop = () => {
  const fileUploadStatus = useAppSelector(selectLatestQuickAnalysisStatus);
  return (
    <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { margin: 20 } }}>
      {fileUploadStatus === "loadingFile" ? <Loader /> : <FileDragAndDrop />}
    </Stack>
  );
};
