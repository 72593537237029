import { useState } from "react";
import { IQROutlierTest, RuleCandidate, StdDevOutlierTest } from "../../../../types/rules";
import { ChoiceGroup, IChoiceGroupOption, Link, Slider, Stack, Text } from "@fluentui/react";
import { t } from "i18next";
import { NameAndDescriptionInput } from "../NameDescription";
import { MODAL_DETAIL_LEFT_MARGIN } from "./bodyStyles";
import { RuleDetailModalFooter } from "../RuleDetailModalFooter";
import {
  iqrCutoffOutlierTest,
  stdDevOutlierTest,
} from "../../../../helpers/predictRules/predictors/predictOutlierRules";
import { useAppSelector } from "../../../../store/hooks";
import { selectLatestUpload } from "../../../../store/slices/upload";
import { choiceGroupStyle } from "./OutlierRuleDetailBody.styles";

export const OutlierRuleDetailBody = ({
  rule,
  toggleModal,
}: {
  rule: RuleCandidate;
  toggleModal: () => void;
}) => {
  const [localRule, setLocalRule] = useState<RuleCandidate>(rule);
  const uploadedFile = useAppSelector(selectLatestUpload);

  if (
    !uploadedFile ||
    (localRule.qualityTest.testFunctionName !== "iqrOutlier" &&
      localRule.qualityTest.testFunctionName !== "stdDevOutlier")
  )
    return null;

  const localQualityTest = localRule.qualityTest;
  const isStandardDeviation = localQualityTest.testFunctionName === "stdDevOutlier";

  const handleOutlierChoice = (selectedKey: "iqrOutlier" | "stdDevOutlier") => {
    if (selectedKey === "iqrOutlier") {
      const localIqrRule: RuleCandidate = {
        ...rule,
        name: "Std. deviation outlier",
        description: "Detects outlier in normally distributed data",
        qualityTest: stdDevOutlierTest,
      };
      setLocalRule(localIqrRule);
    } else if (selectedKey === "stdDevOutlier") {
      const localStdDevRule = {
        ...rule,
        name: "Inter quartile outlier",
        description: "Detects outlier /w IQR method",
        qualityTest: iqrCutoffOutlierTest,
      };
      setLocalRule(localStdDevRule);
    }
  };

  const handleOutlierSlider = (value: number) => {
    if (localQualityTest.testFunctionName === "iqrOutlier") {
      const updatedMeta = {
        iqrCutoff: value,
      };
      const localIqrRule: RuleCandidate = {
        ...localRule,
        qualityTest: {
          testFunctionName: "iqrOutlier",
          meta: updatedMeta,
        },
      };
      setLocalRule(localIqrRule);
    } else if (localQualityTest.testFunctionName === "stdDevOutlier") {
      const updatedMeta = {
        stdDevCutoff: value,
      };
      const localStdDevRule: RuleCandidate = {
        ...localRule,
        qualityTest: {
          testFunctionName: "stdDevOutlier",
          meta: updatedMeta,
        },
      };
      setLocalRule(localStdDevRule);
    }
  };

  const choiceGroupOptions: IChoiceGroupOption[] = [
    { key: "iqrOutlier", text: t("iqr_method") },
    { key: "stdDevOutlier", text: t("std_dev_method") },
  ];
  return (
    <>
      <Stack tokens={{ childrenGap: 12 }}>
        <NameAndDescriptionInput rule={localRule} updateRule={setLocalRule} />
        <Stack horizontal styles={{ root: { display: "flex", alignItems: "center" } }}>
          <Text styles={{ root: { marginRight: 8 } }}>{t("outlier_method")}</Text>
          <ChoiceGroup
            options={choiceGroupOptions}
            onChange={() => handleOutlierChoice(localQualityTest.testFunctionName)}
            defaultSelectedKey={localQualityTest.testFunctionName}
            styles={choiceGroupStyle}
          />
        </Stack>
        <Stack
          styles={{ root: { marginLeft: MODAL_DETAIL_LEFT_MARGIN, marginTop: 4 } }}
          tokens={{ childrenGap: 8 }}
        >
          <Stack horizontal styles={{ root: { display: "flex", alignItems: "center" } }}>
            <Text>
              {isStandardDeviation
                ? t("std_dev_outlier_sensitivity_slider")
                : t("iqr_outlier_sensitivity_slider")}
            </Text>
            <Slider
              styles={{ root: { width: "80%" } }}
              min={1}
              max={5}
              value={getQualityTestCutoffValue(localQualityTest)}
              onChange={handleOutlierSlider}
            />
          </Stack>
          <Link
            styles={{ root: { color: "blue" } }}
            href="https://dqchelp.zendesk.com/hc/en-150/articles/9961456054301-How-outliers-are-calculated"
          >
            {t("how_outliers_calculated")}
          </Link>
        </Stack>
      </Stack>
      <RuleDetailModalFooter rule={localRule} updateRule={setLocalRule} toggleModal={toggleModal} />
    </>
  );
};

const getQualityTestCutoffValue = (qualityTest: StdDevOutlierTest | IQROutlierTest) => {
  if (qualityTest.testFunctionName === "stdDevOutlier") return qualityTest.meta.stdDevCutoff;
  else if (qualityTest.testFunctionName === "iqrOutlier") return qualityTest.meta.iqrCutoff;
};
