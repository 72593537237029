import { CommentBase, CommentData, CommentThread } from "../types/comments";
import { api_base, dqcApi } from "./dqcQuery";

export const commentsApi = dqcApi.injectEndpoints({
  endpoints: (builder) => ({
    createComment: builder.mutation({
      query: (data: { comment: CommentBase; subjectType: string; subjectId: string }) => ({
        url: `/${api_base}/comments/${data.subjectType}/${data.subjectId}`,
        method: "post",
        data: data.comment,
      }),
      invalidatesTags: ["Comments"],
    }),
    getComments: builder.query<CommentThread, any>({
      query: (subject_id: string) => ({
        url: `/${api_base}/comments/${subject_id}`,
        method: "get",
      }),
      extraOptions: { maxRetries: 1 },
      providesTags: ["Comments"],
    }),
    appendComment: builder.mutation({
      query: (data: {
        comment: CommentBase;
        commentThreadId: string;
        subjectType: string;
        subjectId: string;
      }) => ({
        url: `/${api_base}/comments/${data.commentThreadId}/${data.subjectType}/${data.subjectId}`,
        method: "put",
        data: data.comment,
      }),
      invalidatesTags: ["Comments"],
    }),
    updateComment: builder.mutation({
      query: (data: {
        comment: CommentData;
        commentThreadId: string;
        subjectType: string;
        subjectId: string;
        commentId: string;
      }) => ({
        url: `/${api_base}/comments/${data.commentThreadId}/${data.subjectType}/${data.subjectId}/${data.commentId}`,
        method: "put",
        data: data.comment,
      }),
      invalidatesTags: ["Comments"],
    }),
    deleteComment: builder.mutation({
      query: (data: {
        commentThreadId: string;
        subjectType: string;
        subjectId: string;
        commentId: string;
      }) => ({
        url: `/${api_base}/comments/${data.commentThreadId}/${data.subjectType}/${data.subjectId}/${data.commentId}`,
        method: "delete",
      }),
      invalidatesTags: ["Comments"],
    }),
  }),
});

export const {
  useAppendCommentMutation,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useGetCommentsQuery,
  useUpdateCommentMutation,
} = commentsApi;
