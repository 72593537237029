import {
  CheckboxVisibility,
  DetailsHeader,
  DetailsList,
  DirectionalHint,
  IColumn,
  IDetailsHeaderProps,
  IRenderFunction,
  Text,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { hostStyles } from "../../helpers/commonStyles";
import { SurveyDetail, Question } from "../../types/survey";
import { groupFreeTextResults, GroupedTextResults, TextItem, HIDE_IF_LESS_THAN } from "./helpers";

type SurveyFreeTextProps = {
  questions: Question[];
  surveyData: SurveyDetail;
  selectedGroupBy: string;
  columnSorting: Map<string, number>;
};
const textStyle = {
  fontSize: 12,
  fontWeight: 400,
  color: "rgb(96, 94, 92)",
};
export const SurveyFreeText: FunctionComponent<SurveyFreeTextProps> = ({
  questions,
  selectedGroupBy,
}) => {
  const { t } = useTranslation();
  const [groupedResults, setGroupedResults] = useState<GroupedTextResults>(
    groupFreeTextResults(selectedGroupBy, questions)
  );

  useEffect(() => {
    setGroupedResults(groupFreeTextResults(selectedGroupBy, questions));
  }, [selectedGroupBy]);

  const _onRenderDetailsHeader = (
    props: IDetailsHeaderProps | undefined,
    _defaultRender?: IRenderFunction<IDetailsHeaderProps>
  ) => {
    return (
      <DetailsHeader {...props!} ariaLabelForToggleAllGroupsButton={"Expand collapse groups"} />
    );
  };

  const _onRenderColumn = (
    item: TextItem,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (column?.key === "response")
      if (item.totalCount < HIDE_IF_LESS_THAN)
        return <Text style={textStyle}>{`${t("less_than")} 3 ${t("responses")}`}</Text>;
      else
        return (
          <TooltipHost
            tooltipProps={{
              onRenderContent: () => {
                return <Text style={textStyle}>{item.response}</Text>;
              },
            }}
            delay={TooltipDelay.zero}
            directionalHint={DirectionalHint.bottomLeftEdge}
            styles={hostStyles}
          >
            <Text style={textStyle}>{item.response}</Text>
          </TooltipHost>
        );
    if (column?.key === "group") return <Text style={textStyle}>{item.groupName}</Text>;
  };

  return (
    <DetailsList
      checkboxVisibility={CheckboxVisibility.hidden}
      items={groupedResults.items}
      groups={groupedResults.groups}
      columns={groupedResults.columns}
      ariaLabelForSelectAllCheckbox="Toggle selection for all items"
      ariaLabelForSelectionColumn="Toggle selection"
      checkButtonAriaLabel="select row"
      checkButtonGroupAriaLabel="select section"
      onRenderDetailsHeader={_onRenderDetailsHeader}
      groupProps={{
        isAllGroupsCollapsed: true,
        showEmptyGroups: true,
      }}
      onRenderItemColumn={_onRenderColumn}
      compact={true}
    />
  );
};
