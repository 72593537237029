import { CompactPeoplePicker, IBasePickerSuggestionsProps, IPersonaProps } from "@fluentui/react";
import { FunctionComponent, Key, useRef, useState } from "react";
import { fetchUserProfiles } from "../../api/userProfile";
import { ProfileResult } from "../../types/user-profile";
import { useAppSelector } from "../../store/hooks";
import { selectMsGraphApiAccessToken } from "../../store/slices/remarkSlice";
import { t } from "i18next";
import { fetchUserProfileImage } from "../../api/users";
import { SubjectType } from "../../types/comments";

export interface IDQCPersonaProps {
  imageUrl?: string;
  text?: string;
  secondaryText?: string;
  key?: Key | null | string;
  email?: string;
}

const LIMIT_SEARCH_RESULTS = 10;

interface PeoplePickerMSGraphProps {
  subjectType: SubjectType;
  defaultProposedItems?: IPersonaProps[] | undefined;
  defaultSelectedItems?: IPersonaProps[] | undefined;
  onChange: (items: IPersonaProps[]) => void;
}

const removeDuplicates = (personas: IPersonaProps[], possibleDupes: IPersonaProps[]) => {
  return personas.filter((persona) => !listContainsPersona(persona, possibleDupes));
};

const listContainsPersona = (persona: IPersonaProps, personas: IPersonaProps[]) => {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter((item) => item.text === persona.text).length > 0;
};

const convertResultsToPromise = (results: IPersonaProps[]): Promise<IPersonaProps[]> => {
  return new Promise<IPersonaProps[]>((resolve) => resolve(results));
};

const getTextFromItem = (persona: IPersonaProps): string => {
  return persona.text as string;
};

const transformToDQCPersonas = (profiles: ProfileResult[]): IDQCPersonaProps[] =>
  profiles.map((p) => ({
    text: p.displayName,
    key: p.id,
    email: p.email || p.mail,
  }));

export const PeoplePickerMSGraph: FunctionComponent<PeoplePickerMSGraphProps> = ({
  subjectType,
  defaultProposedItems = [],
  defaultSelectedItems,
  onChange,
}) => {
  const [isPickerDisabled] = useState(false);
  const [mostRecentlyUsed, setMostRecentlyUsed] = useState<IPersonaProps[]>(defaultProposedItems);
  const accessToken = useAppSelector(selectMsGraphApiAccessToken);
  const picker = useRef(null);
  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: t("suggested_people"),
    mostRecentlyUsedHeaderText: t("mru_people"),
    noResultsFoundText: t("no_results"),
    loadingText: t("loading"),
    showRemoveButtons: false,
    suggestionsAvailableAlertText: t("suggestions_people_available"),
    suggestionsContainerAriaLabel: t("suggestions_people_label"),
  };

  const onFilterChanged = async (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined
  ): Promise<IPersonaProps[]> => {
    if (filterText) {
      // fetch profiles from MS Graph
      const profiles: ProfileResult[] = await fetchUserProfiles(
        subjectType,
        filterText,
        accessToken
      );
      let filteredPersonas: IDQCPersonaProps[] = transformToDQCPersonas(profiles);
      // load profile images
      let profileImages: Promise<{ data: string } | { error: any }>[] = [];
      profiles.forEach((p) => {
        profileImages.push(fetchUserProfileImage(p.id, accessToken));
      });
      const allImages = await Promise.all(profileImages);
      filteredPersonas = filteredPersonas.map((p, index) => {
        // @ts-ignore
        const { data: imageUrl } = allImages[index];
        p.imageUrl = imageUrl;
        return p;
      });
      // add "all[x]" always
      filteredPersonas.unshift(...defaultProposedItems!);
      // remove duplicates and limit results
      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas!);
      filteredPersonas = filteredPersonas.slice(0, LIMIT_SEARCH_RESULTS);
      setMostRecentlyUsed(filteredPersonas.slice(0, 5));
      return convertResultsToPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  const returnMostRecentlyUsed = (
    currentPersonas: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return convertResultsToPromise(removeDuplicates(mostRecentlyUsed, currentPersonas));
  };

  return (
    <CompactPeoplePicker
      onResolveSuggestions={onFilterChanged}
      getTextFromItem={getTextFromItem}
      // @ts-ignore
      onEmptyInputFocus={returnMostRecentlyUsed}
      pickerSuggestionsProps={suggestionProps}
      selectionAriaLabel={"Selected contacts"}
      removeButtonAriaLabel={"Remove"}
      className={"ms-PeoplePicker"}
      inputProps={{
        "aria-label": t("suggestions_people_label"),
      }}
      componentRef={picker}
      resolveDelay={300}
      disabled={isPickerDisabled}
      onChange={(items) => {
        if (items) {
          onChange(items);
          setMostRecentlyUsed(items);
        }
      }}
      defaultSelectedItems={defaultSelectedItems}
    />
  );
};
