import { uuid4 } from "@sentry/utils";
import { Clause } from "../../../types/customChecks";
import {
  ColumnDefinition,
  UploadDQCDataType,
  UploadDataContainer,
} from "../../../types/fileUploader";
import { CustomCheckIssue, QuickAnalysisIssue } from "../../../types/quickAnalysis";
import { RuleCandidate, RuleCandidateWithoutMeta } from "../../../types/rules";
import { addIssueId } from "../../quickAnalysis/qualityChecks/qualityUtils";
import { numericFunctionMap, textFunctionMap, formatFunctionMap } from "./customRuleFunctions";

export const groupClauses = (clauses: Clause[]): Clause[][] => {
  const groupedClauses: Clause[][] = [];
  let currentClauseGroup: Clause[] = [];
  clauses.forEach((clause) => {
    if (clause.operator === "or") {
      groupedClauses.push(currentClauseGroup);
      currentClauseGroup = [clause];
    } else {
      currentClauseGroup.push(clause);
    }
  });
  groupedClauses.push(currentClauseGroup);
  return groupedClauses;
};

export const getUniqueValues = (data: UploadDQCDataType[][], clauses: Clause[]): boolean[][] => {
  const isUniqueValue: boolean[][] = data.map((row) => row.map((_) => false));
  clauses.forEach((clause) => {
    if (clause.condition !== "unique") return;
    const columnIndex = clause.columnIndex;
    const columnData = data.map((row) => row[columnIndex]);
    const valueSet = new Set();
    columnData.forEach((cell) => {
      isUniqueValue[cell.row][columnIndex] = !valueSet.has(cell.value);
      valueSet.add(cell.value);
    });
  });
  return isUniqueValue;
};

export const getSequentialNumbers = (
  data: UploadDQCDataType[][],
  clauses: Clause[]
): boolean[][] => {
  const isSequentialNumbers: boolean[][] = data.map((row) => row.map((_) => true));
  clauses.forEach((clause) => {
    if (clause.condition !== "sequential numbers") return;
    const columnIndex = clause.columnIndex;
    const columnData = data.map((row) => row[columnIndex]);
    for (let rowIndex = 1; rowIndex < columnData.length; rowIndex++) {
      const previousCell = columnData[rowIndex - 1];
      const cell = columnData[rowIndex];
      isSequentialNumbers[rowIndex][columnIndex] =
        Number(cell.value) - Number(previousCell.value) === 1;
    }
  });
  return isSequentialNumbers;
};

export const customRule = (
  dataContainer: UploadDataContainer,
  rule: RuleCandidate
): QuickAnalysisIssue[] => {
  if (rule.qualityTest.testFunctionName !== "customRule") return [];
  // Here we group clauses by "and" and separate by "or"
  // X or Y and Z => [[X], [Y, Z]]
  const data: UploadDQCDataType[][] = dataContainer.data;
  const ruleClauses = rule.qualityTest.meta.clauses;
  const issues: CustomCheckIssue[] = [];
  const groupedClauses = groupClauses(rule.qualityTest.meta.clauses);
  const isUniqueValue: boolean[][] = getUniqueValues(data, ruleClauses);
  const isSequentialNumbers: boolean[][] = getSequentialNumbers(data, ruleClauses);

  for (const rowData of data) {
    if (
      !groupedClauses.some((clauseGroup) =>
        cellObeysClauseGroup(rowData, clauseGroup, isUniqueValue, isSequentialNumbers)
      )
    ) {
      const lastClauseGroup = groupedClauses[groupedClauses.length - 1];
      for (let clause of lastClauseGroup) {
        const cell = rowData[clause.columnIndex];
        if (!cellObeysClause(cell, clause, isUniqueValue, isSequentialNumbers)) {
          issues.push({
            row: cell.row,
            column: cell.column,
            comment: "custom_rule_comment",
            severity: rule.severity,
            type: "custom",
            rule_id: rule.id,
          });
        }
      }
    }
  }
  return addIssueId(issues);
};

export const getCustomRule = (
  column: ColumnDefinition,
  fileName: string
): RuleCandidateWithoutMeta => {
  return {
    id: JSON.stringify(uuid4()),
    columns: [column],
    dimension: "custom",
    severity: "warning",
    confidence: 5,
    isAccepted: true,
    name: "Custom",
    description: "Custom rule",
    qualityTest: {
      testFunctionName: "customRule",
      meta: { clauses: getDefaultClause(column.index) },
    },
    fileName: fileName,
  };
};

export const getDefaultClause = (columnIndex: number): Clause[] => {
  return [
    {
      columnIndex,
      condition: "greater than",
      value: undefined,
      type: "number",
      isDefaultClause: true,
    },
  ];
};

const monospaced =
  'Menlo, "DejaVu Sans Mono", "Liberation Mono", Consolas, "Ubuntu Mono", "Courier New", "andale mono", "lucida console", monospace';

export const ifMonospaceRequired = (condition: string) => {
  return condition === "regular expression" || condition === "special characters" || "equals"
    ? monospaced
    : "";
};

export const replaceStringIfRequired = (condition: string, text: string | undefined) => {
  if (!text) return "";
  if (condition === "regular expression" || condition === "special characters") {
    return text.replaceAll(/\s/g, "•");
  } else if (condition === "equals") {
    return text.replaceAll(/(\“)|(\”)|(\')/g, '"');
  } else {
    return text;
  }
};
const cellObeysClauseGroup = (
  rowData: UploadDQCDataType[],
  clauseGroup: Clause[],
  isUniqueValue: boolean[][],
  isSequentialNumbers: boolean[][]
): unknown => {
  return clauseGroup.every((clause) =>
    cellObeysClause(rowData[clause.columnIndex], clause, isUniqueValue, isSequentialNumbers)
  );
};
const cellObeysClause = (
  cell: UploadDQCDataType,
  clause: Clause,
  isUniqueValue: boolean[][],
  isSequentialNumbers: boolean[][]
): boolean => {
  const clauseColumnIndex = clause.columnIndex;
  switch (clause.type) {
    case "number":
      if (cell.type !== "Double" && cell.type !== "Integer") return false;
      return numericFunctionMap[clause.condition](cell, clause);
    case "text":
      return textFunctionMap[clause.condition](cell, clause);
    case "format":
      return formatFunctionMap[clause.condition](cell, clause);
    case "other":
      if (clause.condition === "unique") return isUniqueValue[cell.row][clauseColumnIndex];
      if (clause.condition === "sequential numbers")
        return isSequentialNumbers[cell.row][clauseColumnIndex];
      if (clause.condition === "empty") return String(cell.value).trim().length === 0;
      return false;
    default:
      return false;
  }
};
