import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  ICheckboxStyles,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { t } from "i18next";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateSurveyMutation } from "../../api/survey";

interface SurveySendoutDialogState {
  dialogState: boolean;
  surveyId: string;
  hideDialog: () => void;
  waitForHTTPToFinish: () => void;
  HTTPFinished: () => void;
}

const modelProps = {
  isBlocking: false,
  styles: { main: { maxWidth: 650 } },
};

const checkboxStyle: ICheckboxStyles = {
  root: { marginTop: "10px" },
};

const dialogContentProps = {
  type: DialogType.largeHeader,
  title: t("survey_sendout_title"),
  subText: t("survey_sendout_description"),
};

export const SurveySendoutDialog: FunctionComponent<SurveySendoutDialogState> = (
  props: SurveySendoutDialogState
) => {
  const { t } = useTranslation();
  const link = `${window.location.origin}/surveys/form/${props.surveyId}`;
  const [hasBeenSendout, setHasBeenSendout] = useState(false);
  const [description, setDescription] = useState("");
  const [updateSurvey] = useUpdateSurveyMutation();
  const _onChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
    setHasBeenSendout(isChecked!);
  };
  const iconProps = { iconName: "Copy" };

  return (
    <Dialog
      hidden={props.dialogState}
      onDismiss={props.hideDialog}
      dialogContentProps={dialogContentProps}
      modalProps={modelProps}
    >
      <TextField
        value={link}
        iconProps={iconProps}
        description={description}
        onClick={(ev: React.FormEvent<HTMLElement | HTMLInputElement>) => {
          (ev.currentTarget as HTMLInputElement).select();
          navigator.clipboard.writeText(link);
          setDescription(t("copied"));
          setTimeout(() => {
            setDescription("");
          }, 1500);
        }}
      />
      <Checkbox label="Sendout confirmed" onChange={_onChange} styles={checkboxStyle} />
      <DialogFooter>
        <PrimaryButton
          onClick={async () => {
            try {
              const survey = { id: props.surveyId, activated: hasBeenSendout };
              props.waitForHTTPToFinish();
              props.hideDialog();
              await updateSurvey(survey);
              props.HTTPFinished();
            } catch (error) {
              console.log("error for update survey api", error);
            }
          }}
          text={t("save")}
        />
        <DefaultButton onClick={props.hideDialog} text={t("cancel")} />
      </DialogFooter>
    </Dialog>
  );
};
