import React from "react";
import { TopContainer } from "../../components/common/TopContainer";
import { useTranslation } from "react-i18next";
import { Stack, Separator } from "@fluentui/react";
import { BarChart } from "./barChart";
import { Usage } from "./usage";
import { FactsAndFigures } from "../../components/facts-and-figures/FactsAndFigures";
import { InteractionsChart } from "../../components/dashboard/overview/InteractionsChart";
import { flexStackStyles } from "../../helpers/commonStyles";

export const Interactions: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <TopContainer title={t("interactions")}>
      <main>
        <Stack horizontal styles={flexStackStyles}>
          <InteractionsChart />
          <FactsAndFigures interaction={true} />
        </Stack>
        <Separator />
        <Stack horizontal styles={flexStackStyles}>
          <BarChart />
          <Usage />
        </Stack>
      </main>
    </TopContainer>
  );
};
