import { Dropdown, IDropdownProps, IDropdownStyles } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { FunctionComponent } from "react";

export const EditableDropdown: FunctionComponent<IDropdownProps> = (props: IDropdownProps) => {
  const [inputVisible, { setTrue: showInput, setFalse: hideInput }] = useBoolean(false);
  const [mouseOver, { setTrue: mouseIsOver, setFalse: mouseIsOut }] = useBoolean(false);

  const dropdownStyles: Partial<IDropdownStyles> = {
    title: {
      border: inputVisible ? "" : "none",
      backgroundColor: mouseOver && !inputVisible ? "#E8E9ED" : "white",
    },
  };
  return (
    <Dropdown
      {...props}
      styles={dropdownStyles}
      onMouseOver={mouseIsOver}
      onMouseOut={mouseIsOut}
      onFocus={(event) => {
        if (props.onFocus) props.onFocus!(event);
        showInput();
      }}
      onBlur={(event) => {
        if (props.onBlur) props.onBlur!(event);
        hideInput();
      }}
    />
  );
};
