import { RuleCandidate } from "../../../types/rules";
import { UploadDataContainer } from "../../../types/fileUploader";
import { QuickAnalysisIssue } from "../../../types/quickAnalysis";
import { addIssueId } from "../../quickAnalysis/qualityChecks/qualityUtils";

export const missingValues = (
  dataContainer: UploadDataContainer,
  rule: RuleCandidate
): QuickAnalysisIssue[] => {
  const issues: QuickAnalysisIssue[] = [];
  if (rule.qualityTest.testFunctionName !== "missingValues") return issues;
  const { valuesConsideredMissing, emptyRows } = rule.qualityTest.meta;
  const emptyRowSet = new Set(emptyRows);
  const columnIndex = rule.columns[0].index;
  const column = dataContainer.data.map((row) => row[columnIndex]);
  for (let cell of column) {
    const isEmptyRow = emptyRowSet.has(cell.row);
    if (isEmptyRow) continue;
    const isEmptyCell =
      cell.type === "Empty" || valuesConsideredMissing.includes(cell.value.toString());
    if (isEmptyCell) {
      issues.push({
        row: cell.row,
        column: cell.column,
        comment: rule.severity === "warning" ? "exceptional_missing_comment" : "missing_comment",
        severity: rule.severity,
        type: "missing",
        id: "",
        rule_id: rule.id,
      });
    }
  }
  return addIssueId(issues);
};
