import { useTranslation } from "react-i18next";
import { TopContainer } from "../../components/common/TopContainer";
import { DetailsList, IColumn, Icon, SelectionMode, Spinner, Stack, Text } from "@fluentui/react";
import { useGetAllDataConnectorsQuery } from "../../api/v2/dataConnectors";
import { DataConnector, DataTable } from "../dataConnectors/dataConnectorTypes";
import { DataConnectorButtonIcon } from "../dataConnectors/Connector";
import { dqcPalette } from "../../utils/colors";
import { ResponsiveBar } from "@nivo/bar";
import { Link } from "react-router-dom";
import { ActiveRule } from "../../types/rules";
import { useGetAllAlertsQuery } from "../../api/v2/alertsNotifications";
import { useGetAllRulesetsQuery } from "../../api/v2/rulesets";

export const RulesAndPredictionsOverview: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { data: dataConnectors, isLoading: dataConnectorsLoading } = useGetAllDataConnectorsQuery(
    {}
  );
  const { data: rulesets, isLoading: rulesetsLoading } = useGetAllRulesetsQuery({});
  const { data: alerts, isLoading: alertsLoading } = useGetAllAlertsQuery({});

  const iconAndTextStyle = { root: { lineHeight: 16, paddingLeft: 2, fontSize: "small" } };
  const onRenderItemColumn = (
    item?: DataTable,
    itemIndex?: number,
    column?: IColumn
  ): React.ReactNode => {
    return column && item && typeof itemIndex === "number" && itemIndex > -1 ? (
      <Stack horizontal>
        {column?.key === "origin" && (
          <Stack.Item>
            <DataConnectorButtonIcon iconName={item.origin!} />
          </Stack.Item>
        )}
        {column?.key === "name" && (
          <Stack.Item>
            <Link to={`/overview/table/${item.id}`}>{item.name}</Link>
          </Stack.Item>
        )}
        {column?.key === "predictions" && (
          <Stack horizontal>
            <Icon iconName="OpenEnrollment" />
            <Text styles={iconAndTextStyle}>{(item as any)[column?.fieldName!]}</Text>
          </Stack>
        )}
        {column?.key === "rules" && (
          <Stack horizontal>
            <Icon iconName="ClipboardList" />
            <Text styles={iconAndTextStyle}>{(item as any)[column?.fieldName!]}</Text>
          </Stack>
        )}
        {column?.key === "issues" && (
          <Stack horizontal>
            <Icon iconName={(item as any)[column?.fieldName!] > 0 ? "WarningSolid" : "CheckMark"} />
            {(item as any)[column?.fieldName!] ?? ""}
          </Stack>
        )}
        {column?.key === "meta-validity" && (
          <Stack horizontal>
            <Icon iconName={(item as any)[column?.fieldName!] ? "CheckMark" : "WarningSolid"} />
          </Stack>
        )}
        {column?.key === "alerts" && (
          <Stack horizontal>
            <Icon iconName="Ringer" />
            {(item as any)[column?.fieldName!] ?? ""}
          </Stack>
        )}
        {column?.key === "missions" && (
          <Stack horizontal>
            <Icon iconName="WorkFlow" />
            {(item as any)[column?.fieldName!] ?? ""}
          </Stack>
        )}
      </Stack>
    ) : null;
  };

  if (dataConnectorsLoading || rulesetsLoading || alertsLoading)
    return <Spinner label={t("loading")} />;
  if (!dataConnectors) return <div>{t("no_data")}</div>;

  const alertsForDataTable: Map<string, number> = new Map();
  if (rulesets) {
    console.log(rulesets.map((ruleset) => ruleset.id));
    rulesets.forEach((ruleset) => {
      alerts
        ?.filter((alert) => alert.ruleset_id === ruleset.id)
        .forEach(() => {
          const count = alertsForDataTable.get(ruleset.data_table_id);
          if (count) {
            alertsForDataTable.set(ruleset.data_table_id, count + 1);
          } else {
            alertsForDataTable.set(ruleset.data_table_id, 1);
          }
        });
    });
  }

  const items = dataConnectors
    .map((dataConnector: DataConnector) =>
      dataConnector.data_tables
        ? dataConnector.data_tables.map((dt) => ({
            ...dt,
            origin: dataConnector.origin,
          }))
        : []
    )
    .flat()
    .map((dataTable: DataTable) => ({
      ...dataTable,
      key: dataTable.id,
      newRules: dataTable.rules.filter((rule) => !rule.isAccepted).length,
      activeRules: dataTable.rules.filter((rule) => rule.isAccepted).length,
      issues: dataTable.rules
        .filter((rule) => rule.isAccepted)
        .map((rule) => (rule as ActiveRule).issues.filter((issue) => issue.rule_id === rule.id))
        .flat().length,
      metaValidity: true,
      alerts: !alerts ? 0 : alertsForDataTable.get(dataTable.id),
      missions: 0,
    }));
  const groups = dataConnectors.map((dataConnector: DataConnector, i: number) => {
    return {
      key: dataConnector.id,
      name: dataConnector.name,
      startIndex: i + (i > 0 ? dataConnectors[i - 1].data_tables?.length ?? 0 : 0),
      count: dataConnector.data_tables?.length ?? 0,
      level: 0,
    };
  });
  const columns = [
    {
      key: "origin",
      name: t("data_connectors"),
      fieldName: "origin",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      key: "name",
      name: t("name"),
      fieldName: "name",
      minWidth: 130,
      maxWidth: 130,
      isResizable: true,
      flexGrow: 1,
    },
    {
      key: "predictions",
      name: t("new_rules"),
      fieldName: "newRules",
      minWidth: 80,
      maxWidth: 80,
    },
    {
      key: "rules",
      name: t("active_rules"),
      fieldName: "activeRules",
      minWidth: 80,
      maxWidth: 80,
    },
    {
      key: "issues",
      name: t("health"),
      fieldName: "issues",
      minWidth: 50,
      maxWidth: 50,
    },
    {
      key: "meta-validity",
      name: t("meta_validity"),
      fieldName: "metaValidity",
      minWidth: 90,
      maxWidth: 90,
    },
    {
      key: "alerts",
      name: t("alerts"),
      fieldName: "alerts",
      minWidth: 50,
      maxWidth: 50,
    },
    {
      key: "missions",
      name: t("missions"),
      fieldName: "missions",
      minWidth: 50,
      maxWidth: 50,
    },
  ];

  return (
    <TopContainer title={t("rules_predictions")}>
      <Stack styles={{ root: { paddingTop: 16 } }}>
        <Stack></Stack>
        <Stack horizontal tokens={{ childrenGap: 16 }}>
          <Stack styles={{ root: { width: "59%" } }}>
            <Stack.Item>
              <h2>{t("rules_predictions")}</h2>
            </Stack.Item>
            <Stack.Item>
              <DetailsList
                items={items}
                columns={columns}
                onRenderItemColumn={onRenderItemColumn}
                selectionMode={SelectionMode.none}
                groups={groups}
                compact={true}
              />
            </Stack.Item>
          </Stack>
          <Stack
            styles={{
              root: {
                width: "39%",
                borderLeft: `1px solid ${dqcPalette.neutralLighter}`,
                paddingLeft: 16,
              },
            }}
          >
            <Stack styles={{ root: { maxHeight: 400 } }}>
              <h2>{t("issues_over_time")}</h2>
              <Stack.Item styles={{ root: { height: 300 } }}>
                <ResponsiveBar
                  data={[
                    { date: "2023-08-01", issues: 100 },
                    { date: "2023-08-02", issues: 90 },
                    { date: "2023-08-03", issues: 92 },
                    { date: "2023-08-04", issues: 85 },
                    { date: "2023-08-05", issues: 99 },
                  ]}
                  keys={["issues"]}
                  indexBy="date"
                  margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                  padding={0.3}
                  valueScale={{ type: "linear" }}
                  indexScale={{ type: "band", round: true }}
                  colors={{ scheme: "nivo" }}
                />
              </Stack.Item>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </TopContainer>
  );
};
