import { Spinner } from "@fluentui/react";

const stripe_key = process.env.REACT_APP_STRIPE_KEY;
const stripe_pt = process.env.REACT_APP_STRIPE_PT;

function PricingTable({ email }) {
  if (!email) return <Spinner />;
  return (
    <stripe-pricing-table
      pricing-table-id={stripe_pt}
      publishable-key={stripe_key}
      customer-email={email}
    ></stripe-pricing-table>
  );
}

export default PricingTable;
