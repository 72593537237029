import { IStackStyles, Stack } from "@fluentui/react";
import { memo, useState } from "react";
import { DataAsset } from "../../types/dataasset";
import { DataAssetListItem } from "./AssetListItem";
import { dividerLine } from "../../helpers/commonStyles";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../../types/item-types";

const subContainer1: IStackStyles = {
  root: {
    padding: 10,
  },
};
const subContainer2: IStackStyles = {
  root: {
    padding: 10,
    marginLeft: 20,
  },
};
const subContainer3: IStackStyles = {
  root: {
    padding: 10,
    marginLeft: 40,
  },
};

const style: React.CSSProperties = {
  cursor: "move",
};

const dropContainer: IStackStyles = {
  root: {
    border: "2px dashed green",
    background: "#E5F6DF",
  },
};

interface AssetListWrapperProps {
  data: DataAsset;
  index: number;
  assetsLength: number;
  id: string;
  moveCard: (id: string, to: number) => void;
  findCard: (id: string) => { index: number };
  dropCard: () => void;
  isDragAndDrop: boolean;
}

interface Item {
  id: string;
  originalIndex: number;
}

export const AssetListWrapper: React.FunctionComponent<AssetListWrapperProps> = memo(
  ({ data, index, assetsLength, id, moveCard, findCard, dropCard, isDragAndDrop }) => {
    const [indexValue, setIndexValue] = useState(-1);
    const [orderIndexValue, setOrderIndexValue] = useState(-1);
    const originalIndex = findCard(id).index;

    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: ItemTypes.CARD,
        item: { id, originalIndex },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
          const { id: droppedId, originalIndex } = item;
          const didDrop = monitor.didDrop();
          if (!didDrop) {
            moveCard(droppedId, originalIndex);
          } else {
            dropCard();
          }
        },
      }),
      [id, originalIndex, moveCard]
    );

    const [, drop] = useDrop(
      () => ({
        accept: ItemTypes.CARD,
        hover({ id: draggedId }: Item) {
          if (draggedId !== id) {
            const { index: overIndex } = findCard(id);
            moveCard(draggedId, overIndex);
          }
        },
      }),
      [findCard, moveCard]
    );
    const opacity = isDragging ? 0 : 1;

    const closeAsset = (index: number) => {
      if (indexValue !== -1 && indexValue === index) {
        setIndexValue(-1);
      } else {
        setIndexValue(index);
      }
      setOrderIndexValue(-1);
    };

    const closeOrderAsset = (index: number) => {
      if (orderIndexValue !== -1 && orderIndexValue === index) {
        setOrderIndexValue(-1);
      } else {
        setOrderIndexValue(index);
      }
    };

    return (
      <div
        ref={(node) => {
          isDragAndDrop && drag(drop(node));
        }}
        style={isDragAndDrop ? { ...style } : {}}
      >
        <Stack styles={isDragging ? dropContainer : {}}>
          <div style={{ opacity }}>
            <Stack>
              <DataAssetListItem
                data={data}
                index={index}
                indexValue={indexValue}
                subContainer={subContainer1}
                ListIconScore={data.type === "Domain" ? 1 : data.type === "Family" ? 2 : 3}
                functioncloseAsset={closeAsset}
              />
              {index !== assetsLength - 1 && <span style={dividerLine} />}
              {/* Second stack */}
              <Stack>
                {indexValue === index ? (
                  <>
                    {data?.linked_assets.map((data2: DataAsset, index2: number) => (
                      <Stack key={index2}>
                        <DataAssetListItem
                          data={data2}
                          index={index2}
                          indexValue={orderIndexValue}
                          subContainer={subContainer2}
                          ListIconScore={data2.type === "Family" ? 2 : 3}
                          functioncloseAsset={closeOrderAsset}
                        />
                        <span style={dividerLine} />
                        {/* Third stack */}
                        <Stack>
                          {orderIndexValue === index2 ? (
                            <>
                              {data2.linked_assets.map((data3: DataAsset, index3: number) => (
                                <Stack key={index3}>
                                  <DataAssetListItem
                                    data={data3}
                                    index={index3}
                                    subContainer={subContainer3}
                                    ListIconScore={3}
                                  />
                                  <span style={dividerLine} />
                                </Stack>
                              ))}
                            </>
                          ) : null}
                        </Stack>
                      </Stack>
                    ))}
                  </>
                ) : null}
              </Stack>
            </Stack>
          </div>
        </Stack>
      </div>
    );
  }
);
