import React, { useEffect, useMemo, useRef } from "react";
import { Grid, GridCellRenderer } from "react-virtualized";
import IssueSliderStyles from "./IssueSlider.module.css";
import { Stack } from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  handleSelectIssueInSheet,
  selectCalculatedLongestIssueRowTextFromIssues,
  selectHighlightedIssue,
  selectIssuesByDuplicateGroupFactory,
} from "../../../store/slices/issueNavigator";
import { RootState } from "../../../store/store";
import { Arrow } from "./Arrow";
type SliderProps = {
  duplicateGroupId: number;
};

export const DuplicateIssueSlider = ({ duplicateGroupId }: SliderProps) => {
  const dispatch = useAppDispatch();
  const highlightedIssue = useAppSelector(selectHighlightedIssue);
  const dimensionScopedSelector = useMemo(() => selectIssuesByDuplicateGroupFactory(), []);
  const issuesByGroupId = useAppSelector((state: RootState) =>
    dimensionScopedSelector(state, duplicateGroupId)
  )?.slice(1);
  const calculatedTextWidth = useAppSelector(selectCalculatedLongestIssueRowTextFromIssues);
  // We have the first issueRow => Duplicate slider is 220-<firstRowWidth>
  const sideBarWidth = 120;

  const gridRef = useRef<Grid | null>(null);

  const scrollToPosition = (columnIndex: number) => {
    if (gridRef.current) {
      gridRef.current.scrollToCell({ rowIndex: 0, columnIndex });
    }
  };

  useEffect(() => {
    const shouldScroll =
      highlightedIssue?.type === "duplicate" && highlightedIssue?.groupId === duplicateGroupId;
    if (!shouldScroll) return;
    const scrollPosition =
      issuesByGroupId?.findIndex((issue) => {
        return issue.id === highlightedIssue?.id;
      }) ?? -1;
    scrollPosition >= 0 && scrollToPosition(scrollPosition);
  }, [issuesByGroupId]);

  if (!issuesByGroupId) return null;

  const RowRenderer: GridCellRenderer = ({ columnIndex, key, style }) => {
    const issue = issuesByGroupId[columnIndex];
    const isSelected = highlightedIssue?.row === issue.row;
    return (
      <div
        key={key}
        style={{
          display: "inline-block",
          fontSize: 10,
          textDecoration: isSelected ? "underline" : "none",
          fontWeight: isSelected ? 800 : 400,
          marginTop: 2,
          ...style,
        }}
        onClick={() =>
          dispatch(
            handleSelectIssueInSheet({
              issue,
            })
          )
        }
      >
        {issue.row + 2}
      </div>
    );
  };

  return (
    <Stack horizontal verticalAlign="center">
      <Arrow direction={"Left"} />
      <Stack
        styles={{ root: { overflow: "scroll", width: sideBarWidth } }}
        className={IssueSliderStyles.hideScrollbar}
        verticalAlign="center"
      >
        <Grid
          className={IssueSliderStyles.hideScrollbar}
          ref={gridRef}
          width={sideBarWidth}
          height={20}
          rowCount={1}
          columnCount={issuesByGroupId.length}
          rowHeight={20}
          columnWidth={calculatedTextWidth}
          cellRenderer={RowRenderer}
        />
      </Stack>
      <Arrow direction={"Right"} />
    </Stack>
  );
};
