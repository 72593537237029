import { UploadDataContainer } from "../../../types/fileUploader";
import { QuickAnalysisIssue } from "../../../types/quickAnalysis";
import { Graph, alg } from "@dagrejs/graphlib";
import { RuleCandidate } from "../../../types/rules";
import { distance } from "fastest-levenshtein";
import { token_set_ratio } from "fuzzball";
import { normalizeString } from "../../../utils/strings";
import { addIssueId } from "../../quickAnalysis/qualityChecks/qualityUtils";

export const findDuplicateIssuesExact = (
  dataContainer: UploadDataContainer,
  rule: RuleCandidate
): QuickAnalysisIssue[] => {
  const issues: QuickAnalysisIssue[] = [];
  const columnIndices = rule.columns.map(({ index }) => index);
  const graph = new Graph();
  dataContainer.data.forEach((_: any, i: number) => graph.setNode(i.toString()));
  const duplicateRowMap: { [key: string]: number } = {};

  for (let row = 0; row < dataContainer.data.length; ++row) {
    const rowAsString = columnIndices
      .map((column) => dataContainer.data[row][column].value)
      .join(" ");
    if (!rowAsString.trim().length) continue;
    if (duplicateRowMap[rowAsString] || duplicateRowMap[rowAsString] === 0)
      graph.setEdge(row.toString(), duplicateRowMap[rowAsString].toString());
    else duplicateRowMap[rowAsString] = row;
  }
  let groupId = 1;
  alg.components(graph).forEach((components: string[]) => {
    if (components.length < 2) return;
    components.forEach((row) => {
      const originalRow = dataContainer.data[parseInt(row)][0].row;
      issues.push({
        row: originalRow,
        columns: columnIndices,
        type: "duplicate",
        groupId,
        comment: "exact_duplicate_comment",
        severity: "warning",
        isIgnored: false,
        id: "",
        rule_id: rule.id,
      });
    });
    groupId++;
  });
  return addIssueId(issues);
};

export const calculateNormalizedDistance = (str1: string, str2: string): number => {
  const string1 = normalizeString(str1);
  const string2 = normalizeString(str2);

  return distance(string1, string2);
};

const APPLY_FUZZBALL_MATCHING_QUADRANT = 0.1125; // threshold [0.05, 0.2]
/**
 * Function that checks if two rows should be connected on the basis of their distance.
 * If their distance is less than the max allowed, returns true.
 * Else if the threshold is bigger or equal to APPLY_FUZZBALL_MATCHING_QUADRANT returns the second distance if > 0.8
 * Else return false
 * @param rowsAsStrings
 * @param row1
 * @param row2
 * @param fuzzinessThreshold
 * @returns
 */
export const shouldConnectRows = (
  rowsAsStrings: string[],
  row1: number,
  row2: number,
  fuzzinessThreshold: number
): boolean => {
  if (!rowsAsStrings[row1].trim().length) return false;
  const string1 = rowsAsStrings[row1];
  const string2 = rowsAsStrings[row2];
  const maxEditDistance = fuzzinessThreshold * rowsAsStrings[row1].length;
  const calcDist = calculateNormalizedDistance(string1, string2);

  if (calcDist < maxEditDistance) {
    return true;
  } else if (fuzzinessThreshold >= APPLY_FUZZBALL_MATCHING_QUADRANT) {
    const secondDistance = token_set_ratio(string1, string2) / 100;
    return secondDistance > 0.8;
  }
  return false;
};

export const findDuplicateIssuesFuzzy = (
  dataContainer: UploadDataContainer,
  rule: RuleCandidate
): QuickAnalysisIssue[] => {
  const graph = new Graph();
  const issues: QuickAnalysisIssue[] = [];
  const columnIndices = rule.columns.map(({ index }) => index);

  if (rule.qualityTest.testFunctionName !== "duplicates") return issues;
  const { fuzzinessThreshold } = rule.qualityTest.meta;
  const rowsAsStrings: string[] = [];
  for (const rowData of dataContainer.data) {
    const rowAsString = columnIndices.map((column) => rowData[column].value).join(" ");
    rowsAsStrings.push(rowAsString);
  }
  rowsAsStrings.forEach((_: any, i: number) => graph.setNode(i.toString()));

  for (let row = 0; row < rowsAsStrings.length; ++row) {
    for (let otherRow = row + 1; otherRow < rowsAsStrings.length; ++otherRow) {
      if (shouldConnectRows(rowsAsStrings, row, otherRow, fuzzinessThreshold)) {
        graph.setEdge(row.toString(), otherRow.toString());
      }
    }
  }
  let groupId = 1;
  alg.components(graph).forEach((components: string[]) => {
    if (components.length < 2) return;
    components.forEach((row) => {
      const originalRow = dataContainer.data[parseInt(row)][0].row;
      issues.push({
        row: originalRow,
        columns: columnIndices,
        type: "duplicate",
        groupId,
        comment: "exact_duplicate_comment",
        severity: "warning",
        isIgnored: false,
        id: "",
        rule_id: rule.id,
      });
    });
  });
  return issues;
};

export const duplicates = (
  dataContainer: UploadDataContainer,
  rule: RuleCandidate
): QuickAnalysisIssue[] => {
  const issues: QuickAnalysisIssue[] = [];
  if (rule.qualityTest.testFunctionName !== "duplicates") return issues;
  const { fuzzinessThreshold } = rule.qualityTest.meta;
  if (fuzzinessThreshold === 0) {
    return findDuplicateIssuesExact(dataContainer, rule);
  }
  return findDuplicateIssuesFuzzy(dataContainer, rule);
};
