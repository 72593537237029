import { Stack, Label, DirectionalHint, Persona, PersonaSize, TooltipHost } from "@fluentui/react";
import { t } from "i18next";
import { PeoplePickerMSGraph, IDQCPersonaProps } from "../../components/common/PeoplePickerMSGraph";
import { profileLabel } from "../../helpers/commonStyles";
import { CreateRemark, Remark } from "../../types/colab";

export type VisibilityProps = {
  remark: CreateRemark | Remark;
  editMode?: boolean;
  setRemark?: (remark: Remark) => void;
};
export const Visibility = ({ remark, setRemark, editMode }: VisibilityProps): JSX.Element => {
  const all_item = { text: t("all"), key: "all", id: "all" };
  if (!editMode || !setRemark)
    return (
      <Stack wrap horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
        <Label styles={profileLabel}>{t("view")}</Label>
        {remark.specific_visibilities.map((user, index) => {
          return (
            <Stack key={`specific_users_${user.id}`}>
              <TooltipHost content={user.displayName} directionalHint={DirectionalHint.topCenter}>
                <Persona
                  text={user.displayName}
                  hidePersonaDetails={true}
                  size={PersonaSize.size24}
                />
              </TooltipHost>
            </Stack>
          );
        })}
      </Stack>
    );

  const onChangePeoplePicker = (items: IDQCPersonaProps[]) => {
    if (items.find((item) => item.key === "all"))
      setRemark({
        ...remark,
        visibility: "ALL",
      } as Remark);
    else {
      setRemark({
        ...remark,
        visibility: "SPECIFIC",
        specific_visibilities: items.map((i) => ({
          email: i.email,
          id: i.key,
          displayName: i.text,
        })),
      } as Remark);
    }
  };
  return (
    <Stack tokens={{ childrenGap: 5 }} style={{ marginBottom: 12 }} horizontal>
      <Stack.Item>
        <Label style={{ minWidth: 35 }}>{t("view")}</Label>
      </Stack.Item>
      <Stack.Item grow>
        <PeoplePickerMSGraph
          subjectType="remark"
          defaultProposedItems={[all_item]}
          defaultSelectedItems={[
            all_item,
            ...remark.specific_visibilities.map(
              (s): IDQCPersonaProps => ({ text: s.displayName, key: s.id, email: s.email })
            ),
          ]}
          onChange={onChangePeoplePicker}
        />
      </Stack.Item>
    </Stack>
  );
};
