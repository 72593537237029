import {
  Breadcrumb,
  IBreadcrumbItem,
  IRenderFunction,
  ITextStyles,
  ProgressIndicator,
  Stack,
  Text,
} from "@fluentui/react";
import {
  doRulesAnalysis,
  endAnalysisAndClearEverything,
  selectLatestQuickAnalysisStatus,
  setQuickAnalysisStatus,
} from "../../store/slices/quickAnalysis";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { dqcPalette } from "../../utils/colors";
import { selectLatestUpload } from "../../store/slices/upload";
import { t } from "i18next";

const getBreadcrumbTextStyles = (isHighlighted: boolean, isClickable: boolean): ITextStyles => ({
  root: {
    margin: 8,
    fontWeight: isHighlighted ? 600 : 400,
    fontSize: 16,
    color: dqcPalette.neutralDark,
    cursor: isClickable ? "pointer" : "default",
  },
});

export const Breadcrumbs = () => {
  const status = useAppSelector(selectLatestQuickAnalysisStatus);
  const uploadedFile = useAppSelector(selectLatestUpload);
  const dispatch = useAppDispatch();
  const isFileSelected = status !== "selectFile";
  const isFileLoad = ["selectFile", "loadingFile", "filePreview"].includes(status);
  const isAnalyzing = ["analyzing", "results"].includes(status);
  const isRulesView = status === "rules";

  const getProgressPercentage = () => {
    if (status === "selectFile") return 0.2;
    if (status === "loadingFile") return 0.4;
    if (status === "filePreview") return 0.5;
    if (status === "analyzing") return 0.7;
    return 1;
  };
  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      text: t("load_file_breadcrumb"),
      key: "load_file_breadcrumb",
      onClick: isFileSelected ? () => dispatch(endAnalysisAndClearEverything()) : undefined,
    },
    {
      text: t("analysis_breadcrumb"),
      key: "analysis_breadcrumb",
      onClick:
        isRulesView && uploadedFile ? () => dispatch(doRulesAnalysis(uploadedFile)) : undefined,
    },
    {
      text: t("rules_breadcrumb"),
      key: "rules_breadcrumb",
      onClick: isAnalyzing ? () => dispatch(setQuickAnalysisStatus("rules")) : undefined,
    },
  ];
  const onRenderBreadcrumbItem: IRenderFunction<IBreadcrumbItem> = (item) => {
    if (!item) return null;
    const { key, text, onClick } = item;
    const isHighlighted =
      (key === "load_file_breadcrumb" && isFileLoad) ||
      (key === "analysis_breadcrumb" && isAnalyzing) ||
      (key === "rules_breadcrumb" && isRulesView);
    return (
      <Text onClick={onClick} styles={getBreadcrumbTextStyles(isHighlighted, !!onClick)}>
        {text}
      </Text>
    );
  };
  return (
    <Stack
      styles={{
        root: {
          margin: "0 10px 0px 10px",
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "white",
          paddingTop: 15,
          paddingBottom: 20,
          height: 100,
        },
      }}
    >
      <ProgressIndicator
        percentComplete={getProgressPercentage()}
        styles={{
          progressBar: { backgroundColor: dqcPalette.themeSecondary },
          progressTrack: { backgroundColor: dqcPalette.themePrimary },
        }}
      />
      <Breadcrumb
        items={breadcrumbItems}
        ariaLabel="Quick Analysis navigation"
        styles={{ root: { cursor: "default" } }}
        onRenderItem={onRenderBreadcrumbItem}
      />
    </Stack>
  );
};
