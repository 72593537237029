import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IIconProps,
  ITextFieldStyles,
  Link,
  mergeStyles,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  SelectionMode,
  Separator,
  Stack,
  TextField,
} from "@fluentui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import moment from "moment";
import { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetColabDashboardDataQuery } from "../../api/colab";
import { useAppSelector } from "../../store/hooks";
import { selectMsGraphApiAccessToken } from "../../store/slices/remarkSlice";
import {
  ReportData,
  ReportMetricsAPIResponseModel,
  ReportMetricsDisplayModel,
} from "../../types/colab";
import jwt_decode from "jwt-decode";
import { getPowerBIReportLink } from "../../helpers/collab-helper";
const searchIcon: IIconProps = {
  iconName: "Search",
};

const searchBoxStyles: Partial<ITextFieldStyles> = {
  root: { width: 200, borderRadius: "4px", marginRight: "4%" },
};

type CollabState = {
  powerBILink: string;
  colabQuery: string;
  searchTerm: string;
  erorMessages: string[];
};

const initialState: CollabState = {
  powerBILink: "",
  colabQuery: "",
  searchTerm: "",
  erorMessages: [],
};
export const Colab: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [colabState, setColabState] = useState<CollabState>(initialState);
  const accessToken: string = useAppSelector(selectMsGraphApiAccessToken);
  const decoded: Record<string, string> = accessToken ? jwt_decode(accessToken) : {};
  const { data } = useGetColabDashboardDataQuery(colabState.colabQuery ?? skipToken);
  const colabApiResponseData: Map<string, ReportMetricsAPIResponseModel> | undefined = data;
  let colabDisplayData: ReportMetricsDisplayModel[] = [];

  //Method to Check if local storage is loaded and reports are stored
  const getReportsInLocalStorage = (): Map<string, ReportData> | undefined => {
    const lastReportsInLocalStorage = localStorage?.getItem("lastReports");
    if (lastReportsInLocalStorage) {
      const lastOpenedReports: Map<string, ReportData> = JSON.parse(lastReportsInLocalStorage);
      return lastOpenedReports;
    }
    return undefined;
  };
  //Fetch reports stored locally and if api response is recieved prepare table data.
  const lastOpenedReports: Map<string, ReportData> | undefined = getReportsInLocalStorage();
  if (colabApiResponseData && lastOpenedReports) {
    const colabReportData: ReportMetricsDisplayModel[] = [];
    Object.entries(lastOpenedReports).forEach((reportData: [string, ReportData]) => {
      const reportAPIData: [string, ReportMetricsAPIResponseModel] | undefined = Object.entries(
        colabApiResponseData
      ).find(
        (reportRespData: [string, ReportMetricsAPIResponseModel]) =>
          reportRespData[0] === reportData[1].id
      );
      if (reportAPIData && reportAPIData[1]) {
        colabReportData.push({
          id: reportData[1].id,
          name: reportData[0],
          number_of_comments: reportAPIData[1].number_of_comments,
          number_of_remarks: reportAPIData[1].number_of_remarks,
          tagged_users: reportAPIData[1].tagged_users,
          last_access: moment(reportData[1].date).fromNow(),
          link: "/colab/powerbi/report/" + reportData[1].id,
          date: moment(reportData[1].date),
        });
      }
    });
    //Filter data if search term is entered else show full list
    colabDisplayData = colabState.searchTerm
      ? [
          ...colabReportData.filter((report) =>
            report.name.toLocaleLowerCase().includes(colabState.searchTerm.toLocaleLowerCase())
          ),
        ]
      : [...colabReportData];
  }
  //This is being used for local storage loading and setting the required params for api call.
  useEffect(() => {
    const lastOpenedReports = getReportsInLocalStorage();
    if (lastOpenedReports) {
      const reportIds: string[] = [];
      if (lastOpenedReports) {
        Object.entries(lastOpenedReports).forEach((report: [string, ReportData]) => {
          if (!report[1] || !report[1].id) return;
          reportIds.push(report[1].id);
        });
        //Set each report_ids separately in url search param
        const params = new URLSearchParams();
        reportIds.forEach((reportId) => params.append("report_ids", reportId));
        params.append("user_id", decoded["oid"]);
        setColabState({ ...colabState, colabQuery: params.toString() });
      }
    }
  }, [localStorage]);

  const _renderItemColumn = (
    item?: ReportMetricsDisplayModel,
    _index?: number | undefined,
    column?: IColumn | undefined
  ) => {
    switch (column?.key) {
      case "name":
        return <Link href={item?.link}>{item?.name}</Link>;
      case "number_of_remarks":
        return (
          <span
            data-selection-disabled={true}
            className={mergeStyles({ height: "100%", display: "block" })}
          >
            {item?.number_of_remarks}
          </span>
        );
      case "number_of_comments":
        return (
          <span
            data-selection-disabled={true}
            className={mergeStyles({ height: "100%", display: "block" })}
          >
            {item?.number_of_comments}
          </span>
        );
      case "tagged_users":
        let taggedUserNames = "";
        item?.tagged_users.forEach((user, index) => {
          taggedUserNames =
            index === 0 ? `${user.displayName}` : `${taggedUserNames}, ${user.displayName}`;
        });

        return (
          <span
            data-selection-disabled={true}
            className={mergeStyles({ height: "100%", display: "block" })}
          >
            {taggedUserNames}
          </span>
        );

      case "last_access":
        return (
          <span
            data-selection-disabled={true}
            className={mergeStyles({ color: "grey", height: "100%", display: "block" })}
          >
            {item?.last_access}
          </span>
        );

      default:
        return <span>{item?.name}</span>;
    }
  };

  return (
    <section>
      <Stack styles={{ root: { padding: 20 } }}>
        <h2>Analytics suite</h2>
        <>
          <Stack.Item>
            <TextField
              label={t("colab_dashboard_heading")}
              placeholder="https://app.powerbi.com/reportEmbed?reportId=:reportId"
              value={colabState.powerBILink}
              onChange={(_e, newValue) => {
                setColabState({ ...colabState, powerBILink: newValue ? newValue : "" });
              }}
            />
            {colabState.erorMessages.length > 0 && (
              <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                {colabState.erorMessages.map((m) => t(m))}
              </MessageBar>
            )}
          </Stack.Item>
          <Stack.Item style={{ marginTop: 10 }}>
            <PrimaryButton
              text={t("open_report")}
              onClick={() => {
                // validate pbi link
                // https://app.powerbi.com/groups/334da4cf-1810-4eb6-86b4-1fea5bff6bb5/reports/2dd39b8a-c500-4b50-83e8-8606c4e5e65a/ReportSectiondecccb8482fa6cf4f797
                // https://app.powerbi.com/reportEmbed?reportId=2dd39b8a-c500-4b50-83e8-8606c4e5e65a&autoAuth=true&ctid=e55c9d18-974f-4355-bb6f-15889d061070
                const powerBILink = getPowerBIReportLink(colabState.powerBILink);
                if (powerBILink) {
                  navigate(powerBILink);
                } else {
                  setColabState({ ...colabState, erorMessages: ["wrong-powerbi-link"] });
                }
              }}
            />
          </Stack.Item>
        </>
        <Separator />
        <Stack>
          <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="space-between">
            <Stack.Item>
              <h3>{t("last_accessed_dashboard")}</h3>
            </Stack.Item>
            <Stack.Item align="end">
              <TextField
                name="search"
                value={colabState.searchTerm}
                placeholder={t("search_reports")}
                iconProps={searchIcon}
                styles={searchBoxStyles}
                onChange={(e: any) => setColabState({ ...colabState, searchTerm: e.target.value })}
              />
            </Stack.Item>
          </Stack>
          {colabDisplayData.length > 0 ? (
            <Stack.Item>
              <DetailsList
                items={colabDisplayData.sort((a, b) => b.date - a.date)}
                columns={[
                  {
                    key: "name",
                    name: "Dashboard name",
                    fieldName: "name",
                    minWidth: 100,
                    isResizable: true,
                  },
                  {
                    key: "number_of_remarks",
                    name: "Number of Remarks",
                    fieldName: "number_of_remarks",
                    minWidth: 300,
                    isResizable: false,
                  },
                  {
                    key: "number_of_comments",
                    name: "Number of Comments",
                    fieldName: "number_of_comments",
                    minWidth: 300,
                    isResizable: false,
                  },
                  {
                    key: "tagged_users",
                    name: "Tagged Users",
                    fieldName: "tagged_users",
                    minWidth: 400,
                    isResizable: true,
                  },
                  {
                    key: "last_access",
                    name: "Last accessed",
                    fieldName: "last_access",
                    minWidth: 100,
                    maxWidth: 200,
                    isResizable: true,
                  },
                ]}
                onRenderItemColumn={_renderItemColumn}
                selectionMode={SelectionMode.none}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
              />
            </Stack.Item>
          ) : (
            <></>
          )}
        </Stack>
      </Stack>
    </section>
  );
};
