import { Stack, IconButton, Text } from "@fluentui/react";
import { dqcTheme } from "../../helpers/commonStyles";

export type CommentIconProps = {
  count: number;
  toggleShowComments: () => void;
};

export const CommentIcon = ({ count, toggleShowComments }: CommentIconProps) => {
  return (
    <Stack horizontal horizontalAlign="center" verticalAlign="center">
      <IconButton
        iconProps={{ iconName: "Comment" }}
        styles={{
          icon: { color: dqcTheme.palette.neutralPrimary },
        }}
        ariaLabel="Comment"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleShowComments();
        }}
      />
      <Stack style={{ minWidth: 16 }}>{count ? <Text>{count}</Text> : null}</Stack>
    </Stack>
  );
};
