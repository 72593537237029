import { Stack, Icon, IconButton, DefaultButton, Text, Link } from "@fluentui/react";
import { t } from "i18next";
import Dropzone from "react-dropzone";
import { doRulesAnalysis, endAnalysisAndClearEverything } from "../../store/slices/quickAnalysis";
import { loadFile, selectLatestUpload } from "../../store/slices/upload";
import { DataGrid } from "./datagrid/DataGrid";
import { useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { UploadDataContainer } from "../../types/fileUploader";
import { addNotification } from "../../store/slices/notificationCenter";
import { uuid4 } from "@sentry/utils";
import { dataGridPreviewStyle, mainStackStyle } from "./FileDragAndDrop.styles";

export const FileDragAndDrop = () => {
  const inputElement = useRef<HTMLInputElement>(null);
  const uploadContainer: UploadDataContainer | undefined = useAppSelector(selectLatestUpload);

  const dispatch = useAppDispatch();
  const handleFileChange = async (filesList?: File[]) => {
    const file = filesList?.[0];
    if (!file) return;
    await dispatch(loadFile(file));
  };
  useEffect(() => {
    if (uploadContainer?.error) {
      dispatch(
        addNotification({
          id: uuid4().toString(),
          bodyText: t("upload_failed_body"),
          headerText: t("upload_failed_header"),
          type: "error",
        })
      );
    }
  }, [uploadContainer]);

  return (
    <Dropzone onDrop={handleFileChange}>
      {({ getRootProps, getInputProps }) => (
        <Stack
          tokens={{ childrenGap: 20 }}
          styles={mainStackStyle}
          {...getRootProps({
            onClick: (event) => event.stopPropagation(),
            "aria-label": "drag and drop area",
          })}
        >
          <input {...getInputProps()} ref={inputElement} accept=".csv, .xlsx" />
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Icon iconName="wandIcon" />
            <Text styles={{ root: { fontWeight: 600, fontSize: 18 } }}>{t("load_file")}</Text>
          </Stack>
          <Text>
            <Link
              underline
              styles={{ root: { color: "blue" } }}
              onClick={async () => {
                inputElement.current?.click();
              }}
            >
              {t("click_here")}
            </Link>{" "}
            {t("drag_n_drop")}
          </Text>
          <Text>{t("expected_format")}</Text>
          <DataGrid styles={dataGridPreviewStyle} />
          <Text>{t("file_requirements")}</Text>
          <Stack
            horizontal
            horizontalAlign={uploadContainer ? "space-between" : "end"}
            verticalAlign="center"
          >
            {uploadContainer && (
              <>
                <Stack horizontal verticalAlign="center">
                  <Text>{uploadContainer.fileName}</Text>
                  <IconButton
                    onClick={() => {
                      dispatch(endAnalysisAndClearEverything());
                    }}
                    iconProps={{ iconName: "cancel" }}
                  />
                </Stack>
              </>
            )}
            <DefaultButton
              text={t("analyze")}
              iconProps={{ iconName: "highlightedAnalyzeIcon" }}
              disabled={!uploadContainer}
              onClick={() => {
                uploadContainer && dispatch(doRulesAnalysis(uploadContainer));
              }}
            />
          </Stack>
        </Stack>
      )}
    </Dropzone>
  );
};
