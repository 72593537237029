import { ConfigurationContent } from "../../types/tile";
import { TileData } from "../../types/tile";
import { Label, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import {
  backgroundStyles,
  centerDiv,
  innerStackTokens,
  stackStyle,
  topHeading,
} from "../../helpers/commonStyles";
import { useGetAllConfigurationsQuery, useGetInstallationsQuery } from "../../api/installation";

import { CommonModal } from "../../components/common/CommonModal";
import { TopContainer } from "../../components/common/TopContainer";
import { useTranslation } from "react-i18next";
import { TileRoot } from "../../components/common/TileRoot";

export const Plugins: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [tileId, setTileId] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [data, setData] = useState<TileData[]>([]);
  const [modalContent, setModalContent] = useState("");
  const [configData, setConfigData] = useState<ConfigurationContent[]>([]);
  const installationData = useGetInstallationsQuery();
  const allConfigurations = useGetAllConfigurationsQuery("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!installationData.isLoading && installationData.isSuccess) {
      setData(installationData.data.plugins.data);
    }
    if (!allConfigurations.isLoading && allConfigurations.isSuccess) {
      setConfigData(allConfigurations.data.plugins);
      setLoader(false);
    }
  }, [installationData, allConfigurations]);

  const handlePrimaryButtonClick = (id: number, title: string, modal_content: string) => {
    setTileId(id);
    setModalHeading(title);
    setModalContent(modal_content);
    setModal(true);
  };

  return (
    <TopContainer title={t("manage_plugins")}>
      {modalContent ? (
        <CommonModal
          id={tileId}
          title={modalHeading}
          modalState={modal}
          body={modalContent}
          primaryButtonText={t("upload_button_done")}
          secondaryButtonText={t("cancel")}
          secondaryButtonOnClick={(): void => {
            setModal(false);
          }}
        />
      ) : null}
      <main>
        <Label styles={topHeading}>{t("plugin_label")}</Label>
        <Stack styles={backgroundStyles}>
          <Stack horizontal wrap tokens={innerStackTokens} styles={stackStyle}>
            {data && data.length !== 0 ? (
              data.map((item: TileData, index) => (
                <div key={index}>
                  <TileRoot
                    type={"Plugins"}
                    item={item}
                    loader={loader}
                    icon={item.attributes.icon}
                    configData={configData.some((config) => config.bf_id === item.id)}
                    primaryButtonText={t("activate_plugin")}
                    primaryButtonOnClick={() =>
                      handlePrimaryButtonClick(
                        item.id,
                        item.attributes.title,
                        item.attributes.modal_content
                      )
                    }
                    secondaryButtonText={t("learn_more")}
                    secondaryButtonOnClick={() => window.open(item.attributes.learn_more_link)}
                  />
                </div>
              ))
            ) : loader ? (
              <div style={centerDiv}>
                <Spinner size={SpinnerSize.large} />
              </div>
            ) : null}
          </Stack>
        </Stack>
      </main>
    </TopContainer>
  );
};
