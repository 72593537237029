import { IStackStyles, Label, Stack, Icon, IIconStyles } from "@fluentui/react";
import * as React from "react";
import { CSSProperties } from "react";
import { Responsible } from "../../../types/responsibles";

const editLabelContainer: IStackStyles = {
  root: {
    width: "40%",
  },
};
const spanStyle: CSSProperties = {
  width: "auto",
  backgroundColor: "rgb(205 201 201)",
  padding: "6px",
  borderRadius: "25px",
  fontSize: "13px",
};

const chipCloseIconStyle: Partial<IIconStyles> = {
  root: {
    fontSize: 8,
    cursor: "pointer",
    color: "white",
    backgroundColor: "#8c8989",
    borderRadius: "16px",
    padding: "5px",
  },
};
type TaskModalProfileSuggestionsProps = {
  selectedResponsible: Responsible[];
  onChipClose(id: string): void;
};
export const TaskModalProfileSuggestions: React.FunctionComponent<
  TaskModalProfileSuggestionsProps
> = ({ selectedResponsible, onChipClose }) => {
  return (
    <Stack horizontal>
      <Stack.Item styles={editLabelContainer}>
        <Label></Label>
      </Stack.Item>
      <Stack horizontal wrap tokens={{ childrenGap: 10, padding: 10 }}>
        {selectedResponsible.map((item, index) => (
          <span key={index} style={spanStyle}>
            {item.name}{" "}
            <Icon
              iconName="ChromeClose"
              styles={chipCloseIconStyle}
              onClick={() => onChipClose(item.id)}
              aria-label="Close"
            />
          </span>
        ))}
      </Stack>
    </Stack>
  );
};
