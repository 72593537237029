import { FunctionComponent, useEffect, useState } from "react";
import { IStackStyles, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { getPollingInterval, setPollingInterval } from "../../../store/slices/qualitySummarySlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useGetMissionByIdQuery, useGetQualitySummaryByMissionIdQuery } from "../../../api/mission";

import { AddDatasetModal } from "./AddDatasetModal";
import { ConfigureModal } from "./ConfigureModal";
import { Dataset } from "../../../types/dataset";
import { DatasetHeader } from "./DatasetHeader";
import { DatasetItem } from "./DatasetItem";
import { DatasetSubHeader } from "./DatasetSubHeader";
import { deselectDatasets } from "../../../store/slices/missionSlice";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useParams } from "react-router-dom";

const innerMainContentBoxStyles: IStackStyles = {
  root: {
    height: "310px",
    overflowY: "auto",
    border: "1px solid #cec6c6",
    borderRadius: "3px",
    padding: "5px",
  },
};

export const DatasetSection: FunctionComponent = () => {
  const params = useParams();

  const [modalState, setModalState] = useState(false);
  const pollingInterval = useAppSelector(getPollingInterval);
  const dispatch = useAppDispatch();
  const quality = useGetQualitySummaryByMissionIdQuery(params.id!, {
    pollingInterval: pollingInterval,
  });
  const mission = useGetMissionByIdQuery(params.id ?? skipToken);
  const [openModal, setOpenModal] = useState(false);
  const [dataset, setDataset] = useState<Dataset>();

  useEffect(() => {
    if (quality.currentData?.status !== "done") {
      dispatch(setPollingInterval(1000));
    } else {
      dispatch(setPollingInterval(0));
    }
    return function cleanup() {
      dispatch(deselectDatasets());
    };
  }, [dispatch, quality]);

  return (
    <>
      {modalState ? (
        <AddDatasetModal
          modalState={modalState}
          secondaryButtonOnClick={() => {
            setModalState(false);
          }}
          mission={mission.data}
        />
      ) : null}
      {openModal ? (
        <ConfigureModal
          openCloseModal={openModal}
          mission={mission.data}
          dataset={dataset}
          secondaryButtonOnClick={() => {
            setOpenModal(false);
          }}
        />
      ) : null}
      <DatasetHeader setModalState={setModalState}></DatasetHeader>
      <Stack styles={innerMainContentBoxStyles}>
        <DatasetSubHeader
          qualityStatus={
            quality.isLoading || quality.isFetching ? "loading_data" : quality.data?.status
          }
          combinedQuality={quality.data?.combined_quality}
          missionTitle={mission.data?.title}
        ></DatasetSubHeader>
        {mission.isLoading || !mission.data ? (
          <Spinner size={SpinnerSize.large} style={{ margin: "80px" }} />
        ) : (
          <>
            {mission.data?.datasets.map((dataset) => {
              return (
                <DatasetItem
                  dataset={dataset}
                  mission={mission.data!}
                  quality={quality.data!}
                  setOpenModal={setOpenModal}
                  setDataset={setDataset}
                  key={dataset.id}
                  qualityStatus={
                    quality.isLoading || quality.isFetching || mission.isLoading
                      ? "loading_data"
                      : quality.data?.status
                  }
                ></DatasetItem>
              );
            })}
          </>
        )}
      </Stack>
    </>
  );
};
