import { FunctionComponent, MouseEvent, useEffect } from "react";
import useState from "react-usestateref";
import { useTranslation } from "react-i18next";
import { hostStyles } from "../../helpers/commonStyles";
import { copyAndSort, filterColumns } from "../../helpers/utils";
import { Colleague } from "../../types/colleague";
import { useBoolean } from "@fluentui/react-hooks";
import { useDeleteMemberMutation } from "../../api/companyAndGroups";
import { Group } from "../../types/group";
import { ConfirmationDialogProps } from "../../types/missions";
import {
  DetailsList,
  DetailsListLayoutMode,
  DirectionalHint,
  IColumn,
  Icon,
  IIconStyles,
  SelectionMode,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";

const iconStyle: Partial<IIconStyles> = {
  root: {
    fontSize: 16,
    marginRight: "10px",
    color: "#000000",
    cursor: "pointer",
  },
};

const defaultMemberData: Colleague = {
  id: "",
  identityId: "",
  displayName: "",
  firstName: "",
  lastName: "",
  email: "",
};

export interface GroupListProps {
  group: Group;
  setConfirmationDialogProps(confirmationDialogProps: ConfirmationDialogProps): void;
}

export const GroupList: FunctionComponent<GroupListProps> = ({
  group,
  setConfirmationDialogProps,
}) => {
  const { t } = useTranslation();
  const [allMembers, setAllMembers, allMembersRef] = useState<Colleague[]>(group.members);
  const [hideDeleteMemberDialog, { toggle: toggleHideDeleteMemberDialog }] = useBoolean(true);
  const [memberData, setMemberData] = useState<Colleague>(defaultMemberData);
  const [deleteMemberReq] = useDeleteMemberMutation();

  useEffect(() => {
    setAllMembers(group.members);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  useEffect(() => {
    setConfirmationDialogProps({
      showHideDialog: hideDeleteMemberDialog,
      toggleShowHideDialog: toggleHideDeleteMemberDialog,
      primaryButtonText: t("delete"),
      secondaryButtonText: t("cancel"),
      primaryButtonClicked: () => deleteMemberfunc(memberData.id),
      dialogTitle: t("member_delete_title") + ` ${group.name}`,
      confirmationText: t("member_delete_confirmation") + ` ${memberData.displayName}?`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideDeleteMemberDialog, memberData]);

  const deleteMemberfunc = async (id: string) => {
    const deleteMember = await deleteMemberReq({ groupId: group.id, id: id });
    if (deleteMember) {
      toggleHideDeleteMemberDialog();
    }
  };

  const columnDataArray = [
    { name: "Name", fieldName: "name" },
    { name: "E-mails", fieldName: "e-mails" },
    { name: "Action", fieldName: "action" },
  ];

  const onColumnSortClick = (ev: MouseEvent<HTMLElement>, column: IColumn): void => {
    const { newColumns, currColumn } = filterColumns(columns, column);

    const newItems: Colleague[] = copyAndSort(
      allMembersRef.current,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    setColumns(newColumns);
    setAllMembers(newItems);
  };

  const tempColumns: IColumn[] = [];
  columnDataArray.forEach((columnData, index) => {
    tempColumns.push({
      key: `column${index}`,
      name: columnData.name,
      fieldName: columnData.fieldName,
      minWidth: 350,
      maxWidth: 400,
      isRowHeader: index in [0] ? true : false,
      onColumnClick: onColumnSortClick,
      onRender: (item: Colleague) => {
        switch (index) {
          case 0:
            return <>{item.displayName}</>;
          case 1:
            return <>{item.email}</>;
          case 2:
            return (
              <>
                <TooltipHost
                  content={t("delete")}
                  delay={TooltipDelay.zero}
                  directionalHint={DirectionalHint.bottomCenter}
                  styles={hostStyles}
                >
                  <Icon
                    styles={iconStyle}
                    iconName="Delete"
                    onClick={() => {
                      setMemberData({ ...memberData, id: item.id, displayName: item.displayName });
                      toggleHideDeleteMemberDialog();
                    }}
                  />
                </TooltipHost>
              </>
            );
        }
      },
    });
  });

  const [columns, setColumns] = useState<IColumn[]>(tempColumns);

  return (
    <>
      <DetailsList
        items={allMembers}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
      />
    </>
  );
};
