import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { QuickAnalysisIssue } from "../../types/quickAnalysis";
import { RootState } from "../store";
import { CellClassParams, CellStyleFunc, ColDef } from "ag-grid-community";
import {
  DQCTableData,
  DQCTableValue,
  UploadDQCDataType,
  UploadDataContainer,
} from "../../types/fileUploader";
import { getDataContainerFromWorker } from "../../getDataContainerFromWorker";
import { selectHighlightedIssue } from "./issueNavigator";
import { CellStyle, RowStyle, getCellStyle } from "../../helpers/quickAnalysis/getCellStyle";
import { isCSVFile, isXLSXFile } from "../../utils/file";

const uploadEntityAdapter = createEntityAdapter<UploadDataContainer>({
  selectId: (upload) => upload.fileName,
});

export const loadFile = createAsyncThunk<UploadDataContainer, File>(
  "upload/loadFile",
  async (file) => {
    if (!isCSVFile(file) && !isXLSXFile(file)) throw new Error("Wrong file format");
    const dataContainer = await getDataContainerFromWorker({
      file,
    });
    return dataContainer;
  }
);

export type UpdatedColumn = {
  dataType: UploadDQCDataType["type"];
  index: number;
  name: string;
  dataTypeFrequency: number;
  isCategoricalData: boolean;
};

type UpdateColumnsPayload = {
  fileName: string;
  columns: UpdatedColumn[];
};

export const uploadSlice = createSlice({
  name: "upload",
  initialState: uploadEntityAdapter.getInitialState(),
  reducers: {
    clearAllFiles: (state) => {
      uploadEntityAdapter.removeAll(state);
    },
    updateColumnsDataType: (state, action: PayloadAction<UpdateColumnsPayload>) => {
      const { fileName, columns } = action.payload;
      uploadEntityAdapter.updateOne(state, {
        id: fileName,
        changes: { columns },
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadFile.fulfilled, (state, { payload }) => {
      uploadEntityAdapter.setOne(state, payload);
    });
  },
});
export const { selectById: selectUploadByFileName } = uploadEntityAdapter.getSelectors();
export const { clearAllFiles, updateColumnsDataType } = uploadSlice.actions;

const selectUploadeState = (state: RootState) => state.upload;
export const selectLatestUpload = createSelector(
  selectUploadeState,
  (uploadSlice): UploadDataContainer | undefined => {
    if (uploadSlice.ids.length === 0) return undefined;
    const latestId = uploadSlice.ids[uploadSlice.ids.length - 1];
    return uploadSlice.entities[latestId];
  }
);

const selectQuickAnalysisSlice = (state: RootState) => state.quickAnalysis;
const selectActiveFilter = (state: RootState) => state.quickAnalysis.activeFilter;
export const selectColumnDefsFromLatestUpload = createSelector(
  selectLatestUpload,
  selectActiveFilter,
  selectHighlightedIssue,
  (
    uploadContainer,
    activeFilter,
    highlightedIssue
  ): ColDef<DQCTableData, DQCTableValue>[] | undefined => {
    if (!uploadContainer) return undefined;

    const cellStyle = getCellStyleFunction(activeFilter, highlightedIssue);
    return uploadContainer.columns.map(({ name, index }) => ({
      headerName: name,
      field: `${index}.value`,
      filter: true,
      cellStyle,
      suppressMovable: true,
      width: 120,
      resizable: true,
      cellDataType: "text",
    }));
  }
);

const getCellStyleFunction = (activeFilter?: string, selectedIssue?: QuickAnalysisIssue) => {
  const cellStyle: CellStyleFunc<DQCTableData, DQCTableValue> = ({
    colDef,
    data,
  }: CellClassParams): CellStyle | RowStyle => {
    const columnIndexString = colDef.field?.split(".")[0] || "";
    const columnIndex = parseInt(columnIndexString);
    if (!data[columnIndex]?.issues?.length) return null;
    const issues = data[columnIndex]?.issues;
    return getCellStyle(issues, activeFilter, selectedIssue);
  };
  return cellStyle;
};

export const selectRowsFromLatestUpload = createSelector(
  selectLatestUpload,
  selectQuickAnalysisSlice,
  (uploadContainer, { issues }): Record<number, DQCTableValue>[] | undefined => {
    const issuesByRowColumnIndex: Record<string, QuickAnalysisIssue[]> = {};
    issues.forEach((issue) => {
      const issueColumns = issue.type === "duplicate" ? issue.columns : [issue.column];
      for (let column of issueColumns) {
        const issueId = getIssueRowColumnIndex(issue.row, column);
        if (issuesByRowColumnIndex[issueId]) issuesByRowColumnIndex[issueId].push(issue);
        else issuesByRowColumnIndex[issueId] = [issue];
      }
    });

    if (!uploadContainer) return undefined;
    return uploadContainer.data.map((row, rowIndex) =>
      row.reduce((rowMap, cell, columnIndex) => {
        const rowColumnIndex = getIssueRowColumnIndex(rowIndex, columnIndex);
        const issues = issuesByRowColumnIndex[rowColumnIndex];
        rowMap[columnIndex] = { value: cell.value, issues };
        return rowMap;
      }, {} as Record<number, DQCTableValue>)
    );
  }
);

const getIssueRowColumnIndex = (row: number, column = 0): string => {
  return `${row}::${column}`;
};
