import React, { createElement } from "react";
import { ReactComponent as valueNA } from "../../assets/value-NA.svg";
import { ReactComponent as valueLow } from "../../assets/value-low.svg";
import { ReactComponent as valueMedium } from "../../assets/value-medium.svg";
import { ReactComponent as valueHigh } from "../../assets/value-high.svg";

export interface ValueProps {
  score: number;
  width?: string;
  height?: string;
}
const valueIconStyle: React.CSSProperties = {
  marginRight: 10,
  marginLeft: 10,
  cursor: "pointer",
};
export const ValueIcon: React.FunctionComponent<ValueProps> = ({
  score = 1,
  width = "100%",
  height = "12",
}) => {
  const ValueVGs = [valueNA, valueLow, valueMedium, valueHigh].map((val) =>
    createElement(val, { width: `${width}`, height: `${height}px` })
  );
  return <div style={valueIconStyle}>{ValueVGs[score - 1]}</div>;
};
