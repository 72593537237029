import { UploadDataContainer } from "./types/fileUploader";
import { QuickAnalysisIssue } from "./types/quickAnalysis";
import { RuleCandidate } from "./types/rules";

export const getRuleCandidatesFromWorker = (
  dataContainer: UploadDataContainer
): Promise<RuleCandidate[]> => {
  const loadedFilePromise = new Promise<RuleCandidate[]>((resolve, reject) => {
    const worker = new Worker(new URL("./predictRulesWorker.ts", import.meta.url));
    worker.onmessage = (e: MessageEvent<RuleCandidate[]>) => {
      if (!e.data) {
        reject("getRuleCandidatesFromWorker failed unexpectedly");
      }
      resolve(e.data);
    };
    worker.postMessage(dataContainer);
  });
  return loadedFilePromise;
};

export const getIssuesFromWorker = (
  dataContainer: UploadDataContainer,
  rules: RuleCandidate[]
): Promise<QuickAnalysisIssue[]> => {
  const loadedFilePromise = new Promise<QuickAnalysisIssue[]>((resolve, reject) => {
    const worker = new Worker(new URL("./testRulesWorker.ts", import.meta.url));
    worker.onmessage = (e: MessageEvent<QuickAnalysisIssue[]>) => {
      if (!e.data) {
        reject("getIssuesFromWorker failed unexpectedly");
      }
      resolve(e.data);
    };
    worker.postMessage({
      dataContainer,
      rules,
    });
  });
  return loadedFilePromise;
};
