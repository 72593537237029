import { CSSProperties, FunctionComponent } from "react";
import { ILabelStyles, IStackItemStyles, Label, Stack } from "@fluentui/react";

import { MissionData } from "../../../types/missions";
import { MissionQuality } from "../../common/MissionQualityBadge";
import moment from "moment";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useGetQualitySummaryByMissionIdQuery } from "../../../api/mission";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const qualityContainer: IStackItemStyles = {
  root: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "center",
  },
};

const qualityDiv: CSSProperties = {
  border: "1px solid #ccc",
  borderRadius: "0.4rem",
  background: "#f0f0f0",
  flex: "0 0 15vw",
  padding: "15px 10px",
};

const qualityLabelHeading: ILabelStyles = {
  root: {
    fontWeight: 700,
    fontSize: "16px",
  },
};

export interface MissionQualityBriefState {
  mission?: MissionData;
}

export const MissionQualityBrief: FunctionComponent<MissionQualityBriefState> = ({ mission }) => {
  const { t } = useTranslation();
  const params = useParams();
  const qualitySummaryRequest = useGetQualitySummaryByMissionIdQuery(params.id ?? skipToken);
  return (
    <Stack.Item grow={1} styles={qualityContainer}>
      <div style={qualityDiv}>
        <Label styles={qualityLabelHeading}>{t("quality")}</Label>
        <MissionQuality
          value={qualitySummaryRequest.data?.combined_quality}
          status={
            qualitySummaryRequest.isLoading || qualitySummaryRequest.isFetching
              ? "loading_data"
              : qualitySummaryRequest?.data?.status
          }
        />
        <p className="quality-text"></p>
        <Label>
          {t("quality_summary_created_at")}{" "}
          {moment(moment.utc(mission?.created_at)).local().format("DD/MM/YYYY - HH:mm:ss")}
        </Label>
      </div>
    </Stack.Item>
  );
};
