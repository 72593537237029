import { DefaultButton, Icon, MessageBar, PrimaryButton, Stack, Text } from "@fluentui/react";
import { NotificationPayload, removeNotification } from "../../store/slices/notificationCenter";
import {
  getMessageBarType,
  headerTextStyles,
  notificationButtonStyles,
} from "./notificationStyles";
import { useAppDispatch } from "../../store/hooks";
import { useEffect } from "react";
import { t } from "i18next";
import { dqcPalette } from "../../utils/colors";

type NotificationProps = { notification: NotificationPayload };

export const NotificationCard: React.FC<NotificationProps> = ({ notification }) => {
  const {
    type,
    headerText,
    bodyText,
    id,
    primaryButtonText,
    primaryButtonOnClick,
    secondaryButtonText,
    secondaryButtonOnClick,
    autoHideAfterSeconds,
  } = notification;
  const dispatch = useAppDispatch();
  const hasButtons = primaryButtonText || secondaryButtonText;
  useEffect(() => {
    if (autoHideAfterSeconds === undefined) return;
    const autoHideAfterMiliSeconds = autoHideAfterSeconds * 1000;
    const completeInterval = setInterval(() => {
      dispatch(removeNotification(id));
    }, autoHideAfterMiliSeconds);
    return () => clearInterval(completeInterval);
  }, []);

  return (
    <Stack
      styles={{
        root: {
          width: 220,
          backgroundColor: "white",
          boxShadow: `0px 0px 4px 0px ${dqcPalette.grey}`,
        },
      }}
    >
      <Stack styles={{ root: { margin: 8 } }} tokens={{ childrenGap: 4 }}>
        <Stack horizontal styles={{ root: { width: "100%", justifyContent: "space-between" } }}>
          <Stack styles={{ root: { textAlign: "center", width: "100%" } }}>
            <Text styles={headerTextStyles}>{t(headerText)}</Text>
          </Stack>
          <Icon
            iconName="ChromeClose"
            styles={{ root: { fontSize: 8, cursor: "pointer" } }}
            onClick={() => dispatch(removeNotification(id))}
          />
        </Stack>
        <MessageBar messageBarType={getMessageBarType(type)}>
          <Stack>{t(bodyText)}</Stack>
        </MessageBar>
        {hasButtons && (
          <Stack
            horizontal
            styles={{ root: { height: 20, justifyContent: "space-evenly" } }}
            tokens={{ childrenGap: 4 }}
          >
            {primaryButtonText && (
              <PrimaryButton
                text={primaryButtonText}
                onClick={() => primaryButtonOnClick && primaryButtonOnClick()}
                styles={notificationButtonStyles}
              />
            )}
            {secondaryButtonText && (
              <DefaultButton
                text={secondaryButtonText}
                onClick={() => secondaryButtonOnClick && secondaryButtonOnClick()}
                styles={notificationButtonStyles}
              />
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
