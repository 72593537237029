import { CSSProperties, FunctionComponent } from "react";
import { MissionData } from "../../../types/missions";
import { api_base } from "../../../api/dqcREST";
import {
  Icon,
  IIconStyles,
  ILabelStyles,
  ITextFieldStyles,
  Label,
  Stack,
  TextField,
} from "@fluentui/react";

const missionIconStyle: Partial<IIconStyles> = {
  root: {
    fontSize: 16,
    marginRight: "2%",
    color: "#000000",
    cursor: "pointer",
  },
};

const headerDiv: CSSProperties = {
  display: "flex",
  flexDirection: "column",
};

const headerLine: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const missionHeading: ILabelStyles = {
  root: {
    fontWeight: 700,
    fontSize: "18px",
    marginRight: "2%",
  },
};

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: {
    width: "45%",
  },
};

const missionTextField: Partial<ITextFieldStyles> = {
  root: {
    width: "45%",
    margin: "1% 2% 2% 0%",
  },
};

export interface MissionBriefState {
  isEditing: boolean;
  mission?: MissionData;
  setMission(mission: MissionData): void;
  updateMissionFunc(): void;
  toggleHideDeleteMissionDialog(): void;
}

export const MissionBrief: FunctionComponent<MissionBriefState> = ({
  isEditing,
  mission,
  setMission,
  updateMissionFunc,
  toggleHideDeleteMissionDialog,
}) => {
  const getKPIs = () => {
    window.open(`${api_base}/quality_summary/${mission?.id}/kpi-data/xlsx`, "_blank")!.focus();
  };

  return (
    <Stack.Item grow={1}>
      <div style={headerDiv}>
        <div style={headerLine}>
          {!isEditing ? (
            <Label styles={missionHeading}>{mission?.title}</Label>
          ) : (
            <>
              <TextField
                value={mission?.title}
                onChange={(e: any) => {
                  if (mission) {
                    setMission({ ...mission, title: e.target.value });
                  }
                }}
                styles={missionTextField}
              />
            </>
          )}
          <Icon
            styles={missionIconStyle}
            iconName="Edit"
            onClick={() => updateMissionFunc()}
            aria-label="Edit"
          />
          <Icon
            styles={missionIconStyle}
            iconName="Delete"
            onClick={toggleHideDeleteMissionDialog}
            aria-label="Delete"
          />
          <Icon
            styles={missionIconStyle}
            iconName="ExcelLogo16"
            onClick={() => getKPIs()}
            aria-label="getKPIs"
          />
        </div>
        {!isEditing ? (
          <Label>{mission?.description}</Label>
        ) : (
          <TextField
            value={mission?.description}
            onChange={(e: any) => {
              if (mission) setMission({ ...mission, description: e.target.value });
            }}
            styles={textFieldStyles}
          />
        )}
      </div>
    </Stack.Item>
  );
};
