import React, { memo, useCallback, useState } from "react";
import { IStackStyles, Stack } from "@fluentui/react";
import { DataAsset, UpdatedDataAssetList } from "../../types/dataasset";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../../types/item-types";
import { useUpdateDataAssetsWithCustomOrderMutation } from "../../api/cockpit";
import { AssetListWrapper } from "./AssetListWrapper";

const mainContainer: IStackStyles = {
  root: {
    border: "0.5px solid #605E5C",
    borderRadius: 3,
    marginLeft: 10,
  },
};

export interface AssetListProps {
  dataAssets: DataAsset[];
  isDragAndDrop?: boolean;
}

export const AssetList: React.FunctionComponent<AssetListProps> = memo(
  ({ dataAssets, isDragAndDrop = true }) => {
    const [updateDataAssetsWithCustomOrder] = useUpdateDataAssetsWithCustomOrderMutation();
    let sortedAndFilteredAssets = [...dataAssets];
    const [listItems, setListItems] = useState<DataAsset[]>([]);

    if (listItems.length > 0) {
      sortedAndFilteredAssets = [...listItems];
    }

    const updateDataAssets = async (data: UpdatedDataAssetList[]) => {
      await updateDataAssetsWithCustomOrder([
        {
          op: "modify",
          props: { update_custom_order: true },
          assets_custom_order: data,
        },
      ]).unwrap();
    };

    const dropCard = () => {
      const data: UpdatedDataAssetList[] = sortedAndFilteredAssets.map((item, index) => {
        return { id: item.id, custom_order: index + 1 };
      });
      updateDataAssets(data);
    };

    const findCard = useCallback(
      (id: string) => {
        const card = sortedAndFilteredAssets.find((item: DataAsset) => item.id === id) as DataAsset;
        return {
          card,
          index: sortedAndFilteredAssets.indexOf(card),
        };
      },
      [sortedAndFilteredAssets]
    );

    const moveCard = useCallback(
      (id: string, atIndex: number) => {
        const { card, index } = findCard(id);
        const copyListItems = [...sortedAndFilteredAssets];
        copyListItems.splice(index ? index : 0, 1);
        copyListItems.splice(atIndex ? atIndex : 0, 0, card);
        setListItems(copyListItems);
      },
      [findCard, sortedAndFilteredAssets]
    );

    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));

    return (
      <div ref={drop}>
        <Stack styles={sortedAndFilteredAssets.length > 0 ? mainContainer : undefined}>
          {sortedAndFilteredAssets.map((data, index) => (
            <AssetListWrapper
              key={data.id}
              data={data}
              index={index}
              assetsLength={sortedAndFilteredAssets.length}
              id={data.id}
              moveCard={moveCard}
              findCard={findCard}
              dropCard={dropCard}
              isDragAndDrop={isDragAndDrop}
            />
          ))}
        </Stack>
      </div>
    );
  }
);
