import { UploadDataContainer } from "../../../types/fileUploader";
import { QuickAnalysisIssue } from "../../../types/quickAnalysis";
import { RuleCandidate, TestFunctionName } from "../../../types/rules";
import { columnCorrelation } from "./columnCorrelation";
import { iqrOutlier } from "./iqrOutlier";
import { duplicates } from "./duplicates";
import { inconsistentDataType } from "./inconsistentDataType";
import { sensitiveTest } from "./sensitiveTest";
import { stdDevOutlier } from "./stdDevOutlier";
import { strPatterns } from "./strPatterns";
import { missingValues } from "./missingValues";
import { columnMatching } from "./columnMatching";
import { StrPatternRuleDetailBody } from "../../../components/quick-analysis/rules/detail-bodies/StrPatternRuleDetailBody";
import { DuplicateRuleDetailBody } from "../../../components/quick-analysis/rules/detail-bodies/DuplicateRuleDetailBody";
import { MissingRuleDetailBody } from "../../../components/quick-analysis/rules/detail-bodies/MissingRuleDetailBody";
import { ColumnMatchRuleDetailBody } from "../../../components/quick-analysis/rules/detail-bodies/ColMatchRuleDetailBody";
import { InconsistentRuleDetailBody } from "../../../components/quick-analysis/rules/detail-bodies/InconsistentRuleDetailBody";
import { SensitiveRuleDetailBody } from "../../../components/quick-analysis/rules/detail-bodies/SensitiveRuleDetailBody";
import { ColumnCorrelationRuleDetailBody } from "../../../components/quick-analysis/rules/detail-bodies/ColCorrelationRuleDetailBody";
import { OutlierRuleDetailBody } from "../../../components/quick-analysis/rules/detail-bodies/OutlierRuleDetailBody";
import { customRule } from "./customRules";
import { CustomRuleDetailBody } from "../../../components/quick-analysis/rules/detail-bodies/CustomRuleDetailBody";
import { categoricalData } from "./categoricalData";
import { CategoricalDataDetailBody } from "../../../components/quick-analysis/rules/detail-bodies/CategoricalDataDetailBody";

type QualityRuleFunctionType = (
  dataContainer: UploadDataContainer,
  rule: RuleCandidate
) => QuickAnalysisIssue[];
export const QUALITY_RULE_FUNCTION_MAP: Record<TestFunctionName, QualityRuleFunctionType> = {
  stdDevOutlier,
  iqrOutlier,
  strPatterns,
  duplicates,
  sensitiveTest,
  missingValues,
  inconsistentDataType,
  columnCorrelation,
  columnMatching,
  customRule,
  categoricalData,
};
export type QualityFunctionComponent = React.FC<{ rule: RuleCandidate; toggleModal: () => void }>;

export const QUALITY_RULE_COMPONENT_MAP: Record<TestFunctionName, QualityFunctionComponent> = {
  stdDevOutlier: OutlierRuleDetailBody,
  iqrOutlier: OutlierRuleDetailBody,
  strPatterns: StrPatternRuleDetailBody,
  duplicates: DuplicateRuleDetailBody,
  sensitiveTest: SensitiveRuleDetailBody,
  missingValues: MissingRuleDetailBody,
  inconsistentDataType: InconsistentRuleDetailBody,
  columnCorrelation: ColumnCorrelationRuleDetailBody,
  columnMatching: ColumnMatchRuleDetailBody,
  customRule: CustomRuleDetailBody,
  categoricalData: CategoricalDataDetailBody,
};
